import { registerOnConsent } from "@rome2rio/tcf-api";
import { uuid } from "src/utils/uuid";

export type AdaraInit = {
  (eventMethod: "init", apiKey: string, dpId: number): void;
};
export type AdaraIdentity = {
  (
    eventMethod: "identity",
    identifier: {
      uid: string;
    }
  ): void;
};

const ADARA_CONSENT_PURPOSES_REQUIRED = [1, 3, 5];
const ADARA_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];
const ADARA_SALES_CONSENT_REQUIRED = true;
const ADARA_SHARING_CONSENT_REQUIRED = true;

const ADARA_API_KEY = "YjYzNGYwNWQtMjZlYi00ZmFlLWJiNTUtOWEyMTkwYzFmZTYy";
const ADARA_DP_ID = 2477;

export function registerAdaraConsent(isAdaraEnabled?: boolean) {
  registerOnConsent({
    onGiven: isAdaraEnabled ? loadAdaraConsentTag : () => {},
    onRevoked: disableAdara,
    gdpr: {
      consentPurposesRequired: ADARA_CONSENT_PURPOSES_REQUIRED,
      legitimateInterestPurposesRequired:
        ADARA_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
    },
    us: {
      saleConsentRequired: ADARA_SALES_CONSENT_REQUIRED,
      sharingConsentRequired: ADARA_SHARING_CONSENT_REQUIRED,
    },
  });
}

function loadAdaraConsentTag() {
  const adaraScript = document.createElement("script");

  adaraScript.type = "text/javascript";
  adaraScript.src = "https://js.adara.com/index.js";
  adaraScript.async = true;

  document.body.appendChild(adaraScript);

  if (window.adara.q === undefined) {
    window.adara.q = [];
  }

  // Initialise Adara, using API key & Data Partner ID.
  const initArr: Parameters<AdaraInit> = ["init", ADARA_API_KEY, ADARA_DP_ID];

  // Pass unique identifier for current user.
  const identityArr: Parameters<AdaraIdentity> = ["identity", { uid: uuid() }];

  // Init and identity must be the first objects in window.adara.q in order to
  // ensure subsequent events are able to be logged correctly to Adara.
  window.adara.q.unshift(initArr, identityArr);
}

function disableAdara() {
  // Only way to stop the running script is to reload the page
  window.location.reload();
}
