import type { QueryClient } from "@tanstack/react-query";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import type { Geocoded, GeocodedPlace } from "src/PrefetchData";
import type { ApiConfig } from "src/api/ApiConfig";
import type { SupportedLanguageCode } from "./language";
import { geocodeCanonical, getCacheKeyForGeocode } from "./hooks/useGeocode";

export function autocompleteToGeocodedPlace(
  autocompletePlace: AutocompletePlace,
  onComplete: (newPlace: GeocodedPlace) => void,
  languageCode: SupportedLanguageCode,
  queryClient: QueryClient,
  apiConfig: ApiConfig
) {
  // TODO: We shouldn't have to check for the "lat" property, there should be a separate cache for AutocompletePlace and GeocodedPlace.
  // Task: https://rome2rio.atlassian.net/browse/DAP-1978
  const cachedPlace =
    queryClient.getQueryData<AutocompletePlace | Geocoded>(
      getCacheKeyForGeocode(autocompletePlace.canonicalName, languageCode)
    ) ?? autocompletePlace;

  const isGeocoded = "lat" in cachedPlace;
  if (isGeocoded) {
    onComplete(cachedPlace as GeocodedPlace);
  } else {
    try {
      geocodeCanonical(
        apiConfig,
        autocompletePlace.canonicalName,
        languageCode,
        queryClient
      ).then((result) => {
        onComplete(result as GeocodedPlace);
      });
    } catch (error) {
      console.error(error);
    }
  }
}
