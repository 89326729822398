import type { FeatureConfig } from "../../FeatureConfig";
import { useExperimentConfig } from "../../../experiment/useExperimentConfig";
import { getFeatureConfigForExperimentConfig } from "./getFeatureConfigForExperimentConfig";

// Creates and returns the FeatureConfig based on the currently configured
// experiments.
export function useBackendExperimentsFeatures(): Partial<FeatureConfig> {
  const config = useExperimentConfig();
  return getFeatureConfigForExperimentConfig(config);
}
