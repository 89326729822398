import type { SearchResponse } from "src/api/SearchResponse";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { useLayout } from "src/utils/hooks/useLayout";
import { SearchResultListCard } from "./SearchResultListCard/SearchResultListCard";
import type { TripCardTypeProps } from "./TripCard";
import SimpleRouteCard from "./SimpleRouteCard/SimpleRouteCard";

type AnyTripCardProps =
  | TripCardTypeProps & {
      index: number;
      isDragging?: boolean;
      isPendingDragChanges?: boolean;
    };

export function AnyTripCard(cardProps: AnyTripCardProps) {
  const layout = useLayout();
  const { isMultiTrip } = useTripPlannerContext();
  const location = useTypedLocation();
  const screenKey = getScreenKey(location.hash);

  if (isMultiTrip || layout === "mobile") {
    return (
      <SimpleRouteCard
        {...cardProps}
        onboarding={!screenKey && cardProps.index === 0 && layout !== "mobile"}
      />
    );
  } else {
    return (
      <SearchResultListCard
        {...cardProps}
        searchResponse={cardProps.searchResponse as SearchResponse}
      />
    );
  }
}
