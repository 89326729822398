import { type SvgProps, useColor } from "./common";

export function NewTab(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 32 32">
      <title>{props.title}</title>
      <path
        d="M19.583 0H32v12.417h-3.583V6.083L11 23.5 8.5 21 25.917 3.583h-6.334V0zm8.834 28.417V16H32v12.417C32 30.333 30.333 32 28.417 32H3.583C1.583 32 0 30.333 0 28.417V3.583C0 1.667 1.583 0 3.583 0H16v3.583H3.583v24.834h24.834z"
        fill={tintColor}
      />
    </svg>
  );
}

export function NewTabRounded(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{props.title}</title>
      <path
        d="M1.75 3C1.75 2.30965 2.30965 1.75 3 1.75H4.5005C4.91471 1.75 5.2505 1.41421 5.2505 1C5.2505 0.585786 4.91471 0.25 4.5005 0.25H3C1.48122 0.25 0.25 1.48122 0.25 3V11.0013C0.25 12.5201 1.48122 13.7513 3 13.7513H11.0013C12.5201 13.7513 13.7513 12.5201 13.7513 11.0013V9.50083C13.7513 9.08662 13.4155 8.75083 13.0013 8.75083C12.5871 8.75083 12.2513 9.08662 12.2513 9.50083V11.0013C12.2513 11.6917 11.6917 12.2513 11.0013 12.2513H3C2.30965 12.2513 1.75 11.6917 1.75 11.0013V3Z"
        fill={tintColor}
      />
      <path
        d="M13.7378 1.00012C13.7378 0.801209 13.6588 0.610439 13.5181 0.469784C13.3775 0.329128 13.1867 0.250108 12.9878 0.250108H8.70213C8.28791 0.250108 7.95213 0.585895 7.95213 1.00011C7.95213 1.41432 8.28791 1.75011 8.70213 1.75011H11.1718L6.60278 6.30024C6.30928 6.59252 6.30829 7.06739 6.60058 7.36089C6.89286 7.65439 7.36774 7.65538 7.66124 7.36309L12.2378 2.80551L12.2377 5.28634C12.2377 5.70055 12.5735 6.03634 12.9877 6.03635C13.4019 6.03636 13.7377 5.70058 13.7377 5.28637L13.7378 1.00012Z"
        fill={tintColor}
      />
    </svg>
  );
}
