import { type SvgProps, useColor } from "./common";

export function SpeechBubble(props: SvgProps) {
  const tint = useColor(props.tint);

  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33334 2.66667C3.15653 2.66667 2.98696 2.73691 2.86194 2.86193C2.73691 2.98696 2.66668 3.15652 2.66668 3.33334V12.3905L4.19527 10.8619C4.3203 10.7369 4.48987 10.6667 4.66668 10.6667H12.6667C12.8435 10.6667 13.0131 10.5964 13.1381 10.4714C13.2631 10.3464 13.3333 10.1768 13.3333 10V3.33334C13.3333 3.15653 13.2631 2.98696 13.1381 2.86193C13.0131 2.73691 12.8435 2.66667 12.6667 2.66667H3.33334ZM1.91913 1.91912C2.2942 1.54405 2.80291 1.33334 3.33334 1.33334H12.6667C13.1971 1.33334 13.7058 1.54405 14.0809 1.91912C14.456 2.29419 14.6667 2.8029 14.6667 3.33334V10C14.6667 10.5304 14.456 11.0391 14.0809 11.4142C13.7058 11.7893 13.1971 12 12.6667 12H4.94282L2.47141 14.4714C2.28075 14.6621 1.994 14.7191 1.74489 14.6159C1.49577 14.5127 1.33334 14.2696 1.33334 14V3.33334C1.33334 2.8029 1.54406 2.2942 1.91913 1.91912Z"
        fill={tint}
        stroke={tint}
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
