import { type SvgProps, useColor } from "../common";

export function Globe(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fill={tintColor}
        fillRule="evenodd"
        d="M6.088 15.75a6.957 6.957 0 01-1.048-3h2.332c.032 1.053.133 2.062.278 3H6.088zm1.842 1.5h-.56c.216.19.444.369.683.532a23.24 23.24 0 01-.123-.532zm1.924 1.415a19.801 19.801 0 01-.387-1.415h5.06c-.13.483-.283.964-.464 1.441-.652.2-1.345.309-2.063.309-.749 0-1.47-.117-2.146-.335zm-.686-2.915a22.98 22.98 0 01-.295-3h6.241c-.008.98-.086 1.988-.259 3H9.168zm-.29-4.5h6.194a23.192 23.192 0 00-.389-3H9.227a19.111 19.111 0 00-.349 3zm.72-4.5h4.74a20.377 20.377 0 00-.458-1.495A7.006 7.006 0 0012 5c-.65 0-1.28.089-1.876.254-.203.494-.378.994-.527 1.496zm-1.372-.646c-.067.215-.13.43-.188.646H7.37c.268-.236.554-.453.856-.646zM7.699 8.25a20.745 20.745 0 00-.322 3H5.04a6.957 6.957 0 011.048-3h1.61zm8.677 7.5c.159-1.015.232-2.021.239-3h2.345a6.956 6.956 0 01-1.048 3h-1.536zm-.3 1.5c-.044.18-.09.36-.14.54.242-.166.474-.346.694-.54h-.553zm.133-9c.172.933.304 1.942.366 3h2.385a6.957 6.957 0 00-1.048-3h-1.703zm.421-1.5h-.745a24.17 24.17 0 00-.182-.692c.328.205.638.437.927.692zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}
