import type { SearchResponse } from "../api/SearchResponse";
import type { ExperimentConfig } from "./useExperimentConfig";
import { decompressExperimentConfig } from "./decompressExperimentConfig";
import { experimentDefinitions } from "./ExperimentDefinition";

export function createExperimentConfig(
  searchResponse?: SearchResponse
): ExperimentConfig | undefined {
  if (!searchResponse) {
    return undefined;
  }

  const compressedExperimentConfig =
    searchResponse?.analytics?.experiment_config ?? "";
  const experimentConfig = decompressExperimentConfig(
    compressedExperimentConfig
  );
  // The compressed experiment string in the search response omits experiments
  // that the user was not allocated to, so, we need to replace any experiments
  // that are missing from the search response with 'None' to indicate the user
  // is unallocated.
  return { ...allRegisteredExperimentsNone, ...experimentConfig };
}

const allRegisteredExperimentsNone = Object.keys(experimentDefinitions).reduce(
  (previousValue, experimentName) => {
    return { ...previousValue, [experimentName]: "None" };
  },
  {} as ExperimentConfig
);
