import {
  type History,
  type HistoryAction,
  clearAction,
  reduceAddAction,
  reduceRedoAction,
  reduceUndoAction,
} from "src/utils/historyReducer";
import {
  type Action,
  type TripPlannerDetails,
  tripPlannerActionReducer,
} from "../TripPlannerProvider";

/**
 * Some actions are not user-activated, so they shouldn't be added into the history.
 * For example, when the trip planner is first loaded, it will fetch the trip plan from the server.
 */
export const ignoredTypes: Action["type"][] = ["PASSIVE_UPDATE"];

/**
 * Some properties of the trip planner details should not be overwritten when reading from history.
 * For example, the id and slug should not be overwritten. In addition, if an id or slug is not set,
 * it should stay that way when reading from history.
 */
const persistedProperties: (keyof TripPlannerDetails)[] = [
  "id",
  "slug",
  "name",
];

export function tripPlannerHistoryReducer(
  state: History<TripPlannerDetails>,
  action: HistoryAction<Action>
) {
  let newHistory: History<TripPlannerDetails>;

  switch (action.type) {
    case "UNDO":
      newHistory = reduceUndoAction(state, persistedProperties);
      break;
    case "REDO":
      newHistory = reduceRedoAction(state, persistedProperties);
      break;
    case "SET_TRIP":
    case "CREATE_TRIP_FROM_SEARCH_TRIP":
      newHistory = clearAction(
        tripPlannerActionReducer([state.present], action as Action)[0]
      );
      break;
    default:
      if (!ignoredTypes.includes(action.type)) {
        newHistory = reduceAddAction(
          state,
          action,
          tripPlannerActionReducer,
          3
        );
      } else {
        newHistory = {
          ...state,
          present: tripPlannerActionReducer(
            [state.present],
            action as Action
          )[0],
        };
      }
      break;
  }

  return newHistory;
}
