import { type SvgProps, useColor } from "./common";

export function Pin(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fill={tintColor}
        stroke="transparent"
        d="M12 0C6.577 0 2 4.076 2 9.312c0 3.005 1.734 6.14 3.43 8.53 1.734 2.446 3.615 4.367 4.228 4.982l.086.088C10.108 23.288 10.797 24 12 24c1.2 0 1.89-.711 2.253-1.085a40.72 40.72 0 0 0 4.245-4.998c1.714-2.408 3.501-5.594 3.501-8.605C22 4.076 17.423 0 12 0zM4.495 9.312c0-3.67 3.263-6.817 7.505-6.817s7.505 3.147 7.505 6.817c0 2.17-1.358 4.797-3.039 7.158a38.24 38.24 0 0 1-3.89 4.592l-.103.104c-.065.066-.115.116-.172.17-.074.07-.121.107-.155.13a.211.211 0 0 1-.056.03.293.293 0 0 1-.09.01.295.295 0 0 1-.09-.01.218.218 0 0 1-.056-.03 1.268 1.268 0 0 1-.155-.13c-.058-.054-.108-.105-.175-.173l-.1-.1c-.58-.582-2.345-2.387-3.96-4.663-1.652-2.33-2.969-4.913-2.969-7.088zm7.508-2.31a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
      />
    </svg>
  );
}
