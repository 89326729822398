import { defineMessages } from "react-intl";

const messages = defineMessages({
  nightPer: {
    id: "hotel.nightPer",
    description:
      "Label for price when staying at hotel per night e.g. Price per night",
    defaultMessage: "Price per night",
  },
  nightSingle: {
    id: "hotel.nightSingle",
    description:
      "Label for price when staying at hotel for one night e.g. Price for 1 night",
    defaultMessage: "Price for {nightsAmount} night",
  },
  nightSingleShortened: {
    id: "hotel.nightSingleShortened",
    description:
      "Shorter label for price when staying at hotel for one night e.g. 1 night",
    defaultMessage: "{nightsAmount} night",
  },
  nightMultiple: {
    id: "hotel.nightMultiple",
    description:
      "Label for price when staying at hotel for multiple nights e.g. Price for 3 nights",
    defaultMessage: "Price for {nightsAmount} nights",
  },
  nightsMultipleShortened: {
    id: "hotel.nightMultipleShortened",
    description:
      "Shorter label for price when staying at hotel for multiple nights e.g. 3 nights",
    defaultMessage: "{nightsAmount} nights",
  },
  reviewSingle: {
    id: "hotel.reviewSingle",
    description:
      "Label for when there is only one review for hotel e.g. 1 review",
    defaultMessage: "{reviewAmount} review",
  },
  reviewMultiple: {
    id: "hotel.reviewMultiple",
    description:
      "Label for when there is multiple reviews for hotel e.g. 300 reviews",
    defaultMessage: "{reviewAmount} reviews",
  },
  ratingPassable: {
    defaultMessage: "Passable",
    id: "hotel.rating.passable",
    description: "Label on quality of hotel",
  },
  ratingExceptional: {
    defaultMessage: "Exceptional",
    id: "hotel.rating.exceptional",
    description: "Label on quality of hotel",
  },
  ratingSuperb: {
    defaultMessage: "Superb",
    id: "hotel.rating.superb",
    description: "Label on quality of hotel",
  },
  ratingFabulous: {
    defaultMessage: "Fabulous",
    id: "hotel.rating.fabulous",
    description: "Label on quality of hotel",
  },
  ratingVeryGood: {
    defaultMessage: "Very Good",
    id: "hotel.rating.veryGood",
    description: "Label on quality of hotel",
  },
  ratingGood: {
    defaultMessage: "Good",
    id: "hotel.rating.good",
    description: "Label on quality of hotel",
  },
  ratingPleasant: {
    defaultMessage: "Pleasant",
    id: "hotel.rating.pleasant",
    description: "Label on quality of hotel",
  },
  callToAction: {
    id: "hotel.cta",
    description:
      "Label for a button that directs users to booking site on a new tab",
    defaultMessage: "View deal",
  },
  freeCancellation: {
    id: "hotel.freeCancellation",
    description:
      "Promotion text used to inform user if a hotel has a free cancellation policy",
    defaultMessage: "Free cancellation",
  },
  breakfastIncluded: {
    id: "hotel.breakfastIncluded",
    description:
      "Promotion text used to inform user if a hotel has breakfast included",
    defaultMessage: "Breakfast included",
  },
  distanceToCityCentre: {
    id: "hotel.distanceToCityCentre",
    description:
      "Label for distance to city center from hotel e.g. 3 km to city center",
    defaultMessage: "{distance} to city center",
  },
  lessThanDistanceToCityCentre: {
    id: "hotel.lessThanDistanceToCityCentre",
    description:
      'A message stating that something is within a limit of the city center. e.g "less than 250 m to city center"',
    defaultMessage: "less than {distance} to city center",
  },
  getToCityCenter: {
    id: "hotel.getToCityCenter",
    description:
      "a title message announcing content about how to get to a CityCenter ",
    defaultMessage: "Get to the city center",
  },
  distanceToCentre: {
    id: "hotel.distanceToCentre",
    description: "Label for distance to center from hotel e.g. 3 km to center",
    defaultMessage: "{distance} to {place} center",
  },
  lessThanDistanceToCentre: {
    id: "hotel.lessThanDistanceToPlaceCentre",
    description:
      'A message stating that something is within a limit of the center of a place. e.g "less than 250 m to Place center"',
    defaultMessage: "less than {distance} to {place} center",
  },
  getToCenter: {
    id: "hotel.getToCenter",
    description:
      "a title message announcing content about how to get to a Center ",
    defaultMessage: "Get to {place} center",
  },
  distanceToAirport: {
    id: "hotel.distanceToAirport",
    description:
      "Label for distance to an airport from hotel e.g. 3 km to airport",
    defaultMessage: "{distance} to airport",
  },
  lessThanDistanceToAirport: {
    id: "hotel.lessThanDistanceToAirport",
    description:
      'A message stating that something is within a limit of the airport. e.g "less than 250 m to airport"',
    defaultMessage: "less than {distance} to airport",
  },
  getToAirport: {
    id: "hotel.getToAirport",
    description:
      "a title message announcing content about how to get to a Airport ",
    defaultMessage: "Get to the airport",
  },
  distanceToStation: {
    id: "hotel.distanceToStation",
    description:
      "Label for distance to a station from hotel e.g. 3 km to station",
    defaultMessage: "{distance} to station",
  },
  lessThanDistanceToStation: {
    id: "hotel.lessThanDistanceToStation",
    description:
      'A message stating that something is within a limit of the station. e.g "less than 250 m to station"',
    defaultMessage: "less than {distance} to station",
  },
  getToStation: {
    id: "hotel.getToStation",
    description:
      "a title message announcing content about how to get to a station",
    defaultMessage: "Get to the station",
  },
  distanceToPoint: {
    id: "hotel.distanceToPoint",
    description: "Label for distance to a point from hotel e.g. 3 km to point",
    defaultMessage: "{distance} to {place}",
  },
  lessThanDistanceToPoint: {
    id: "hotel.lessThanDistanceToPoint",
    description:
      'A message stating that something is within a limit of a point. e.g "less than 250 m to point"',
    defaultMessage: "less than {distance} to {place}",
  },
  getToPoint: {
    id: "hotel.getToPoint",
    description:
      "a title message announcing content about how to get to a point",
    defaultMessage: "Get to {place}",
  },
  mobileDeal: {
    defaultMessage: "Mobile Deal",
    id: "hotels.mobileDeal",
    description: "a label to displayed inside a tag",
  },
  percentOff: {
    defaultMessage: "{ percent } off",
    id: "hotels.percentOff",
    description: 'a percent off message. e.g: "30% off"',
  },
  /** @deprecated - previously used for the hotels transport integration (ACCOM-1136) */
  whatsNearby: {
    id: "hotel.whatsNearby",
    description:
      "Label for a dropdown display that shows user what is around the hotel.",
    defaultMessage: "What's nearby",
  },
});

export default messages;
