import type { KeyboardEvent } from "react";
import styled, { css } from "styled-components";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { type iconSize, color, spacing, fontSize } from "../../theme";
import type { MergeElementProps } from "../../utils/MergeElementProps";
import { useFocusState } from "../../FocusContext";
import { AutocompleteIcon } from "./AutocompleteIcon";

type Props = {
  results: AutocompletePlace[];
  onSelectPlace: (place: AutocompletePlace) => void;
  focusRef?: React.RefObject<HTMLLIElement>;
  applyLargeHorizontalPadding?: boolean;
  size?: "sm" | "md";
  iconSize?: keyof typeof iconSize;
  isInputFocused: boolean;
  prependItem?: React.ReactNode;
};

export function AutocompleteList({
  results,
  isInputFocused,
  prependItem,
  ...other
}: Props) {
  return (
    <ol>
      {isInputFocused && prependItem ? prependItem : null}
      {isInputFocused &&
        results.map((result, index) => {
          return (
            <AutocompleteItem
              key={result.canonicalName}
              place={result}
              index={index}
              {...other}
            />
          );
        })}
    </ol>
  );
}

type AutocompleteItemProps = MergeElementProps<
  "li",
  {
    index: number;
    place: AutocompletePlace;
    onSelectPlace: (place: AutocompletePlace) => void;
    focusRef?: React.RefObject<HTMLLIElement>;
    applyLargeHorizontalPadding?: boolean;
    size?: "sm" | "md";
    iconSize?: keyof typeof iconSize;
  }
>;

export function AutocompleteItem({
  index,
  place,
  onSelectPlace,
  applyLargeHorizontalPadding,
  size,
  iconSize,
  focusRef,
  ...elementProps
}: AutocompleteItemProps) {
  const { focusedElement, onFocusChanged } = useFocusState();

  // We do index + 1 because 0 is the SearchInput, so, the 0th index of the
  // autocomplete list is at focus index 1.
  const isFocused = focusedElement.index === index + 1;

  function onKeyDown(event: KeyboardEvent<HTMLLIElement>) {
    const focusedIndex = focusedElement.index;
    switch (event.key) {
      case "ArrowDown":
        onFocusChanged({
          ...focusedElement,
          index: focusedIndex + 1,
        });
        break;
      case "ArrowUp":
        onFocusChanged({
          ...focusedElement,
          index: focusedIndex - 1,
        });
        break;
    }
  }

  return (
    <StyledListItem
      onClick={() => onSelectPlace(place)}
      applyLargeHorizontalPadding={applyLargeHorizontalPadding}
      onKeyDown={onKeyDown}
      isFocused={isFocused}
      data-testid={isFocused && "focused-autocomplete-item"}
      size={size}
      ref={isFocused ? focusRef : undefined}
      aria-label={place.longName}
      aria-selected={isFocused}
      {...elementProps}
    >
      <AutocompleteIcon size={iconSize} kind={place.kind} />
      <ResultTitle size={size}>{place.longName}</ResultTitle>
    </StyledListItem>
  );
}

type ItemProps = {
  applyLargeHorizontalPadding?: boolean;
  size?: "sm" | "md";
  isFocused: boolean;
};

const StyledListItem = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  align-content: flex-start;
  text-align: left;
  padding: ${(props) =>
    `${spacing.lg} ${
      props.applyLargeHorizontalPadding ? spacing.xxl : spacing.sm
    }`};

  ${(props) =>
    props.size === "sm" &&
    css`
      padding: ${spacing.lg} ${spacing.xs};
      font-size: ${fontSize.h6};
    `}

  cursor: pointer;
  -webkit-tap-highlight-color: ${color.transparent};

  // Fake a focus effect rather than allowing actual focus.
  background-color: ${(props) =>
    props.isFocused
      ? props.theme.searchBar.autocompleteList.backgroundHover
      : "initial"};

  &:hover {
    background-color: ${(props) =>
      props.theme.searchBar.autocompleteList.backgroundHover};

    @media (hover: none) {
      background-color: ${(props) =>
        props.isFocused
          ? props.theme.searchBar.autocompleteList.backgroundHover
          : "initial"};
    }
  }

  &:active {
    background-color: ${(props) =>
      props.theme.searchBar.autocompleteList.backgroundHover};
  }
`;

const ResultTitle = styled.span<{ size?: "sm" | "md" }>`
  margin-left: ${spacing.lg};
  color: ${(props) => props.theme.searchBar.autocompleteList.text};

  ${(props) =>
    props.size === "sm" &&
    css`
      margin-left: ${spacing.md};
    `}
`;
