import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useFeature } from "src/feature/useFeature";
import { hashChange } from "../location/hashChange";
import { getHash } from "../url";
import { useLayout } from "./useLayout";
import useSearch from "./useSearch";
import { useTypedLocation } from "./useTypedLocation";

// useHash ensures the correct initial hash is set when the page loads.
// This can be used to override the initial hash- for example: when running
// the TripsAsCore experiment, we wanted to route all users straight to "#trips"
// unless they had a different hash in their URL.
export function useHash() {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const isDesktop = layout !== "mobile";
  const { searchResponse } = useSearch();
  const returnToCore = useFeature("ReturnToCore");

  useEffect(() => {
    if (returnToCore) return;
    const currentHash = getHash(location.hash, isDesktop, searchResponse);
    if (currentHash !== location.hash) {
      navigate(hashChange(currentHash, location), { replace: true });
    }
  }, [isDesktop, location, navigate, searchResponse, returnToCore]);
}
