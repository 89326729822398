import type { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import type { SearchResponse } from "../api/SearchResponse";
import { vehicleFromSegment } from "./adapters/vehicle";

/**
 * Returns undefined if there is not a single major segment that is a flight.
 * Returns segmentIndexInRoute of the single major flight segment if there is one.
 */

export function getSingleMajorFlightSegment(
  searchResponse?: SearchResponse | PartialSearchResponse,
  routeIndex?: number
): number | undefined {
  if (!searchResponse || routeIndex === undefined) return undefined;

  const route = searchResponse.routes[routeIndex];

  if (!route) {
    // routeIndex was likely out-of-bounds.
    return undefined;
  }

  const majorSegments = route.segments
    .map((segmentIndex, originalSegmentInRouteIndex) => {
      return {
        originalIndex: originalSegmentInRouteIndex,
        segment: searchResponse.segments[segmentIndex],
      };
    })
    .filter((s) => s.segment.isMajor);

  if (majorSegments.length > 1) {
    return undefined;
  }

  const vehicle = vehicleFromSegment(searchResponse, majorSegments[0].segment);

  if (vehicle.kind !== "plane") return undefined;

  return majorSegments[0].originalIndex;
}

export function getMajorFlightSegmentIndex(
  searchResponse?: SearchResponse | PartialSearchResponse,
  routeIndex?: number
): number | undefined {
  if (!searchResponse || routeIndex === undefined) return undefined;

  const route = searchResponse.routes[routeIndex];

  if (!route) {
    // routeIndex was likely out-of-bounds.
    return undefined;
  }

  const majorSegments = route.segments
    .map((segmentIndex, originalSegmentInRouteIndex) => {
      return {
        originalIndex: originalSegmentInRouteIndex,
        segment: searchResponse.segments[segmentIndex],
      };
    })
    .filter((s) => s.segment.isMajor);

  const flightSegmentIndex = majorSegments.findIndex(
    (majorSegment) =>
      vehicleFromSegment(searchResponse, majorSegment.segment).kind === "plane"
  );

  if (flightSegmentIndex === -1) {
    return undefined;
  }

  return majorSegments[flightSegmentIndex]?.originalIndex;
}
