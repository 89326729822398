import { useIntl } from "react-intl";
import { Rome2rioLogo } from "src/svg/Rome2rioLogo";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { font_size, font_weight } from "src/design-system/tokens/typography";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { ArrowBackward } from "../../svg/ArrowBackward";
import { useTheme } from "../../utils/hooks/useTheme";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import messages from "./Navbar.messages";

type NavbarProps = {
  headingText?: string;
  onBackClick?: () => void;
};

export function Navbar({ headingText, onBackClick }: NavbarProps) {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <HeaderContainer>
      <Header>
        {onBackClick && (
          <BackButton
            inline
            size="large"
            aria-label={intl.formatMessage(messages.backLabel)}
            backgroundColor="n10"
            textColor="primaryOnLight"
            onClick={handleClick(onBackClick)}
            leftIcon={<ArrowBackward tint={theme.navbar.iconTint} />}
          />
        )}
        <HeadingText>{headingText}</HeadingText>
      </Header>
    </HeaderContainer>
  );
}

export function ErrorNavbar() {
  return (
    <HeaderContainer>
      <Header>
        <SiteLogo />
      </Header>
    </HeaderContainer>
  );
}

function SiteLogo() {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <HeadingWrapper>
      <a href="/" aria-label={intl.formatMessage(messages.logoLabel)}>
        <StyledIcon size="md">
          <Rome2rioLogo title="logo" tint={theme.navbar.iconTint} />
        </StyledIcon>
      </a>
    </HeadingWrapper>
  );
}

function handleClick(callback: () => void) {
  return () => {
    sendAnalyticsInteractionEvent("TopNav", "Click:BackButton");
    callback();
  };
}

const headerHeight = "44px";

const HeaderContainer = styled.div`
  display: inline;
`;

const Header = styled.header`
  height: ${headerHeight};
  text-align: center;
  line-height: ${headerHeight};
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  z-index: 50;
  background-color: ${(props) => props.theme.navbar.background};
  color: ${(props) => props.theme.navbar.text};
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.05);
`;

const HeadingWrapper = styled.h1`
  display: inline-block;
`;

const HeadingText = styled.h1`
  font-size: ${font_size.text_sm};
  font-weight: ${font_weight.normal};
  padding: 0 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: ${headerHeight};
`;

const BackButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  /* Overwrite default button padding. */
  padding: 0 ${spacing.xl};
  /* Overwrite default button height -1 so we can see the border. */
  height: calc(${headerHeight} - 1px);
  min-height: calc(${headerHeight} - 1px);
  line-height: ${headerHeight};
`;

const StyledIcon = styled(Icon)`
  width: 85px;
  height: 42px;
`;
