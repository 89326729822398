import { Skeleton } from "src/components/Skeleton/Skeleton";
import { spacing } from "src/theme";
import { useLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import color, { primitive } from "src/design-system/tokens/color";
import { PinnedPillsBarSkeleton } from "./PinnedPillsBarSkeleton";
import { ListCardDesktopLoading } from "./Cards/ListCardDesktopLoading";
import { ListCardMobileLoading } from "./Cards/ListCardMobileLoading";

export default function HotelsScreenLoading() {
  const isMobile = useLayout() === "mobile";

  return (
    <>
      {isMobile ? (
        <>
          <MobileSearch>
            <BlockWhite />
            <Skeleton height={32} />
          </MobileSearch>
          <LoadingHotels />
        </>
      ) : (
        <>
          <DesktopSearch>
            {/* Breadcrumb */}
            <Skeleton height={60} width={120} />
            {/* Header text */}
            <Skeleton height={80} width={220} />
            <PinnedPillsBarSkeleton />
          </DesktopSearch>
          <DesktopBody>
            <LoadingHotels />
          </DesktopBody>
        </>
      )}
    </>
  );
}

const DesktopSearch = styled.div`
  height: 90px;
  padding: 16px;
  background-color: ${color.bg.fill.hover};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const MobileSearch = styled.div`
  margin-top: 69px;
  min-height: 94px;
  padding: 10px 16px;
  background-color: ${color.bg.fill.fill};
  box-shadow: 0px ${spacing.sm} ${spacing.sm} ${primitive.neutral_1600 + "22"};
`;
const BlockWhite = styled.div`
  background-color: white;
  min-height: 42px;
`;

const DesktopBody = styled.div`
  background-color: ${color.bg.fill.hover};
`;

export function LoadingHotels() {
  const isMobile = useLayout() === "mobile";
  const Component = isMobile ? ListCardMobileLoading : ListCardDesktopLoading;

  return (
    <HotelListWrapper>
      {Array(12)
        .fill(undefined)
        .map((_, i) => (
          <Component key={i} />
        ))}
    </HotelListWrapper>
  );
}

const HotelListWrapper = styled.ol`
  display: flex;
  flex-direction: column;
  padding: ${spacing.xl};
  gap: ${spacing.xl};
  background-color: ${color.bg.surface.active};
`;
