import { type IntlShape, useIntl } from "react-intl";
import { operatorDetailsRedesignMessages } from "./Frequency.messages";

type Props = {
  frequencyPerWeek: number;
  isBookable?: boolean;
  className?: string;
};

export function Frequency(props: Props) {
  const intl = useIntl();
  return (
    <span className={props.className}>
      {formatFrequency(props.frequencyPerWeek, intl)}
    </span>
  );
}

// Note: all frequency values are from the map product
export function formatFrequency(frequencyPerWeek: number, intl: IntlShape) {
  if (frequencyPerWeek <= 0) {
    return intl.formatMessage(operatorDetailsRedesignMessages.on_demand);
  } else if (frequencyPerWeek <= 1) {
    return intl.formatMessage(operatorDetailsRedesignMessages.weekOnce);
  } else if (frequencyPerWeek === 2) {
    return intl.formatMessage(operatorDetailsRedesignMessages.weekTwice);
  } else if (frequencyPerWeek < 7) {
    return intl.formatMessage(operatorDetailsRedesignMessages.weekMultiple, {
      frequencyPerWeek,
    });
  }

  let dayFrequency = Math.round(frequencyPerWeek / 7.0);
  if (dayFrequency === 1) {
    return intl.formatMessage(operatorDetailsRedesignMessages.dailyOnce);
  } else if (dayFrequency === 2) {
    return intl.formatMessage(operatorDetailsRedesignMessages.dailyTwice);
  } else if (dayFrequency < 6) {
    return intl.formatMessage(operatorDetailsRedesignMessages.dailyMultiple, {
      frequencyPerDay: dayFrequency,
    });
  } else if (dayFrequency < 9) {
    return intl.formatMessage(operatorDetailsRedesignMessages.hours, {
      hours: 4,
    });
  } else if (dayFrequency < 11) {
    return intl.formatMessage(operatorDetailsRedesignMessages.hours, {
      hours: 3,
    });
  } else if (dayFrequency < 13) {
    return intl.formatMessage(operatorDetailsRedesignMessages.hours, {
      hours: 2,
    });
  }

  let hourFrequency = Math.round(frequencyPerWeek / 7.0 / 24.0);
  if (hourFrequency === 1) {
    return intl.formatMessage(operatorDetailsRedesignMessages.hourly);
  } else if (hourFrequency === 2) {
    return intl.formatMessage(operatorDetailsRedesignMessages.minutes, {
      minutes: 30,
    });
  } else if (hourFrequency === 3) {
    return intl.formatMessage(operatorDetailsRedesignMessages.minutes, {
      minutes: 20,
    });
  } else if (hourFrequency === 4 || hourFrequency === 5) {
    return intl.formatMessage(operatorDetailsRedesignMessages.minutes, {
      minutes: 15,
    });
  } else if (hourFrequency <= 10) {
    return intl.formatMessage(operatorDetailsRedesignMessages.minutes, {
      minutes: 10,
    });
  }

  return intl.formatMessage(operatorDetailsRedesignMessages.minutes, {
    minutes: 5,
  });
}
