import { animated } from "@react-spring/web";
import {
  type ComponentProps,
  type ReactElement,
  type SyntheticEvent,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Backdrop } from "../Backdrop/Backdrop";
import { Portal } from "../Portal/Portal";
import { Fade } from "../Transition/Fade";

// The order prop sets the visibility priority of the modal on the page.
// Higher values appear in front of lower values. The default value is 1
type Props = {
  open: boolean;
  onBackdropClicked?: ComponentProps<typeof Backdrop>["onClick"];
  children: ReactElement<{ onEnter(): void; onExited(): void }>;
  order?: number;
  displayAtBase?: boolean;
  disableBackgroundScroll?: boolean;
};

export function Modal(props: Props) {
  const [exited, setExited] = useState(true);
  // We need to check if the component is mounted before updating the state as a workaround to
  // suppress the react warning "Can't perform a React state update on an unmounted component..."
  // TODO : This warning has been removed in React 18! Once we update we can remove the check.
  // See discussion here: https://github.com/reactwg/react-18/discussions/82
  const isMounted = useRef(false);

  const {
    onBackdropClicked,
    open,
    displayAtBase,
    order,
    children,
    disableBackgroundScroll = true,
  } = props;
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (document.documentElement && document.body) {
      if (open && disableBackgroundScroll) {
        document.documentElement.style.overflow = "hidden";
      }
      if (open) {
        document.body.style.touchAction = "none";
      } else {
        document.documentElement.style.overflow = "";
        document.body.style.touchAction = "";
      }
      // Called on unmount to cleanup any styles.
      return () => {
        document.documentElement.style.overflow = "";
        document.body.style.touchAction = "";
      };
    }
  }, [open, disableBackgroundScroll]);

  if (!open && exited) {
    return null;
  }

  function handleEnter() {
    if (isMounted.current) {
      setExited(false);
    }
  }

  function handleExit() {
    if (isMounted.current) {
      setExited(true);
    }
  }

  return (
    <Portal>
      <Root
        $open={open}
        $order={order ?? 1}
        $displayAtBase={displayAtBase}
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
      >
        {!displayAtBase && (
          <Fade open={open} onEnter={handleEnter} onExited={handleExit}>
            <AnimatedBackdrop open={open} onClick={onBackdropClicked} />
          </Fade>
        )}
        {cloneElement(children, {
          onEnter: handleEnter,
          onExited: handleExit,
        })}
      </Root>
    </Portal>
  );
}

const Root = styled.div<{
  $open: boolean;
  $order: number;
  $displayAtBase?: boolean;
}>`
  position: fixed;
  right: 0;
  bottom: 0;
  top: ${({ $displayAtBase }) => ($displayAtBase ? "auto" : "0")};
  left: 0;
  z-index: ${({ $order }) => 10000 + $order};
  pointer-events: ${({ $open }) => ($open ? "initial" : "none")};
`;

const AnimatedBackdrop = animated(Backdrop);
