import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { Button } from "src/design-system/components/Button/Button";
import { Icon } from "src/design-system/components/Icon/Icon";
import { Typography } from "src/design-system/components/Typography/Typography";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { useTripSearchResponse } from "src/domain/TripPlanner/hooks/useTripSearchResponse";
import type { GeocodedPlace } from "src/PrefetchData";
import { RotatingChevron } from "src/svg/RotatingChevron";
import { Plus } from "src/svg/tripplanner/Plus";
import { fontSize, spacing } from "src/theme";
import styled from "styled-components";
import { TopOverviewMessages } from "./TopOverview.messages";

type TopOverviewProps = {
  bannerImgSrc: string;
  title: string;
  description: string;
};

export default function TopOverview({
  bannerImgSrc,
  title,
  description,
}: TopOverviewProps) {
  const intl = useIntl();
  const [isViewMore, setIsViewMore] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const { dispatch } = useTripPlannerContext();
  const { tripDestination } = useTripSearchResponse();

  useEffect(() => {
    if (descriptionRef.current) {
      const isOverflowing =
        descriptionRef.current.scrollHeight >
        descriptionRef.current.clientHeight;
      setShowViewMore(isOverflowing);
    }
  }, [description]);

  return (
    <TopOverviewDiv>
      <TopDiv>
        <BannerImg
          {...{
            // TODO: With react 19 we can use the fetchPriority attribute. See: https://github.com/facebook/react/issues/27233
            fetchpriority: "high",
          }}
          loading="lazy"
          src={bannerImgSrc}
          alt={title}
          onClick={() => {
            sendAnalyticsInteractionEvent(
              "Destination",
              "Click:HeroImage",
              tripDestination?.canonicalName
            );
          }}
        />
      </TopDiv>
      <BottomDiv>
        <Heading>{title}</Heading>
        {tripDestination && (
          <CTAButton
            onPress={() => {
              sendAnalyticsInteractionEvent(
                "Destination",
                "Click:AddToTrip",
                tripDestination.canonicalName
              );
              dispatch({
                type: "ADD_DESTINATION",
                destination: tripDestination as GeocodedPlace,
              });
            }}
          >
            <Plus tint="white" />
            {intl.formatMessage(TopOverviewMessages.addToTrip)}
          </CTAButton>
        )}

        <DescriptionWrapper>
          <Description
            as="p"
            $isViewMore={isViewMore}
            ref={descriptionRef}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {!isViewMore && showViewMore && <Fade />}
          {showViewMore && (
            <Button
              size="small"
              variant="subtle"
              onPress={() => {
                sendAnalyticsInteractionEvent(
                  "Destination",
                  isViewMore ? "Click:ViewLess" : "Click:ViewMore"
                );
                setIsViewMore((prev) => !prev);
              }}
            >
              {intl.formatMessage(
                isViewMore
                  ? TopOverviewMessages.viewLess
                  : TopOverviewMessages.viewMore
              )}
              <Icon size="small">
                <RotatingChevron
                  direction={isViewMore ? "up" : "down"}
                  tint="grey5"
                />
              </Icon>
            </Button>
          )}
        </DescriptionWrapper>
      </BottomDiv>
    </TopOverviewDiv>
  );
}

export function TopOverviewSkeleton() {
  return (
    <TopOverviewDiv>
      <TopDiv>
        <Skeleton height="100%" width="100%" />
      </TopDiv>
      <BottomDiv>
        <Heading>
          <Skeleton height="32px" width="100px" />
        </Heading>
        <Skeleton height="36px" width="108px" />

        <DescriptionWrapper>
          <Description $isViewMore={false} as="div">
            <Skeleton
              borderRadius="rounded_sm"
              margin={`${spacing.xs} 0 0 0`}
              height={spacing.xxl}
              width="100%"
            />
            <Skeleton
              margin={`${spacing.xs} 0 0 0`}
              height={spacing.xxl}
              width="100%"
            />
            <Skeleton
              margin={`${spacing.xs} 0 0 0`}
              height={spacing.xxl}
              width="100%"
            />
            <Skeleton
              margin={`${spacing.xs} 0 ${spacing.xs} 0`}
              height={spacing.xxl}
              width="100%"
            />
          </Description>
          <Skeleton
            margin={`${spacing.sm} 0 0 0`}
            height="20px"
            width="100px"
          />
        </DescriptionWrapper>
      </BottomDiv>
    </TopOverviewDiv>
  );
}

const TopOverviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
`;

const TopDiv = styled.div`
  position: relative;
  height: 204px;
`;

const BannerImg = styled.img`
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.xxl};
  padding: 20px ${spacing.xxl};
`;

const Heading = styled.h1`
  font-size: ${fontSize.h1};
  line-height: ${spacing.xxxl};
`;

const CTAButton = styled(Button)`
  width: fit-content;
`;

const DescriptionWrapper = styled.div`
  position: relative;
  width: 100%;
  button {
    position: relative;
    z-index: 1;
    margin-left: -${spacing.sm};
  }
`;

const Description = styled(Typography)<{ $isViewMore: boolean }>`
  position: relative;
  line-height: ${spacing.xxl};
  max-height: ${(props) =>
    props.$isViewMore
      ? "none"
      : `calc(${spacing.xxl} * 4)`}; // 4 line clamp based on line height
  overflow: hidden;
  margin: 0;
`;

const Fade = styled.div`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(to bottom, transparent, white);
`;
