import { cloneElement } from "react";
import { useTransition } from "@react-spring/web";
import type { TransitionProps } from "./TransitionProps";

type Props = {
  /** This is the % offset the element will have at the start of the animation. Defaults to 100.  */
  slideAmount: number;
  /** The final left position of a stackedNavSlide */
  left: number;
} & TransitionProps;

export function StackedNavSlide(props: Props) {
  const {
    open,
    onEnter,
    onExited,
    children,
    slideAmount,
    config,
    immediate,
    left,
  } = props;

  const stackedNavSlide = useTransition(open, {
    immediate,
    from: {
      left: `${-slideAmount}px`,
      opacity: 0,
    },
    enter: { left: `${left}px`, opacity: 1 },
    leave: {
      left: `${-slideAmount}px`,
      opacity: 0,
      onRest: () => {
        if (!open) {
          onExited && onExited();
        }
      },
    },
    onStart: () => {
      if (open) {
        onEnter && onEnter();
      }
    },
    config,
  });

  return stackedNavSlide(
    (style, item, { key }) => item && cloneElement(children, { style, key })
  );
}
