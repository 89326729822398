import { type SvgProps, useColor } from "./common";

/**
 * @deprecated Use the ChevronDown svg instead
 */

export function ChevronDown(props: SvgProps) {
  const tintColor = useColor(props.tint);
  return (
    <svg fill="none" viewBox="0 0 32 32">
      <title>{props.title}</title>
      <path
        d="M2 9.75l14.046 12.5L30 9.75"
        stroke={tintColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
