import { registerOnConsent } from "@rome2rio/tcf-api";
import {
  metaPixel,
  META_CONSENT_PURPOSES_REQUIRED,
  META_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
  META_SHARING_CONSENT_REQUIRED,
  META_SALE_CONSENT_REQUIRED,
} from "src/data-partners/meta/metaPixel";

const IS_PRODUCTION = import.meta.env.PROD;

export function registerMetaPixel() {
  registerOnConsent({
    onGiven: () => {
      if (!IS_PRODUCTION) {
        console.log("Meta Pixel is not loaded in development");
        return;
      }
      metaPixel.initPixel();
      metaPixel.grantMetaConsent();
    },
    onRevoked: metaPixel.revokeMetaConsent,
    gdpr: {
      consentPurposesRequired: META_CONSENT_PURPOSES_REQUIRED,
      legitimateInterestPurposesRequired:
        META_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
    },
    us: {
      saleConsentRequired: META_SALE_CONSENT_REQUIRED,
      sharingConsentRequired: META_SHARING_CONSENT_REQUIRED,
    },
  });
}
