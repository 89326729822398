import type { NavigateFunction } from "react-router";
import type { LocationState, TypedLocation } from "../hooks/useTypedLocation";
import { hashAndStateChange } from "./hashAndStateChange";

export function navigateToNewStateHash(
  navigate: NavigateFunction,
  newState: Partial<LocationState>,
  newHash: string | undefined,
  current: TypedLocation,
  replace?: boolean
) {
  const newLocation = hashAndStateChange(newHash, newState, current);
  navigate(newLocation, { state: newLocation.state, replace: !!replace });
}
