import type { SuccessfulDestinationInfoResponse } from "src/api/DestinationInfoResponse";
import createAttractionViewModel from "./AttractionList/createAttractionViewModel";

type DestinationViewModel = {
  imageUrl: string;
  canonicalName: string;
  title: string;
  description: string;
  attractions: ReturnType<typeof createAttractionViewModel>[];
};

export default function createDestinationViewModel(
  requestCanonicalName: string,
  destinationInfo: SuccessfulDestinationInfoResponse
): DestinationViewModel {
  return {
    imageUrl: getStaticDestinationURL(requestCanonicalName),
    canonicalName: destinationInfo.destination.city.canonicalName,
    title: destinationInfo.destination.displayName,
    description: destinationInfo.destination.cityDescription,
    attractions: destinationInfo.attractions.map(createAttractionViewModel),
  };
}

function getStaticDestinationURL(canonicalName: string) {
  return `https://www.rome2rio.com/images/destinations/recommendations/${canonicalName}_lg.jpg`;
}
