import type { GeocodedPlace } from "src/PrefetchData";
import useSearch from "src/utils/hooks/useSearch";
import { useTripGeocode } from "src/utils/hooks/useTripGeocode";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { useSearchParamPlaces } from "../../../utils/hooks/useSearchParamPlaces";
import type { TripPlanWithID } from "../util/api";
import { useTripIdPlaces } from "./useTripId";

export function useTripURLGeocodedPlaces(): {
  places: GeocodedPlace[];
  tripIdData: TripPlanWithID | undefined;
  isFromSearchParam: boolean;
} {
  const { getPlacesFromSearchParams } = useSearchParamPlaces();
  const {
    data: tripIdData,
    places: tripIdPlaces,
    isUsingTripId,
  } = useTripIdPlaces();
  const { origin, destination } = useSearch();

  const searchParamPlaces = getPlacesFromSearchParams();
  const geocodedPlaces = useTripGeocode(
    searchParamPlaces.length
      ? searchParamPlaces
      : [origin, destination]
          .filter((place) => place !== undefined)
          .map((place) => place.canonicalName),
    !isUsingTripId
  );

  // URL place priority from highest to lowest: tripId > search parameter > path parameter
  return {
    isFromSearchParam: searchParamPlaces.length > 0,
    places: isUsingTripId ? tripIdPlaces : geocodedPlaces,
    tripIdData,
  };
}

export function useIsTripCreated() {
  const location = useTypedLocation();
  const searchParams = new URLSearchParams(location.search);
  const localTrip = searchParams.get("search");
  const remoteTrip = searchParams.get("tripId");
  return !!(localTrip ?? remoteTrip);
}
