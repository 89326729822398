import type { Place, SearchResponse } from "src/api/SearchResponse";
import { useTripSearchResponse } from "src/domain/TripPlanner/hooks/useTripSearchResponse";
import { useUnpairedDestination } from "src/domain/TripPlanner/hooks/useUnpairedDestination";
import type { Geocoded } from "src/PrefetchData";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { isValidRouteOrSegment } from "src/domain/SegmentScreen/useEnsureValidSegment";
import type { TripPlannerDetails } from "src/domain/TripPlanner/TripPlannerProvider";
import {
  destinationPlaceFromRoute,
  destinationPlaceFromSearch,
  destinationPlaceFromSegment,
} from "../adapters/place";
import { type GeoKind, canShowHotelsForPlace } from "../geoKind";
import { getRouteIndexFromHash } from "../location/routeIndexFromHash";
import { getSegmentIndexFromHash } from "../location/segmentIndexFromHash";
import { vehicleFromSegment } from "../adapters/vehicle";
import { transitModeFromVehicleKind } from "../adapters/transitMode";
import type { Mode } from "../types/mode";
import { useIsTripScreen } from "./useIsTripScreen";
import useSearch, { type SearchPlace } from "./useSearch";
import { useTypedLocation } from "./useTypedLocation";
import { getTransportKeyFromHash } from "./useTripTransportIndex";

// TODO: Ideally, information like the active route/segment should be passed
// to the HotelsScreen as props as part of the routing process, rather than
// the hotel screen parsing url info.

/**
 * Gets the destination we are searching hotels for. To determine this we have to check
 * a few different sources, namely:
 *  - A solo destination url style (e.g. /map/Paris/Berlin#trips/hotels/Paris)
 *  - If we're in a return flow, use the origin
 *  - Whether we're in the trip planner or the non-trip planner search
 *
 * Additionally, once the destination is determined, if we find it's a region, state, island or country (e.g. Queensland),
 * we try to choose an appropriate city based on the first available route.
 */
export function useHotelDestinationFromUrl():
  | SearchPlace
  | Place
  | Geocoded
  | undefined {
  const location = useTypedLocation();
  const { searchResponse } = useSearch();
  const { tripSearchResponse } = useTripSearchResponse();
  const { tripPlannerDetails } = useTripPlannerContext();
  const soloDestination = useUnpairedDestination();
  const isTripScreen = useIsTripScreen();

  // If it's a single location, for now that means it's the starting point on the journey, so use the city/location centre.
  if (isTripScreen && soloDestination) {
    return soloDestination;
  }

  const activeSearchResponse = isTripScreen
    ? tripSearchResponse
    : searchResponse;

  if (!activeSearchResponse) {
    return undefined;
  }

  // Note: We use the destination from search as the geocoded
  // dest may not have lat/lng coordinates if it's an
  // injected AutocompleteResult.
  const activeDestination = destinationPlaceFromSearch(activeSearchResponse);

  // Get the active route index if it exists
  const { routeIndex } = getActiveTransportInfo(
    activeSearchResponse,
    location.hash,
    isTripScreen ? tripPlannerDetails : undefined
  );

  const hotelDestination = getHotelAppropriateDestination(
    activeSearchResponse,
    activeDestination,
    routeIndex
  );

  // Note: This is a temporary workaround whilst our test mocks expect hotelList is always called with
  // a canonicalName. We will remove this once we get a fix for this issue. ACCOM-1495
  return {
    canonicalName: activeDestination.canonicalName,
    ...hotelDestination,
  };
}

export type ArrivalPlace = Place & { transitMode: Mode };

/**
 * Gets the last point in the journey for the active transport selection. This will
 * be the point where the user arrives at relative to their selected destination.
 *
 * Example: A user who takes the train from Melbourne to Sydney
 *          will have Central Station, Sydney as their point of arrival
 *
 * Example: A user who is travelling from Melbourne to Sydney but
 *          has not selected a route yet will have no point of arrival.
 *
 * The path chosen is based, in order, on the first of these that exists:
 *  - The provided route (& segment) index
 *  - The route (& segment) hash parameters
 *  - The trip planner hash transport key
 *
 * If the user has not selected a route/segment, the search response has no routes,
 * this is the first trip stop in the trip planner, or the provided route/segment is invalid,
 * then undefined is returned.
 */
export const usePointOfArrival = (
  routeIndex?: number,
  routeSegmentIndex?: number
): ArrivalPlace | undefined => {
  const location = useTypedLocation();
  const { searchResponse } = useSearch();
  const isTripScreen = useIsTripScreen();
  const { tripSearchResponse } = useTripSearchResponse();
  const { tripPlannerDetails } = useTripPlannerContext();

  const soloDestination = useUnpairedDestination();

  if (isTripScreen && soloDestination) {
    return undefined;
  }

  const activeSearchResponse = isTripScreen
    ? tripSearchResponse
    : searchResponse;

  if (!activeSearchResponse) {
    return undefined;
  }

  if (
    routeIndex !== undefined &&
    !isValidRouteOrSegment(activeSearchResponse, routeIndex, routeSegmentIndex)
  ) {
    return undefined;
  }

  if (routeIndex === undefined) {
    // Fall back to the active route/segment
    ({ routeIndex, routeSegmentIndex } = getActiveTransportInfo(
      activeSearchResponse,
      location.hash,
      isTripScreen ? tripPlannerDetails : undefined
    ));

    if (routeIndex === undefined) {
      return undefined;
    }
  }

  const route = activeSearchResponse.routes[routeIndex];
  routeSegmentIndex ??= route.segments.length - 1;

  const segmentIndex = route.segments[routeSegmentIndex];
  const segment = activeSearchResponse.segments[segmentIndex];
  const transitMode = transitModeFromVehicleKind(
    vehicleFromSegment(activeSearchResponse, segment).kind
  );
  const place = destinationPlaceFromSegment(activeSearchResponse, segmentIndex);

  return { ...place, transitMode };
};

/**
 * If the location we're searching for is too large for an accommodation view to make sense (e.g. a country),
 * we scope in to the destination at the end of the provided route instead.
 *
 * @param searchResponse
 * @param destination Note: generic type due to never knowing which is being used!
 * @param routeIndex The route to use for getting a smaller destination (defaults to 0)
 * @returns
 */
export function getHotelAppropriateDestination<
  DestType extends { kind?: GeoKind }
>(
  searchResponse: SearchResponse,
  destination: DestType,
  routeIndex: number = 0
) {
  if (
    destination.kind &&
    !canShowHotelsForPlace(destination.kind) &&
    isValidRouteOrSegment(searchResponse, routeIndex)
  ) {
    return destinationPlaceFromRoute(searchResponse, routeIndex);
  }

  return destination;
}

/**
 * Checks the URL for an active route and segment index.
 * Can use the trip planner key/trip planner details for this if present.
 */
function getActiveTransportInfo(
  searchResponse: SearchResponse,
  hash: string,
  tripPlannerDetails?: TripPlannerDetails
) {
  const hashRouteIndex = getRouteIndexFromHash(hash, searchResponse);
  const hashRouteSegmentIndex = getSegmentIndexFromHash(hash);

  if (
    hashRouteIndex !== undefined &&
    isValidRouteOrSegment(searchResponse, hashRouteIndex, hashRouteSegmentIndex)
  ) {
    return {
      routeIndex: hashRouteIndex,
      routeSegmentIndex: hashRouteSegmentIndex,
    };
  }

  if (tripPlannerDetails) {
    const transportKey = getTransportKeyFromHash(hash);
    const transportRouteIndex =
      transportKey &&
      tripPlannerDetails.transport[transportKey]?.selectedRouteIndex;

    if (isValidRouteOrSegment(searchResponse, transportRouteIndex)) {
      return {
        routeIndex: transportRouteIndex,
        routeSegmentIndex: undefined,
      };
    }
  }

  return { routeIndex: undefined, routeSegmentIndex: undefined };
}
