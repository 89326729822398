import { type Ref, forwardRef } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Button } from "src/components/Button/Button";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { border_radius } from "src/design-system/tokens/border";
import { Close } from "src/svg/Close";
import { color, fontSize, fontWeight, lineHeight, spacing } from "src/theme";
import { desktopLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";
import messages from "./UserPrompt.messages";

type UserPromptProps = {
  onSave: () => void;
  hasBeenClosed: boolean;
  onClose: () => void;
};

function UserPromptInner(
  { onSave, onClose, hasBeenClosed }: UserPromptProps,
  ref: Ref<HTMLDivElement>
) {
  const intl = useIntl();

  function handleSave() {
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:SaveTrip",
      hasBeenClosed ? "Bottom" : "Top"
    );
    onSave();
  }

  function handleClose() {
    sendAnalyticsInteractionEvent("TripPlanner", "Close:SaveTrip");
    onClose();
  }

  return (
    <Wrapper hasBeenClosed={hasBeenClosed} ref={ref} tabIndex={-1}>
      <Title>{intl.formatMessage(messages.title)}</Title>
      {!hasBeenClosed && (
        <IconButton onClick={handleClose}>
          <Close title={intl.formatMessage(messages.close)} tint="n90" />
        </IconButton>
      )}
      <Description>{intl.formatMessage(messages.description)}</Description>
      <SaveButton
        onClick={handleSave}
        backgroundColor="pink"
        textColor="primaryOnDark"
        inline
      >
        {intl.formatMessage(messages.save)}
      </SaveButton>
    </Wrapper>
  );
}

export const UserPrompt = forwardRef<HTMLDivElement, UserPromptProps>(
  UserPromptInner
);

const Wrapper = styled.section<{ hasBeenClosed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing.lg} ${spacing.xl};
  gap: ${spacing.md};
  z-index: 1;

  position: relative;
  width: 100%;

  background: ${({ hasBeenClosed }) =>
    hasBeenClosed ? "transparent" : "#fff1f9"};
  border: 1px solid
    ${({ hasBeenClosed }) => (hasBeenClosed ? color.n40 : "#f8deed")};
  border-radius: ${border_radius.rounded_md};

  margin-top: ${spacing.xl};

  ${desktopLayout} {
    ${({ hasBeenClosed }) =>
      !hasBeenClosed &&
      css`
        width: 360px;
        position: fixed;
        top: 56px;
        right: ${spacing.xxxl};
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.16);
      `}
  }
`;

const Title = styled.h3`
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h4};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
`;

const Description = styled.p`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.normal};
  color: ${color.cod};
`;

const SaveButton = styled(Button)`
  border-radius: 24px;
  min-height: ${spacing.xxxl};
  padding-left: ${spacing.xl};
  padding-right: ${spacing.xl};

  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
    z-index: 1;
  }
  &:focus:not(:focus-visible) {
    outline: 0;
  }
`;

const IconButton = styled(ButtonBase)`
  position: absolute;
  top: ${spacing.lg};
  right: ${spacing.xl};
  width: 24px;
  height: 24px;
  svg {
    width: 12px;
    height: 12px;
  }
`;
