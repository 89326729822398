import type { SupportedLanguageCode } from "src/utils/language";
export type SojernOptions = {
  origin: SojernPlace;
  destination: SojernPlace;
  languageCode: SupportedLanguageCode;
  pageType: SojernPageType;
  uid: string;
  path: string;
  currencyCode?: string;
  adultsPassengers?: number;
  childPassengers?: number;
  departureDate?: string;
  returnDate?: string;
};

export type SojernPlace = {
  shortName?: string;
  countryCode?: string;
  region?: string;
  nearestAirportCode?: string;
};

export type SojernPageType =
  | "AirportLanding"
  | "BusSearch"
  | "TrainSearch"
  | "FerrySearch"
  | "FlightSearch"
  | "TripSearch"
  | "TicketSearch";

export function buildSojernUrl(values: SojernOptions): string {
  const builder = new URL(
    "https://static.sojern.com/cip/p/j78NerKP1syLlJXS.html"
  );

  const searchParams = {
    // c1 and c2 are the origin and destination city. But we seem
    // to use the shortName here as our best approximation of the city.
    c1: values.origin.shortName,
    c2: values.destination.shortName,
    // s1 and s2 are the origin and destination state or regions.
    s1: values.origin.region,
    s2: values.destination.region,
    n1: values.origin.countryCode,
    n2: values.destination.countryCode,
    a1: values.origin.nearestAirportCode,
    a2: values.destination.nearestAirportCode,
    l: values.languageCode,
    cur: values.currencyCode,
    ccid: values.uid,
    // p is the unique description of the page the interest data is being collected from.
    p: values.pageType,
    // The path (e.g. /Nearby-Airports/Boston) is used to identify
    // the page the interest data is being collected from.
    pname: values.path,
    tad: values.adultsPassengers?.toString(),
    tch: values.childPassengers?.toString(),
    t: (
      (values.adultsPassengers ?? 1) + (values.childPassengers ?? 0)
    ).toString(),
    d1: values.departureDate,
    d2: values.returnDate,
    // fow denotes if this is a one-way or round-trip search.
    fow: values.departureDate
      ? values.returnDate
        ? "true"
        : "false"
      : undefined,
  };

  builder.search = new URLSearchParams(
    removeEmptyValues(searchParams)
  ).toString();

  return builder.href;
}

function removeEmptyValues(params: Record<string, string | undefined>) {
  const result: Record<string, string> = {};
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      result[key] = value;
    }
  }
  return result;
}
