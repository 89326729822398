import type { ScreenComponent } from "src/analytics/generateScreenComponentsRendered/ScreenComponent";
import type { LayoutType } from "src/utils/hooks/useLayout";
import type { TypedLocation } from "src/utils/hooks/useTypedLocation";
import type { ApiState } from "./api";

export function getTripPlannerComponentsToRender(
  layout: LayoutType,
  apiState: ApiState,
  location?: TypedLocation
): ScreenComponent[] {
  let components: ScreenComponent[] = [];

  if (apiState.fetchState === "fetching") {
    components.push("TripPlannerSkeleton");
    return components;
  }

  if (location?.state?.reorderingTripPlan) {
    components.push("ReorderPage");
  }

  if (location?.state?.editTripPlan) {
    components.push("TripPlannerEdit");
  }

  if (!location?.state?.reorderingTripPlan) {
    components.push("TripPlannerCards");
  }

  if (layout === "mobile" && !location?.state?.reorderingTripPlan) {
    components.push("TripPlannerMobileMap");
  }

  components.push("TripPlannerTitle");
  components.push("TripPlannerDatePicker");

  return components;
}
