import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import { CloseCircle } from "src/svg/CloseCircle";
import { useCookieState } from "src/utils/hooks/useCookieState";
import { useLayout } from "src/utils/hooks/useLayout";
import { useRouteIndex } from "src/utils/hooks/useRoute";
import { useRouteSegmentIndex } from "src/utils/hooks/useSegment";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import styled from "styled-components";
import { breakpoints, color } from "../../theme";
import { DisplayAd } from "../DisplayAd/DisplayAd";
import { TieredDisplayAd } from "../DisplayAd/TieredDisplayAd";
import { ScrollableContainer } from "../ScrollableContainer/ScrollableContainer";
import { TextButton } from "../TextButton/TextButton";
import messages from "./RightRail.messages";

const HIDE_RIGHT_RAIL_COOKIE = "hideRightRail";
const HIDE_RIGHT_RAIL_COOKIE_EXPIRY = 0.004167; // 6 minutes
const MAX_NUMBER_OF_REFRESHES = 2;

export function RightRail() {
  const intl = useIntl();
  const layout = useLayout();
  const location = useTypedLocation();

  const [hide, setHide] = useCookieState(
    HIDE_RIGHT_RAIL_COOKIE,
    false,
    HIDE_RIGHT_RAIL_COOKIE_EXPIRY
  );

  // Used to hide the right rail until the ads have filled
  const [hasAd, setHasAd] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [hasUserNavigatedSerpToRoute, setHasUserNavigatedSerpToRoute] =
    useState(false);
  const [timerElapsed, setTimerElapsed] = useState(false);
  const [adViewed, setAdViewed] = useState(false);

  const routeIndex = useRouteIndex();
  const routeSegmentIndex = useRouteSegmentIndex();
  const newRefreshCount = refreshCount + 1;

  useEffect(() => {
    if (
      location?.state?.fromHash === "#trips" &&
      routeIndex !== undefined &&
      routeSegmentIndex === undefined &&
      !hasUserNavigatedSerpToRoute
    ) {
      setHasUserNavigatedSerpToRoute(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!timerElapsed && adViewed) {
      setTimeout(() => {
        setTimerElapsed(true);
      }, 60 * 1000);
    }
  }, [adViewed, timerElapsed]);

  useEffect(() => {
    if (
      timerElapsed &&
      hasUserNavigatedSerpToRoute &&
      refreshCount < MAX_NUMBER_OF_REFRESHES &&
      layout === "desktop"
    ) {
      setTimerElapsed(false);
      setRefreshCount(newRefreshCount);
      sendAnalyticsNonInteractionEvent(
        "Ads",
        "Refresh",
        "rightRail2",
        newRefreshCount
      );
    }
    setHasUserNavigatedSerpToRoute(false);
  }, [
    hasUserNavigatedSerpToRoute,
    layout,
    newRefreshCount,
    refreshCount,
    timerElapsed,
  ]);

  if (hide) {
    return null;
  }

  return (
    <ScrollableContainer>
      <Container isOpen={hasAd}>
        {showHideAdsButton(window.innerWidth) && (
          <TextButton
            textColor="primaryOnLight"
            onClick={() => {
              sendAnalyticsInteractionEvent("Ads", "Click:HideRightRail");
              setHide(true);
            }}
            rightIcon={<CloseCircle tint="secondaryOnLight" />}
            rightIconSize="lg"
          >
            {intl.formatMessage(messages.closeAdsLabel)}
          </TextButton>
        )}
        <TieredDisplayAd
          slotConfigId="rightRail"
          onFilled={() => setHasAd(true)}
        />
        <DisplayAd
          slotConfigId="rightRail2"
          onFilled={() => setHasAd(true)}
          key={`display-count-${refreshCount}`}
          setAdViewed={setAdViewed}
          refreshCount={refreshCount}
        />
      </Container>
    </ScrollableContainer>
  );
}

function showHideAdsButton(screenWidth: number) {
  return screenWidth <= breakpoints.xl;
}

const Container = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-end;

  background-color: ${color.grey1};
`;
