import type { LineColor } from "../../api/SearchResponse";
import type { Mode } from "../types/mode";

type LineColorDictionary = { [key: string]: LineColor };

export type LineName = {
  name: string;
  transitMode: Mode;
  textColor?: string;
  backgroundColor?: string;
};

export const lineNameFromNamesAndColors = (
  names: string[],
  colors: LineColor[] | undefined,
  transitMode: Mode
): LineName[] => {
  const colorsDict = colorsDictionary(colors);
  return names.map((name) =>
    lineNameFromName(name, colorsDict[name], transitMode)
  );
};

const lineNameFromName = (
  name: string,
  lineColor: LineColor | undefined,
  transitMode: Mode
): LineName => {
  return {
    name,
    backgroundColor: lineColor?.lineColor,
    textColor: lineColor?.textColor,
    transitMode: transitMode,
  };
};

const colorsDictionary = (colors: LineColor[] = []): LineColorDictionary => {
  return colors.reduce((acc: LineColorDictionary, color: LineColor) => {
    acc[color.name] = color;
    return acc;
  }, {});
};
