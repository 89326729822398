import { type ForwardedRef, forwardRef } from "react";
import { type MessageDescriptor, useIntl } from "react-intl";
import { border_radius } from "src/design-system/tokens/border";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import color from "src/design-system/tokens/color";
import type { MergeElementProps } from "../../../utils/MergeElementProps";
import messages from "./SearchInput.messages.ts";

type Kind = "origin" | "destination" | "date" | "hotelDestination";

export type SearchInputProps = MergeElementProps<
  "input",
  {
    kind: Kind;
    value?: string;
    hint?: string;
    className?: string;
  }
>;

export const SearchInput = forwardRef(
  (props: SearchInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { kind, value, hint, ...inputProps } = props;

    const intl = useIntl();
    const valueOrEmpty = props.value ?? "";
    const hintOrEmpty = props.hint ?? "";
    const label = labelFromKind(props.kind);
    const placeholder = placeholderFromKind(props.kind);
    return (
      <TextFieldContainer className={props.className}>
        <HintInput
          type="text"
          value={props.hint ? `${valueOrEmpty}${hintOrEmpty}` : ""}
          disabled
          autoComplete="off"
          autoCapitalize="off"
          aria-label={`${intl.formatMessage(label)}, ${intl.formatMessage(
            messages.hint
          )}`}
        />
        <Input
          {...inputProps}
          type="search"
          placeholder={intl.formatMessage(placeholder)}
          value={valueOrEmpty}
          aria-label={intl.formatMessage(label)}
          spellCheck={false}
          ref={ref}
        />
      </TextFieldContainer>
    );
  }
);
SearchInput.displayName = "SearchInput";

function labelFromKind(kind: Kind): MessageDescriptor {
  switch (kind) {
    case "origin":
      return messages.originLabel;
    case "destination":
      return messages.destinationLabel;
    case "hotelDestination":
      return messages.destinationLabel;
    case "date":
      return messages.dateLabel;
  }
}

function placeholderFromKind(kind: Kind): MessageDescriptor {
  switch (kind) {
    case "origin":
      return messages.originPlaceholder;
    case "destination":
      return messages.destinationPlaceholder;
    case "date":
      return messages.datePlaceholder;
    case "hotelDestination":
      return messages.hotelDestinationPlaceholder;
  }
}

const BaseInput = styled.input`
  // Reset and remove browser-specific styles so that we can style the input
  // ourselves.
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  display: block;
  min-width: 0;
  min-height: 48px;
  width: 100%;
  padding: ${spacing.lg};
  border: 1px solid transparent;

  &:active,
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.searchBar.input.borderFocus};
  }

  // Remove styles applied to the input because we specify use type=search.
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    -webkit-appearance: none;
  }
`;

export const Input = styled(BaseInput)`
  position: relative;
  color: ${(props) => props.theme.searchBar.input.text};
  border-radius: ${border_radius.rounded_md};
  border-color: ${color.border.secondary};
  padding: ${spacing.lg};

  &::placeholder {
    color: ${(props) => props.theme.searchBar.input.placeholder};
  }

  &:hover {
    border: 1px solid ${color.input.borderHover};
  }
`;

const TextFieldContainer = styled.div`
  border-radius: ${border_radius.rounded_md};
  cursor: text;
  display: inline-flex;
  position: relative;
  width: 100%;
`;

const HintInput = styled(BaseInput)`
  position: absolute;
  background-color: ${color.bg.surface.active};
  color: ${(props) => props.theme.searchBar.input.hint};
`;
