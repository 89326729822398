import { useCallback, useEffect, useState } from "react";
import { type LayoutType, useLayout } from "src/utils/hooks/useLayout";
import {
  type TypedLocation,
  useTypedLocation,
} from "src/utils/hooks/useTypedLocation";
import type { ScreenComponent } from "src/analytics/generateScreenComponentsRendered/ScreenComponent";
import { getTripPlannerComponentsToRender } from "../util/getTripPlannerComponentsToRender";
import type { ApiState } from "../util/api";
import { useTripPlannerContext } from "./useTripPlannerContext";

type ComponentLayout = {
  layout: LayoutType;
  location: TypedLocation;
  apiState: ApiState;
  components: ScreenComponent[];
};

/**
 * This hook determines which components to render based on:
 * - the current layout
 * - the current api state
 */
export function useTripPlannerComponents() {
  const layout = useLayout();
  const location = useTypedLocation();
  const { apiState } = useTripPlannerContext();

  const [tripPlannerComponentLayout, setTripPlannerComponentLayout] =
    useState<ComponentLayout>({
      layout,
      apiState,
      location,
      components: getTripPlannerComponentsToRender(layout, apiState, location),
    });

  const updateComponents = useCallback(() => {
    setTripPlannerComponentLayout({
      layout,
      apiState,
      location,
      components: getTripPlannerComponentsToRender(layout, apiState, location),
    });
  }, [layout, apiState, location]);

  useEffect(() => {
    if (
      layout !== tripPlannerComponentLayout.layout ||
      apiState.fetchState !== tripPlannerComponentLayout.apiState.fetchState ||
      location.state?.reorderingTripPlan !==
        tripPlannerComponentLayout.location.state?.reorderingTripPlan ||
      location.state?.editTripPlan !==
        tripPlannerComponentLayout.location.state?.editTripPlan
    ) {
      updateComponents();
    }
  }, [
    layout,
    updateComponents,
    tripPlannerComponentLayout.layout,
    apiState.fetchState,
    tripPlannerComponentLayout.apiState.fetchState,
    location.state?.reorderingTripPlan,
    tripPlannerComponentLayout.location.state?.reorderingTripPlan,
    location.state?.editTripPlan,
    tripPlannerComponentLayout.location.state?.editTripPlan,
  ]);

  return tripPlannerComponentLayout.components;
}
