import type { Mode } from "src/utils/types/mode";
import { modeMessages } from "./Schedule.messages";

export function getModeMessage(modeKind: Mode) {
  if (modeKind in modeMessages) {
    return modeMessages[modeKind];
  } else {
    throw new Error(`Unrecognized mode ${modeKind}`);
  }
}
