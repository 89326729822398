import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { FocusContext } from "src/FocusContext";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { AutocompleteInput } from "src/components/AutocompleteInput/AutocompleteInput";
import { AutocompleteList } from "src/components/AutocompleteList/AutocompleteList";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import { Icon } from "src/components/Icon/Icon";
import { Typography } from "src/design-system/components/Typography/Typography";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { DestinationIcon } from "src/domain/LargeSearchBar/SearchInput/DestinationIcon";
import { AutocompleteListWrapper } from "src/domain/TripPlanner/AddDestinationInline/AddDestinationInline";
import useAutocomplete, {
  useKeyboardAutocompleteNavigation,
} from "src/utils/hooks/useAutocomplete";
import styled from "styled-components";
import { messages } from "./DiscoverAutocomplete.messages";

type Props = {
  onSelect: (place: AutocompletePlace) => void;
  initialQuery?: string;
};

export function DiscoverAutocomplete({
  onSelect,
  initialQuery,
}: Readonly<Props>) {
  const intl = useIntl();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const autoCompleteListScrollRef = useRef<HTMLDivElement>(null);

  const { results, query, changeQuery } = useAutocomplete();

  useEffect(() => {
    changeQuery(initialQuery ?? "", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQuery]);

  const filteredResults = results.filter(
    (result) => result.countryCode === "IT"
  );

  function onAutoCompleteChange(query: string) {
    changeQuery(query);
  }

  function handleFocus() {
    setIsInputFocused(true);
  }

  function onPressEscape(event?: React.KeyboardEvent<HTMLInputElement>) {
    if (event) event.preventDefault(); // we don't want to trigger onAutoCompleteChange
    resetFocus("escapeKeyDown");
  }

  function resetFocus(escapeKeyDown?: "escapeKeyDown") {
    if (focusedIndex > 0) {
      setFocusIndex(0);
      if (escapeKeyDown) {
        return false; // early exit to keep focus on combobox per ARIA
      }
    }
    setIsInputFocused(false);
  }

  const { onKeyDown, onFocusChanged, focusedIndex, setFocusIndex, focusRef } =
    useKeyboardAutocompleteNavigation({
      id: "discover-autocomplete-list",
      results: filteredResults,
      onPressEnter: handleSelect,
      onPressEscape: onPressEscape,
      onPressTab: onPressEscape,
      scrollContainerRef: autoCompleteListScrollRef,
    });

  function handleSelect(place: AutocompletePlace) {
    onSelect(place);
    changeQuery(place.shortName, false);
    setIsInputFocused(false);
  }

  // Use setFocusInput to blur/focus the input.
  const destinationInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isInputFocused) {
      destinationInputRef.current?.select();
    } else {
      destinationInputRef.current?.blur();
    }
  }, [isInputFocused]);

  return (
    <FocusContext.Provider
      value={{
        focusedElement: { id: "hotelDestination", index: focusedIndex },
        onFocusChanged,
      }}
    >
      <Wrapper>
        <ClickAwayListener onClickAway={() => resetFocus()}>
          <StyledAutocompleteInput
            type="destination"
            value={query ?? ""}
            onChange={onAutoCompleteChange}
            onFocus={handleFocus}
            isFocused={isInputFocused}
            addDestinationRef={destinationInputRef}
            onKeyDown={onKeyDown}
            transparentBackground
            noBorder
            icon={
              <StyledIcon size="lg">
                <DestinationIcon tint="iconForeground" />
              </StyledIcon>
            }
            role="combobox"
            aria-controls="discover-autocomplete-list"
            aria-expanded={isInputFocused}
          />
          {isInputFocused && (
            <AutoCompleteListWrapper
              ref={autoCompleteListScrollRef}
              id="hotel-destination-autocomplete-list"
              role="listbox"
            >
              <AutocompleteList
                isInputFocused={true}
                results={filteredResults}
                onSelectPlace={handleSelect}
                size="sm"
                focusRef={focusRef}
                prependItem={
                  <FilteredRecommendation>
                    <Typography weight="bold" variant="body-md">
                      {intl.formatMessage(messages.autocompleteFilter)}
                    </Typography>
                  </FilteredRecommendation>
                }
              />
            </AutoCompleteListWrapper>
          )}
        </ClickAwayListener>
      </Wrapper>
    </FocusContext.Provider>
  );
}

const StyledIcon = styled(Icon)`
  margin-left: ${spacing.md};
`;

const StyledAutocompleteInput = styled(AutocompleteInput)`
  input {
    margin-left: ${spacing.md};
  }
`;

export const FilteredRecommendation = styled.li`
  padding: ${spacing.lg} ${spacing.sm};
  list-style: none;
`;

const AutoCompleteListWrapper = styled(AutocompleteListWrapper)`
  width: 100%;
  left: auto;
  top: 95%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color.bg.surface.active};
  border: 1px solid ${color.border.border};
  border-radius: ${border_radius.rounded_md};

  &:focus-within {
    border: 1px solid black; // R2R themed outline style.
  }
`;
