import { useState } from "react";
import { useIntl } from "react-intl";
import { BorderLine } from "src/components/CommonComponents/CommonComponents";
import { Icon } from "src/components/Icon/Icon";
import { useApplicablePrivacyRegs } from "src/privacy-regulations/hooks/useApplicablePrivacyRegs";
import useCcpaManagement, {
  CcpaStatus,
} from "src/privacy-regulations/hooks/useCcpaManagement";
import { QuestionMark } from "src/svg/QuestionMark";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { font_size, font_weight } from "src/design-system/tokens/typography";
import color from "src/design-system/tokens/color";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { dropdownPadding } from "../../../components/Dropdown/Dropdown";
import { type iconSize, color as oldColor } from "../../../theme";
import {
  getHelpLanguageCode,
  localeToLanguageCode,
} from "../../../utils/conversions/languageCode";
import { NavDropdown } from "../NavDropdown";
import { messages } from "./HelpDropdown.messages";

type Props = {
  size?: keyof typeof iconSize;
};

export function HelpDropdown({ size = "xxl" }: Props) {
  const intl = useIntl();
  const language = localeToLanguageCode(intl.locale);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const privacyRegulations = useApplicablePrivacyRegs();
  const gdprApplies = privacyRegulations?.includes("gdpr");
  const usConsentApplies = privacyRegulations?.includes("usprivacy");

  const [ccpaStatus, handleCcpaLinkClick] = useCcpaManagement();

  function handleClickButton() {
    if (!isDropdownOpen) {
      sendAnalyticsInteractionEvent("TopNav", "Click:More");
    }
    setIsDropdownOpen(true);
  }

  function handleNavDropdownLinkClick(type: string) {
    return () => {
      setIsDropdownOpen(false);
      sendAnalyticsInteractionEvent("TopNav", `Click:${type}`);
    };
  }

  return (
    <NavDropdown
      text={
        <StyledIcon size={size}>
          <QuestionMark title={intl.formatMessage(messages.help)} tint="cod" />
        </StyledIcon>
      }
      hideChevron={true}
      anchor="right"
      $padding={`${spacing.lg} 0`}
      borderRadius="rounded_md"
      isDropdownOpen={isDropdownOpen}
      handleClickButton={handleClickButton}
      handleClickOffDropdown={() => {
        setIsDropdownOpen(false);
      }}
    >
      <List>
        <ListItem>
          <SubList>
            <li>
              <Link
                href={`https://help.rome2rio.com/${getHelpLanguageCode(
                  language
                )}/support/home`}
                onClick={handleNavDropdownLinkClick("FAQ")}
              >
                {intl.formatMessage(messages.faq)}
              </Link>
            </li>
            <li>
              <Link
                href={`https://help.rome2rio.com/${getHelpLanguageCode(
                  language
                )}/support/tickets/new`}
                onClick={handleNavDropdownLinkClick("Contact")}
              >
                {intl.formatMessage(messages.contact)}
              </Link>
            </li>
            <li>
              <Link
                type="About"
                href="https://rome2rio.com/about"
                onClick={handleNavDropdownLinkClick("About")}
              >
                {intl.formatMessage(messages.about)}
              </Link>
            </li>
            {usConsentApplies && (
              <>
                {ccpaStatus === CcpaStatus.NOT_OPTED_OUT && (
                  <>
                    <BorderLine />
                    <li>
                      <Link
                        as="button"
                        onClick={handleCcpaLinkClick}
                        ccpaLink={true}
                      >
                        {intl.formatMessage(messages.doNotShareCcpaLink)}
                      </Link>
                    </li>
                  </>
                )}
                {ccpaStatus === CcpaStatus.OPTED_OUT && (
                  <>
                    <BorderLine />
                    <OptOutMessage>
                      {intl.formatMessage(messages.doNotShareCcpaOptedOut)}
                    </OptOutMessage>
                  </>
                )}
              </>
            )}
            {gdprApplies && (
              <li>
                <Link
                  as="button"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    sendAnalyticsInteractionEvent(
                      "TopNav",
                      `Click:ManageConsent`
                    );
                    window.googlefc?.callbackQueue?.push(
                      window.googlefc.showRevocationMessage
                    );
                  }}
                >
                  {intl.formatMessage(messages.manageConsent)}
                </Link>
              </li>
            )}
          </SubList>
        </ListItem>
      </List>
    </NavDropdown>
  );
}

const Link = styled.a<{ ccpaLink?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding-left: ${dropdownPadding};
  padding-right: ${dropdownPadding};
  text-decoration: none;
  color: ${(props) => props.theme.dropdown.text};
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.dropdown.backgroundHover};

    /* Reset the background-color on touch devices so that they don't get a
    lingering hover effect after a click event. */
    @media (hover: none) {
      background-color: unset;
    }
  }

  ${(props) => {
    if (props.ccpaLink)
      return `
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      font-size: ${font_size.text_sm};
      font-weight: ${font_weight.normal};`;
  }}
`;

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  &:after {
    content: "";
    display: block;
    border-bottom: 1px solid ${oldColor.grey2};
    margin-left: ${dropdownPadding};
    margin-right: ${dropdownPadding};
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

const SubList = styled.ul`
  padding: ${spacing.lg} 0;
  list-style: none;

  ${ListItem}:first-child & {
    padding-top: 0;
  }

  ${ListItem}:last-child & {
    padding-bottom: 0;
  }
`;

const OptOutMessage = styled.p`
  padding: ${spacing.lg} ${spacing.xxl};
`;

const StyledIcon = styled(Icon)`
  &:hover {
    path {
      fill: ${color.primitive.neutral1200};
    }
  }
`;
