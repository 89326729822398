import { useIntl } from "react-intl";
import styled from "styled-components";
import brazilIcon from "src/svg/flags/BrazilianFlag.svg";
import germanFlag from "src/svg/flags/GermanFlag.svg";
import unitedKingdomFlag from "src/svg/flags/UnitedKingdomFlag.svg";
import spanishFlag from "src/svg/flags/SpanishFlag.svg";
import italianFlag from "src/svg/flags/ItalianFlag.svg";
import frenchFlag from "src/svg/flags/FrenchFlag.svg";
import russianFlag from "src/svg/flags/RussianFlag.svg";
import polishFlag from "src/svg/flags/PolishFlag.svg";
import netherlandsFlag from "src/svg/flags/NetherlandsFlag.svg";
import swedenFlag from "src/svg/flags/SwedenFlag.svg";
import norwayFlag from "src/svg/flags/NorwayFlag.svg";
import denmarkFlag from "src/svg/flags/DenmarkFlag.svg";
import japanFlag from "src/svg/flags/JapanFlag.svg";
import southKoreaFlag from "src/svg/flags/SouthKoreaFlag.svg";
import thaiFlag from "src/svg/flags/ThaiFlag.svg";
import chineseFlag from "src/svg/flags/ChineseFlag.svg";
import romanianFlag from "src/svg/flags/RomanianFlag.svg";
import malaysianFlag from "src/svg/flags/MalaysianFlag.svg";
import czechFlag from "src/svg/flags/CzechFlag.svg";
import hungarianFlag from "src/svg/flags/HungarianFlag.svg";
import greekFlag from "src/svg/flags/GreekFlag.svg";
import finnishFlag from "src/svg/flags/FinnishFlag.svg";
import slovakianFlag from "src/svg/flags/SlovakianFlag.svg";
import croatianFlag from "src/svg/flags/CroatianFlag.svg";

import { spacing } from "../../theme";
import type { SupportedLanguageCode } from "../../utils/language";
import { messages } from "./Flag.messages";

export function Flag(props: { language: SupportedLanguageCode }) {
  const intl = useIntl();

  let flag;
  switch (props.language) {
    case "de":
      flag = (
        <img src={germanFlag} alt={intl.formatMessage(messages.germanFlag)} />
      );
      break;
    case "en":
      flag = (
        <img
          src={unitedKingdomFlag}
          alt={intl.formatMessage(messages.unitedKingdomFlag)}
        />
      );
      break;
    case "es":
      flag = (
        <img src={spanishFlag} alt={intl.formatMessage(messages.spanishFlag)} />
      );
      break;
    case "it":
      flag = (
        <img src={italianFlag} alt={intl.formatMessage(messages.italianFlag)} />
      );
      break;
    case "fr":
      flag = (
        <img src={frenchFlag} alt={intl.formatMessage(messages.frenchFlag)} />
      );
      break;
    case "pt":
      flag = (
        <img
          src={brazilIcon}
          alt={intl.formatMessage(messages.brazilianFlag)}
        />
      );
      break;
    case "ru":
      flag = (
        <img src={russianFlag} alt={intl.formatMessage(messages.russianFlag)} />
      );
      break;
    case "pl":
      flag = (
        <img src={polishFlag} alt={intl.formatMessage(messages.polishFlag)} />
      );
      break;
    case "nl":
      flag = (
        <img
          src={netherlandsFlag}
          alt={intl.formatMessage(messages.netherlandsFlag)}
        />
      );
      break;
    case "sv":
      flag = (
        <img src={swedenFlag} alt={intl.formatMessage(messages.swedenFlag)} />
      );
      break;
    case "no":
      flag = (
        <img src={norwayFlag} alt={intl.formatMessage(messages.norwayFlag)} />
      );
      break;
    case "da":
      flag = (
        <img src={denmarkFlag} alt={intl.formatMessage(messages.denmarkFlag)} />
      );
      break;
    case "ja":
      flag = (
        <img src={japanFlag} alt={intl.formatMessage(messages.japanFlag)} />
      );
      break;
    case "ko":
      flag = (
        <img
          src={southKoreaFlag}
          alt={intl.formatMessage(messages.southKoreaFlag)}
        />
      );
      break;
    case "th":
      flag = (
        <img src={thaiFlag} alt={intl.formatMessage(messages.thailandFlag)} />
      );
      break;
    case "zh":
      flag = (
        <img src={chineseFlag} alt={intl.formatMessage(messages.chinaFlag)} />
      );
      break;
    case "ro":
      flag = (
        <img
          src={romanianFlag}
          alt={intl.formatMessage(messages.romainiaFlag)}
        />
      );
      break;
    case "ms":
      flag = (
        <img
          src={malaysianFlag}
          alt={intl.formatMessage(messages.malaysiaFlag)}
        />
      );
      break;
    case "cs":
      flag = (
        <img src={czechFlag} alt={intl.formatMessage(messages.czechiaFlag)} />
      );
      break;
    case "hu":
      flag = (
        <img
          src={hungarianFlag}
          alt={intl.formatMessage(messages.hungaryFlag)}
        />
      );
      break;
    case "el":
      flag = (
        <img src={greekFlag} alt={intl.formatMessage(messages.greeceFlag)} />
      );
      break;
    case "fi":
      flag = (
        <img src={finnishFlag} alt={intl.formatMessage(messages.finlandFlag)} />
      );
      break;
    case "sk":
      flag = (
        <img
          src={slovakianFlag}
          alt={intl.formatMessage(messages.slovakiaFlag)}
        />
      );
      break;
    case "hr":
      flag = (
        <img
          src={croatianFlag}
          alt={intl.formatMessage(messages.croatiaFlag)}
        />
      );
      break;
  }

  return <FlagWrapper>{flag}</FlagWrapper>;
}

const FlagWrapper = styled.span`
  width: 30px;
  height: 20px;
  margin-right: ${spacing.lg};

  img {
    width: 100%;
    height: 100%;
  }
`;
