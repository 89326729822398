import { type ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

// Hide visually, but not from screen readers
const VisuallyHiddenSpan = styled.span`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

// https://www.joshwcomeau.com/snippets/react-components/visually-hidden/
// https://rome2rio.atlassian.net/browse/EXTD-9

export function VisuallyHidden({
  children,
  ...delegated
}: {
  children: ReactNode;
}) {
  const [forceShow, setForceShow] = useState(false);
  useEffect(() => {
    if (import.meta.env.DEV) {
      const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Alt") {
          event.preventDefault();
          setForceShow(!forceShow);
        }
      };

      window.addEventListener("keyup", handleKeyUp);
      return () => {
        window.removeEventListener("keyup", handleKeyUp);
      };
    }
  }, [forceShow]);
  if (forceShow) {
    return <>{children}</>;
  }
  return <VisuallyHiddenSpan {...delegated}>{children}</VisuallyHiddenSpan>;
}
