import type { Place } from "../../api/SearchResponse";
import type { Geocoded } from "../../PrefetchData";
import useSearch, { type SearchPlace } from "./useSearch";

export function useFallbackPlaceForDeeplink(): Geocoded | SearchPlace | Place {
  const { origin, destination } = useSearch();
  const guessedLocation = guessLocationFromBrowser();
  // TODO : get user location from cloudflare.
  return destination ?? origin ?? guessedLocation;
}

function guessLocationFromBrowser(): SearchPlace {
  // In the unlikely case that we have to guess the user's location from
  // the browser and the timezone is undefined - use 'Rome'.
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Rome";
  const [region, city] = timezone.split("/");
  const location = city ?? region;
  return {
    shortName: location,
    longName: location,
    canonicalName: location,
  };
}
