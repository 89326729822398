import { useRoutesBetween } from "src/utils/hooks/useRoutesBetween";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { Suspense, useEffect } from "react";
import { centerZoomMapForBounds } from "../../centerZoomMapForSegments";
import { Polyline } from "./Polyline";

type Props = {
  from: google.maps.LatLngLiteral;
  to: google.maps.LatLngLiteral;
  count?: number;
  highlightedRoute?: number;
  googleMap: google.maps.Map;
  isFitBounds?: boolean;
};
/**
 * This function relies on two coordinates to fetch the transport polylines between them
 * @param from Origin coordinates
 * @param to Destination coordinates
 * @param count Specifies how many top routes to display
 * @param highlightedRoute Index of the route result to highlight, the rest are blurred/greyed out
 * @googleMap
 * @isFitBounds Turns the fitBounds on/off
 * @returns transport polylines as JSX
 */
export function RoutePolylines(props: Props) {
  return (
    <Suspense fallback={null}>
      <RoutePolylinesContent {...props} />
    </Suspense>
  );
}

function RoutePolylinesContent({
  from,
  to,
  count,
  highlightedRoute,
  googleMap,
  isFitBounds = true,
}: Props) {
  const { LatLngBounds } = useMapsLibrary("core") || {};
  const routes = useRoutesBetween({ from, to, count });

  // When highlighting a route, we should zoom in
  useEffect(() => {
    if (!isFitBounds || !LatLngBounds) {
      return;
    }

    const prevZoom = googleMap.getZoom();
    const prevCenter = googleMap.getCenter();

    const places = routes
      .flatMap((route) => route.mapSegments)
      .flatMap((segment) => segment.places);

    const bounds = new LatLngBounds();
    places.forEach((place) => bounds.extend(place));

    centerZoomMapForBounds({ map: googleMap, bounds });

    return () => {
      if (prevZoom !== undefined && prevCenter !== undefined) {
        googleMap.setZoom(prevZoom);
        googleMap.setCenter(prevCenter);
      }
    };
  }, [googleMap, routes, isFitBounds, LatLngBounds]);

  return (
    <>
      {routes.map(({ mapSegments }, index) => {
        return mapSegments.map((segment, segmentIndex) => {
          return segment.paths.map((path, pathIndex) => {
            const focused = highlightedRoute === index;
            const key = `segment-${segmentIndex}-path-${pathIndex}`;
            return (
              <Polyline
                googleMap={googleMap}
                path={path}
                transitMode={segment.transitMode}
                blurred={!focused}
                key={key}
              />
            );
          });
        });
      })}
    </>
  );
}
