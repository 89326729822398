import type { GeocodedPlace } from "src/PrefetchData";
import type { TripPlannerDetails } from "../TripPlannerProvider";

export type PlacePair = {
  origin: GeocodedPlace;
  destination: GeocodedPlace;
};

export function getPlacePairs(
  tripPlannerDetails: TripPlannerDetails
): PlacePair[] {
  const { places } = tripPlannerDetails;
  const placePairs: PlacePair[] = [];
  for (let i = 0; i < places.length - 1; i++) {
    placePairs.push({
      origin: places[i],
      destination: places[i + 1],
    });
  }
  return placePairs;
}
