import { useState } from "react";
import styled from "styled-components";
import { CsaAd } from "../CsaAd/CsaAd";
import { type SlotConfigId, slotConfigMap } from "../DisplayAd/config";
import { DisplayAd } from "../DisplayAd/DisplayAd";
import {
  type AdUnitFillStatus,
  TieredDisplayAd,
} from "../DisplayAd/TieredDisplayAd";

const Variants = {
  DisplayAd: DisplayAd,
  TieredDisplayAd: TieredDisplayAd,
  CsaAd: CsaAd,
};

type Props = {
  variant: keyof typeof Variants;
  slotConfigId?: SlotConfigId;
  onFilled?: () => void;
  onRequesting?: () => void;
  onNotFilled?: () => void;
  className?: string;
  topPadding?: number;
  reservedHeight?: string;
  applyMinHeight?: boolean;
  disableCollapse?: boolean;
};

export function CollapsibleAdUnit({
  variant,
  slotConfigId,
  reservedHeight = "450px",
  onFilled,
  onRequesting,
  onNotFilled,
  disableCollapse,
  ...props
}: Props) {
  const [adUnitFillState, setAdUnitFillState] =
    useState<AdUnitFillStatus>("initializing");
  const Ad = Variants[variant];

  // If we're rendering a DisplayAd or TieredDisplayAd we must have a slotConfigId.
  // CsaAds don't use slotConfigId's.
  if (variant !== "CsaAd" && !slotConfigId) return null;

  const config = slotConfigId ? slotConfigMap[slotConfigId] : undefined;

  // Ensure the div is taking up space whilst the ad is loading.
  // Exclude fluid ads and just look at SingleSizeArray ads of the form [width, height]
  const maxAdHeight = config
    ? Array.isArray(config.sizes)
      ? Math.max(
          ...config.sizes.flatMap((size): number[] =>
            Array.isArray(size) && typeof size[1] === "number" ? [size[1]] : []
          )
        )
      : undefined
    : undefined;

  const totalAdHeight =
    props.topPadding && maxAdHeight
      ? maxAdHeight + props.topPadding
      : maxAdHeight;

  const loadingHeight =
    variant === "CsaAd" ? reservedHeight : totalAdHeight + "px";

  const isDevelopment = import.meta.env.MODE === "development";

  // Don't reserve space for tests.
  const reservedAdsHeight = isDevelopment ? "0px" : loadingHeight;
  const reservedAdsTopPadding = isDevelopment ? 0 : props.topPadding;

  return (
    <CollapsibleContainer
      collapse={adUnitFillState === "failedToFill"}
      adFilled={adUnitFillState === "filled"}
      topPadding={reservedAdsTopPadding}
      reservedHeight={reservedAdsHeight}
      csaAd={variant === "CsaAd"}
      disableCollapse={disableCollapse ?? false}
    >
      <Ad
        {...props}
        // We only care if slotConfigId is undefined for DisplayAds so
        // we explicitly check for this case above.
        slotConfigId={slotConfigId as SlotConfigId}
        onNotFilled={() => {
          setAdUnitFillState("failedToFill");
          onNotFilled?.();
        }}
        onRequesting={() => {
          setAdUnitFillState("initializing");
          onRequesting?.();
        }}
        onFilled={() => {
          setAdUnitFillState("filled");
          onFilled?.();
        }}
      />
    </CollapsibleContainer>
  );
}

const CollapsibleContainer = styled.div<{
  collapse: boolean;
  adFilled: boolean;
  disableCollapse: boolean;
  reservedHeight?: string;
  topPadding?: number;
  csaAd?: boolean;
}>`
  transition: ${(props) => !props.disableCollapse && "all ease 1s"};
  ${({ csaAd }) => csaAd && "width: 100%"};
  padding-top: ${(props) => {
    // Only apply collapse if collapse transition is allowed.
    // If collapse transition is disabled, then add any required top padding
    // to the ad when it has loaded to ensure there is no additional layout shift.
    if (
      (!props.disableCollapse && props.collapse) ||
      (props.disableCollapse && !props.adFilled)
    ) {
      return "0px";
    } else {
      return props.topPadding + "px";
    }
  }};
  min-height: ${(props) => {
    // Only reserve height if transition is allowed and the ad is still initialising.
    if (props.collapse || props.adFilled || props.disableCollapse) {
      return "0px";
    } else {
      return props.reservedHeight;
    }
  }};
`;
