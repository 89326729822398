import { type SvgProps, useColor } from "./common";

export function ChevronRightBold(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
      <title>{props.title}</title>
      <g clipPath="url(#a)">
        <path
          fill={tintColor}
          fillRule="evenodd"
          stroke={tintColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".5"
          clipRule="evenodd"
          d="M3.546 11.058a.716.716 0 0 1 0-.928l3.67-4.133-3.67-4.127a.716.716 0 0 1 0-.928.539.539 0 0 1 .825 0l4.083 4.59a.716.716 0 0 1 0 .929l-4.083 4.597a.539.539 0 0 1-.825 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
