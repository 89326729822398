import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { Button } from "src/design-system/components/Button/Button";
import { Icon } from "src/design-system/components/Icon/Icon";
import { Typography } from "src/design-system/components/Typography/Typography";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { ArrowBackward } from "src/svg/ArrowBackward";
import { ArrowForward } from "src/svg/ArrowForward";
import imagePlaceholder from "src/svg/ImagePlaceholder.svg";
import { ONE_DAY_IN_MILLISECONDS } from "src/utils/conversions/time";
import { useHorizontalMouseScroll } from "src/utils/hooks/useHorizontalMouseScroll";
import { scrollbarHiddenStyling } from "src/utils/styles/common";
import styled, { css } from "styled-components";
import messages from "./AttractionList.messages";
import type createAttractionViewModel from "./createAttractionViewModel";

const CARD_SIZE = 120;

export default function AttractionList({
  attractions,
  destination,
}: {
  attractions: ReturnType<typeof createAttractionViewModel>[];
  destination: string;
}) {
  const intl = useIntl();

  const carouselRef = useRef<HTMLUListElement>(null);
  const { handleMouseDown, isDragging } = useHorizontalMouseScroll(carouselRef);

  const initScrollArrows = useCallback(() => {
    if (carouselRef.current) {
      const scrollable =
        carouselRef.current.scrollWidth > carouselRef.current.clientWidth;
      if (!scrollable) {
        setCanScroll(null);
        return;
      }
      if (carouselRef.current.scrollLeft === 0) {
        setCanScroll("right");
      } else if (
        carouselRef.current.scrollLeft + carouselRef.current.clientWidth ===
        carouselRef.current.scrollWidth
      ) {
        setCanScroll("left");
      }
    }
  }, [carouselRef]);

  const [canScroll, setCanScroll] = useState<"left" | "right" | null>(null);

  useEffect(() => {
    initScrollArrows();
    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener("scroll", initScrollArrows);
    }
    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener("scroll", initScrollArrows);
      }
    };
  }, [carouselRef, initScrollArrows]);

  if (attractions.length === 0) {
    return null;
  }

  return (
    <Container>
      <HeadingContainer>
        <Typography
          variant="heading-md"
          as="h2"
          id="attractions-heading"
          onClick={() => {
            sendAnalyticsInteractionEvent(
              "Destination",
              "Click:AttractionList",
              destination
            );
          }}
        >
          {intl.formatMessage(messages.heading)}
        </Typography>
      </HeadingContainer>
      <ListContainer>
        {canScroll === "left" && <ScrollLeftButton carouselRef={carouselRef} />}

        <List
          aria-labelledby="attractions-heading"
          $isDragging={isDragging}
          ref={carouselRef}
          onMouseDown={handleMouseDown}
        >
          {attractions.map((attraction) => (
            <li key={attraction.title} style={{ display: "contents" }}>
              <Card
                draggable="false"
                target="_blank"
                href={attraction.url}
                rel="noopener nofollow"
                onClick={() =>
                  sendAnalyticsInteractionEvent(
                    "Destination",
                    "Attraction:Click",
                    attraction.title
                  )
                }
                title={attraction.title}
              >
                <AttractionImage
                  alt={attraction.title}
                  imageUrl={attraction.imageUrl}
                />
                <Typography as="h5" weight="bold" variant="label-md">
                  {attraction.title}
                </Typography>
              </Card>
            </li>
          ))}
        </List>
        {canScroll === "right" && (
          <ScrollRightButton carouselRef={carouselRef} />
        )}
      </ListContainer>
    </Container>
  );
}

function AttractionImage({ alt, imageUrl }: { alt: string; imageUrl: string }) {
  async function checkImageIsValid(url: string) {
    // We need to do this because some images return a 307 status code, which is not an error.
    // This is in-place of img.onerror, which doesn't work for all of our cases here.
    const res = await fetch(url, { method: "HEAD" });
    return res.status === 200;
  }

  const { data: isValid } = useQuery({
    queryKey: ["checkImageIsValid", imageUrl],
    queryFn: () => checkImageIsValid(imageUrl),
    enabled: !!imageUrl,
    staleTime: ONE_DAY_IN_MILLISECONDS,
  });

  return (
    <ImageContainer>
      <img
        draggable="false"
        src={isValid ? imageUrl : imagePlaceholder}
        alt={alt}
        loading="lazy"
        onError={(e) => {
          e.currentTarget.src = imagePlaceholder;
        }}
      />
    </ImageContainer>
  );
}

function ScrollLeftButton({
  carouselRef,
}: {
  carouselRef: React.RefObject<HTMLUListElement>;
}) {
  const intl = useIntl();

  return (
    <ScrollLeftArrow>
      <Button
        aria-label={intl.formatMessage(messages.scrollLeft)}
        size="small"
        variant="secondary"
        onPress={() => {
          if (carouselRef.current) {
            sendAnalyticsInteractionEvent("Destination", "Click:ScrollLeft");
            carouselRef.current.scrollBy({
              left: -carouselRef.current.clientWidth,
              behavior: "smooth",
            });
          }
        }}
      >
        <Icon size="small">
          <ArrowBackward tint="cod" />
        </Icon>
      </Button>
    </ScrollLeftArrow>
  );
}

function ScrollRightButton({
  carouselRef,
}: {
  carouselRef: React.RefObject<HTMLUListElement>;
}) {
  const intl = useIntl();

  return (
    <ScrollRightArrow>
      <Button
        aria-label={intl.formatMessage(messages.scrollRight)}
        size="small"
        variant="secondary"
        onPress={() => {
          if (carouselRef.current) {
            sendAnalyticsInteractionEvent("Destination", "Click:ScrollRight");
            carouselRef.current.scrollBy({
              left: carouselRef.current.clientWidth,
              behavior: "smooth",
            });
          }
        }}
      >
        <Icon size="small">
          <ArrowForward tint="cod" />
        </Icon>
      </Button>
    </ScrollRightArrow>
  );
}

export function AttractionListSkeleton() {
  return (
    <Container>
      <HeadingContainer>
        <Skeleton width="120px" height="24px" />
      </HeadingContainer>
      <List $isDragging={false}>
        {[...Array(8)].map((_, index) => (
          <li key={index} style={{ display: "contents" }}>
            <Card>
              <ImageContainer>
                <Skeleton width={`${CARD_SIZE}px`} height={`${CARD_SIZE}px`} />
              </ImageContainer>
              <Skeleton width="100px" height="18px" />
            </Card>
          </li>
        ))}
        <ScrollRightArrow>
          <Skeleton borderRadius="rounded_full" width="32px" height="32px" />
        </ScrollRightArrow>
      </List>
    </Container>
  );
}

const arrowStyles = css`
  position: absolute;
  top: ${CARD_SIZE / 2}px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  button {
    border-radius: ${border_radius.rounded_full};
  }
`;

const ScrollRightArrow = styled.div`
  right: ${spacing.lg};
  ${arrowStyles}
`;

const ScrollLeftArrow = styled.div`
  left: ${spacing.lg};
  z-index: 2;
  ${arrowStyles}
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: ${spacing.xxxl} 0;
  background-color: ${color.bg.fill.fill};
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${spacing.xxl};
  margin-bottom: ${spacing.sm};
`;

const ListContainer = styled.div`
  position: relative;
`;

const List = styled.ul<{ $isDragging: boolean }>`
  // Reset
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  position: relative;
  gap: ${spacing.lg};
  overflow-x: scroll;
  padding: ${spacing.xl} ${spacing.xxl};

  &:active {
    cursor: grabbing;
  }

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      * {
        pointer-events: none;
      }
    `}

  ${scrollbarHiddenStyling}
`;

const ImageContainer = styled.div`
  overflow: hidden;
  width: min(33vw, ${CARD_SIZE}px);
  height: min(33vw, ${CARD_SIZE}px);
  margin-bottom: ${spacing.sm};
  border-radius: ${border_radius.rounded_md};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Card = styled.a`
  max-width: ${CARD_SIZE}px;
  text-decoration: none;
  color: inherit;

  img {
    transition: transform 0.2s;
    transform-origin: center center;
  }
  &:hover,
  &:focus-visible {
    text-decoration: underline;
    img {
      transform: scale(1.05);
    }
  }
  &:focus-visible {
    outline: none;
    ${ImageContainer} {
      outline: 2px solid var(--color-ring);
    }
  }
`;
