import { DEFAULT_MAP_ZOOM } from "src/components/Map/constants";
import type { TicketableSchedulesPassengerDetails } from "../PassengerDetailsProvider";
import type { SupportedCurrencyCode } from "../utils/currency";
import type { SupportedLanguageCode } from "../utils/language";
import type { HotelProviderKind } from "../utils/types/accommodationProviders";
import { buildUrl } from "../utils/url";
import type { ApiConfig } from "./ApiConfig";

export function autocompleteEndpoint(
  config: ApiConfig,
  query: string,
  language: SupportedLanguageCode
) {
  const publicApiService = window.publicApiService;
  return buildUrl(`${config.endpoint16Base}/Autocomplete`, {
    key: config.key,
    query: query,
    languageCode: language,
    ...(publicApiService && { publicApiService: true }),
  });
}

export function searchEndpoint(
  config: ApiConfig,
  origin: string | undefined,
  originGooglePlaceId: string | undefined,
  destination: string | undefined,
  destinationGooglePlaceId: string | undefined,
  language: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  oPos?: string,
  dPos?: string,
  lightweightRequest = false // rome2rio-core/OpenAPI/docs/SearchApi.md
) {
  return buildUrl(`${config.endpoint15Base}/search`, {
    key: config.key,
    oName: origin,
    oGooglePlaceId: originGooglePlaceId,
    dName: destination,
    dGooglePlaceId: destinationGooglePlaceId,
    oPos,
    dPos,
    languageCode: language,
    currencyCode: currencyCode,
    uid: config.uid,
    aqid: config.aqid,
    analytics: !!config.uid,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    groupOperators: true,
    noAir: lightweightRequest,
    noPrice: lightweightRequest,
  });
}

export function geocodeCanonicalEndpoint(
  config: ApiConfig,
  canonicalName: string | undefined,
  language: SupportedLanguageCode
) {
  const publicApiService = window.publicApiService;
  return buildUrl(`${config.endpoint16Base}/canonical`, {
    key: config.key,
    canonicalName,
    languageCode: language,
    ...(publicApiService && { publicApiService: true }),
  });
}

export function schedulesEndpoint(
  config: ApiConfig,
  searchData: string,
  oDateTime: string, // ISO
  languageCode: SupportedLanguageCode,
  oneDayOnly?: boolean,
  iDateTime?: string, // ISO
  currencyCode?: string, // ISO
  requestId?: string
) {
  return buildUrl(`${config.endpoint15Base}/schedules`, {
    key: config.key,
    searchData,
    languageCode,
    oDateTime,
    iDateTime,
    uid: config.uid,
    aqid: config.aqid,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    maxForwardSearchDays: oneDayOnly ? 1 : undefined,
    useIndicativePrices: true,
    currencyCode,
    requestId,
  });
}

export function currenciesEndpoint(config: ApiConfig) {
  const publicApiService = window.publicApiService;
  return buildUrl(`${config.endpoint15Base}/currencies`, {
    key: config.key,
    uid: config.uid,
    aqid: config.aqid,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    ...(publicApiService && { publicApiService: true }),
  });
}

export function ticketableSchedulesEndpoint(
  config: ApiConfig,
  searchData: string,
  oDateTime: string, // ISO
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  isReturn: boolean,
  passengerDetails?: TicketableSchedulesPassengerDetails,
  iDateTime?: string, // ISO
  outboundSelectionId?: string,
  requestId?: string,
  extendScheduleId?: string,
  extendDirection?: "outbound" | "inbound",
  extendTo?: "earlier" | "later"
) {
  return buildUrl(`${config.endpoint15Base}/ticketableschedules`, {
    key: config.key,
    searchData,
    languageCode,
    oDateTime,
    iDateTime,
    uid: config.uid,
    aqid: config.aqid,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    currencyCode,
    paxAges: passengerDetails ? passengerDetails.paxAges : "",
    paxCards: passengerDetails ? passengerDetails.paxCards : "",
    paxTypes: passengerDetails ? passengerDetails.paxTypes : "",
    outboundSelection: outboundSelectionId,
    allowLazyReturnSearch: isReturn,
    requestId,
    extendScheduleId: extendScheduleId,
    extendDirection: extendDirection,
    extendTo: extendTo,
  });
}

// This is used for searching Static Connected Schedules
export function connectedSchedulesEndpoint(
  config: ApiConfig,
  routeData: string,
  oDateTime: string, // ISO
  languageCode: SupportedLanguageCode,
  oneDayOnly?: boolean,
  requestId?: string
) {
  return buildUrl(`${config.endpoint15Base}/ConnectedSchedule`, {
    key: config.key,
    routeData,
    languageCode,
    oDateTime,
    uid: config.uid,
    aqid: config.aqid,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    pruneSchedules: true,
    schedulesResponse: true,
    maxForwardSearchDays: oneDayOnly ? 1 : undefined,
    useIndicativePrices: true,
    requestId,
  });
}

export function flightSchedulesEndpoint(
  config: ApiConfig,
  origin: string,
  destination: string,
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  departureDateTime?: string,
  passengerDetails?: TicketableSchedulesPassengerDetails,
  returnDateTime?: string,
  requestId?: string
) {
  let flightSchedulesRequest = {
    key: config.key,
    oName: origin,
    dName: destination,
    languageCode,
    currencyCode,
    uid: config.uid,
    aqid: config.aqid,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    requestId,
  };

  return buildUrl(`${config.endpoint15Base}/flightSchedules`, {
    ...flightSchedulesRequest,
    oDateTime: departureDateTime,
    iDateTime: returnDateTime,
    paxAges: passengerDetails ? passengerDetails.paxAges : "",
    paxCards: passengerDetails ? passengerDetails.paxCards : "",
    paxTypes: passengerDetails ? passengerDetails.paxTypes : "",
  });
}

export function hotelListEndpoint(
  config: ApiConfig,
  canonicalName: string | undefined,
  lat: number | undefined,
  lng: number | undefined,
  googlePlaceId: string | undefined,
  arrivalDate: string | undefined, // YYYY-MM-DD,
  departureDate: string | undefined, // YYYY-MM-DD,
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  zoom = DEFAULT_MAP_ZOOM,
  radius = 25
) {
  const publicApiService = window.publicApiService;
  // RoomsData is included in POST body.
  return buildUrl(`${config.endpoint16Base}/hotels/hotelList`, {
    key: config.key,
    pos: lat && lng ? `${lat},${lng}` : undefined,
    languageCode,
    currencyCode,
    name: canonicalName,
    googlePlaceId,
    arrivalDate,
    departureDate,
    uid: config.uid,
    aqid: config.aqid,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    zoom, // Zoom is required because the default zoom doesn't return any results with minPrice > 0
    radius,
    flags: config.backendFlags,
    ...(publicApiService && { publicApiService: true }),
  });
}

export function hotelInfoEndpoint(
  config: ApiConfig,
  hotelId: string,
  arrivalDate: string | undefined, // YYYY-MM-DD,
  departureDate: string | undefined, // YYYY-MM-DD,
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  provider: HotelProviderKind
) {
  const publicApiService = window.publicApiService;
  return buildUrl(`${config.endpoint16Base}/hotels/hotelInfo`, {
    key: config.key,
    hotelId,
    arrivalDate,
    departureDate,
    languageCode,
    currencyCode,
    provider,
    uid: config.uid,
    aqid: config.aqid,
    useHotelsRedirect: true,
    debugFeatures: config.backendFeatures,
    debugExperiments: config.backendExperiments,
    flags: config.backendFlags,
    ...(publicApiService && { publicApiService: true }),
  });
}

export function logExitEndpoint(config: ApiConfig) {
  return buildUrl(`${config.endpointNonVersioned}json/LogExit`, {
    uid: config.uid,
    aqid: config.aqid,
  });
}

export function assignUidEndpoint(endpoint: string) {
  return `${endpoint}/api/json/AssignUid`;
}

export function sessionEndpoint(config: ApiConfig): string {
  const publicApiService = window.publicApiService;
  return buildUrl(`${config.endpoint16Base}/Auth/Session`, {
    key: config.key,
    uid: config.uid,
    aqid: config.aqid,
    ...(publicApiService && { publicApiService: true }),
  });
}

export function initializeAuthEndpoint(config: ApiConfig) {
  const publicApiService = window.publicApiService;
  return buildUrl(`${config.endpoint16Base}/Auth/Initialize`, {
    key: config.key,
    uid: config.uid,
    aqid: config.aqid,
    ...(publicApiService && { publicApiService: true }),
  });
}

export function getUserEndpoint(config: ApiConfig, path?: string): string {
  const publicApiService = window.publicApiService;
  return buildUrl(`${config.endpoint16Base}/User${path}`, {
    key: config.key,
    uid: config.uid,
    aqid: config.aqid,
    debugFeatures: "DeleteUserProfiles",
    ...(publicApiService && { publicApiService: true }),
  });
}

export function getDestinationInfoEndpoint(
  config: ApiConfig,
  destination: string,
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  radius?: number,
  maxAttractions?: number,
  origin?: string
) {
  return buildUrl(`${config.endpoint16Base}/destinationInfo`, {
    key: config.key,
    uid: config.uid,
    aqid: config.aqid,
    destination,
    languageCode,
    currencyCode,
    radius,
    maxAttractions,
    origin,
  });
}
