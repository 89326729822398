import styled from "styled-components";
import { type PropsWithChildren, useEffect, useRef, useState } from "react";
import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import { useLocation } from "react-router";
import { useResizeObserver } from "src/utils/hooks/useResizeObserver";
import { useGetPageType } from "src/utils/hooks/useGetPageType";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import { safeSessionStorage } from "src/utils/safeLocalStorage";

type Props = {
  isDisabled?: boolean;
  zIndex?: number;
  headerOffset?: number;
  className?: string;
  logScrollPercent?: boolean;
  isScrollNudgeHidden?: boolean;
  setIsScrollNudgeHidden?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * This component enables the container to stick its end of content to the bottom of the viewport on scroll.
 * To do this, it gets the height of the container's content, and offsets this from the user's view height,
 * as well as factoring in any offset required for the header height.
 * It uses a resize observer to ensure any container height changes such as ad load or accordion expand/collapse
 * are correctly factored into the calculations.
 *
 * It is best suited to 2+ column layouts and does not really provide any value for mobile.
 */
export function ScrollableContainer({
  isDisabled,
  zIndex,
  headerOffset = 0,
  className,
  children,
  logScrollPercent = false,
  isScrollNudgeHidden,
  setIsScrollNudgeHidden,
}: PropsWithChildren<Props>) {
  const [containerHeight, setContainerHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const location = useLocation();
  const ref = useRef<HTMLDivElement | null>(null);
  const pageType = useGetPageType();
  const [maxScrollDepth, setMaxScrollDepth] = useState(0);
  const isMobile = useLayout() === "mobile";

  const { height } = useResizeObserver(ref) || {};
  const hotelListRendered = document.getElementById("hotel-list-wrapper");

  useEffect(() => {
    if (height !== undefined) {
      setContainerHeight(height);
    }
  }, [height]);

  useEffect(() => {
    // Set initial height for container and header height
    const current = ref.current;
    if (current) {
      setHeaderHeight(window.scrollY + current.getBoundingClientRect().top);
      setContainerHeight(current.offsetHeight);
    }
  }, [location.key]);

  useEffect(() => {
    // Reset max scroll depth when the page changes
    setMaxScrollDepth(0);
  }, [pageType]);

  // SCROLL NUDGE LOGIC
  useEffect(() => {
    // if we don't have the setter, we don't need to check
    if (!setIsScrollNudgeHidden) return;
    // if the user has already hidden the nudge, don't show it again
    if (safeSessionStorage.getItem("scrollNudgeHidden") === "true") return;
    // when the hotelList resets, we need to reset the scroll nudge
    if (hotelListRendered === null) {
      setIsScrollNudgeHidden(true);
    } else {
      // if user can scroll, show the nudge
      if (hotelListRendered.clientHeight > window.innerHeight - headerHeight) {
        setIsScrollNudgeHidden(false);
      } else {
        // if the user can't scroll, hide the nudge, but don't set the session storage
        setIsScrollNudgeHidden(true);
      }
    }
  }, [
    containerHeight,
    headerHeight,
    hotelListRendered,
    setIsScrollNudgeHidden,
  ]);

  useEffect(() => {
    // we don't want to record this for right rail ads
    if (!logScrollPercent) return;

    function handleScroll() {
      if (!ref.current) return;
      const scrollPercent = getScrollPercent(ref.current);
      // round down to the nearest 10
      const roundedScrollPercent = Math.floor(scrollPercent / 10) * 10;
      if (roundedScrollPercent > maxScrollDepth) {
        // Log the scroll percentage
        setMaxScrollDepth(roundedScrollPercent);
        sendAnalyticsNonInteractionEvent(
          pageType,
          "ScrolledPercent",
          `${roundedScrollPercent.toString()}%`
        );
      }

      // If the user is scrolling, they are aware that they can scroll and the nudge is unnecessary.
      if (
        setIsScrollNudgeHidden &&
        !isScrollNudgeHidden &&
        scrollPercent > 20
      ) {
        setIsScrollNudgeHidden(true);
        safeSessionStorage.setItem("scrollNudgeHidden", "true");
      }
    }

    const scrollableContainer = isMobile
      ? window
      : document.getElementById("layout-container");
    if (!scrollableContainer) return;

    scrollableContainer.addEventListener("scroll", handleScroll, false);
    return () =>
      scrollableContainer.removeEventListener("scroll", handleScroll, false);
  }, [
    logScrollPercent,
    isMobile,
    maxScrollDepth,
    pageType,
    isScrollNudgeHidden,
    setIsScrollNudgeHidden,
  ]);

  return (
    <Container
      ref={ref}
      className={className}
      contentHeight={containerHeight}
      headerHeight={headerHeight}
      headerOffset={headerOffset}
      zIndex={zIndex}
      isDisabled={isDisabled}
    >
      {children}
    </Container>
  );
}

const Container = styled.div<{
  contentHeight: number;
  headerHeight?: number;
  headerOffset?: number;
  zIndex?: number;
  isDisabled?: boolean;
}>`
  ${desktopLayout} {
    ${(props) =>
      !props.isDisabled &&
      `
      display: flex;
      flex-direction: column;
      height: fit-content;
      min-height: calc(100vh + ${props.headerOffset}px - ${
        props.headerHeight
      }px);

      position: sticky;
      top: min(${props.headerHeight}px + ${
        props.headerOffset
      }px, calc(100vh - ${props.contentHeight}px + ${props.headerOffset}px - ${
        props.headerHeight
      }px));
      z-index: ${props.zIndex ?? 0};
    `}
  }
`;

function getScrollPercent(element: HTMLDivElement) {
  // Height of the header
  const headerHeight = element.offsetTop;
  // The height of the content
  const scrollHeight = element.scrollHeight;
  // The height of the viewport minus the header
  const innerHeight = window.innerHeight - headerHeight;
  // Top of the div
  const top = element.getBoundingClientRect().top;
  // Our current position in the div
  const topMinusHeader = Math.abs(top - headerHeight);
  // Percentage of the page scrolled rounded
  const scrollPercent = Math.round(
    (topMinusHeader / (scrollHeight - innerHeight)) * 100
  );
  return scrollPercent;
}
