import type { SearchResponse } from "../../api/SearchResponse";
import { vehicleFromSegment } from "../../utils/adapters/vehicle";
import { transitModeFromVehicleKind } from "../../utils/adapters/transitMode";
import type { Mode } from "../../utils/types/mode";

export function getTransportModes(
  searchResponse: SearchResponse,
  routeIndex: number,
  segmentIndexOfRoute?: number
): Mode[] {
  let modes: Mode[];
  if (segmentIndexOfRoute === undefined) {
    modes = modesOfRoute(searchResponse, routeIndex);
  } else {
    modes = modesOfSegment(searchResponse, routeIndex, segmentIndexOfRoute);
  }

  return modes;
}

function modesOfRoute(
  searchResponse: SearchResponse,
  routeIndex: number
): Mode[] {
  return (
    searchResponse.routes[routeIndex].segments
      .map((segmentIndex) => {
        const segment = searchResponse.segments[segmentIndex];
        const vehicle = vehicleFromSegment(searchResponse, segment);
        if (vehicle.kind === "transfer") {
          // We don't want to include transfer segments because the user can't
          // click into them and they don't represent a real segment.
          return undefined;
        }
        return transitModeFromVehicleKind(vehicle.kind);
      })
      // Remove undefined modes and coerce the type to match.
      .filter((mode) => mode) as Mode[]
  );
}

function modesOfSegment(
  searchResponse: SearchResponse,
  routeIndex: number,
  segmentIndexOfRoute: number
): Mode[] {
  const route = searchResponse.routes[routeIndex];
  const segment = searchResponse.segments[route.segments[segmentIndexOfRoute]];
  const vehicle = vehicleFromSegment(searchResponse, segment);
  return [transitModeFromVehicleKind(vehicle.kind)];
}
