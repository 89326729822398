import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight } from "../../../theme";

export const TitleTripPlanner = styled.h2`
  position: relative;
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
`;
