import type { DurationFormat } from "../conversions/duration";

export interface RangeOrRoundedHours {
  lowerHours: number;
  upperHours: number;
  layout: DurationLayout;
}

const HOURS_IN_DAY = 24;
const MINUTES_IN_HOUR = 60;
const MINS_HALFHOUR = 30;
const DAYS_TO_SHOW_AS_DAYS = 4;
const MINUTES_TO_GIVE_WHEN_DURATION_WAS_ZERO = 1;

export type DurationLayout =
  | "exactDays"
  | "exactDaysHours"
  | "exactHours"
  | "exactHoursMinutes"
  | "exactMinutes"
  | "approxDays"
  | "approxDaysHours"
  | "approxDayRangeHours"
  | "approxHours"
  | "approxRoundedHoursMinutes"
  | "approxRoundedMinutesOnly"
  | "approxRangeDays"
  | "approxRangeHours"
  | "approxRangeMinsRoundedBy5"
  | "approxRangeMinsRoundedBy30"
  | "approxHoursRounded30Fraction"
  | "approxFractionOfHourOnly";

const FRACTION_HALF = "\u00BD";
const FRACTION_NONE = "";

/**
 * Represent a single duration in multiple units that make up
 * that duration - number days, hours and minutes.
 */
export type TimeUnits = {
  minutes: number;
  hours: number;
  days: number;
  lowerHours: number;
  upperHours: number;
  layout: DurationLayout;
  fraction: string;
  isDaysConvertedToHours: boolean;
};

export function makeTimeUnits(originalDuration: DurationFormat): TimeUnits {
  let time: TimeUnits;
  let init: TimeUnits = {
    days: originalDuration.days,
    hours: originalDuration.hours,
    minutes: originalDuration.minutes,
    layout: "exactDays",
    fraction: FRACTION_NONE,
    lowerHours: 0,
    upperHours: 0,
    isDaysConvertedToHours: false,
  };
  time = convertDaysToHoursWhereNumberIsSmall(init);
  time = setBestExactLayout(time);
  return time;
}

/**
 * Rounds the minutes component to nearest 30 minutes. No side-effect (pure function)
 * Rounding up can increase the hours number.
 * @param originalTime - original TimeUnits value to workfrom
 * @returns - new TimeUnits object with rounded minutes.
 */
export function roundedTo30Mins(originalTime: TimeUnits): TimeUnits {
  let days = originalTime.days;
  let hours = originalTime.hours;
  let minutes = originalTime.minutes;
  let layout: DurationLayout;

  let showMinutes = function (): boolean {
    return minutes > 0;
  };
  let showHours = function (): boolean {
    return hours > 0;
  };
  let showDays = function (): boolean {
    return days > 0;
  };

  if (isZeroEstimate(originalTime)) {
    minutes = MINUTES_TO_GIVE_WHEN_DURATION_WAS_ZERO;
  } else if (showMinutes()) {
    let rounding = roundToNum(minutes, MINS_HALFHOUR);
    if (rounding === MINUTES_IN_HOUR) {
      hours++;
      minutes = 0;
    } else {
      minutes = rounding;
    }
  }

  if (showDays()) {
    layout = showHours() ? "approxDaysHours" : "approxDays";
  } else if (showHours()) {
    layout = showMinutes() ? "approxRoundedHoursMinutes" : "approxHours";
  } else {
    layout = "approxRoundedMinutesOnly";
  }

  const rounded: TimeUnits = applyLayoutOverrides(originalTime, {
    ...originalTime,
    minutes: minutes,
    hours: hours,
    days: days,
    lowerHours: 0,
    upperHours: 0,
    layout: layout,
  });

  return rounded;
}

/**
 * Expresses a duration as a range of hours or days, hours.
 * Any minutes are reduced to zero and the upper hour range is set,
 * otherwise, the duration is considered a whole number of hours (no range).
 * @returns Duration with range or no-range flagged and marked as APPROXIMATE
 */
export function rangeHours(time: TimeUnits): TimeUnits {
  const rangeHours = applyBestRangeLayoutAndRange(time, "approxRangeHours");
  const finalAnswer = applyLayoutOverrides(time, rangeHours);
  return finalAnswer;
}

/**
 * Rounded to nearest 30 min. If it's a time that ends in 30, becomes
 * a range.
 * e.g., 12h 28m -> 12h 30m (rounded to nearest 30); final result is: "12 - 13 hours"
 * @returns hour range based on minutes rounded to 30 or not and marked as APPROXIMATE
 */
export function roundedRangeHours(originalTime: TimeUnits): TimeUnits {
  const roundedTime = roundedTo30Mins(originalTime);
  const roundedRange = applyLayoutOverrides(
    originalTime,
    applyBestRangeLayoutAndRange(roundedTime, "approxRangeMinsRoundedBy30")
  );

  return roundedRange;
}

/**
 * Rounded to nearest 30 min and then flagged to show 30 min (if present) as fraction (1/2)
 * No range of hours.
 *
 * e.g., 12h 28m -> 12h 30m -> 12[1/2] hours
 * @returns duration with minutes rounded to 30 minutes and marked as APPROXIMATE
 */
export function roundedFractionHours(time: TimeUnits): TimeUnits {
  const roundedTime: TimeUnits = roundedTo30Mins(time);
  let layout: DurationLayout;
  let fractionIndicator: string;

  if (hasDays(roundedTime)) {
    layout = hasHours(roundedTime) ? "approxDaysHours" : "approxDays";
    fractionIndicator = "";
  } else {
    layout = getLayoutInHourFractionFor(roundedTime);
    fractionIndicator = getHourFractionFor(roundedTime);
  }

  const roundedFraction: TimeUnits = {
    ...roundedTime,
    layout: layout,
    fraction: fractionIndicator,
  };

  return applyLayoutOverrides(time, roundedFraction);
}

/**
 * Indicates if given time was effectively 0 minutes so special handing is probably
 * needed.
 * @param time - TimeUnits to check
 * @returns true if needs ZERO time handling.
 */
export function isZeroEstimate(time: TimeUnits): boolean {
  return (
    time.days === 0 &&
    time.hours === 0 &&
    (time.minutes === 0 ||
      time.minutes === MINUTES_TO_GIVE_WHEN_DURATION_WAS_ZERO)
  );
}

///--- internal ----
/**
 * Checks for special cases that are to be applied for any
 * feture and creates an adjusted version of the given
 * time record with adjusted the layouts (if not other values).
 * - main case here is < 1 hour should take original time and use 'exactMinutes' layout.
 * @param time original (TimeUnits) record
 * @param formatted record (TimeUnits) where range, rounding and layout had been adjusted.
 * @returns copy that is adjusted where needed.
 */
function applyLayoutOverrides(
  time: TimeUnits,
  formatted: TimeUnits
): TimeUnits {
  const overiddenFormat: TimeUnits = isDurationTooSmallForApproxFormatting(time)
    ? { ...time, layout: "exactMinutes" }
    : { ...formatted };
  return overiddenFormat;
}

function isDurationTooSmallForApproxFormatting(time: TimeUnits): boolean {
  return time.days === 0 && time.hours === 0;
}

function hasDays(time: TimeUnits) {
  return time.days > 0;
}
function hasHours(time: TimeUnits) {
  return time.hours > 0 || isHourRange(time);
}
function hasMinutes(time: TimeUnits) {
  return time.minutes > 0;
}
function isHourRange(time: TimeUnits) {
  return time.layout === "approxRangeHours";
}

function isHoursMinutesToBeShownMatchingFormattedDurationBehaviour(
  time: TimeUnits
) {
  return time.isDaysConvertedToHours || hasMinutes(time);
}

/**
 * Takes a TimeUnits and returns a range-hours version
 * @param time original TimeUnits
 * @param layoutForHoursMins - selected layout to apply
 * @returns TimeUnits object with best-fit layout for a range feature
 */
function applyBestRangeLayoutAndRange(
  time: TimeUnits,
  layoutForHoursMins: DurationLayout
): TimeUnits {
  let layout: DurationLayout;
  let hasHourRange = hasMinutes(time);

  if (hasDays(time)) {
    layout = hasHourRange ? layoutForHoursMins : "approxDays";
  } else {
    layout = hasHourRange ? layoutForHoursMins : "approxHours";
  }
  let lowerHours = time.hours;
  let upperHours = hasHourRange ? time.hours + 1 : 0;

  return {
    ...time,
    lowerHours: lowerHours,
    upperHours: upperHours,
    layout: layout,
  };
}

function setBestExactLayout(duration: TimeUnits): TimeUnits {
  let layout: DurationLayout;

  if (hasDays(duration)) {
    if (hasHours(duration)) {
      layout = "exactDaysHours";
    } else {
      layout = "exactDays";
    }
  } else if (hasHours(duration)) {
    if (isHoursMinutesToBeShownMatchingFormattedDurationBehaviour(duration)) {
      layout = "exactHoursMinutes";
    } else {
      layout = "exactHours";
    }
  } else {
    layout = "exactMinutes";
  }
  return { ...duration, layout: layout };
}

function convertDaysToHoursWhereNumberIsSmall(time: TimeUnits): TimeUnits {
  let hours = time.hours;
  let days = time.days;
  let isDaysConvertedToHours: boolean;

  if (hasDays(time) && time.days < DAYS_TO_SHOW_AS_DAYS) {
    hours = hoursWithDaysAsHours(time);
    days = 0;
    isDaysConvertedToHours = true;
  } else {
    isDaysConvertedToHours = false;
  }

  return {
    ...time,
    days: days,
    hours: hours,
    isDaysConvertedToHours: isDaysConvertedToHours,
  };
}

/**
 *
 * @param value Rounds down/up to a number of (minutes mostly) to a given quotient.
 *  The round-up threshold is half of the quotient - e.g. 15 mins if quotient is 30 mins
 * @param quotient means the number you're dividing by or rounding to... (5 or 30 likely
 * @returns the rounded number
 */
function roundToNum(value: number, quotient: number): number {
  let remainder = value % quotient;
  let base = value - remainder;
  let isToRoundUp = remainder >= quotient / 2;
  let result = base + (isToRoundUp ? quotient : 0);
  return result;
}

/**
 * Determines the correct layout given possibilites of hours-only, hours & mins and minutes-only.
 * @param rounded The object to check for hours, minutes and fractional hours worth of minutes
 * @returns Appropriate layout that can be rendered.
 */
function getLayoutInHourFractionFor(rounded: TimeUnits): DurationLayout {
  if (hasHours(rounded))
    return isHalfFraction(rounded)
      ? "approxHoursRounded30Fraction"
      : "approxHours";
  else
    return isHalfFraction(rounded) ? "approxFractionOfHourOnly" : "approxHours";
}

/**
 * Determines the correct UNICODE fraction const to return
 * @param rounded TimeUnit object to check
 * @returns string containing fraction as UNICODE
 */
function getHourFractionFor(rounded: TimeUnits): string {
  return isHalfFraction(rounded) ? FRACTION_HALF : FRACTION_NONE;
}

/**
 * Determines if minutes is half an hour.
 * @param rounded - TimeUnits object to check
 * @returns TRUE if half an hour.
 */
function isHalfFraction(rounded: TimeUnits): boolean {
  return hasMinutes(rounded) && rounded.minutes === MINS_HALFHOUR;
}

function hoursWithDaysAsHours(time: TimeUnits): number {
  return time.hours + HOURS_IN_DAY * time.days;
}
