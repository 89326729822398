import type { TypedLocation } from "../hooks/useTypedLocation";

// Clones the provided location object WITHOUT including the key which we don't
// want to copy into the new object because key is automatically generated
// for new states and is supposed to be unique between states and is used
// internally by the router.
export function cloneLocation(current: TypedLocation): TypedLocation {
  return {
    search: current.search,
    hash: current.hash,
    pathname: current.pathname,
    state: current.state,
  };
}
