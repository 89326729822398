import type { MouseEvent } from "react";
import { useIntl } from "react-intl";
import { useHref, useLinkClickHandler } from "react-router-dom";
import { border_radius } from "src/design-system/tokens/border";
import styled from "styled-components";
import { useSkipToFlightSegment } from "src/utils/hooks/useSkipToFlightSegment";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { formatDistance } from "../../../components/FormattedDistance/FormattedDistance";
import { FormattedDuration } from "../../../components/FormattedDuration/FormattedDuration";
import { FormattedPriceRange } from "../../../components/FormattedPriceRange/FormattedPriceRange";
import { Icon } from "../../../components/Icon/Icon";
import { useHoveredIndex } from "../../../HoveredIndexProvider";
import { ChevronRightBold } from "../../../svg/ChevronRightBold";
import { Gas } from "../../../svg/Gas";

import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  notificationColor,
  spacing,
} from "../../../theme";
import { desktopLayout } from "../../../utils/hooks/useLayout";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";
import useUser from "../../../utils/hooks/useUser";
import { hashChange } from "../../../utils/location/hashChange";
import type { SearchResultViewModel } from "../createSearchResultViewModel";
import messages from "../SearchResultsContent/SearchResultsContent.messages";
import { TransitIcons } from "./TransitIcons";

export type SearchResultTweakedProps = {
  className?: string;
  routeLabel: string;
} & SearchResultViewModel;

export function SearchResult(props: SearchResultTweakedProps) {
  const intl = useIntl();
  const { distanceSystem } = useUser();
  const { setHoveredRouteIndex } = useHoveredIndex();
  const singleFlightSegmentIndex = useSkipToFlightSegment(props.routeIndex);

  function handleMouseEnter() {
    setHoveredRouteIndex(props.routeIndex);
  }

  // Skip straight to segment screen if only one segment in the route or one major flight.
  let suffix = "";
  if (props.segments.length === 1) {
    suffix = "/s/0";
  } else if (singleFlightSegmentIndex !== undefined) {
    suffix = `/s/${singleFlightSegmentIndex}`;
  }

  const hash = `#r/${props.canonicalName}${suffix}`;
  const location = useTypedLocation();
  const { state, ...to } = hashChange(hash, location);

  // We can't use react-router's <Link> component because it doesn't give us the standard
  // behaviour of an anchor tag that we want eg. ctrl+click opens the link in a new tab,
  // but also navigates to the link in the current tab. Instead we need to use an anchor
  // tag with the helper hook useLinkClickHandler.
  const href = useHref(to);
  const linkClickHandler = useLinkClickHandler(to, { state });

  // Handler for react-router navigation on an anchor tag.
  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    sendAnalyticsInteractionEvent(
      "SearchResults",
      "Click:Result",
      props.routeLabel
    );
    linkClickHandler(event);
  }

  const majorModes = props.segments
    .filter((it) => it.isMajor)
    .map((it) => it.transitMode);

  let resultTitle = props.title;

  const isDriveRoute = props.canonicalName === "Drive";
  // A drive route should only have one segment, which we can get the distance from.
  if (isDriveRoute && props.totalDistance) {
    const distance = formatDistance(props.totalDistance, distanceSystem, intl);
    resultTitle = `${props.title} ${distance}`;
  }

  return (
    <Container
      className={props.className}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      href={href}
    >
      <SpaceBetween>
        <TransitIcons modes={majorModes} />
        {props.tag && (
          <TagContainer>
            <Tag>{intl.formatMessage(messages[props.tag])}</Tag>
          </TagContainer>
        )}
      </SpaceBetween>
      <TypographyHeading>{resultTitle}</TypographyHeading>
      <SpaceBetween>
        <TypographyBody>
          <FormattedDuration totalMinutes={props.durationInMinutes} />
        </TypographyBody>

        <PriceRange>
          {props.priceRange && isDriveRoute && (
            <Icon size="md">
              <Gas tint="n300" />
            </Icon>
          )}
          <TypographyCta>
            {props.priceRange && <FormattedPriceRange {...props.priceRange} />}
            {/* When there's no price range we still show a chevron for the cta. */}
            <Icon size="sm-1" data-testid="chevron">
              <ChevronRightBold tint="pink" />
            </Icon>
          </TypographyCta>
        </PriceRange>
      </SpaceBetween>
    </Container>
  );
}

const Container = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: ${color.white};
  padding: ${spacing.lg};
  border: 1px solid ${color.n30};
  border-radius: ${border_radius.rounded_md};
  cursor: pointer;

  ${desktopLayout} {
    padding: ${spacing.xl};
  }

  &:not(:last-child) {
    margin-bottom: ${spacing.md};
  }

  &:active {
    background-color: ${color.grey1};
    -webkit-tap-highlight-color: ${color.grey1};
  }

  &:hover {
    background-color: ${color.grey2};
    -webkit-tap-highlight-color: transparent;

    // Reset hover state on touch devices so that they don't get a
    // lingering hover effect after a click event.
    &:not(:active) {
      @media (hover: none) {
        background-color: ${color.white};
        -webkit-tap-highlight-color: transparent;
      }
    }
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TagContainer = styled.div`
  padding-left: ${spacing.md};
`;

const Tag = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.small};
  line-height: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${notificationColor.low};
  background-color: #e6f2ee;
  border-radius: ${border_radius.rounded_sm};
  padding: 0 ${spacing.md};
`;

const TypographyHeading = styled.h1`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h5};
  line-height: ${lineHeight.tight};
  color: ${color.cod};
  margin: 8px 0;

  ${desktopLayout} {
    font-weight: ${fontWeight.semibold};
    line-height: ${lineHeight.snug};
    margin: 12px 0;
  }
`;

const PriceRange = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
`;

const TypographyBody = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  color: ${color.cod};
`;

const TypographyCta = styled(TypographyBody)`
  font-weight: ${fontWeight.medium};
  color: ${color.pink};
  display: flex;
  align-items: center;
  gap: 2px;
`;
