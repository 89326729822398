import styled from "styled-components";
import { CollapsibleAdUnit } from "./components/CollapsibleAdUnit/CollapsibleAdUnit";
import { LazyLoader } from "./components/DisplayAd/LazyLoader";
import { spacing, color } from "./theme";
import { useConst } from "./utils/hooks/useConst";
import {
  desktopLayout,
  mobileLayout,
  useLayout,
} from "./utils/hooks/useLayout";
import useSearch from "./utils/hooks/useSearch";
import { vehicleFromSegment } from "./utils/adapters/vehicle";
import { transitModeFromVehicleKind } from "./utils/adapters/transitMode";
import { useIsTripScreen } from "./utils/hooks/useIsTripScreen";
import { useTripSearchResponse } from "./domain/TripPlanner/hooks/useTripSearchResponse";
import { useTripOrTransportIndexes } from "./utils/hooks/useTripOrTransportIndexes";

type Props = {
  hide: boolean;
};

const footerAdLayouts = {
  mobile: ["singleMobile", "singleMobile2"],
  tablet: ["singleDesktop", "singleDesktop2"],
  desktop: ["singleDesktop", "singleDesktop2"],
} as const;

export function PaneFooterAds({ hide }: Props) {
  const layout = useLayout();
  // We want to keep track of the initial state of the ad because
  // we don't want to rerequest the ad if the layout changes.
  const [firstAd, secondAd] = useConst(footerAdLayouts[layout]);

  const isTripsScreen = useIsTripScreen();
  const { tripSearchResponse } = useTripSearchResponse();
  const { searchResponse: searchFromQuery } = useSearch();
  const searchResponse = isTripsScreen ? tripSearchResponse : searchFromQuery;

  const { routeIndex, routeSegmentIndex } = useTripOrTransportIndexes();

  // We are changing the background colour of the pane footer ads to match the segment screen
  // if it is part of the Drive Segment Optimisation experiment, so we are only needing to get
  // the transit mode if those conditions are met for now.
  const getTransitMode = () => {
    if (
      routeIndex === undefined ||
      searchResponse === undefined ||
      routeSegmentIndex === undefined
    ) {
      return undefined;
    }
    const route = searchResponse.routes[routeIndex];
    const segmentIndex = route.segments[routeSegmentIndex];
    const segment = searchResponse.segments[segmentIndex];

    const vehicle = vehicleFromSegment(searchResponse, segment);
    const transitMode = transitModeFromVehicleKind(vehicle.kind);

    return transitMode;
  };

  const isDriveSegment = getTransitMode() === "car";

  // We are currently disabling the collapsible ad functionality on mobile SERP screens
  // to investigate how it impacts CWV, specifically CLS.
  const disableAdCollapse = routeIndex === undefined && layout === "mobile";

  return (
    <Wrapper hide={hide} isDriveSegment={isDriveSegment}>
      <CollapsibleAdUnit
        variant="TieredDisplayAd"
        slotConfigId={firstAd}
        disableCollapse={disableAdCollapse}
      />
      <StyledCollapsibleAdUnit
        variant="CsaAd"
        hide={hide}
        disableCollapse={disableAdCollapse}
      />
      <LazyLoader loadMargin={0.4}>
        <CollapsibleAdUnit
          variant="DisplayAd"
          slotConfigId={secondAd}
          disableCollapse={disableAdCollapse}
        />
      </LazyLoader>
    </Wrapper>
  );
}

const Wrapper = styled.div<{
  hide: boolean;
  isDriveSegment: boolean;
}>`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;

  gap: ${spacing.xl};
  align-items: center;
  padding-bottom: ${spacing.xxl};

  ${mobileLayout} {
    padding: ${spacing.xxl} 0;
  }

  ${desktopLayout} {
    background-color: ${(props) => props.isDriveSegment && color.grey0};
  }
`;

const StyledCollapsibleAdUnit = styled(CollapsibleAdUnit)<{
  hide?: boolean;
}>`
  margin-inline: ${spacing.sm};
  align-self: stretch; // The CSA ad can be wider
`;
