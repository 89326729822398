import { useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useIntl } from "react-intl";
import { useApiConfig } from "src/api/ApiConfigProvider";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { spacing } from "src/design-system/tokens/spacing";
import { autocompleteToGeocodedPlace } from "src/utils/autocompleteToGeocodedPlace";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useSearchParamPlaces } from "src/utils/hooks/useSearchParamPlaces";
import styled from "styled-components";
import { DiscoverAutocomplete } from "../DiscoverAutocomplete/DiscoverAutocomplete";
import { messages } from "../DiscoverAutocomplete/DiscoverAutocomplete.messages";

type Props = {
  initialQuery?: string;
  setIsGeocoding: (isGeocoding: boolean) => void;
  setOrigin: (origin?: {
    canonicalName: string;
    lat: number;
    lng: number;
  }) => void;
};

export default function DiscoverSearch({
  setOrigin,
  setIsGeocoding,
  initialQuery,
}: Readonly<Props>) {
  const scrollContainerRef = useRef(null);
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();
  const { navigateSearchParamsFromPlaces } = useSearchParamPlaces();

  function handleAutocompleteSelect(place: AutocompletePlace) {
    setOrigin(undefined);
    setIsGeocoding(true);
    autocompleteToGeocodedPlace(
      place,
      (geocodedPlace) => {
        setOrigin({
          canonicalName: geocodedPlace.canonicalName,
          lat: geocodedPlace.lat,
          lng: geocodedPlace.lng,
        });
        navigateSearchParamsFromPlaces([geocodedPlace]);
        setIsGeocoding(false);
      },
      languageCode,
      queryClient,
      apiConfig
    );
  }

  return (
    <Wrapper>
      <DiscoverHeading>
        {intl.formatMessage(messages.findGetaways)}
      </DiscoverHeading>
      <AutocompleteWrapper ref={scrollContainerRef}>
        <DiscoverAutocomplete
          onSelect={handleAutocompleteSelect}
          initialQuery={initialQuery}
        />
      </AutocompleteWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;
  padding: ${spacing.xxl};
`;

const AutocompleteWrapper = styled.div`
  position: relative;
`;

const DiscoverHeading = styled.h3`
  margin-bottom: ${spacing.xl};
`;
