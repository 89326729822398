import { type SvgProps, useColor } from "./common";

export function AppleLogo(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3955 7.8407C18.2601 7.9442 15.8699 9.2713 15.8699 12.2222C15.8699 15.6354 18.9113 16.8429 19.0023 16.8728C18.9883 16.9464 18.5192 18.5265 17.3988 20.1365C16.3998 21.5533 15.3564 22.9678 13.7692 22.9678C12.1819 22.9678 11.7735 22.0593 9.94116 22.0593C8.15553 22.0593 7.52064 22.9977 6.0688 22.9977C4.61695 22.9977 3.60393 21.6867 2.43919 20.0767C1.09005 18.1861 0 15.249 0 12.4614C0 7.9902 2.95037 5.6189 5.85405 5.6189C7.39693 5.6189 8.68305 6.6171 9.65172 6.6171C10.5737 6.6171 12.0115 5.5591 13.7668 5.5591C14.4321 5.5591 16.8222 5.6189 18.3955 7.8407ZM12.9335 3.6662C13.6595 2.8175 14.173 1.6399 14.173 0.4623C14.173 0.299 14.159 0.1334 14.1286 0C12.9475 0.0437 11.5424 0.7751 10.6951 1.7434C10.0299 2.4886 9.40897 3.6662 9.40897 4.8599C9.40897 5.0393 9.43931 5.2187 9.45332 5.2762C9.52801 5.29 9.64939 5.3061 9.77076 5.3061C10.8305 5.3061 12.1633 4.6069 12.9335 3.6662Z"
        fill={tintColor}
      />
    </svg>
  );
}
