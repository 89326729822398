import { type MutableRefObject, useEffect, useState } from "react";

export function useIsInViewport(
  ref: MutableRefObject<HTMLElement | null>,
  threshold?: number, //between 0 and 1
  baseOffset?: number
) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    // If we don't have access to IntersectionObserver in older browsers or tests.
    if (
      !("IntersectionObserver" in window) ||
      !("IntersectionObserverEntry" in window) ||
      !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
    ) {
      return;
    }

    const rootMarginString = `0px 0px -${baseOffset ? baseOffset : 0}px 0px`;

    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      {
        threshold: threshold ?? 0,
        rootMargin: rootMarginString,
      }
    );
    if (ref && ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, threshold, baseOffset]);

  return isIntersecting;
}
