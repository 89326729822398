import type { ChangeEvent, ReactNode } from "react";
import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight, spacing } from "../../theme";
import { largeDesktopLayout } from "../../utils/hooks/useLayout";
import { Radio } from "./Radio";

const defaultGroup = "code-for-default-group";

type Option = {
  value: string;
  label: string;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
  group?: string;
};

type RadioGroupListProps = {
  options: Option[];
  selectedValue: string;
  onChange: (value: string) => void;
  name: string;
  groups?: string[];
};

export function RadioGroupList(props: RadioGroupListProps) {
  const { name, options, selectedValue, onChange, groups = [] } = props;

  // create an object with keys for each group so we can use it to separate options into groups
  const initialGroups = groups.reduce(
    (acc, key) => ({ ...acc, [key]: [] }),
    {}
  );
  // separate options into groups
  const optionsGrouped = options.reduce((acc, option) => {
    const group = option.group || defaultGroup;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(option);
    return acc;
  }, initialGroups as Record<string, Option[]>);

  return (
    <Container>
      {Object.keys(optionsGrouped).map((group) => {
        const options = optionsGrouped[group];
        return (
          <RadioGroup
            key={group}
            title={group}
            name={name}
            options={options}
            selectedValue={selectedValue}
            onChange={(event) => onChange(event.target.value)}
          />
        );
      })}
    </Container>
  );
}

type RadioGroupProps = {
  title: string;
  options: Option[];
  selectedValue: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  name: string;
};

function RadioGroup(props: RadioGroupProps) {
  const { title, name, options, selectedValue, onChange } = props;

  return (
    <>
      {defaultGroup !== title && (
        <Legend id={`${title}-legend-id`}>{title}</Legend>
      )}
      <Fieldset aria-labelledby={`${title}-legend-id`}>
        {options.map((option) => {
          const { value, label, subtitle, icon } = option;
          return (
            <Radio
              key={`${name}-${value}`}
              id={`${name}-${value}`}
              name={name}
              value={value}
              checked={value === selectedValue}
              onChange={onChange}
              icon={icon}
              subtitle={subtitle}
            >
              {label}
            </Radio>
          );
        })}
      </Fieldset>
    </>
  );
}
const Fieldset = styled.fieldset`
  all: unset;
  min-width: 300px;
  width: 100%;
  ${largeDesktopLayout} {
    gap: ${spacing.xxl};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
    grid-auto-rows: repeat(auto-fill, minmax(66px, 1fr));
    justify-content: flex-start;
  }
`;

const Legend = styled.legend`
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.snug};
  padding-bottom: ${spacing.md};
  padding-left: ${spacing.xl};

  ${largeDesktopLayout} {
    padding-left: 0;
    padding-bottom: ${spacing.md};
    width: 100%;
    border-bottom: 1px solid ${color.n30};
    height: 37px;
    line-height: ${lineHeight.loose};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxl};
  ${largeDesktopLayout} {
    gap: ${spacing.xxxl};
  }
`;
