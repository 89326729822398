import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import styled from "styled-components";
import { Icon } from "src/components/Icon/Icon";
import ChevronDown from "src/svg/ChevronDown.svg?react";
import ChevronUp from "src/svg/ChevronUp.svg?react";
import { font_size, font_weight } from "src/design-system/tokens/typography";
import { messages } from "./SearchResultListCard.messages";

type ShowMoreButtonProps = {
  onOpenClick: () => void;
  onCloseClick: () => void;
  numResults: number;
  searchResultsLength: number;
  index?: number;
};

export function ShowMoreButton(props: ShowMoreButtonProps) {
  const intl = useIntl();

  return (
    <ButtonWrapper>
      {props.numResults < props.searchResultsLength ? (
        <Button
          onClick={props.onOpenClick}
          data-testid={`show-more-${props.index ? `${props.index}` : "0"}`}
        >
          {intl.formatMessage(messages.showMoreResults, {
            count: props.searchResultsLength - props.numResults,
          })}
          <Icon size="sm">
            <ChevronDown color={color.text.text} />
          </Icon>
        </Button>
      ) : (
        <Button onClick={props.onCloseClick}>
          {intl.formatMessage(messages.showLessResults)}
          <Icon size="sm">
            <ChevronUp color={color.text.text} />
          </Icon>
        </Button>
      )}
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${spacing.xl} ${spacing.md} ${spacing.md};
`;

const Button = styled(ButtonBase)`
  gap: ${spacing.md};
  text-align: center;
  padding: ${spacing.md};
  border-radius: ${border_radius.rounded_md};
  font-size: ${font_size.text_base};
  font-weight: ${font_weight.medium};
  text-decoration: underline;

  &:hover {
    background-color: ${color.border.border};
    text-decoration: none;
  }
`;
