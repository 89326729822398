import type React from "react";
import type { FeatureConfig } from "src/feature/FeatureConfig";
import Deals from "src/svg/Discount.svg?react";
import Packages from "src/svg/Beach.svg?react";
import Utensils from "src/svg/Utensils.svg?react";
import Activities from "src/svg/Bank.svg?react";
import { useScreenSize } from "src/utils/hooks/useScreenSize";
import styled from "styled-components";
import color from "src/design-system/tokens/color";
import { PanePromoButton } from "../TripPlanner/PanePromoButton/PanePromoButton";
import { SearchResultsPromo } from "../SearchResultsScreen/SearchResultsPromo/SearchResultsPromo";

type SerpPromoType = Exclude<
  FeatureConfig["AlternativeSerpPromos"],
  "Baseline"
>;

type AlternativeSerpPromoProps = {
  destination: string;
  promoType: SerpPromoType;
  className?: string;
};

export default function AlternativeSerpPromo({
  destination,
  promoType,
  className,
}: AlternativeSerpPromoProps) {
  const isSmallScreenSize = useScreenSize() === "small";
  const message = `${destination} ${promoType}`;

  const { Icon, targetUrl } = promoData[promoType];

  const handlePromoClicked = () => {
    // TODO - add analytics tracking for exits
    // https://rome2rio.atlassian.net/browse/MN-96

    window.open(targetUrl, "_blank", "noopener");
  };

  return isSmallScreenSize ? (
    <SearchResultsPromo
      className={className}
      header={message}
      onClick={handlePromoClicked}
      icon={
        <IconStyles>
          <Icon />
        </IconStyles>
      }
      iconBackgroundColor="mint"
    />
  ) : (
    <PanePromoButton promoText={message} onClick={handlePromoClicked}>
      <Icon />
    </PanePromoButton>
  );
}

const IconStyles = styled.span`
  & path {
    fill: ${color.icon.onBgFill};
  }
`;

const promoData: Record<
  SerpPromoType,
  {
    Icon: () => React.ReactNode;
    targetUrl: string;
  }
> = {
  Deals: {
    Icon: () => <Deals />,
    targetUrl: "https://www.expedia.com/deals",
  },
  Packages: {
    Icon: () => <Packages />,
    targetUrl: "https://www.expedia.com/Vacation-Packages",
  },
  Restaurants: {
    Icon: () => <Utensils />,
    targetUrl: "https://www.expedia.com/lp/t/hotels/Hotels-with-Restaurants",
  },
  Activities: {
    Icon: () => <Activities />,
    targetUrl: "https://www.expedia.com/Activities",
  },
};
