import { type SvgProps, useColor } from "./common";

export function Gas(props: SvgProps) {
  const tintColor = useColor(props.tint);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <title>{props.title}</title>
      <g fill={tintColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0a2 2 0 0 0-2 2v10.583h23.655V2a2 2 0 0 0-2-2H2Zm4.844 4.889a1.333 1.333 0 1 0 0 2.666h9.777a1.333 1.333 0 0 0 0-2.666H6.844Z"
        />
        <path d="M23.655 14.083H0v15.916a2 2 0 0 0 2 2h19.655a2 2 0 0 0 2-2v-4.617c.81.097 1.91.077 2.905-.448 1.31-.692 2.088-2.063 2.088-4.062v-8.827c0-.448.163-.755.426-.973.287-.238.798-.451 1.593-.451a1.333 1.333 0 0 0 0-2.667c-1.253 0-2.418.338-3.295 1.065-.9.747-1.39 1.82-1.39 3.026v8.827c0 1.249-.424 1.575-.668 1.704-.388.205-.985.23-1.58.123a1.267 1.267 0 0 0-.08-.012v-8.604Z" />
      </g>
    </svg>
  );
}
