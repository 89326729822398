const color = {
  bg: {
    fill: {
      fill: "var(--color-fill)",
      hover: "var(--color-fill-hover)",
      active: "var(--color-fill-active)",
    },
    brand: {
      fill: "var(--color-fill-brand)",
      hover: "var(--color-fill-brand-hover)",
      active: "var(--color-fill-brand-active)",
      selected: "var(--color-fill-brand-selected)",
    },
    transparent: {
      fill: "var(--color-fill-transparent)",
      hover: "var(--color-fill-transparent-hover)",
      active: "var(--color-fill-transparent-active)",
    },
    surface: {
      surface: "var(--color-surface)",
      active: "var(--color-surface-active)",
      secondary: "var(--color-surface-secondary)",
      primaryHover: "var(--color-surface-secondary-hover)",
    },
  },
  border: {
    border: "var(--color-border)",
    brand: "var(--color-border-brand)",
    hover: "var(--color-border-hover)",
    active: "var(--color-border-active)",
    selected: "var(--color-border-selected)",
    secondary: "var(--color-border-secondary)",
  },
  ring: {
    ring: "var(--color-ring)",
  },
  icon: {
    icon: "var(--color-icon)",
    secondary: "var(--color-icon-secondary)",
    error: "var(--color-border-error)",
    onBgFill: "var(--color-icon-on-bg-fill)",
    brand: "var(--color-icon-brand)",
  },
  text: {
    text: "var(--color-text)",
    success: "var(--color-text-success)",
    onBgFill: "var(--color-text-on-bg-fill)",
    primary: {
      primary: "var(--color-text-primary)",
      active: "var(--color-text-primary-active)",
    },
    secondary: "var(--color-text-secondary)",
    tertiary: "var(--color-text-tertiary)",
    error: "var(--color-text-error)",
    brand: "var(--color-text-brand)",
  },
  input: {
    bgSurface: "var(--color-input)",
    bgSurfaceActive: "var(--color-input-active)",
    border: "var(--color-input-border)",
    borderHover: "var(--color-input-border-hover)",
    borderHoverActive: "var(--color-input-border-active)",
    borderError: "var(--color-input-border-error)",
  },
  /**
   * @deprecated Use the CSS variable variant instead.
   */
  legacy: {
    bg: {
      brand: {
        fill: "#de007b",
      },
    },
  },
  primitive: {
    product100: "var(--product-100)",
    product200: "var(--product-200)",
    product300: "var(--product-300)",
    product400: "var(--product-400)",
    product500: "var(--product-500)",
    product600: "var(--product-600)",
    product700: "var(--product-700)",
    product800: "var(--product-800)",
    product900: "var(--product-900)",
    product1000: "var(--product-1000)",
    product1100: "var(--product-1100)",
    product1300: "var(--product-1300)",
    neutral100: "var(--neutral-100)",
    neutral200: "var(--neutral-200)",
    neutral300: "var(--neutral-300)",
    neutral400: "var(--neutral-400)",
    neutral500: "var(--neutral-500)",
    neutral600: "var(--neutral-600)",
    neutral700: "var(--neutral-700)",
    neutral800: "var(--neutral-800)",
    neutral900: "var(--neutral-900)",
    neutral1000: "var(--neutral-1000)",
    neutral1100: "var(--neutral-1100)",
    neutral1200: "var(--neutral-1200)",
    neutral1300: "var(--neutral-1300)",
    neutral1400: "var(--neutral-1400)",
    neutral1500: "var(--neutral-1500)",
    neutral1600: "var(--neutral-1600)",
    green100: "var(--green-100)",
    green200: "var(--green-200)",
    green300: "var(--green-300)",
    green400: "var(--green-400)",
    green500: "var(--green-500)",
    green600: "var(--green-600)",
    green700: "var(--green-700)",
    green800: "var(--green-800)",
    green900: "var(--green-900)",
    yellow100: "var(--yellow-100)",
    yellow200: "var(--yellow-200)",
    yellow300: "var(--yellow-300)",
    yellow400: "var(--yellow-400)",
    yellow500: "var(--yellow-500)",
    yellow600: "var(--yellow-600)",
    yellow700: "var(--yellow-700)",
    yellow800: "var(--yellow-800)",
    yellow900: "var(--yellow-900)",
    red100: "var(--red-100)",
    red200: "var(--red-200)",
    red300: "var(--red-300)",
    red400: "var(--red-400)",
    red500: "var(--red-500)",
    red600: "var(--red-600)",
    red700: "var(--red-700)",
    red800: "var(--red-800)",
    red900: "var(--red-900)",
    blue100: "var(--blue-100)",
    blue200: "var(--blue-200)",
    blue300: "var(--blue-300)",
    blue400: "var(--blue-400)",
    blue500: "var(--blue-500)",
    blue600: "var(--blue-600)",
    blue700: "var(--blue-700)",
    blue800: "var(--blue-800)",
    blue900: "var(--blue-900)",
    transportTeal100: "var(--transport-teal-100)",
    transportTeal200: "var(--transport-teal-200)",
    transportTeal300: "var(--transport-teal-300)",
    transportTeal400: "var(--transport-teal-400)",
    transportTeal500: "var(--transport-teal-500)",
    transportTeal600: "var(--transport-teal-600)",
    transportTeal700: "var(--transport-teal-700)",
    transportTeal800: "var(--transport-teal-800)",
    transportTeal900: "var(--transport-teal-900)",
    transportOrange100: "var(--transport-orange-100)",
    transportOrange200: "var(--transport-orange-200)",
    transportOrange300: "var(--transport-orange-300)",
    transportOrange400: "var(--transport-orange-400)",
    transportOrange500: "var(--transport-orange-500)",
    transportOrange600: "var(--transport-orange-600)",
    transportOrange700: "var(--transport-orange-700)",
    transportOrange800: "var(--transport-orange-800)",
    transportOrange900: "var(--transport-orange-900)",
    transportBlue100: "var(--transport-blue-100)",
    transportBlue200: "var(--transport-blue-200)",
    transportBlue300: "var(--transport-blue-300)",
    transportBlue400: "var(--transport-blue-400)",
    transportBlue500: "var(--transport-blue-500)",
    transportBlue600: "var(--transport-blue-600)",
    transportBlue700: "var(--transport-blue-700)",
    transportBlue800: "var(--transport-blue-800)",
    transportBlue900: "var(--transport-blue-900)",
    transportRed100: "var(--transport-red-100)",
    transportRed200: "var(--transport-red-200)",
    transportRed300: "var(--transport-red-300)",
    transportRed400: "var(--transport-red-400)",
    transportRed500: "var(--transport-red-500)",
    transportRed600: "var(--transport-red-600)",
    transportRed700: "var(--transport-red-700)",
    transportRed800: "var(--transport-red-800)",
    transportRed900: "var(--transport-red-900)",
    transportGreen100: "var(--transport-green-100)",
    transportGreen200: "var(--transport-green-200)",
    transportGreen300: "var(--transport-green-300)",
    transportGreen400: "var(--transport-green-400)",
    transportGreen500: "var(--transport-green-500)",
    transportGreen600: "var(--transport-green-600)",
    transportGreen700: "var(--transport-green-700)",
    transportGreen800: "var(--transport-green-800)",
    transportGreen900: "var(--transport-green-900)",
    transportPurple100: "var(--transport-purple-100)",
    transportPurple200: "var(--transport-purple-200)",
    transportPurple300: "var(--transport-purple-300)",
    transportPurple400: "var(--transport-purple-400)",
    transportPurple500: "var(--transport-purple-500)",
    transportPurple600: "var(--transport-purple-600)",
    transportPurple700: "var(--transport-purple-700)",
    transportPurple800: "var(--transport-purple-800)",
    transportPurple900: "var(--transport-purple-900)",
    transportYellow100: "var(--transport-yellow-100)",
    transportYellow200: "var(--transport-yellow-200)",
    transportYellow300: "var(--transport-yellow-300)",
    transportYellow400: "var(--transport-yellow-400)",
    transportYellow500: "var(--transport-yellow-500)",
    transportYellow600: "var(--transport-yellow-600)",
    transportYellow700: "var(--transport-yellow-700)",
    transportYellow800: "var(--transport-yellow-800)",
    transportYellow900: "var(--transport-yellow-900)",
    transportGrey100: "var(--transport-grey-100)",
    transportGrey200: "var(--transport-grey-200)",
    transportGrey300: "var(--transport-grey-300)",
    transportGrey400: "var(--transport-grey-400)",
    transportGrey500: "var(--transport-grey-500)",
    transportGrey600: "var(--transport-grey-600)",
    transportGrey700: "var(--transport-grey-700)",
    transportGrey800: "var(--transport-grey-800)",
    transportGrey900: "var(--transport-grey-900)",
    transportBlack100: "var(--transport-black-100)",
    transportBlack200: "var(--transport-black-200)",
    transportBlack300: "var(--transport-black-300)",
    transportBlack400: "var(--transport-black-400)",
    transportBlack500: "var(--transport-black-500)",
    transportBlack600: "var(--transport-black-600)",
    transportBlack700: "var(--transport-black-700)",
    transportBlack800: "var(--transport-black-800)",
    transportBlack900: "var(--transport-black-900)",
    accommodationBlue100: "var(--accommodation-blue-100)",
    accommodationBlue200: "var(--accommodation-blue-200)",
    accommodationBlue300: "var(--accommodation-blue-300)",
    accommodationBlue400: "var(--accommodation-blue-400)",
    accommodationBlue500: "var(--accommodation-blue-500)",
    accommodationBlue600: "var(--accommodation-blue-600)",
    accommodationBlue700: "var(--accommodation-blue-700)",
    accommodationBlue800: "var(--accommodation-blue-800)",
    accommodationBlue900: "var(--accommodation-blue-900)",
    pointOfInterestAmusement100: "var(--point-of-interest-amusement-100)",
    pointOfInterestLandmark100: "var(--point-of-interest-landmark-100)",
    pointOfInterestAquarium100: "var(--point-of-interest-aquarium-100)",
    pointOfInterestGardenpark100: "var(--point-of-interest-gardenpark-100)",
    pointOfInterestMuseum100: "var(--point-of-interest-museum-100)",
    pointOfInterestArchitecture100: "var(--point-of-interest-architecture-100)",
    pointOfInterestCasino100: "var(--point-of-interest-casino-100)",
    pointOfInterestCastledfort100: "var(--point-of-interest-castledfort-100)",
    pointOfInterestChurch100: "var(--point-of-interest-church-100)",
    pointOfInterestFountain100: "var(--point-of-interest-fountain-100)",
    pointOfInterestMountain100: "var(--point-of-interest-mountain-100)",
    pointOfInterestSculpture100: "var(--point-of-interest-sculpture-100)",
    pointOfInterestSkiing100: "var(--point-of-interest-skiing-100)",
    pointOfInterestTheatre100: "var(--point-of-interest-theatre-100)",
    pointOfInterestWaterfall100: "var(--point-of-interest-waterfall-100)",
    pointOfInterestZoo100: "var(--point-of-interest-zoo-100)",
    neutralAlpha100: "var(--neutral-alpha-100)",
    neutralAlpha200: "var(--neutral-alpha-200)",
    neutralAlpha300: "var(--neutral-alpha-300)",
    neutralAlpha400: "var(--neutral-alpha-400)",
    neutralAlpha500: "var(--neutral-alpha-500)",
    neutralAlpha600: "var(--neutral-alpha-600)",
    neutralAlpha700: "var(--neutral-alpha-700)",
    neutralAlpha800: "var(--neutral-alpha-800)",
    neutralAlpha900: "var(--neutral-alpha-900)",
    neutralAlpha1000: "var(--neutral-alpha-1000)",
    neutralAlpha1100: "var(--neutral-alpha-1100)",
    neutralAlpha1200: "var(--neutral-alpha-1200)",
    neutralAlpha1300: "var(--neutral-alpha-1300)",
    neutralAlpha1400: "var(--neutral-alpha-1400)",
    neutralAlpha1500: "var(--neutral-alpha-1500)",
    neutralAlpha1600: "var(--neutral-alpha-1600)",
  },
} as const;

/**
 * @deprecated Use tokens instead of primitives.
 */
export const primitive = {
  product_500: "#c90070",
  neutral_100: "#ffffff",
  neutral_300: "#fafafa",
  neutral_400: "#f7f7f7",
  neutral_500: "#f3f3f3",
  neutral_700: "#ebebeb",
  neutral_800: "#e3e3e3",
  neutral_900: "#d4d4d4",
  neutral_1100: "#b5b5b5",
  neutral_1500: "#303030",
  neutral_1600: "#1a1a1a",
  neutral_alpha_700: "rgba(0, 0, 0, 0.13)",
  neutral_alpha_1200: "rgba(0, 0, 0, 0.45)",
  blue_500: "#3269d9",
} as const;

export default color;
