import { type FeatureConfig, defaultFeatureConfig } from "../FeatureConfig";
import { useBackendFeatureMapping } from "./backendFeatures/useBackendFeatureMapping";
import { useCustomFeatureConfig } from "./custom/useCustomFeatureConfig";
import { useBackendExperimentsFeatures } from "./experiment/useBackendExperimentsFeatures";
import { useSearchParamsFeatures } from "./searchParams/useSearchParamsFeatures";

export function useConfigureFeatures(): FeatureConfig {
  /**
   * This hook configures the FeatureConfig!
   */
  // Order here is important, features apply in order of listing here.
  return {
    // Production use
    ...defaultFeatureConfig,
    ...useBackendFeatureMapping(),
    ...useBackendExperimentsFeatures(),

    // Debug / custom use
    ...useCustomFeatureConfig(),
    ...useSearchParamsFeatures(),
  };
}
