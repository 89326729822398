import { type FormEvent, type KeyboardEvent, createRef } from "react";
import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight, spacing } from "../../theme";
import { InputBase } from "../Input/InputBase";

// We need to set a value on an input element native to the browser in order to trigger the input event.
// More info https://github.com/facebook/react/issues/10135
function setNativeValue(element: HTMLInputElement, value: string) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, "value")?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    "value"
  )?.set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter?.call(element, value);
  } else {
    valueSetter?.call(element, value);
  }
}

export type InputProps = {
  id: string;
  label: string;
  digits: number;
  onChange: (value: string) => void;
  isValid?: boolean;
  validationMessage?: string;
};

export function DigitCode(props: InputProps) {
  const { label, id, isValid, validationMessage, digits, onChange, ...other } =
    props;
  // create dynamic refs
  const refs = Array.from({ length: digits }, () =>
    createRef<HTMLInputElement>()
  );

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.code === "Backspace" && event.currentTarget.value === "") {
      const previousInput = event.currentTarget
        .previousSibling as HTMLInputElement;
      if (previousInput) {
        previousInput.focus();
      }
    }
  }

  function handleInput(event: FormEvent<HTMLInputElement>) {
    // take the first character of the input
    const [first, ...rest] = event.currentTarget.value
      .split("")
      .filter((char) => char.match(/[0-9]/));
    event.currentTarget.value = first ?? "";
    const didInsertContent = first !== undefined;
    const nextInput = event.currentTarget.nextSibling as HTMLInputElement;
    if (didInsertContent && nextInput) {
      // continue to input the rest of the string
      nextInput.focus();
      if (rest.length) {
        setNativeValue(nextInput, rest.join(""));
        nextInput.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  }

  function handleChange() {
    onChange(refs.map((ref) => ref.current?.value).join(""));
  }

  function handleFocus(event: FormEvent<HTMLInputElement>) {
    event.currentTarget.select();
  }

  return (
    <InputContainer {...other}>
      <StyledLabel id={`${id}-label`} htmlFor={id}>
        {label}
      </StyledLabel>
      <Fieldset
        id={id}
        aria-invalid={!isValid}
        aria-describedby={`${id}-validation`}
        onChange={handleChange}
      >
        {refs.map((ref, index) => (
          <StyledInputBase
            id={`token-${index}`}
            name={`token-${index}`}
            type="text"
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            key={`token-${index}`}
            ref={ref}
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="[0-9]*"
            aria-labelledby={`${id}-label`}
            required
          />
        ))}
      </Fieldset>
      {!isValid && (
        <Collapse>
          <Validation id={`${id}-validation`}>{validationMessage}</Validation>
        </Collapse>
      )}
    </InputContainer>
  );
}

const InputContainer = styled.div`
  width: 100%;
`;
const StyledLabel = styled.label`
  font-size: ${fontSize.h6};
`;

const StyledInputBase = styled(InputBase)`
  margin-top: ${spacing.md};
  flex: 1;
  width: 44px;
  height: 44px;
`;
const Collapse = styled.div`
  position: relative;
`;

const Validation = styled.span`
  position: absolute;
  display: block;
  font-size: ${fontSize.small};
  line-height: ${lineHeight.loose};
  font-weight: ${fontWeight.normal};
  padding-top: ${spacing.sm};
  color: ${color.high};
  height: calc(${fontSize.small} * ${lineHeight.loose});
  right: 0;
`;

const Fieldset = styled.fieldset`
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 100%;
  gap: ${spacing.md};
`;
