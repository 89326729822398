import { type SvgProps, useColor } from "./common";

export function ChevronUp(props: SvgProps & { className?: string }) {
  const tintColor = useColor(props.tint);
  return (
    <svg fill="none" viewBox="0 0 32 32" className={props.className}>
      <title>{props.title}</title>
      <path
        d="M2 22.25l14.046 -12.5L30 22.25"
        stroke={tintColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
