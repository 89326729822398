import type { PageType } from "src/analytics/analyticsEventTypes";
import { getRouteCanonicalFromHash } from "../location/routeCanonicalFromHash";
import { useIsRouteSkipped } from "./useIsRouteSkipped";
import { useIsTripScreen } from "./useIsTripScreen";
import {
  useIsHotelsUrlDeeplink,
  useIsTripHotelsUrlDeeplink,
} from "./useNavigateToHotelsPage";
import { useRouteIndex } from "./useRoute";
import { useTypedLocation } from "./useTypedLocation";

function useIsHotelsTypePage() {
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  const isTripPlannerHotelScreen = useIsTripHotelsUrlDeeplink();

  return isHotelsScreen || isTripPlannerHotelScreen;
}

export function useGetPageType(): PageType {
  const location = useTypedLocation();
  const isHotelsTypePage = useIsHotelsTypePage();
  const isTripsPage = useIsTripScreen();
  const isRouteSegmentScreen = !!getRouteCanonicalFromHash(location.hash);
  const routeIndex = useRouteIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);

  if (isHotelsTypePage) {
    return "Hotels";
  } else if (isTripsPage) {
    const parts = location.hash.slice(1).split("/");
    if (parts.includes("transport")) {
      if (isRouteSkipped) {
        return "TripsSegment";
      } else {
        return "TripsRoute";
      }
    }
    return "TripPlanner";
  } else if (isRouteSegmentScreen) {
    if (isRouteSkipped) {
      return "Segment";
    } else {
      return "Route";
    }
  } else {
    return "Search Results";
  }
}
