import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { searchParamsChange } from "src/utils/location/searchParamsChange";
import { useScreenSize } from "src/utils/hooks/useScreenSize";
import { useFeature } from "src/feature/useFeature";
import { type TripPlannerDetails } from "../TripPlannerProvider";

export const MAX_SEARCH_PARAM_PLACES = 10;

/** Manually switch to using TripId in the URL*/
export function useSwitchURLToTripId() {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const isSmall = useScreenSize() === "small";
  const isCore = useFeature("ReturnToCore");

  const switchToTripId = useCallback(
    (props: {
      tripId: string;
      pathname?: string;
      tripPlannerDetails: TripPlannerDetails;
    }) => {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get("tripId") !== props.tripId) {
        searchParams.set("tripId", props.tripId);
        searchParams.delete("search");
        navigate(
          searchParamsChange(searchParams, {
            ...location,
            pathname: props.pathname ?? location.pathname,
          }),
          {
            replace: true,
            state: {
              ...location.state,
              tripPlannerDetails: props.tripPlannerDetails,
            },
          }
        );
      }
    },
    [navigate, location]
  );

  const clearTripId = useCallback(
    (pathname?: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete("tripId");
      /* On logout, if redirecting back to trips, show create screen */
      // TODO: Remove isSmall and isCore when ReturnToCore is graduated
      if (location.hash === "#trips" && (isCore || isSmall)) {
        location.hash = "#trips/create/";
      }
      navigate(
        searchParamsChange(searchParams, {
          ...location,
          pathname: pathname ?? location.pathname,
        }),
        { replace: true }
      );
    },
    [location, navigate, isCore, isSmall]
  );
  return { switchToTripId, clearTripId };
}
