import { useCallback, useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import { registerOnConsent } from "@rome2rio/tcf-api";
import {
  type HotjarOrigin,
  calculateHotjarChance,
  hotjarConfig,
} from "../hotjarConfig";
import { type LayoutType, useLayout } from "./useLayout";

const IS_PRODUCTION = import.meta.env.PROD;

const HOTJAR_CONSENT_PURPOSES_REQUIRED = [1, 10];
const HOTJAR_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];
const HOTJAR_SALE_CONSENT_REQUIRED = true;
const HOTJAR_SHARING_CONSENT_REQUIRED = true;

export function initializeHotjarOnConsent() {
  registerOnConsent({
    onGiven: () => initializeHotjar(),
    onRevoked: disableHotjar,
    gdpr: {
      consentPurposesRequired: HOTJAR_CONSENT_PURPOSES_REQUIRED,
      legitimateInterestPurposesRequired:
        HOTJAR_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
    },
    us: {
      saleConsentRequired: HOTJAR_SALE_CONSENT_REQUIRED,
      sharingConsentRequired: HOTJAR_SHARING_CONSENT_REQUIRED,
    },
  });
}

function initializeHotjar() {
  if (IS_PRODUCTION) {
    hotjar.initialize({ id: 348555, sv: 6 });
  }
}

export function isHotjarInitialized() {
  return window.hj !== undefined;
}

type UseHotjarProps = {
  id: HotjarOrigin;
  layout?: LayoutType;
  isFiredOnLoad?: boolean;
  isEnabled?: boolean;
  callback?: () => void;
};
/**
 * @returns fireHotjarEvent callback
 * @param id name of the hotjarConfig point. This is usually the name of the component where the event is fired.
 * @param layout optional layout to restrict the event to. Defaults to all layouts.
 * @param isFiredOnLoad optional fires the hotjar event as soon as the component loads rather than through the returned hotjar callback
 * @param isEnabled optional allows the hotjar event to be fired when certain conditions are met
 * @param callback optional additional callback to be fired alongside the hotjar callback such as for analytics
 * Note: HotJar has built-in filtering options, use this if you want to ignore other devices.
 */
export function useHotjar({
  id,
  layout,
  isFiredOnLoad = false,
  isEnabled = true,
  callback,
}: UseHotjarProps) {
  const [hotjarEventSent, setHotjarEventSent] = useState(false);
  const currentLayout = useLayout();
  const layoutMatch = layout ? currentLayout === layout : true;

  const fireHotjarEvent = useCallback(
    (callback?: () => void) => {
      const chance = calculateHotjarChance(id, hotjarConfig);

      if (
        isEnabled &&
        !hotjarEventSent &&
        layoutMatch &&
        Math.random() < chance
      ) {
        callback?.();
        setHotjarEventSent(true);
        sendHotjarEvent(hotjarConfig[id].eventName);
      }
    },
    [hotjarEventSent, layoutMatch, id, isEnabled]
  );

  useEffect(() => {
    if (!isFiredOnLoad) return;
    fireHotjarEvent(callback);
  }, [isFiredOnLoad, fireHotjarEvent, callback]);

  return fireHotjarEvent;
}

function disableHotjar() {
  // Hotjar doesn't have a disable function, so we just reload the page.
  window.location.reload();
}

export function sendHotjarEvent(eventName: string) {
  if (IS_PRODUCTION) {
    window.hj && window.hj("event", eventName);
  } else {
    console.log("%c Hotjar event:", "background: #222; color: #bada55", {
      eventName,
    });
  }
}
