import { type LoaderFunctionArgs, Route, redirect } from "react-router";
import type { PropsWithChildren } from "react";
import Discover from "./pages/Discover";
import { AdPageWithTimer } from "./domain/SegmentScreen/CarrierSection/AdPage/AdPageWithTimer";

export function AppRoutes({
  children,
  global,
}: PropsWithChildren<{ global?: React.ReactNode }>) {
  return (
    <Route key="rootPath" element={global}>
      <Route
        path="/:languageCode?/map/:originCanonical?/:destinationCanonical?"
        element={children}
      />
      <Route path="/map/ad-page" element={<AdPageWithTimer />} />
      <Route path="/:languageCode?/discover/italy" element={<Discover />} />
      <Route
        path="/:languageCode?/trips"
        element={children}
        loader={redirectToMapRoute}
      >
        <Route path="new" element={children} />
        <Route path=":tripId" element={children} />
      </Route>
      <Route path="*" element={children} loader={redirectToMapRoute} />
    </Route>
  );
}

async function redirectToMapRoute({ params }: LoaderFunctionArgs<any>) {
  let pathname = params["languageCode"] ? `/${params["languageCode"]}` : "";
  pathname += `/map`;
  return redirect(pathname);
}
