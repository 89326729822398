import { cloneElement } from "react";
import { useTransition } from "@react-spring/web";
import type { TransitionProps } from "./TransitionProps";

type Anchor = "top" | "bottom" | "right";

type Props = {
  /** This is the direction the element comes from when animating in. */
  anchor: Anchor;
} & TransitionProps;

export function Slide(props: Props) {
  const { anchor, open, onEnter, onExited, children, config } = props;

  let fromY = 0;
  let fromX = 0;

  switch (anchor) {
    case "top":
      fromY = -100;
      break;
    case "bottom":
      fromY = 100;
      break;
    case "right":
      fromX = 100;
      break;
  }

  const drawerTransitions = useTransition(open, {
    from: {
      transform: `translate(${fromX}%, ${fromY}%)`,
    },
    enter: { transform: `translate(0%, 0%)` },
    leave: {
      transform: `translate(${fromX}%, ${fromY}%)`,
      onRest: () => {
        if (!open) {
          onExited && onExited();
        }
      },
    },
    onStart: () => {
      if (open) {
        onEnter && onEnter();
      }
    },
    config,
  });

  return drawerTransitions(
    (style, item, { key }) => item && cloneElement(children, { style, key })
  );
}
