import hotelListCardMessages from "../HotelListCard/HotelListCard.messages";

const messages = {
  // TODO: Move messages into here when HEx Refresh UI is complete
  freeCancellation: hotelListCardMessages.freeCancellation,
  nightsPer: hotelListCardMessages.nightPer,
  nightsSingle: hotelListCardMessages.nightSingle,
  nightsMultiple: hotelListCardMessages.nightMultiple,
  callToAction: hotelListCardMessages.callToAction,
  percentOff: hotelListCardMessages.percentOff,
};

export default messages;
