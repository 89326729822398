import { type SvgProps, useColor } from "./common";

export function Menu(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        d="M2 4a1 1 0 000 2h20a1 1 0 100-2H2zm-1 8a1 1 0 011-1h20a1 1 0 110 2H2a1 1 0 01-1-1zm0 7a1 1 0 011-1h20a1 1 0 110 2H2a1 1 0 01-1-1z"
        fill={tintColor}
      />
    </svg>
  );
}
