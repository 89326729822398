import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import type { AccomPromoLoggingCategory } from "src/analytics/analyticsEventTypes";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import { useExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { useFeature } from "src/feature/useFeature";
import { useScroll } from "src/ScrollContext";
import { HotelAlternative } from "src/svg/HotelAlternative";
import { type GeoKind, isPointKind } from "src/utils/geoKind";
import { useIsInViewport } from "src/utils/hooks/useIsInViewport";
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import useSearch from "src/utils/hooks/useSearch";
import type { HotelProviderKind } from "src/utils/types/accommodationProviders";
import { SegmentPromoDriveVariant } from "../SegmentPromoDriveVariant/SegmentPromoDriveVariant";
import messages from "./SegmentAccommodationPromoDriveVariant.messages";

type Props = {
  location: string;
  kind: GeoKind;
  loggingFrom: AccomPromoLoggingCategory;
  hotelProvider: HotelProviderKind;
  id: string;
};

export function SegmentAccommodationPromoDriveVariant({
  location,
  kind,
  loggingFrom,
  hotelProvider,
  id,
}: Props) {
  const hexIsExpedia = useFeature("ExpediaOnEntry");
  const handleExpediaExit = useExitOnHExEntry("Expedia");

  const intl = useIntl();
  const { setScrollTop } = useScroll();
  const { navigateToHotels } = useNavigateToHotelsPage();
  const { origin, destination } = useSearch();
  const { isMdScreen } = useScreenMinWidth();
  const isFullSizePromo = !isMdScreen;
  const isTripsScreen = useIsTripScreen();
  const copyPreposition = isPointKind(kind) ? "Near" : "Around";
  const calloutMessage = messages[`findHotels${copyPreposition}`];

  // We want to see when an accommodation promo gets into view but only fire the log once.
  const accommodationPromoRef = useRef<HTMLButtonElement>(null);
  const accommodationPromoInViewport = useIsInViewport(accommodationPromoRef);
  const [hasAccommodationPromoBeenViewed, setHasAccommodationPromoBeenViewed] =
    useState(false);

  function handleClick() {
    const gaLoggingCategory =
      loggingFrom === "Schedule" ? "ScheduleDetails" : loggingFrom;

    sendAnalyticsInteractionEvent(gaLoggingCategory, "Click:HotelPromo");
    setScrollTop(0);

    if (hexIsExpedia && handleExpediaExit) {
      handleExpediaExit({
        lat: destination?.lat,
        lng: destination?.lng,
        canonicalName: destination?.canonicalName,
      });
    } else {
      if (isTripsScreen) {
        sendAnalyticsInteractionEvent("TripPlanner", "Click:HotelPromo");
        navigateToHotels({
          originCanonical: origin?.canonicalName,
          destinationCanonical: destination?.canonicalName,
        });
      } else {
        navigateToHotels();
      }
    }
  }

  useEffect(() => {
    if (accommodationPromoInViewport && !hasAccommodationPromoBeenViewed) {
      sendAnalyticsNonInteractionEvent(
        "Screen",
        "AccomPromoVisible",
        `ap0:${hotelProvider}`
      );
      setHasAccommodationPromoBeenViewed(true);
    }
  }, [
    accommodationPromoInViewport,
    id,
    hotelProvider,
    hasAccommodationPromoBeenViewed,
  ]);

  return (
    <SegmentPromoDriveVariant
      ref={accommodationPromoRef}
      heading={intl.formatMessage(calloutMessage, {
        location,
      })}
      tagline={intl.formatMessage(messages.tagline)}
      icon={<HotelAlternative tint="cod" />}
      iconAlign={isFullSizePromo ? "left" : "right"}
      outline={isFullSizePromo}
      fullWidth={isFullSizePromo}
      onClick={handleClick}
    />
  );
}
