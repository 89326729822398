import { type SvgProps, useColor } from "./common";

export function SwitchLight(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="19.5"
        fill="white"
        stroke="#A2A2A2"
      />
      <path
        d="M21.978 20.855C22.2394 21.0904 22.6363 21.0904 22.8978 20.855L26.3354 17.7612C26.6177 17.5072 26.6405 17.0725 26.3865 16.7903C26.1325 16.508 25.6978 16.4852 25.4156 16.7392L23.1254 18.8003V12.4375C23.1254 12.0578 22.8176 11.75 22.4379 11.75C22.0582 11.75 21.7504 12.0578 21.7504 12.4375V18.8003L19.4602 16.7392C19.178 16.4852 18.7433 16.508 18.4893 16.7903C18.2353 17.0725 18.2581 17.5072 18.5404 17.7612L21.978 20.855Z"
        fill={tintColor}
      />
      <path
        d="M16.0225 19.1454C15.7611 18.9101 15.3642 18.9101 15.1027 19.1454L11.6651 22.2393C11.3829 22.4933 11.36 22.928 11.614 23.2102C11.868 23.4924 12.3027 23.5153 12.5849 23.2613L14.8751 21.2001V27.563C14.8751 27.9427 15.1829 28.2505 15.5626 28.2505C15.9423 28.2505 16.2501 27.9427 16.2501 27.563V21.2001L18.5403 23.2613C18.8226 23.5153 19.2573 23.4924 19.5113 23.2102C19.7653 22.928 19.7424 22.4933 19.4602 22.2393L16.0225 19.1454Z"
        fill={tintColor}
      />
    </svg>
  );
}
