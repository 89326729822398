import { type SvgProps, useColor } from "./common";

type BookmarkProps = {
  active?: boolean;
} & SvgProps;

export function UserCircleOutline(props: BookmarkProps) {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66658 7.91671C6.66658 6.07576 8.15897 4.58337 9.99992 4.58337C11.8409 4.58337 13.3333 6.07576 13.3333 7.91671C13.3333 9.75766 11.8409 11.25 9.99992 11.25C8.15897 11.25 6.66658 9.75766 6.66658 7.91671ZM9.99992 6.25004C9.07944 6.25004 8.33325 6.99623 8.33325 7.91671C8.33325 8.83718 9.07944 9.58337 9.99992 9.58337C10.9204 9.58337 11.6666 8.83718 11.6666 7.91671C11.6666 6.99623 10.9204 6.25004 9.99992 6.25004Z"
        fill={tintColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 0.833374C4.93731 0.833374 0.833252 4.93743 0.833252 10C0.833252 15.0626 4.93731 19.1667 9.99992 19.1667C15.0625 19.1667 19.1666 15.0626 19.1666 10C19.1666 4.93743 15.0625 0.833374 9.99992 0.833374ZM2.49992 10C2.49992 5.8579 5.85778 2.50004 9.99992 2.50004C14.1421 2.50004 17.4999 5.8579 17.4999 10C17.4999 12.0636 16.6665 13.9325 15.318 15.2886C14.315 13.3757 12.3105 12.0704 9.99984 12.0704C7.68918 12.0704 5.68478 13.3756 4.68178 15.2885C3.33327 13.9324 2.49992 12.0636 2.49992 10ZM6.01746 16.3565C7.17146 17.0811 8.53676 17.5 9.99992 17.5C11.463 17.5 12.8283 17.0811 13.9823 16.3566C13.3177 14.8153 11.7839 13.7371 9.99984 13.7371C8.21581 13.7371 6.68204 14.8153 6.01746 16.3565Z"
        fill={tintColor}
      />
    </svg>
  );
}
