import type { RefObject } from "react";
import type { IntlShape } from "react-intl";
import type {
  AdvancedBooking,
  Carrier,
  HopDebugInfo,
  PricingDebug,
  SearchResponse,
} from "../../../api/SearchResponse";
import {
  type LineName,
  lineNameFromNamesAndColors,
} from "../../../utils/adapters/lineNames";
import {
  type PriceRange,
  displayPriceRangeFromIndicativePrice,
  priceRangeFromHop,
} from "../../../utils/adapters/priceRange";
import { transitModeFromVehicleKind } from "../../../utils/adapters/transitMode";
import { vehicleFromHop } from "../../../utils/adapters/vehicle";
import type { Mode } from "../../../utils/types/mode";
import messages from "./CarrierSection.messages";

// Occasionally, carriers will not have a code. In that case, we compare their names for equality.
const checkCarrierEquality = (
  carrierfromSegments: Carrier,
  carrierFromOptions: Carrier
) => {
  if (!carrierFromOptions.code || !carrierfromSegments.code) {
    return carrierfromSegments.name === carrierFromOptions.name;
  }
  return carrierFromOptions.code === carrierfromSegments.code;
};

export function carrierAdapter(
  response: SearchResponse,
  routeIndex: number,
  segmentIndexOfRoute?: number
): CarrierViewModel[] {
  const route = response.routes[routeIndex];
  let allCarriers: CarrierViewModel[] = [];

  // If calling with segment index return specific segments carriers,
  // if not return all segments carriers in route.
  if (segmentIndexOfRoute !== undefined) {
    const segmentIndex = route.segments[segmentIndexOfRoute];
    const segment = response.segments[segmentIndex];

    allCarriers = carriersFromOptions(response, segment.options);
  } else {
    const segmentIndexes = route.segments;
    const segments = segmentIndexes.map((index) => response.segments[index]);
    allCarriers = segments.flatMap((segment) =>
      carriersFromOptions(response, segment.options)
    );
  }
  // Return only unique carriers, we don't want duplicates.
  return allCarriers.filter(
    (value, index, carriers) =>
      carriers.findIndex((carrier) => checkCarrierEquality(carrier, value)) ===
      index
  );
}

function carriersFromOptions(
  response: SearchResponse,
  optionIndices: number[]
) {
  const carriers: CarrierViewModel[] = [];

  for (let i = 0; i < optionIndices.length; i++) {
    const option = response.options[optionIndices[i]];
    for (let j = 0; j < option.hops.length; j++) {
      const carrier = carrierFromHop(response, option.hops[j]);
      if (carrier) {
        carriers.push(carrier);
      }
    }
  }

  return carriers;
}

function carrierFromHop(
  response: SearchResponse,
  hopIndex: number
): CarrierViewModel | null {
  const hop = response.hops[hopIndex];

  if (hop.marketingCarrier == null) {
    return null;
  }

  const vehicle = vehicleFromHop(response, hopIndex);
  const transitMode = transitModeFromVehicleKind(vehicle.kind);

  const carrier = response.carriers[hop.marketingCarrier];
  const line = response.lines[hop.line];
  const lineNames = [];

  // Collect the line names
  if (line.names) {
    lineNames.push(
      ...lineNameFromNamesAndColors(line.names, line.colors, transitMode)
    );
  }

  const images = hop.transitImages?.map((imageIndex) => {
    const image = response.transitImages[imageIndex];
    return {
      title: image.title,
      url: image.thumbnailUrl,
      width: image.width,
      height: image.height,
      credit: image.credit,
      creditUrl: image.link,
    };
  });

  const indicativePrices = hop.indicativePrices ?? [];
  const priceClasses = [];
  const debugPriceClass: DebugPriceClass[] = [];
  for (let i = 0; i < indicativePrices.length; i++) {
    const indicativePrice = indicativePrices[i];
    const priceRange = displayPriceRangeFromIndicativePrice(indicativePrice);
    const name = indicativePrice.className;

    if (indicativePrice.debug) {
      debugPriceClass.push({
        ...indicativePrice.debug,
        name,
      });
    }

    if (priceRange && name) {
      priceClasses.push({
        ...priceRange,
        name,
      });
    }
  }

  const bookingTexts = hop.bookingInfo?.bookingTexts ?? [];
  const advancedBooking = hop.bookingInfo?.advancedBooking;

  const externalLinks = (hop.externalLinks ?? []).reduce(
    (links: ExternalLink[], link) => {
      if (link.url) {
        links.push({
          kind: link.kind,
          url: link.url,
          urlName: link.urlName,
          text: link.text,
        });
      }
      return links;
    },
    []
  );

  // Some website urls are only attached to the carrier object eg. Taxis.
  if (!externalLinks.length && carrier.url) {
    externalLinks.push({
      kind: "home",
      url: carrier.url,
      urlName: carrier.urlLabel,
    });
  }

  const purchaseLocations = getPurchaseLocations(hop.bookingInfo?.canPurchases);

  return {
    name: carrier.name,
    code: carrier.code,
    transitMode: transitMode,
    lineNames,
    frequencyPerWeek: hop.frequency,
    durationInMinutes: hop.duration / 60,
    priceRange: priceRangeFromHop(hop),
    iconUrl: carrier.iconUrl,
    email: carrier.email,
    phone: carrier.phone,
    priceClasses: priceClasses,
    images: images ?? [],
    bookingTexts,
    advancedBooking,
    externalLinks,
    purchaseLocations: purchaseLocations,
    index: hop.marketingCarrier,
    additionalTexts: hop.additionalTexts ?? [],
    debug: hop.debug,
    ...(debugPriceClass.length && { debugPriceClass }),
  };
}

export function getPurchaseLocations(canPurchase?: number) {
  if (!canPurchase) {
    return [];
  }

  const station = canPurchase & 0x1;
  const onBoard = canPurchase & 0x2;
  const online = canPurchase & 0x4;
  const phone = canPurchase & 0x8;

  const purchaseLocations: PurchaseLocation[] = [];
  if (station) {
    purchaseLocations.push("station");
  }
  if (onBoard) {
    purchaseLocations.push("on board");
  }
  if (online) {
    purchaseLocations.push("online");
  }
  if (phone) {
    purchaseLocations.push("phone");
  }

  return purchaseLocations;
}

export function findCarrierByCode(
  carriers: CarrierViewModel[],
  code?: string
): CarrierViewModel | undefined {
  return carriers.find((carrier) => carrier.code === code);
}

export function findCarrierRefByCode(
  carriers?: CarrierRef[],
  code?: string
): RefObject<HTMLDivElement> | undefined {
  if (!carriers) return undefined;
  return carriers.find((carrier) => carrier.code === code)?.ref;
}

export function getAdvancedBookingText(
  intl: IntlShape,
  advancedBooking?: AdvancedBooking
): string | undefined {
  if (!advancedBooking || advancedBooking === "Unknown") return undefined;
  return intl.formatMessage(messages[advancedBooking]);
}

export function getDestructedLinks(links: ExternalLink[]): {
  homeLink?: ExternalLink;
  schedulesLink?: ExternalLink;
  bookingLink?: ExternalLink;
  otherLinks: ExternalLink[];
} {
  const homeLink = links.find((link) => link.kind === "home");
  const schedulesLink = links.find((link) => link.kind === "schedules");
  const bookingLink = links.find((link) => link.kind === "book");
  const otherLinks = links.filter(
    (link) =>
      link.kind !== "home" && link.kind !== "schedules" && link.kind !== "book"
  );

  return {
    homeLink,
    schedulesLink,
    bookingLink,
    otherLinks,
  };
}

export function getOverallCarrierPriceRange(
  carriers: CarrierViewModel[]
): PriceRange | undefined {
  const carrierPriceRanges = carriers.flatMap(
    (carrier) => carrier.priceRange ?? []
  );

  let overallPriceRange: PriceRange | undefined;
  if (carrierPriceRanges.length > 0) {
    overallPriceRange = carrierPriceRanges.reduce((acc, curr) => {
      return {
        low: Math.min(acc.low, curr.low),
        high: Math.max(acc.high, curr.high),
        currencyCode: curr.currencyCode, // Assuming all price ranges have the same currency code
      };
    });
  }
  return overallPriceRange;
}

export type CarrierRef = {
  code?: string;
  ref: RefObject<HTMLDivElement>;
};

export type CarrierListRefs = {
  carrierRefs?: CarrierRef[];
};

export type CarrierViewModel = {
  index: number;
  priceClasses: PriceClass[];
  debugPriceClass?: DebugPriceClass[];
  email?: string;
  phone?: string;
  images: CarrierImage[];
  name: string;
  code?: string;
  transitMode: Mode;
  lineNames: LineName[];
  durationInMinutes: number;
  frequencyPerWeek?: number;
  priceRange?: PriceRange;
  iconUrl?: string;
  bookingTexts: string[];
  advancedBooking?: AdvancedBooking;
  externalLinks: ExternalLink[];
  purchaseLocations: PurchaseLocation[];
  additionalTexts: string[];
  debug?: HopDebugInfo;
};

type PriceClass = PriceRange & { name: string };
export type DebugPriceClass = PricingDebug & { name: string | undefined };

type CarrierImage = {
  url: string;
  title: string;
  width: number;
  height: number;
  credit: string;
  creditUrl: string;
};

type ExternalLink = {
  kind: LinkKind;
  url: string;
  urlName?: string;
  text?: string;
};

type LinkKind =
  | "unknown"
  | "home"
  | "schedules"
  | "book"
  | "moreinfo"
  | "signup";
type PurchaseLocation = "online" | "station" | "phone" | "on board";
