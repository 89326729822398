import { type SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { AccessibilityLink } from "./AccessibilityLink";
import messages from "./SkipLink.messages.ts";

export function SkipLink() {
  const intl = useIntl();
  const handleFocus = () => {
    sendAnalyticsInteractionEvent("A11y", "Focus:SkipToContent");
  };

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();

    const container: HTMLElement | null = document.querySelector("#main");

    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute("tabindex"), 1000);
    }
  };

  return (
    <AccessibilityLink
      outlineColor="black"
      onFocus={handleFocus}
      onClick={handleClick}
    >
      {intl.formatMessage(messages.skipToMainContent)}
    </AccessibilityLink>
  );
}
