import type { SearchResponse } from "../api/SearchResponse";

export function getTicketLinkProviders(
  response: SearchResponse | undefined,
  routeIndex: number,
  segmentIndexOfRoute?: number,
  linkIndex?: number
): string[] {
  if (!response) {
    return [];
  } else if (segmentIndexOfRoute === undefined) {
    // The absence of a segment index means we have to look at the route.
    const links = response.routes[routeIndex]?.links;
    const link = links ? links[linkIndex ? linkIndex : 0] : undefined;
    const linkProviders = link?.providers;
    return linkProviders ? linkProviders : [];
  } else {
    const route = response.routes[routeIndex];
    const segmentIndex = route.segments[segmentIndexOfRoute];
    const links = response.segments[segmentIndex]?.links;
    const link = links ? links[linkIndex ? linkIndex : 0] : undefined;
    const linkProviders = link?.providers;
    return linkProviders ? linkProviders : [];
  }
}
