import { type SvgProps, useColor } from "./common";

export function ArrowForward(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <title>{props.title}</title>
      <path
        fill={tintColor}
        d="M-2 224h389.4L208.6 45.1 254 0l256 256-256 256-45.1-45.1L387.4 288H-2v-64z"
      />
    </svg>
  );
}
