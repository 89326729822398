import type {
  TripPlannerDetails,
  TripPlannerTransportKey,
} from "../TripPlannerProvider";
import { transportKeyHasPlacePair } from "./transportKeyHasPlacePair";

export function removeTransportWithoutPlaces(
  transport: TripPlannerDetails["transport"],
  places: TripPlannerDetails["places"]
): TripPlannerDetails["transport"] {
  const newTransport = { ...transport };
  const transportKeys = Object.keys(newTransport) as TripPlannerTransportKey[];

  transportKeys.forEach((key) => {
    if (!transportKeyHasPlacePair(places, key)) {
      delete newTransport[key];
    }
  });

  return newTransport;
}
