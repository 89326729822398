import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import type { SearchResponse } from "src/api/SearchResponse";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { createSearchResultViewModel } from "src/domain/SearchResultsScreen/createSearchResultViewModel";
import { TripSearchResult } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResult";
import { TripSearchResultSkeleton } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResultSkeleton";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import useSearch from "src/utils/hooks/useSearch";
import styled from "styled-components";
import { Typography } from "src/design-system/components/Typography/Typography";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { ShowMoreButton } from "src/components/TripPlanner/TripCard/SearchResultListCard/ShowMoreButton";
import messages from "./TransportList.messages";

const INITIAL_NUM_RESULTS = 3;

type Props = {
  origin?: string;
  destination: string;
};

export default function TransportList({ origin, destination }: Props) {
  const { searchResponse } = useSearch();

  if (
    !searchResponse ||
    origin !== searchResponse.request.oName ||
    destination !== searchResponse.request.dName
  ) {
    return <TransportListSkeleton />;
  }

  return <SearchResultList searchResponse={searchResponse} />;
}

function SearchResultList({
  searchResponse,
}: {
  searchResponse: SearchResponse;
}) {
  const intl = useIntl();
  const { setHoveredRecommendation } = useTripPlannerContext();
  const [numResults, setNumResults] = useState(INITIAL_NUM_RESULTS);

  const searchResults = createSearchResultViewModel(searchResponse);

  useEffect(() => {
    setHoveredRecommendation({
      hoverId: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleShowMoreClick() {
    setNumResults(searchResults.length);
    sendAnalyticsInteractionEvent(
      "Destination",
      "Click:ShowMoreTransportResults"
    );
  }

  function handleShowLessClick() {
    setNumResults(INITIAL_NUM_RESULTS);
    sendAnalyticsInteractionEvent("Destination", "Click:ShowLessResults");
  }

  const routes = searchResults.slice(0, numResults);

  return (
    <Container>
      <HeadingContainer>
        <Typography
          variant="heading-md"
          as="h2"
          onClick={() =>
            sendAnalyticsInteractionEvent("Destination", "Click:TransportTitle")
          }
        >
          {intl.formatMessage(messages.transportOptions, {
            origin: searchResponse.request.oName,
          })}
        </Typography>
      </HeadingContainer>
      {routes.map((searchResult) => (
        <TripSearchResult
          $border
          selectedRouteIndex="0"
          key={searchResult.canonicalName}
          analyticsCategory="Destination"
          isRadioHidden
          {...searchResult}
        />
      ))}
      {INITIAL_NUM_RESULTS < searchResults.length && (
        <ShowMoreButton
          onOpenClick={handleShowMoreClick}
          onCloseClick={handleShowLessClick}
          numResults={numResults}
          searchResultsLength={searchResults.length}
        />
      )}
    </Container>
  );
}

export function TransportListSkeleton() {
  return (
    <Container>
      <HeadingContainer>
        <Skeleton height="25px" width="200px" />
      </HeadingContainer>
      {[...Array(INITIAL_NUM_RESULTS)].map((_, index) => (
        <TripSearchResultSkeleton key={index} />
      ))}
      <ButtonWrapper>
        <Skeleton width="170px" height="20px" />
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: ${spacing.xxxl} ${spacing.xxl};
  background-color: ${color.bg.fill.fill};
`;

const HeadingContainer = styled.div`
  margin-bottom: ${spacing.lg};
`;

const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${spacing.xl} ${spacing.md} ${spacing.md};
`;
