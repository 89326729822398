import { defineMessages } from "react-intl";

export const messages = defineMessages({
  transportOptions: {
    id: "tripPlanner.tripCard.transportOptions",
    description: "Label for the CTA under the Search trip card",
    defaultMessage: "Transport options",
  },
  waysToTravel: {
    id: "tripPlanner.tripCard.waysToTravel",
    description: "Title for the CTA under the Search trip card",
    defaultMessage: "{ways} ways to travel to {destination}",
  },
  travelOptions: {
    id: "tripPlanner.tripCard.travelOptions",
    description: "Title for the search results on trip planner",
    defaultMessage: "Travel options",
  },
});
