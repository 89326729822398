import type { ComponentProps } from "react";
import { Button } from "src/components/Button/Button";
import { Icon } from "src/components/Icon/Icon";
import { SpeechBubble } from "src/svg/SpeechBubble";
import { color, spacing } from "src/theme";
import { desktopLayout } from "src/utils/hooks/useLayout";
import type { MergeElementProps } from "src/utils/MergeElementProps";
import styled from "styled-components";

type Props = MergeElementProps<
  "button",
  {
    message: string;
    onFeedbackButtonClick: () => void;
    backgroundColor: ComponentProps<typeof Button>["backgroundColor"];
    fontWeight?: ComponentProps<typeof Button>["fontWeight"];
  }
>;

export function FeedbackButton({
  message,
  backgroundColor,
  onFeedbackButtonClick,
  fontWeight,
  ...elementProps
}: Props) {
  return (
    <StyledButton
      {...elementProps}
      title={message}
      textColor="primaryOnLight"
      backgroundColor={backgroundColor}
      border={color.n40}
      onClick={onFeedbackButtonClick}
      fontSize="body"
      padding={`${spacing.lg} ${spacing.sm}`}
      borderRadius="rounded_md"
      fontWeight={fontWeight}
      inlineText
    >
      {message}
      <NonBreakingSpan>
        &nbsp;
        <Icon size="md">
          <SpeechBubble tint="cod" />
        </Icon>
      </NonBreakingSpan>
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  margin-top: ${spacing.xxl};
  ${desktopLayout} {
    margin-top: ${spacing.xxxxl};
  }
`;

// This ensures the icon wraps with the last word, not by itself
const NonBreakingSpan = styled.span`
  white-space: nowrap;
  display: inline;
  margin: ${spacing.xs};
  > * {
    vertical-align: middle;
  }
`;
