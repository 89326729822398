import { type SvgProps, useColor } from "../../svg/common";
import { color, neutralColor } from "../../theme";

type Props = SvgProps & {
  isChecked: boolean;
  disabled?: boolean;
  inactiveBackgroundColor?: keyof typeof color;
};

export function RoundRadioIcon({
  inactiveBackgroundColor,
  isChecked,
  disabled,
  ...svgProps
}: Props) {
  // Note: if the button is checked and disabled, we still show it as checked.
  if (isChecked) {
    return <RoundRadioButtonChecked {...svgProps} />;
  } else {
    return (
      <RoundRadioButtonUnchecked
        backgroundColor={inactiveBackgroundColor}
        disabled={disabled}
      />
    );
  }
}

function RoundRadioButtonChecked(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="11" stroke={tintColor} strokeWidth="2" />
      <circle cx="12" cy="12" r="6" fill={tintColor} />
    </svg>
  );
}

function RoundRadioButtonUnchecked({
  disabled,
  backgroundColor,
}: {
  disabled?: boolean;
  backgroundColor?: keyof typeof color;
}) {
  const strokeColour = disabled ? neutralColor.n40 : neutralColor.n60;
  const bgColor = backgroundColor ? color[backgroundColor] : "none";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={bgColor}>
      <circle cx="12" cy="12" r="11" stroke={strokeColour} strokeWidth="2" />
    </svg>
  );
}
