import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import type { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import type {
  Trigger,
  View,
} from "../../analytics/generateExitPoint/generateExitPoint";
import { isTicketCtaLike } from "../../analytics/generateExitPoint/generateExitPoint";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { useLogExitCallback } from "../../analytics/useLogExitCallback/useLogExitCallback";
import { carrierAdapter } from "../../domain/SegmentScreen/CarrierSection/carrierAdapter";
import { fontSize, fontWeight, spacing } from "../../theme";
import { capitalize } from "../../utils/capitalize";
import { formatIsoDate } from "../../utils/dateTime";
import useSearch from "../../utils/hooks/useSearch";
import type { Mode } from "../../utils/types/mode";
import { useTicketForm } from "../../utils/useTicketForm";
import { DatePicker } from "../DatePicker/DatePicker";
import { CalendarConfirmation } from "./CalendarConfirmation/CalendarConfirmation";
import messages from "./TicketExitConfirmation.messages";

type State = "pick_departing_date" | "pick_returning_date" | "confirm_dates";

type TicketExitConfirmationProps = {
  routeIndex: number;
  segmentIndexOfRoute?: number;
  exitPoint: {
    view: View;
    trigger: Trigger;
    transitMode?: Mode;
  };
  isFlightSchedule?: boolean;
  transitKind?: string;
};

export function TicketExitConfirmation({
  routeIndex,
  segmentIndexOfRoute,
  exitPoint,
  isFlightSchedule,
  transitKind,
}: TicketExitConfirmationProps) {
  const intl = useIntl();
  const { searchResponse } = useSearch();

  let form = useTicketForm({
    routeIndex: routeIndex,
    segmentIndexOfRoute: segmentIndexOfRoute,
    exitPoint: exitPoint,
  });

  const [currentState, setCurrentState] = useState<State>("confirm_dates");

  const logTicketExit = useLogExitCallback(
    "Tickets",
    exitPoint.view,
    exitPoint.trigger,
    exitPoint.transitMode
  );

  if (!form) {
    return null;
  }

  // When we know form is not null create new var to confirm type check.
  const ticketForm = form;

  const handleFindTicketsClick = () => {
    sendAnalyticsInteractionEvent(
      capitalize(exitPoint.view) as AnalyticsEventCategory,
      "Click:FindTickets"
    );

    let provider: string | undefined;
    let agencyIds: string[] | undefined;

    if (isTicketCtaLike(exitPoint.trigger) && searchResponse) {
      const carriers = carrierAdapter(
        searchResponse,
        routeIndex,
        segmentIndexOfRoute
      );
      provider = form?.exitProvider;
      agencyIds = carriers
        .map((carrier) => carrier.code)
        .filter((carrier): carrier is string => Boolean(carrier));
    }
    logTicketExit(ticketForm.exitUrl, provider, agencyIds);
  };

  switch (currentState) {
    case "confirm_dates":
      return (
        <CalendarConfirmation
          departureDate={formatIsoDate(ticketForm.picker.outboundDate)}
          returnDate={
            ticketForm.picker.returnDate
              ? formatIsoDate(ticketForm.picker.returnDate)
              : undefined
          }
          onDepartureDateClicked={() => {
            sendAnalyticsInteractionEvent("Tickets", "Click:DepartureDate");
            setCurrentState("pick_departing_date");
          }}
          onReturnDateClicked={() => {
            sendAnalyticsInteractionEvent("Tickets", "Click:ReturnDate");
            setCurrentState("pick_returning_date");
          }}
          onFindTicketsClicked={handleFindTicketsClick}
          ticketsExitUrl={ticketForm.exitUrl}
          isFlightSchedule={isFlightSchedule}
          transitKind={transitKind}
        />
      );
    case "pick_departing_date":
      return (
        <div>
          <Title>{intl.formatMessage(messages.departureTitle)}</Title>
          <StyledDatePicker
            minDate={ticketForm.picker.minOutboundDate}
            selectedDate={ticketForm.picker.outboundDate}
            onDateChange={(date) => {
              sendAnalyticsInteractionEvent("Tickets", "Select:DepartureDate");
              ticketForm.picker.setOutboundDate(date);
              setCurrentState("confirm_dates");
            }}
            maxDaysAhead={form.picker.maxDaysAhead}
            onMonthChange={() =>
              sendAnalyticsInteractionEvent("Tickets", "Scroll:Month")
            }
          />
        </div>
      );
    case "pick_returning_date":
      return (
        <div>
          <Title>{intl.formatMessage(messages.returnTitle)}</Title>
          <StyledDatePicker
            minDate={ticketForm.picker.minReturnDate}
            selectedDate={
              ticketForm.picker.returnDate
                ? ticketForm.picker.returnDate
                : ticketForm.picker.minReturnDate
            }
            onDateChange={(date) => {
              sendAnalyticsInteractionEvent("Tickets", "Select:ReturnDate");
              ticketForm.picker.setReturnDate(date);
              setCurrentState("confirm_dates");
            }}
            isSelectingReturn={true}
            maxDaysAhead={form.picker.maxDaysAhead}
            onMonthChange={() => {
              sendAnalyticsInteractionEvent("Tickets", "Scroll:Month");
            }}
          />
        </div>
      );
  }
}

const Title = styled.div`
  line-height: 28px;
  padding: ${spacing.lg};
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.medium};
  text-align: center;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: ${spacing.xl};
  padding-top: ${spacing.md};
`;
