import { useEffect, useState } from "react";
import type { Geocoded, GeocodedPlace } from "src/PrefetchData";
import { useQueryClient } from "@tanstack/react-query";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useIntl } from "react-intl";
import {
  getCacheKeyForGeocode,
  useGeocodeCanonical,
} from "../../../utils/hooks/useGeocode";
import { getLastTripPlannerPlace } from "../util/getTripPlannerPlace";
import type { TripPlannerDetails } from "../TripPlannerProvider";
import type { TripPlannerContextType } from "./useTripPlannerContext";

type TripDestinationArgs = {
  tripPlannerDetails: TripPlannerDetails;
  dispatch: TripPlannerContextType["dispatch"];
  tripPlanningState: TripPlannerContextType["tripPlanningState"];
};

/**
 * This hook is used to add a destination to the trip planner.
 * It will geocode the destination and then add the destination to the trip planner.
 */
export function useTripDestination({
  tripPlannerDetails,
  dispatch,
  tripPlanningState,
}: TripDestinationArgs) {
  const intl = useIntl();
  const [destination, setDestination] = useState<{
    canonicalName: string;
    shortName?: string;
    longName?: string;
  }>();
  const languageCode = localeToLanguageCode(intl.locale);
  const queryClient = useQueryClient();
  const destinationResult = useGeocodeCanonical(destination?.canonicalName);
  const cacheKey = getCacheKeyForGeocode(
    destination?.canonicalName,
    languageCode
  );
  const cachedQuery = queryClient.getQueryData<Geocoded>(cacheKey);

  useEffect(() => {
    if (cachedQuery && !cachedQuery.lat) {
      // We need to refetch to get the new lat/lng
      // This is because the autocomplete hook has cached place data that doesn't have lat/lng
      destinationResult.refetch();
      tripPlanningState.updatedPlace.set(tripPlannerDetails.places.length);
    }
  }, [
    tripPlanningState.updatedPlace,
    tripPlannerDetails.places.length,
    cachedQuery,
    destinationResult,
  ]);

  useEffect(() => {
    const lastPlace = getLastTripPlannerPlace(tripPlannerDetails);
    if (
      destinationResult.data &&
      destinationResult.data.lat &&
      lastPlace?.canonicalName !== destinationResult.data.canonicalName
    ) {
      dispatch({
        type: "ADD_DESTINATION",
        destination: destinationResult.data as GeocodedPlace,
      });
      tripPlanningState.updatedPlace.set(tripPlannerDetails.places.length);
      setDestination(undefined);

      return () => {
        setDestination(undefined);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationResult.data]);

  return {
    destinationCanonical: destination?.canonicalName,
    isLoading: destinationResult.isLoading || destinationResult.isRefetching,
    shortName: destination?.shortName,
    longName: destination?.longName,
    setDestination,
  };
}
