import { useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useFeature } from "src/feature/useFeature";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { encodeSearchParamsForUrl } from "src/utils/encodeSearchParamsForUrl";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { getPath } from "src/utils/url";
import { setSearchParamFromPlaces } from "../../../utils/hooks/useSearchParamPlaces";
import type { TripPlannerDetails } from "../TripPlannerProvider";
import { MAX_SEARCH_PARAM_PLACES } from "./useSwitchURLToTripId";

/** This function:
 * - Updates A-B to reflect trip plan first and second location e.g.`map/Sydney/Melbourne`
 * - Updates search query when tripID is not being used e.g.`search=Sydney,Melbourne`
 */
export default function useUpdatePathForTrip() {
  const intl = useIntl();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const updatedPathRef = useRef<string | undefined>(undefined);
  const isReturnToCore = useFeature("ReturnToCore");

  const updateTripPath = useCallback(
    (tripPlannerDetails: TripPlannerDetails) => {
      if (tripPlannerDetails.places.length < 2) {
        return;
      }
      const [origin, destination] = tripPlannerDetails.places;
      const pathname = getPath(
        origin.canonicalName,
        destination.canonicalName,
        localeToLanguageCode(intl.locale)
      );
      let search: string | undefined = location.search;
      const shouldUpdateTripSearchParam =
        !user?.id &&
        !tripPlannerDetails.slug &&
        tripPlannerDetails.places.length <= MAX_SEARCH_PARAM_PLACES;

      if (shouldUpdateTripSearchParam) {
        const places = tripPlannerDetails.places;
        const searchParams = new URLSearchParams(location.search);
        setSearchParamFromPlaces(searchParams, places);
        search = encodeSearchParamsForUrl(searchParams);
      }
      navigateToNewStateHash(
        navigate,
        {
          tripPlannerDetails,
        },
        "#trips",
        {
          ...location,
          pathname: isReturnToCore ? location.pathname : pathname,
          search: search ?? location.search,
        }
      );
    },
    [intl.locale, location, user?.id, isReturnToCore, navigate]
  );

  return { updateTripPath, updatedPathRef };
}
