import { encodeSearchParamsForUrl } from "../encodeSearchParamsForUrl";
import type { TypedLocation } from "../hooks/useTypedLocation";
import { cloneLocation } from "./cloneLocation";

export function searchParamsChange(
  update: URLSearchParams,
  current: TypedLocation
): TypedLocation {
  return {
    ...cloneLocation(current),
    search: encodeSearchParamsForUrl(update) ?? "",
  };
}
