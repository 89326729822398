import { type RefObject, useEffect, useRef } from "react";

// This hook calls `handleClickOff` when a user clicks off of `elementRef.current`.
export function useHandleClickOffElement(
  elementRef: RefObject<HTMLElement>,
  handleClickOff?: (e: MouseEvent) => void
) {
  const activatedRef = useRef(false);
  useEffect(() => {
    // There is a bug in React where rendering a portal causes a timing change
    // in when callbacks are triggered. This ensures that we ignore the click
    // event that rendered this hook to work around the issue.
    // See: https://github.com/facebook/react/issues/20074
    setTimeout(() => {
      activatedRef.current = true;
    }, 0);
    return () => {
      activatedRef.current = false;
    };
  }, []);

  // A ref is used for the callback because the useEffect doesn't
  // need to run again if the callback changes.
  const handleClickOffRef = useRef(handleClickOff);
  useEffect(() => {
    handleClickOffRef.current = handleClickOff;
  }, [handleClickOff]);

  useEffect(() => {
    function handlePageClick(e: any) {
      // We don't yet know what the element is, so we can't know whether it's been clicked off.
      if (!elementRef.current || !activatedRef.current) {
        return;
      }

      // We need to check if the click event will bubble to our element, composed path will tell us this.
      const path = e.composedPath ? e.composedPath() : e.path;

      // If a user clicks on something other than the element, we want to trigger the callback.
      if (
        !path?.includes(elementRef.current) &&
        !elementRef.current.contains(e.target) &&
        handleClickOffRef.current
      ) {
        handleClickOffRef.current(e);
      }
    }

    document.addEventListener("click", handlePageClick);

    return () => {
      document.removeEventListener("click", handlePageClick);
    };
    // `elementRef` is needed in the dependency array because it could change
    // if the user of this hook passes in an entirely new ref. But this dependency
    // won't cause a rerender so long as the ref passed into this
    // custom hook is the same.
  }, [elementRef]);
}
