import { font_size, font_weight } from "src/design-system/tokens/typography";
import { screenMinWidth } from "src/theme";
import styled from "styled-components";

export const FeatureItem = styled.div<{ pointer?: boolean }>`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${font_size.text_sm};
  font-weight: ${font_weight.normal};
  border-bottom: 1px solid #ebebeb;
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};
  width: 100%;
  ${screenMinWidth.xl} {
    width: 400px;
  }
`;
