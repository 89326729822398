import { defineMessages } from "react-intl";

const messages = defineMessages({
  back: {
    id: "createTrip.back",
    defaultMessage: "Back",
    description: "Back button to return to trips screen",
  },
  homepage: {
    id: "createTrip.homepage",
    defaultMessage: "Rome2Rio homepage",
    description: "The label on a logo image that links to the homepage",
  },
  heading: {
    id: "createTrip.heading",
    defaultMessage: "Where do you want to go?",
    description: "Heading asking the user where they'd like to go to",
  },
  loggedInHeading: {
    id: "createTrip.loggedInHeading",
    defaultMessage: "New trip",
    description: "Logged in user heading for creating a new trip",
  },
  cta: {
    id: "createTrip.cta",
    defaultMessage: "Create trip",
    description: "CTA message for user to create their trip",
  },
  withTrips: {
    id: "createTrip.withTrips",
    defaultMessage: "With trips you can",
    description: "Heading to describe what the benefits of trips are",
  },
  addDestinations: {
    id: "createTrip.addDestinations",
    defaultMessage: "Add as many destinations as you like",
    description: "Let user know how many destinations they can add",
  },
  saveTrips: {
    id: "createTrip.saveTrips",
    defaultMessage: "Save your trips for later",
    description: "Letting user know they can save trip to return to later",
  },
  bestWays: {
    id: "createTrip.bestWays",
    defaultMessage: "Find the best ways to travel",
    description: "Letting user know what trip planner has to offer",
  },
});

export default messages;
