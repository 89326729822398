import { type SvgProps, useColor } from "./common";

export function Switch(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{props.title}</title>
      <path
        d="M17.7215 5.62641C18.0928 5.23947 18.0928 4.62847 17.7215 4.24153L13.9459 0.307564C13.5634 -0.0908974 12.9304 -0.103899 12.5319 0.278523C12.1335 0.660946 12.1205 1.29398 12.5029 1.69244L14.6542 3.93397L7.54424 3.93397C6.99196 3.93397 6.54424 4.38168 6.54424 4.93397C6.54424 5.48625 6.99196 5.93397 7.54424 5.93397L14.6542 5.93397L12.5029 8.1755C12.1205 8.57396 12.1335 9.20699 12.5319 9.58941C12.9304 9.97184 13.5634 9.95883 13.9459 9.56037L17.7215 5.62641Z"
        fill={tintColor}
      />
      <path
        d="M0.278522 12.3725C-0.0928407 12.7594 -0.0928408 13.3704 0.278522 13.7574L4.05414 17.6913C4.43656 18.0898 5.0696 18.1028 5.46806 17.7204C5.86652 17.338 5.87952 16.7049 5.4971 16.3065L3.34579 14.0649L10.4558 14.0649C11.008 14.0649 11.4558 13.6172 11.4558 13.0649C11.4558 12.5127 11.008 12.0649 10.4558 12.0649L3.34579 12.0649L5.4971 9.82341C5.87952 9.42495 5.86652 8.79192 5.46806 8.40949C5.0696 8.02707 4.43657 8.04007 4.05414 8.43853L0.278522 12.3725Z"
        fill={tintColor}
      />
    </svg>
  );
}
