import type { Geocoded } from "src/PrefetchData";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import type { SearchResponse } from "src/api/SearchResponse";
import { configureAnalytics } from "src/analytics/configureAnalytics";
import { searchEndpoint } from "src/api/endpoints";
import type { ApiConfig } from "src/api/ApiConfig";

import type { SupportedCurrencyCode } from "./currency";
import type { SupportedLanguageCode } from "./language";

export async function search(
  origin: Geocoded,
  destination: Geocoded,
  currencyCode: SupportedCurrencyCode,
  languageCode: SupportedLanguageCode,
  config: ApiConfig
): Promise<SearchResponse> {
  const url = searchEndpoint(
    config,
    origin.canonicalName,
    origin.googlePlaceId,
    destination.canonicalName,
    destination.googlePlaceId,
    languageCode,
    currencyCode
  );

  try {
    logApiInfo("Sent", origin.canonicalName, destination.canonicalName);

    const requestTimestamp = Date.now();
    const response = await fetch(url, {
      referrerPolicy: "no-referrer-when-downgrade",
    });

    if (!response.ok) {
      throw Error(`GetRoutes Error: ${response.status}`);
    }

    const json: SearchResponse = await response.json();

    logApiInfo(
      "Received",
      origin.canonicalName,
      destination.canonicalName,
      Date.now() - requestTimestamp
    );

    processSearchResponse(json, origin, destination);

    return json;
  } catch (error) {
    logApiInfo("Error", origin.canonicalName, destination.canonicalName);
    throw error;
  }
}

// Process our search response as a seperate step.
// This lets a prefetched search response send the same events as a normal one.
export function processSearchResponse(
  json: SearchResponse,
  origin: Geocoded,
  destination: Geocoded
) {
  // Update the custom dimensions in the Analytics config now that we have access to them
  // so they're included in following Analytics events.
  configureAnalytics({
    pageConfig: {
      pagePath:
        window.location.pathname +
        window.location.search +
        window.location.hash,
      pageLocation: window.location.href,
    },
    customDimensions: json.analytics?.custom_dimensions,
  });

  logApiInfo("Success", origin.canonicalName, destination.canonicalName);
}

export function logApiInfo(
  status: "Sent" | "Received" | "Success" | "Error",
  originCanonical: string,
  destinationCanonical: string,
  value?: number // milliseconds
) {
  sendAnalyticsNonInteractionEvent(
    "GenericExplore",
    `SearchRequest:${status}`,
    `${originCanonical}:${destinationCanonical}`,
    value
  );
}

export function getCacheKeyForSearch(
  originCanonical: string | undefined,
  destinationCanonical: string | undefined,
  currencyCode: string,
  languageCode: string,
  searchDate?: string
) {
  return [
    "search",
    originCanonical,
    destinationCanonical,
    currencyCode,
    languageCode,
    searchDate ?? "anytime",
  ];
}
