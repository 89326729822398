import type { TripPlanWithID, TripResponse } from "./api";

export function tripResponseToTripPlannerDetails(
  tripResponse: TripResponse
): TripPlanWithID {
  return {
    id: tripResponse.tripId,
    slug: tripResponse.slug,
    transport: tripResponse.data.transport,
    places: tripResponse.data.places,
    name: tripResponse.data.name,
    startDate: tripResponse.data.startDate,
  };
}
