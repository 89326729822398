import type { SearchResponse } from "../../api/SearchResponse";
import {
  type TicketInfo,
  ticketInfoFromLinks,
} from "../../utils/adapters/ticketInfo";
import type { Mode } from "../../utils/types/mode";
import {
  type PriceRange,
  priceRangeFromRoute,
} from "../../utils/adapters/priceRange";
import { durationInMinutesFromRoute } from "../../utils/adapters/duration";
import { transitModeFromSegment } from "../../utils/adapters/transitMode";
import { originUtcOffsetFromRoute } from "../../utils/adapters/utcOffset";
import {
  type CarrierViewModel,
  carrierAdapter,
} from "../SegmentScreen/CarrierSection/carrierAdapter";
import type { PartialSearchResponse } from "../TripPlanner/util/getPartialSearchResponse";

export type RouteScreenViewModel = {
  title: string;
  priceRange?: PriceRange;
  durationInMinutes: number;
  ticketInfo?: TicketInfo;
  transitMode: Mode;
  carriers: CarrierViewModel[];
};

export function createRouteScreenViewModel(
  response: SearchResponse,
  routeIndex: number
): RouteScreenViewModel {
  const route = response.routes[routeIndex];

  const originUtcOffset = originUtcOffsetFromRoute(response, routeIndex);

  return {
    title: route.name,
    priceRange: priceRangeFromRoute(route),
    durationInMinutes: durationInMinutesFromRoute(response, route),
    ticketInfo: ticketInfoFromLinks(route.links, originUtcOffset),
    transitMode: getTransitModeFromFirstMajorSegment(response, routeIndex),
    carriers: carrierAdapter(response, routeIndex),
  };
}

export function getTransitModeFromFirstMajorSegment(
  response: SearchResponse | PartialSearchResponse,
  routeIndex: number
): Mode {
  let firstMajorSegmentIndex = 0;
  const segmentIndices = response.routes[routeIndex].segments;
  for (let i = 0; i < segmentIndices.length; i++) {
    const segment = response.segments[segmentIndices[i]];
    if (segment.isMajor) {
      firstMajorSegmentIndex = i;
    }
  }

  return transitModeFromSegment(response, routeIndex, firstMajorSegmentIndex);
}
