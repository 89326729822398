import type { SearchResponse, Segment } from "../../../../api/SearchResponse";
import { vehicleFromSegment } from "../../../../utils/adapters/vehicle";
import { transitModeFromVehicleKind } from "../../../../utils/adapters/transitMode";
import type { TripPointAlternativeNames } from "../../../../utils/hooks/useTimelineTripPointAlternativeNames";
import {
  type AccommodationPromoTimelineProps,
  createAccommodationCellFromProps,
  createUnScheduledAccommodationPromoCells,
} from "../../Shared/AccommodationPromoCell";
import type { Cell } from "./Cell";
import { originCellFromSegment } from "./OriginCell";
import { detailCellFromSegment } from "./DetailCell";
import { destinationCellFromSegment } from "./DestinationCell";
import { changeOrTransferCellFromSegment } from "./TransferCell";
import { interchangeCellFromSegment } from "./InterchangeCell";

type RouteTimelineProps = {
  response: SearchResponse;
  routeIndex: number;
  isStandalone?: boolean;
  tripPointAlternatives?: TripPointAlternativeNames;
};

export function createTimelineCellsFromRoute(
  props: RouteTimelineProps & AccommodationPromoTimelineProps
): Cell[] {
  const result: Cell[] = [];
  const route = props.response.routes[props.routeIndex];

  // Loop through the Segments
  for (let i = 0; i < route.segments.length; i++) {
    result.push(
      ...createTimelineCellsFromSegmentInternal({
        ...props,
        currentSegmentIndex: i,
        isStandalone: false,
      })
    );
  }

  createUnScheduledAccommodationPromoCells(result, props);
  return result;
}

/**
 * isJoint determines whether it should be rendered as a card or a connecting joint between cards
 * isInteractive determines whether a group should show hover styling to indicate interactivity
 */
export type GroupedTimelineCells = {
  cells: Cell[];
  origin?: string;
  destination?: string;
  isJoint?: boolean;
  isInteractive?: boolean;
};

export function createGroupedTimelineCellsFromRoute(
  props: RouteTimelineProps & AccommodationPromoTimelineProps
): GroupedTimelineCells[] {
  const results: GroupedTimelineCells[] = [];
  const route = props.response.routes[props.routeIndex];

  // Loop through the Segments
  for (let i = 0; i < route.segments.length; i++) {
    results.push(
      ...createGroupedTimelineCellsFromSegmentInternal({
        ...props,
        currentSegmentIndex: i,
      })
    );
  }
  const accomCell = createAccommodationCellFromProps(props);
  if (accomCell) {
    results.push({
      cells: [{ id: "hotel-joint", type: "joint" }],
      isJoint: true,
    });
    results.push({ cells: [accomCell], isInteractive: true });
  }

  return results;
}

type SegmentTimelineProps = {
  response: SearchResponse;
  routeIndex: number;
  currentSegmentIndex: number;
  isStandalone?: boolean;
  tripPointAlternatives?: TripPointAlternativeNames;
};

export function createTimelineCellsFromSegment(
  props: SegmentTimelineProps & AccommodationPromoTimelineProps
) {
  let cells = createTimelineCellsFromSegmentInternal(props);

  const route = props.response.routes[props.routeIndex];
  const isFinalSegment =
    props.currentSegmentIndex === route.segments.length - 1;

  if (isFinalSegment) {
    createUnScheduledAccommodationPromoCells(cells, props);
  }

  return cells;
}

function createTimelineCellsFromSegmentInternal({
  response,
  routeIndex,
  currentSegmentIndex,
  isStandalone,
  tripPointAlternatives,
}: SegmentTimelineProps): Cell[] {
  const route = response.routes[routeIndex];
  const segmentLookupIndex = route.segments[currentSegmentIndex];
  const currentSegment = response.segments[segmentLookupIndex];

  const isFinalSegment = currentSegmentIndex === route.segments.length - 1;
  const isFirstSegment = currentSegmentIndex === 0;

  let previousSegment;
  let nextSegment;

  if (!isFirstSegment) {
    const previousSegmentIndex = route.segments[currentSegmentIndex - 1];
    previousSegment = response.segments[previousSegmentIndex];
  }

  if (!isFinalSegment) {
    const nextSegmentIndex = route.segments[currentSegmentIndex + 1];
    nextSegment = response.segments[nextSegmentIndex];
  }

  if (isWalkingSegment(response, currentSegment)) {
    const result = [];
    // Walking Segments don't show the origin/destination cells if they're not at one of the ends of the route
    if (isFirstSegment || isStandalone) {
      result.push(
        originCellFromSegment(
          response,
          routeIndex,
          currentSegmentIndex,
          isStandalone,
          tripPointAlternatives
        )
      );
    }
    result.push(
      detailCellFromSegment(response, routeIndex, currentSegmentIndex)
    );
    if (isFinalSegment || isStandalone) {
      result.push(
        destinationCellFromSegment(
          response,
          routeIndex,
          currentSegmentIndex,
          isStandalone,
          tripPointAlternatives
        )
      );
    }
    return result;
  } else if (
    !isStandalone &&
    nextSegment &&
    isInterchange(response, currentSegment, nextSegment)
  ) {
    // The next is an interchange, so we need to end with an InterchangeCell
    if (
      previousSegment &&
      isInterchange(response, previousSegment, currentSegment)
    ) {
      // Previous was an interchange, don't return an origin
      return [
        detailCellFromSegment(response, routeIndex, currentSegmentIndex),
        interchangeCellFromSegment(
          response,
          routeIndex,
          currentSegmentIndex,
          tripPointAlternatives
        ),
      ];
    } else {
      // The we need to include an interchange at the end instead of a destination
      return [
        originCellFromSegment(
          response,
          routeIndex,
          currentSegmentIndex,
          isStandalone,
          tripPointAlternatives
        ),
        detailCellFromSegment(response, routeIndex, currentSegmentIndex),
        interchangeCellFromSegment(
          response,
          routeIndex,
          currentSegmentIndex,
          tripPointAlternatives
        ),
      ];
    }
  } else if (
    !isStandalone &&
    previousSegment &&
    isInterchange(response, previousSegment, currentSegment)
  ) {
    // If the previous was an interchange we don't include an origin
    // If the next is also an interchange then we don't push a destination, and instead push an interchange
    if (nextSegment && isInterchange(response, currentSegment, nextSegment)) {
      return [
        detailCellFromSegment(response, routeIndex, currentSegmentIndex),
        interchangeCellFromSegment(
          response,
          routeIndex,
          currentSegmentIndex,
          tripPointAlternatives
        ),
      ];
    } else {
      return [
        detailCellFromSegment(response, routeIndex, currentSegmentIndex),
        destinationCellFromSegment(
          response,
          routeIndex,
          currentSegmentIndex,
          isStandalone,
          tripPointAlternatives
        ),
      ];
    }
  } else if (isTransfer(response, currentSegment)) {
    return [
      changeOrTransferCellFromSegment(
        response,
        routeIndex,
        currentSegmentIndex
      ),
    ];
  } else {
    return [
      originCellFromSegment(
        response,
        routeIndex,
        currentSegmentIndex,
        isStandalone,
        tripPointAlternatives
      ),
      detailCellFromSegment(response, routeIndex, currentSegmentIndex),
      destinationCellFromSegment(
        response,
        routeIndex,
        currentSegmentIndex,
        isStandalone,
        tripPointAlternatives
      ),
    ];
  }
}

function createGroupedTimelineCellsFromSegmentInternal({
  response,
  routeIndex,
  currentSegmentIndex,
  isStandalone,
  tripPointAlternatives,
}: SegmentTimelineProps): GroupedTimelineCells[] {
  const route = response.routes[routeIndex];
  const segmentLookupIndex = route.segments[currentSegmentIndex];
  const currentSegment = response.segments[segmentLookupIndex];

  const isFinalSegment = currentSegmentIndex === route.segments.length - 1;
  const isFirstSegment = currentSegmentIndex === 0;

  let previousSegment;

  const results: GroupedTimelineCells[] = [];

  const originCell = originCellFromSegment(
    response,
    routeIndex,
    currentSegmentIndex,
    true,
    tripPointAlternatives
  );

  const destinationCell = destinationCellFromSegment(
    response,
    routeIndex,
    currentSegmentIndex,
    true,
    tripPointAlternatives
  );

  if (!isFirstSegment) {
    const previousSegmentIndex = route.segments[currentSegmentIndex - 1];
    previousSegment = response.segments[previousSegmentIndex];
  }

  function addNewGroup({
    cells,
    isJoint,
    isInteractive,
  }: {
    cells: Cell[];
    isJoint?: boolean;
    isInteractive?: boolean;
  }) {
    results.push({
      origin: originCell.placeName,
      destination: destinationCell.placeName,
      cells,
      isJoint,
      isInteractive,
    });
  }
  if (isWalkingSegment(response, currentSegment)) {
    // Walking Segments don't show the origin/destination cells if they're not at one of the ends of the route
    if (isFirstSegment || isStandalone) {
      addNewGroup({ cells: [originCell] });
    }
    addNewGroup({
      cells: [detailCellFromSegment(response, routeIndex, currentSegmentIndex)],
      isInteractive: true,
      isJoint: true,
    });
    if (isFinalSegment || isStandalone) {
      addNewGroup({ cells: [destinationCell] });
    }
  } else if (isTransfer(response, currentSegment)) {
    // Frontend now determines what is considered a transfer or change.
    // Transfers are now defined as any changes in transit modes apart from walking.
    return results;
  } else {
    if (previousSegment && !isWalkingSegment(response, previousSegment)) {
      addNewGroup({
        cells: [
          changeOrTransferCellFromSegment(
            response,
            routeIndex,
            currentSegmentIndex - 1,
            isInterchange(response, previousSegment, currentSegment)
          ),
        ],
        isJoint: true,
      });
    }
    addNewGroup({
      cells: [
        originCell,
        detailCellFromSegment(response, routeIndex, currentSegmentIndex),
        destinationCell,
      ],
      isInteractive: true,
    });
  }

  return results;
}

function isInterchange(
  response: SearchResponse,
  currentSegment: Segment,
  nextSegment: Segment
): boolean {
  return isSameMode(response, currentSegment, nextSegment);
}

function isTransfer(
  response: SearchResponse,
  currentSegment: Segment
): boolean {
  // Transfers will only show up if provided by the API
  return vehicleFromSegment(response, currentSegment).kind === "transfer";
}

function isSameMode(
  response: SearchResponse,
  currentSegment: Segment,
  nextSegment: Segment
): boolean {
  const kindA = vehicleFromSegment(response, currentSegment).kind;
  const kindB = vehicleFromSegment(response, nextSegment).kind;
  return (
    transitModeFromVehicleKind(kindA) === transitModeFromVehicleKind(kindB)
  );
}

function isWalkingSegment(response: SearchResponse, segment: Segment): boolean {
  const vehicle = vehicleFromSegment(response, segment);
  return vehicle.kind === "walk";
}
