import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./polyfill";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { initPrefetchedData } from "src/prefetchedMetadata";
import { webVitals } from "src/utils/logging/webVitals";
import logError from "src/utils/logError";
import { trackErrors } from "src/utils/logging/errorFree";
import { QueryClient } from "@tanstack/react-query";
import { initSentry } from "./initSentry";
import { refreshOnPreloadErrors } from "./utils/vitePreloadErrorHandler";
import { AppRoutes } from "./AppRoutes";
import { App } from "./App";
import { ProviderLayout } from "./pages/ProviderLayout";
import { attachNavigateConflictListener } from "./utils/logNavigate";

refreshOnPreloadErrors();

const queryClient = new QueryClient();

try {
  // The `touchstart` listener lets our :active styles show on iOS Safari.
  // The `{passive: true}` object is to improve scroll performance:
  // https://developers.google.com/web/tools/lighthouse/audits/passive-event-listeners
  document.addEventListener("touchstart", () => {}, { passive: true });

  // Calls errorFree.tsx which takes what our index.html has caught prior to our
  // bundle loading. Then will log those errors + anymore during a session to GA.
  trackErrors();

  // The Cloudflare worker will attempt to do a geocode/search itself, storing the data in
  // a script tag with `id=prefetch-data`. We can use it to pre-cache our future requests.
  const initialRequestId = initPrefetchedData(
    queryClient,
    document.getElementById("prefetch-data")?.textContent?.toString()
  );

  // Initialises web vitals for sending 'vital' metrics for real sessions to GA.
  // Use requestid from initial search if available.
  webVitals(initialRequestId);
} catch (error) {
  logError(error);
}

initSentry();
const root = createRoot(document.getElementById("root")!);
const router = createBrowserRouter(
  createRoutesFromElements(
    AppRoutes({
      children: <App />,
      global: <ProviderLayout queryClient={queryClient} />,
    })
  )
);

async function startApplication() {
  if (import.meta.env.MODE === "development") {
    attachNavigateConflictListener(router);
  }
  if (import.meta.env.VITE_ENVIRONMENT === "Development") {
    const { getWorker } = await import("./test-utils/mocks/browser");
    const worker = await getWorker();
    await worker?.start({
      onUnhandledRequest: "bypass",
    });
  }
  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
}

startApplication();
