import { type VariantProps, cva } from "class-variance-authority";
import type { ComponentProps } from "react";
import styles from "./Icon.module.css";

export const iconVariants = cva(styles.base, {
  variants: {
    size: {
      small: styles.small,
      default: styles.default,
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export type ButtonProps = ComponentProps<"span"> &
  VariantProps<typeof iconVariants>;

export function Icon({ className, size, ...props }: ButtonProps) {
  return <span {...props} className={iconVariants({ className, size })} />;
}
