import { type SvgProps, useColor } from "./common";

type MeatBallsProps = {
  active?: boolean;
} & SvgProps;

export function MeatBalls(props: MeatBallsProps) {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="12" r="1.5" fill={tintColor} />
      <circle cx="12" cy="12" r="1.5" fill={tintColor} />
      <circle cx="18" cy="12" r="1.5" fill={tintColor} />
    </svg>
  );
}
