type Props = {
  isChecked: boolean;
};

export function TransitRadioIcon({ isChecked }: Props) {
  return (
    <div>
      {isChecked ? <TransitRadioIconChecked /> : <TransitRadioIconUnchecked />}
    </div>
  );
}

function TransitRadioIconChecked() {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.5C0 7.66344 7.16344 0.5 16 0.5C24.8366 0.5 32 7.66344 32 16.5C32 25.3366 24.8366 32.5 16 32.5C7.16344 32.5 0 25.3366 0 16.5Z"
        fill="#008B2F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2559 10.9108C23.5814 11.2363 23.5814 11.7639 23.2559 12.0893L14.0893 21.256C13.7638 21.5814 13.2362 21.5814 12.9107 21.256L8.74408 17.0893C8.41864 16.7639 8.41864 16.2363 8.74408 15.9108C9.06951 15.5854 9.59715 15.5854 9.92259 15.9108L13.5 19.4882L22.0774 10.9108C22.4028 10.5854 22.9305 10.5854 23.2559 10.9108Z"
        fill="white"
      />
    </svg>
  );
}

function TransitRadioIconUnchecked() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 15.9998C0.5 7.43934 7.43959 0.499756 16 0.499756C24.5604 0.499756 31.5 7.43934 31.5 15.9998C31.5 24.5602 24.5604 31.4998 16 31.4998C7.43959 31.4998 0.5 24.5602 0.5 15.9998Z"
        fill="white"
      />
      <path
        d="M0.5 15.9998C0.5 7.43934 7.43959 0.499756 16 0.499756C24.5604 0.499756 31.5 7.43934 31.5 15.9998C31.5 24.5602 24.5604 31.4998 16 31.4998C7.43959 31.4998 0.5 24.5602 0.5 15.9998Z"
        stroke="#E3E3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0002 9.33301C16.4604 9.33301 16.8335 9.7061 16.8335 10.1663V21.833C16.8335 22.2932 16.4604 22.6663 16.0002 22.6663C15.5399 22.6663 15.1668 22.2932 15.1668 21.833V10.1663C15.1668 9.7061 15.5399 9.33301 16.0002 9.33301Z"
        fill="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3335 15.9997C9.3335 15.5394 9.70659 15.1663 10.1668 15.1663H21.8335C22.2937 15.1663 22.6668 15.5394 22.6668 15.9997C22.6668 16.4599 22.2937 16.833 21.8335 16.833H10.1668C9.70659 16.833 9.3335 16.4599 9.3335 15.9997Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}
