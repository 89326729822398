import type { ExperimentConfig } from "./useExperimentConfig";
import {
  getExperimentNameFromId,
  getExperimentVariantFromExperiment,
} from "./ExperimentDefinition";

// Accepts a full compressed experiment config string and returns an
// ExperimentConfig. For example, it accepts a string like '70:1|77:0|6d:1' and
// returns a full experiment config.
export function decompressExperimentConfig(
  compressedExperimentConfig: string
): ExperimentConfig {
  return compressedExperimentConfig
    .split("|")
    .reduce((previous, compressedExperiment) => {
      const experimentConfig = decompressExperiment(compressedExperiment) ?? {};
      return { ...previous, ...experimentConfig };
    }, {} as ExperimentConfig);
}

// Accepts a compressed experiment string and returns an ExperimentConfig.
// For example, it accepts a string like '70:1' and returns a partial experiment
// config or undefined if the experiment definition can't be mapped.
function decompressExperiment(
  experiment: string
): Partial<ExperimentConfig> | undefined {
  const [experimentId, variantId] = experiment.split(":");
  const experimentName = getExperimentNameFromId(experimentId);
  if (!experimentName) {
    return undefined;
  }
  const experimentVariant = getExperimentVariantFromExperiment(
    experimentName,
    Number(variantId)
  );
  if (!experimentVariant) {
    return undefined;
  }

  return { [experimentName]: experimentVariant };
}
