import { type SvgProps, useColor } from "../common";

type ExpandProps = {
  active?: boolean;
} & SvgProps;

export function Expand(props: ExpandProps) {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        d="M16 0.666579C16 0.489791 15.9298 0.320244 15.8047 0.195237C15.6797 0.0702285 15.5101 -4.23858e-07 15.3332 0L10.0002 3.45707e-06C9.63197 3.88093e-06 9.33345 0.298441 9.33345 0.666583C9.33345 1.03472 9.63197 1.33316 10.0002 1.33316L13.7241 1.33316L8.86199 6.1963C8.60167 6.45668 8.60177 6.87873 8.86223 7.13898C9.12268 7.39923 9.54486 7.39912 9.80518 7.13874L14.6665 2.27642L14.6665 6.00075C14.6665 6.36889 14.965 6.66733 15.3332 6.66733C15.7015 6.66733 16 6.36889 16 6.00075L16 0.666579Z"
        fill={tintColor}
      />
      <path
        d="M1.66893e-06 15.3362C1.88086e-06 15.5129 0.0702999 15.6823 0.195432 15.8072C0.320563 15.9322 0.490278 16.0023 0.667241 16.0023L6.00025 16.0023C6.36875 16.0023 6.66749 15.7041 6.66749 15.3362C6.66749 14.9683 6.36875 14.6701 6.00025 14.6701L2.27592 14.6701L7.13911 9.80584C7.39943 9.54546 7.39903 9.1237 7.13821 8.86381C6.87739 8.60393 6.45491 8.60434 6.19459 8.86472L1.33448 13.7259L1.33448 10.002C1.33448 9.63416 1.03574 9.33594 0.667238 9.33594C0.298733 9.33594 0 9.63416 0 10.002L1.66893e-06 15.3362Z"
        fill={tintColor}
      />
    </svg>
  );
}
