import {
  type ElementType,
  type PropsWithChildren,
  type ReactNode,
  forwardRef,
} from "react";
import styled from "styled-components";
import { type ColorType, color, fontWeight, fontSize } from "../../theme";
import { Icon } from "../Icon/Icon";
import type { MergeElementProps } from "../../utils/MergeElementProps";
import { OutlineButtonBase } from "./OutlineButtonBase";

type CommonProps = PropsWithChildren<{
  textColor?: ColorType;
  outlineColor: ColorType;
  size?: OutlineButtonBaseProps["size"];
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  inline?: boolean;
  className?: string;
  iconPosition?: "static" | "relative" | "absolute" | "fixed" | "sticky";
}>;

type OutlineButtonElementProps = MergeElementProps<
  "button",
  {
    href?: undefined;
    disabled?: boolean;
  }
>;

type AnchorElementProps = MergeElementProps<
  "a",
  { href: string; disabled?: undefined }
>;
type ElementProps = OutlineButtonElementProps | AnchorElementProps;
export type OutlineButtonProps = CommonProps & ElementProps;

type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>["ref"];

function OutlineButtonInner(
  props: OutlineButtonProps,
  ref: PolymorphicRef<ElementType>
) {
  const {
    size,
    outlineColor,
    className,
    leftIcon,
    rightIcon,
    inline,
    children,
    disabled,
    iconPosition,
    ...other
  } = props;
  const sizeOrDefault = size ?? "medium";
  const tag = isAnchorElement(other) ? "a" : "button";
  let buttonTextColor: ColorType;
  let elementProps: ElementProps;

  if (!!props.disabled) {
    buttonTextColor = "n40";
  } else {
    buttonTextColor = props.textColor ?? props.outlineColor;
  }
  const outlineColorName = !!props.disabled ? "n40" : props.outlineColor;

  if (isAnchorElement(other)) {
    elementProps = other;
  } else {
    other.type = "button";
    elementProps = other;
  }
  return (
    <StyledOutlineButtonBase
      as={tag}
      size={sizeOrDefault}
      outlineColor={outlineColorName}
      inline={inline}
      className={className}
      disabled={disabled}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      ref={ref}
      {...elementProps}
    >
      <Label
        labelColor={buttonTextColor}
        size={sizeOrDefault}
        isDisabled={disabled}
      >
        {leftIcon && (
          <LeftIcon
            iconPosition={iconPosition}
            size={sizeOrDefault === "large" ? "lg" : "md"}
          >
            {leftIcon}
          </LeftIcon>
        )}
        {children}
        {rightIcon && (
          <RightIcon
            iconPosition={iconPosition}
            size={sizeOrDefault === "large" ? "lg" : "md"}
          >
            {rightIcon}
          </RightIcon>
        )}
      </Label>
    </StyledOutlineButtonBase>
  );
}

/**
 * OutlineButton properties are defined by @see OutlineButtonProps.
 * The `outlineColor` prop will define the colour of the border and
 * button text, by default.
 *
 * The `textColor` is optional but overrides the border colour with
 * a theme colour name.
 *
 * The `leftIcon` and `rightIcon` props are used to indicate if there
 * is an icon. If so and button is disabled, have the tint colour change
 * so the button does not look strange.
 */
export const OutlineButton = forwardRef(OutlineButtonInner);

export type OutlineButtonBaseProps = {
  outlineColor: ColorType;
  size: "large" | "medium";
  inline: boolean | undefined;
  iconPosition?: "static" | "relative" | "absolute" | "fixed" | "sticky";
};

/**
 * Renders the OutlineButtonBase with appropriate styling based
 * on props @see OutlineButtonBaseProps
 */
const StyledOutlineButtonBase = styled(
  OutlineButtonBase
)<OutlineButtonBaseProps>`
  border-radius: 4px;
  border: ${(props) => `1px solid ${color[props.outlineColor]}`};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  min-height: ${(props: OutlineButtonBaseProps) =>
    props.size === "large" ? "48px" : "40px"};
  width: ${(props: OutlineButtonBaseProps) => (props.inline ? "auto" : "100%")};
  padding-left: 24px;
  padding-right: 24px;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? color.n20 : "transparent"};

    // Reset the background-color on touch devices so that they don't get a
    // lingering hover effect after a click event.
    @media (hover: none) {
      background-color: ${(props) =>
        props.disabled ? color.n20 : "transparent"};
    }
  }

  &:active {
    background-color: ${(props) =>
      props.disabled ? color.n20 : "transparent"};
  }

  // We're currently missing focus styles because they're non-trivial to
  // implement correctly, see: https://rome2rio.atlassian.net/browse/EXP-785
`;

type IconProps = {
  iconPosition?: "static" | "relative" | "absolute" | "fixed" | "sticky";
};

const RightIcon = styled(Icon)<IconProps>`
  margin-left: 8px;
  right: 0;
  ${(props) => {
    if (props.iconPosition) return `position: ${props.iconPosition};`;
  }}
`;

const LeftIcon = styled(Icon)<IconProps>`
  margin-right: 8px;
  left: 0;
  ${(props) => {
    if (props.iconPosition) return `position: ${props.iconPosition};`;
  }}
`;

type LabelProps = {
  labelColor: ColorType;
  size: OutlineButtonBaseProps["size"];
  isDisabled?: boolean;
};

const Label = styled.span<LabelProps>`
  align-items: inherit;
  color: ${(props) => color[props.labelColor]};
  display: inherit;
  font-weight: ${fontWeight.medium};
  font-size: ${(props) =>
    props.size === "large" ? fontSize.body : fontSize.h6};
  justify-content: inherit;
  width: 100%; // Ensures correct width on Safari
  position: relative;
`;

function isAnchorElement(
  props: OutlineButtonElementProps | AnchorElementProps
): props is AnchorElementProps {
  return !!props.href;
}
