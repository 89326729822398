import type { GeoKind } from "src/utils/geoKind";
import type { SearchResponse } from "../../../../api/SearchResponse";
import { transitModeFromVehicleKind } from "../../../../utils/adapters/transitMode";
import {
  type TripPointAlternativeNames,
  getAlternativeNameForRouteSegment,
} from "../../../../utils/hooks/useTimelineTripPointAlternativeNames";
import type { Mode } from "../../../../utils/types/mode";

export function destinationCellFromSegment(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  isStandalone: boolean | undefined,
  tripPointAlternativeNames: TripPointAlternativeNames | undefined
): DestinationCell {
  return destinationCellFromOption(
    response,
    routeIndex,
    segmentIndex,
    0,
    isStandalone,
    tripPointAlternativeNames
  );
}

function destinationCellFromOption(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  optionIndex: number,
  isStandalone: boolean = false,
  tripPointAlternatives?: TripPointAlternativeNames
): DestinationCell {
  const route = response.routes[routeIndex];
  const segmentLookupIndex = route.segments[segmentIndex];
  const segment = response.segments[segmentLookupIndex];

  const option = response.options[segment.options[optionIndex]];

  const lastHopIndex = option.hops[option.hops.length - 1];
  const lastHop = response.hops[lastHopIndex];

  const vehicle = response.vehicles[lastHop.vehicle];

  const lastLine = response.lines[lastHop.line];
  const lastPlace =
    response.places[lastLine.places[lastLine.places.length - 1]];

  const isLastSegment = segmentIndex === route.segments.length - 1;

  const transitMode = transitModeFromVehicleKind(vehicle.kind);

  const tripDestinationName = getAlternativeNameForRouteSegment(
    segmentIndex + 1,
    route,
    transitMode,
    tripPointAlternatives
  );

  return {
    type: "destination",
    transitMode,
    placeName: lastPlace.shortName,
    kind: lastPlace.kind,
    id: `destination-${routeIndex}-${segmentIndex}-${optionIndex}`,
    tripDestinationName,
    isLastSegment,
    isStandalone: isStandalone,
  };
}

export type DestinationCell = {
  type: "destination";
  id: string;
  placeName: string;
  kind: GeoKind;
  transitMode: Mode;
  tripDestinationName?: string;
  isLastSegment: boolean;
  isStandalone: boolean;
};
