import { animated } from "@react-spring/web";
import {
  type CSSProperties,
  type ComponentProps,
  type PropsWithChildren,
  useEffect,
  useRef,
} from "react";
import { border_radius } from "src/design-system/tokens/border";
import styled, { type CSSProp, css } from "styled-components";
import { type ColorType, color, spacing } from "../../theme";
import { Modal } from "../Modal/Modal";
import { Fade } from "../Transition/Fade";

type DialogWidthVariant =
  | "default"
  | "fullScreen"
  | "small"
  | "large"
  | "extra-large";

type Props = PropsWithChildren<{
  isOpen: boolean;
  onBackdropClicked: ComponentProps<typeof Modal>["onBackdropClicked"];
  onClickOutside?: EventListener;
  variant?: DialogWidthVariant;
  duration?: number;
  focusRef?: React.RefObject<HTMLElement>;
  backgroundColor?: ColorType;
  overflow?: CSSProperties["overflow"];
  align?: CSSProperties["alignItems"];
  topOffset?: CSSProperties["top"];
  className?: string;
}>;

export function Dialog(props: Props) {
  const {
    isOpen,
    variant = "default",
    duration,
    onBackdropClicked,
    focusRef,
    backgroundColor,
    overflow,
    align,
    topOffset,
    onClickOutside,
  } = props;

  const surfaceRef = useRef<HTMLDivElement>(null);

  // Create a method to close the modal when the user tab ESC
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        onBackdropClicked(event);
      }
    }
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onBackdropClicked, isOpen, focusRef]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        onClickOutside &&
        surfaceRef?.current &&
        !surfaceRef.current.contains(event.target as Node)
      ) {
        onClickOutside(event);
      }
    }
    if (onClickOutside && isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside, isOpen]);

  // Improve A11Y by ensuring the focus is moved to the modal when it opens
  useEffect(() => {
    if (focusRef && isOpen) {
      if (focusRef.current) {
        focusRef.current.focus();
      }
    }
  }, [isOpen, focusRef]);

  return (
    <Modal open={isOpen} onBackdropClicked={props.onBackdropClicked}>
      <Fade open={isOpen} duration={duration}>
        <AnimatedDialogContainer
          data-testid="dialog"
          role="dialog"
          aria-modal="true"
          aria-live="polite"
          align={align}
          tabIndex={-1}
        >
          <DialogSurface
            variant={variant}
            backgroundColor={backgroundColor}
            overflow={overflow}
            topOffset={topOffset}
            className={props.className}
            ref={surfaceRef}
          >
            {props.children}
          </DialogSurface>
        </AnimatedDialogContainer>
      </Fade>
    </Modal>
  );
}

const DialogContainer = styled.div<{
  align?: CSSProperties["alignItems"];
}>`
  height: 100%;
  align-items: ${({ align }) => align ?? "center"};
  justify-content: center;
  display: flex;
`;

const AnimatedDialogContainer = animated(DialogContainer);

const defaultDialogStyles = css`
  // 64px here ensures there is space for the top and bottom margins.
  max-height: calc(100% - 64px);
  max-width: 600px;
  border-radius: ${border_radius.rounded_md};
  margin: 32px;
`;

const smallDialogStyles = css`
  max-width: 480px;
  border-radius: ${border_radius.rounded_md};
  margin: 32px;
`;
const fullScreenDialogStyles = css`
  width: 100%;
  max-width: 100%;
  height: 100%;
`;

const largeStyles = css`
  max-width: 800px;
  width: 100%;
  border-radius: ${border_radius.rounded_md};
  max-height: calc(100% - 64px);
`;

const extraLargeStyles = css`
  max-width: 1000px;
  width: 100%;
  border-radius: ${border_radius.rounded_xl};
  max-height: calc(100% - 64px);
  margin: ${spacing.xl};
`;

const DialogSurface = styled.div<{
  variant: DialogWidthVariant;
  backgroundColor?: ColorType;
  overflow?: CSSProp<"overflow">;
  topOffset?: CSSProperties["top"];
}>`
  display: flex;
  background-color: ${({ backgroundColor }) =>
    color[backgroundColor ?? "white"]};
  position: relative;
  flex-direction: column;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.12);
  overflow: ${({ overflow }) => overflow ?? "auto"};
  top: ${({ topOffset }) => topOffset ?? "unset"};

  ${(props) => {
    if (props.variant === "default") {
      return defaultDialogStyles;
    } else if (props.variant === "fullScreen") {
      return fullScreenDialogStyles;
    } else if (props.variant === "small") {
      return smallDialogStyles;
    } else if (props.variant === "large") {
      return largeStyles;
    } else if (props.variant === "extra-large") {
      return extraLargeStyles;
    }
  }};
`;
