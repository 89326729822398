import styled from "styled-components";
import { useIntl } from "react-intl";
import { TripSearchResult } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResult";
import { getSearchTransportKey } from "src/domain/TripPlanner/util/getSearchTransportKey";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { getCardRouteIndex } from "src/domain/TripPlanner/TripPlannerCard/createTripCardProperties";
import { RadioGroup } from "src/components/RadioGroup/RadioGroup";
import {
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
  Fragment,
  useState,
} from "react";
import { font_size } from "src/design-system/tokens/typography";
import { TripInlineSerpAd } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripInlineSerpAd";
import { useFeature } from "src/feature/useFeature";
import { useScreenSize } from "src/utils/hooks/useScreenSize";
import { ShowMoreButton } from "src/components/TripPlanner/TripCard/SearchResultListCard/ShowMoreButton";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import type { SearchResponse } from "../../../api/SearchResponse";
import { createSearchResultViewModel } from "../createSearchResultViewModel";
import {
  originPlaceFromSearch,
  destinationPlaceFromSearch,
} from "../../../utils/adapters/place";
import {
  desktopLayout,
  largeDesktopLayout,
} from "../../../utils/hooks/useLayout";
import { SearchResult } from "../SearchResult/SearchResult";
import messages from "./SearchResultsContent.messages";

type Props = {
  context: "transport" | "tripPlanner";
  searchResponse: SearchResponse;
  handleTripSave: (routeIndex?: string) => void;
};

const ConditionalSearchResultWrapper = ({
  children,
  wrapper,
}: PropsWithChildren<{ wrapper: (children: ReactNode) => ReactElement }>) => {
  return wrapper(children);
};

export function SearchResultsContent(props: Props) {
  const intl = useIntl();
  const searchResults = createSearchResultViewModel(props.searchResponse);
  const originPlace = originPlaceFromSearch(props.searchResponse);
  const destinationPlace = destinationPlaceFromSearch(props.searchResponse);
  const isSmallScreen = useScreenSize() === "small";
  const initialNumResults = isSmallScreen ? searchResults.length : 4;
  const [numResult, setNumResults] = useState(initialNumResults);
  const isReturnToCore = useFeature("ReturnToCore") && !isSmallScreen;

  const isSingular = searchResults.length === 1;
  const headingMesage = isSingular
    ? messages.headingSingleRoute
    : messages.headingMultipleRoutes;

  const formattedHeading = intl.formatMessage(headingMesage, {
    numberOfRoutes: searchResults.length,
    origin: originPlace.shortName,
    destination: destinationPlace.shortName,
  });

  const SearchResultListing =
    props.context === "tripPlanner" || isReturnToCore
      ? TripSearchResult
      : SearchResult;

  const { tripPlannerDetails } = useTripPlannerContext();
  const transportKey = getSearchTransportKey(props.searchResponse);
  const transport = tripPlannerDetails.transport[transportKey];
  const selectedRouteIndex = transport?.selectedRouteIndex;
  const defaultSelectedRouteIndex =
    tripPlannerDetails.places.length > 2 ? "0" : "";
  const routeIndex = transport
    ? getCardRouteIndex({
        ...transport,
        searchResponse: props.searchResponse,
      })
    : undefined;

  function handleShowMoreClick() {
    setNumResults(searchResults.length);
    sendAnalyticsInteractionEvent("SearchResults", "Click:ShowMoreResults");
  }

  function handleShowLessClick() {
    setNumResults(initialNumResults);
    sendAnalyticsInteractionEvent("SearchResults", "Click:ShowLessResults");
  }

  const dynamicSearchResultsList = isReturnToCore
    ? searchResults.slice(0, numResult)
    : searchResults;

  return (
    <>
      {props.context === "tripPlanner" ? (
        <TripWrapper>
          <TypographyHeading>{formattedHeading}</TypographyHeading>
        </TripWrapper>
      ) : (
        <BaseTypographyHeading>{formattedHeading}</BaseTypographyHeading>
      )}

      <ConditionalSearchResultWrapper
        wrapper={
          props.context === "tripPlanner"
            ? (children) => (
                <TripVariantWrapper
                  name="search-result"
                  value={routeIndex?.toString() ?? defaultSelectedRouteIndex}
                  onChange={props.handleTripSave}
                >
                  {children}
                </TripVariantWrapper>
              )
            : (children) => <>{children}</>
        }
      >
        {dynamicSearchResultsList.map((searchResult, listingIndex) => (
          <Fragment key={searchResult.title}>
            <SearchResultListing
              $size="sm"
              selectedRouteIndex={selectedRouteIndex?.toString()}
              isRadioHidden={props.context === "transport"}
              isSearchScreen={props.context === "transport"}
              {...searchResult}
            />
            {listingIndex === 0 && props.context !== "tripPlanner" && (
              <TripInlineSerpAd />
            )}
          </Fragment>
        ))}
        {initialNumResults < searchResults.length && (
          <ShowMoreButton
            onOpenClick={handleShowMoreClick}
            onCloseClick={handleShowLessClick}
            numResults={numResult}
            searchResultsLength={searchResults.length}
          />
        )}
      </ConditionalSearchResultWrapper>
    </>
  );
}

const TripVariantWrapper = styled(RadioGroup)`
  padding: ${spacing.xs} ${spacing.md};
  ${largeDesktopLayout} {
    padding: 20px;
  }
`;

const BaseTypographyHeading = styled.h1`
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.snug};
  color: ${color.black};
  margin: ${spacing.md} ${spacing.md} ${spacing.xl};
  font-weight: ${fontWeight.medium};
`;

const TypographyHeading = styled.h1`
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.snug};
  color: ${color.black};
  margin: ${spacing.lg} ${spacing.md} 0 ${spacing.lg};
  font-weight: ${fontWeight.medium};

  ${desktopLayout} {
    margin: ${spacing.lg} ${spacing.md} 0 ${spacing.lg};
  }

  ${largeDesktopLayout} {
    font-weight: ${fontWeight.semibold};
    margin: 0;
    padding: ${spacing.md} 20px 20px;
    font-size: ${font_size.text_2xl};
  }
`;

const TripWrapper = styled.div`
  position: relative;
  ${desktopLayout} {
    background-color: ${color.white};
  }
`;
