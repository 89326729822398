import type { SearchResponse } from "src/api/SearchResponse";
import { getRouteIndexFromHash } from "src/utils/location/routeIndexFromHash";
import type { TripPlannerDetails } from "../TripPlannerProvider";
import { getSearchTransportKey } from "./getSearchTransportKey";

export function isRouteSaved(
  tripPlannerDetails: TripPlannerDetails,
  searchResponse: SearchResponse,
  routeIndex: number
) {
  const key = getSearchTransportKey(searchResponse);
  const routeIndexFromSavedTransport = getRouteIndexFromHash(
    tripPlannerDetails.transport[key]?.url.hash,
    searchResponse
  );
  return routeIndexFromSavedTransport === routeIndex;
}
