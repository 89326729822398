import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useScroll } from "src/ScrollContext";
import type { AccomPromoLoggingCategory } from "src/analytics/analyticsEventTypes";
import { Icon } from "src/components/Icon/Icon";
import { border_radius } from "src/design-system/tokens/border";
import { spacing } from "src/design-system/tokens/spacing";
import {
  font_line_height,
  font_size,
  font_weight,
} from "src/design-system/tokens/typography";
import { useExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { useFeature } from "src/feature/useFeature";
import { ArrowForward } from "src/svg/ArrowForward";
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import useSearch from "src/utils/hooks/useSearch";
import styled, { css } from "styled-components";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { sendAnalyticsNonInteractionEvent } from "../../../analytics/sendAnalyticsNonInteractionEvent";
import BookingCom from "../../../svg/logos/BookingCom.svg?react";
import { Expedia } from "../../../svg/logos/Expedia";
import { color } from "../../../theme";

import { useIsInViewport } from "../../../utils/hooks/useIsInViewport";
import type { HotelProviderKind } from "../../../utils/types/accommodationProviders";
import { Timeline } from "../Timeline";
import type { AccommodationPromoCell } from "./AccommodationPromoCell";
import messages from "./TroniconTimelineAccommodationPromo.messages";

type Props = {
  cell: AccommodationPromoCell;
  loggingFrom: AccomPromoLoggingCategory;
  showCardLayout?: boolean;
  className?: string;
  showTransfer?: boolean;
};

export function TroniconTimelineAccommodationPromo({
  cell,
  loggingFrom,
  showTransfer,
  showCardLayout,
  className,
}: Props) {
  const hexIsExpedia = useFeature("ExpediaOnEntry");
  const handleExpediaExit = useExitOnHExEntry("Expedia");

  const intl = useIntl();
  const copyPreposition = cell.useNearbyCopy ? "Near" : "In";
  const calloutMessage = messages[`findHotels${copyPreposition}`];
  const { navigateToHotels } = useNavigateToHotelsPage();
  const { setScrollTop } = useScroll();
  const { origin, destination } = useSearch();
  const { isTablet } = useScreenMinWidth();
  const isTripsScreen = useIsTripScreen();

  const accomCtaMessage = intl.formatMessage(messages.findHotelsCta);

  // We want to see when an accommodation promo gets into view but only fire the log once.
  const accommodationPromoRef = useRef<HTMLDivElement>(null);
  const accommodationPromoInViewport = useIsInViewport(accommodationPromoRef);
  const [hasAccommodationPromoBeenViewed, setHasAccommodationPromoBeenViewed] =
    useState(false);

  function handleClick() {
    const gaLoggingCategory =
      loggingFrom === "Schedule" ? "ScheduleDetails" : loggingFrom;

    sendAnalyticsInteractionEvent(gaLoggingCategory, "Click:HotelPromo");
    setScrollTop(0);

    if (hexIsExpedia && handleExpediaExit) {
      handleExpediaExit({
        lat: destination?.lat,
        lng: destination?.lng,
        canonicalName: destination?.canonicalName,
      });
    } else {
      if (isTripsScreen) {
        navigateToHotels({
          originCanonical: origin?.canonicalName,
          destinationCanonical: destination?.canonicalName,
        });
        sendAnalyticsInteractionEvent("TripPlanner", "Click:HotelPromo");
      } else {
        navigateToHotels();
      }
    }
  }

  useEffect(() => {
    if (accommodationPromoInViewport && !hasAccommodationPromoBeenViewed) {
      sendAnalyticsNonInteractionEvent(
        "Screen",
        "AccomPromoVisible",
        `ap0:${cell.provider}`
      );
      setHasAccommodationPromoBeenViewed(true);
    }
  }, [
    accommodationPromoInViewport,
    cell.id,
    cell.provider,
    hasAccommodationPromoBeenViewed,
  ]);

  const extraLeftPadding = isTablet ? 90 : 110;

  return (
    <AccommodationWrapper
      showCardLayout={showCardLayout}
      extraLeftPadding={
        !showCardLayout && loggingFrom === "Schedule"
          ? extraLeftPadding
          : undefined
      }
      ref={accommodationPromoRef}
      onClick={handleClick}
      className={className}
    >
      <Timeline type="accommodation" transitMode="train" />
      <LeftAligned>
        <PrimaryCallout>
          <span>
            {intl.formatMessage(calloutMessage, { location: cell.placeName })}
          </span>
          <AccomCta>
            <span>{accomCtaMessage}</span>
            <Icon size="sm">
              <ArrowForward tint="pink" title="open-hotels-arrow" />
            </Icon>
          </AccomCta>
        </PrimaryCallout>
      </LeftAligned>
    </AccommodationWrapper>
  );
}

export function ProviderLogo(props: { provider?: HotelProviderKind }) {
  // It's rare that provider will be null but
  // we fall back to bookingCom because that's what the backend does.
  return props.provider === "Expedia" ? (
    <ExpediaWrapper>
      <Expedia />
    </ExpediaWrapper>
  ) : (
    <BookingComWrapper>
      <BookingCom />
    </BookingComWrapper>
  );
}

const AccommodationWrapper = styled.div<{
  showCardLayout?: boolean;
  extraLeftPadding?: number;
}>`
  display: flex;
  padding: ${spacing.lg} ${spacing.xl};
  gap: ${spacing.xl};
  cursor: pointer;

  ${(props) => {
    if (props.showCardLayout) {
      return `
          border: 1px solid ${color.grey2};
          border-radius: ${border_radius.rounded_md};
          `;
    }
  }}

  ${({ extraLeftPadding }) =>
    extraLeftPadding &&
    css`
      padding-left: ${extraLeftPadding}px;
    `}

  &:active {
    background-color: ${color.grey1};
  }
`;

const BookingComWrapper = styled.div`
  display: inline;
  svg {
    height: 16px;
    margin-bottom: -4px;
    margin-left: -11px;
  }
`;

const ExpediaWrapper = styled.div`
  display: inline;
  svg {
    height: 16px;
    margin-bottom: -3px;
  }
`;

const LeftAligned = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: ${font_line_height.leading_tight};
`;

const PrimaryCallout = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: ${font_size.text_base};
  font-weight: ${font_weight.bold};
  column-gap: ${spacing.md};
`;

const AccomCta = styled.div`
  display: flex;
  align-items: center;
  color: ${color.pink};
  gap: ${spacing.md};
`;
