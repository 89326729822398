import type { Place, SearchResponse } from "../api/SearchResponse";
import { routeIsFlight } from "../components/CsaAd/CsaAd";

export function airportsFromFlightRoute(
  searchResponse: SearchResponse,
  routeIndex: number
) {
  if (!routeIsFlight(searchResponse.routes[routeIndex].canonicalName))
    return [];

  const airports: (Place | undefined)[] = [];

  searchResponse.routes[routeIndex].places.forEach((place) => {
    if (searchResponse.places[place].kind === "airport") {
      airports.push(searchResponse.places[place]);
    }
  });

  return airports;
}
