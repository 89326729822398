import { Fragment, useRef, useState } from "react";
import type { SearchResponse } from "src/api/SearchResponse";
import { createSearchResultViewModel } from "src/domain/SearchResultsScreen/createSearchResultViewModel";
import { searchResultScreenComponentList } from "src/analytics/generateScreenComponentsRendered/searchResultScreenComponentList";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { border_radius } from "src/design-system/tokens/border";
import { TripInlineSerpAd } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripInlineSerpAd";
import { TripSearchResult } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResult";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { color, fontSize, spacing } from "src/theme";
import { SearchOverrideProvider } from "src/utils/hooks/SearchOverrideProvider";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import type { Mode } from "src/utils/types/mode";
import styled, { css } from "styled-components";
import {
  SearchTripCardContainer,
  SearchTripCardDetails,
} from "../SearchTripCard/SearchTripCard";
import { type TripCardTypeProps, TripCard } from "../TripCard";
import { ShowMoreButton } from "./ShowMoreButton";

export function SearchResultListCard(
  props: TripCardTypeProps & {
    searchResponse: SearchResponse;
    index?: number;
    isDragging?: boolean;
    isPendingDragChanges?: boolean;
  }
) {
  const location = useTypedLocation();
  const { tripInteraction, isMultiTrip } = useTripPlannerContext();
  const searchResults = createSearchResultViewModel(props.searchResponse);
  let pageViewTrackingSent = useRef(false);
  const sendExpandedPageView = !pageViewTrackingSent.current;
  const initialNumResults = 4;
  const [numResults, setNumResults] = useState(initialNumResults);
  const paginatedSearchResults = searchResults.slice(
    0,
    !props.isDragging ? numResults : initialNumResults
  );

  const tripLegIndex = props.index;

  function handleShowMoreClick() {
    setNumResults(searchResults.length);
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:ShowMoreResults",
      props.gaLabel
    );
    tripInteraction.setIsInteractionMade(true);
  }

  function handleShowLessClick() {
    setNumResults(4);
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:ShowLessResults",
      props.gaLabel
    );
    tripInteraction.setIsInteractionMade(true);
  }

  function ExpandedAnalyticsEvent() {
    const components = searchResultScreenComponentList(props.searchResponse);

    useAnalyticsPageView(
      {
        pagePath: location.pathname + location.search + location.hash,
        pageLocation: window.location.href,
      },
      props.searchResponse,
      "SearchResults",
      undefined,
      components
    );
    pageViewTrackingSent.current = true;

    return <></>;
  }

  function getModeSummary(): Mode[] {
    // Return flattened array of major modes from all results
    const majorModeSummaryOfResults = searchResults.flatMap((result) =>
      result.segments.filter((it) => it.isMajor).map((it) => it.transitMode)
    );

    // Remove any duplicates from the flattened array and returns max 3
    const modeSummaryNoDuplicates = majorModeSummaryOfResults
      .filter((item, index, self) => self.indexOf(item) === index)
      .slice(0, 3);

    return modeSummaryNoDuplicates;
  }

  return (
    <SearchOverrideProvider
      value={{
        searchResponse: props.searchResponse,
      }}
    >
      <TripCard
        {...props}
        ContainerOverride={({ children, onClick }) => {
          if (isMultiTrip) {
            return (
              <TripCardContainer
                data-testid={`search-card-accordion-${tripLegIndex}`}
                onClick={onClick}
              >
                {children}
              </TripCardContainer>
            );
          }
        }}
        TimelineDetailsContent={
          <SearchTripCardDetails {...props} modeSummary={getModeSummary()} />
        }
      />
      <TripSearchResultsContainer $hasBackground={isMultiTrip}>
        <>
          {sendExpandedPageView && <ExpandedAnalyticsEvent />}
          {paginatedSearchResults.map((searchResult, index) => (
            <Fragment key={searchResult.title + index}>
              <TripSearchResult {...searchResult} $size="sm" isRadioHidden />
              {tripLegIndex === 0 && index === 0 && <TripInlineSerpAd />}
            </Fragment>
          ))}
          {initialNumResults < searchResults.length && (
            <ShowMoreButton
              onOpenClick={handleShowMoreClick}
              onCloseClick={handleShowLessClick}
              numResults={numResults}
              searchResultsLength={searchResults.length}
              index={props.index}
            />
          )}
        </>
      </TripSearchResultsContainer>
    </SearchOverrideProvider>
  );
}

const TripCardContainer = styled.a`
  display: block;
  text-decoration: none;
  color: ${color.cod};
  font-size: ${fontSize.h6};
  border-radius: ${border_radius.rounded_md};
  border: 1px solid transparent;
  padding: ${spacing.md};
  background-color: ${color.n10};

  &:hover {
    cursor: pointer;

    ${SearchTripCardContainer} {
      background-color: ${color.n30};

      span {
        text-decoration: none;
      }
    }
  }
`;

export const TripSearchResultsContainer = styled.div<{
  $isDragging?: boolean;
  $hasBackground?: boolean;
}>`
  ${({ $hasBackground }) =>
    $hasBackground &&
    css`
      background-color: ${color.n10};
      padding: ${spacing.md};
      padding-top: 0;
      border-bottom-right-radius: ${border_radius.rounded_md};
      border-bottom-left-radius: ${border_radius.rounded_md};
      border-color: transparent;
    `}
`;
