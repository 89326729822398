import type { GeocodedPlace } from "src/PrefetchData";
import type { TripPlannerDetails } from "../TripPlannerProvider";

export function getFirstTripPlannerPlace(
  tripPlannerDetails: TripPlannerDetails
) {
  return tripPlannerDetails.places[0];
}

export function getLastTripPlannerPlace(
  tripPlannerDetails: TripPlannerDetails
) {
  return tripPlannerDetails.places[tripPlannerDetails.places.length - 1] as
    | GeocodedPlace
    | undefined;
}
