import type { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import type {
  SearchResponse,
  Vehicle,
  Option,
  Segment,
} from "../../api/SearchResponse";

export const vehicleFromHop = (
  response: SearchResponse,
  hopIndex: number
): Vehicle => {
  const hop = response.hops[hopIndex];
  const vehicleIndex = hop.vehicle;
  return response.vehicles[vehicleIndex];
};

export const vehicleFromOption = (
  response: SearchResponse | PartialSearchResponse,
  option: Option
): Vehicle => {
  const hopIndex = option.hops[0];
  const hop = response.hops[hopIndex];
  return response.vehicles[hop.vehicle];
};

export const vehicleFromSegment = (
  response: SearchResponse | PartialSearchResponse,
  segment: Segment
): Vehicle => {
  const optionIndex = segment.options[0];
  const option = response.options[optionIndex];
  return vehicleFromOption(response, option);
};
