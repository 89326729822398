import { Suspense, lazy } from "react";
import { TripSearchBarSkeleton } from "./TripSearchBarSkeleton";

const ImportBar = lazy(() => import("./TripSearchBar"));

export function LazyTripSearchBar() {
  return (
    <Suspense fallback={<TripSearchBarSkeleton />}>
      <ImportBar />
    </Suspense>
  );
}
