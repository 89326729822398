import { type Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { isLoggedIn } from "src/auth/utils/session";
import type { Action, TripPlannerDetails } from "../TripPlannerProvider";

export default function useAccountTrip(
  dispatch: Dispatch<Action>,
  tripPlannerDetails: TripPlannerDetails,
  userId?: string
) {
  // This is to prevent unnecessary requests.
  // If the user was never logged out, we don't need to make any requests when they log in.
  const wasLoggedOut = useRef(!isLoggedIn());
  const [syncedUserId, setSyncedUserId] = useState<string | null>(null);

  const syncTripPlanWithAccount = useCallback(async () => {
    if (!userId || !wasLoggedOut.current) {
      return;
    }
    dispatch({ type: "PASSIVE_UPDATE", trip: tripPlannerDetails });
    setSyncedUserId(userId);
  }, [dispatch, tripPlannerDetails, userId]);

  useEffect(() => {
    if (
      userId &&
      userId !== syncedUserId &&
      tripPlannerDetails.places.length > 0
    ) {
      syncTripPlanWithAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, syncedUserId, tripPlannerDetails.places]);

  useEffect(() => {
    if (!userId) {
      wasLoggedOut.current = true;
      setSyncedUserId(null);
    }
  }, [userId]);
}
