import type { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getScreenKey } from "./getScreenKey";

export function getOriginDestinationFromHash(
  hash: ReturnType<typeof useTypedLocation>["hash"]
) {
  const screenKey = getScreenKey(hash);
  const transportPart = hash.split(`/${screenKey}/`)[1];
  const parts = transportPart?.split("/");

  if (!parts) {
    return undefined;
  }

  const origin = parts[0] ? decodeURI(parts[0]) : undefined;
  const destination = parts[1] ? decodeURI(parts[1]) : undefined;

  return { origin, destination };
}
