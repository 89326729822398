import styled, { ThemeProvider } from "styled-components";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { font_size, font_weight } from "src/design-system/tokens/typography";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import { FormattedDuration } from "../../../components/FormattedDuration/FormattedDuration";
import {
  type FormattedPriceRangeProps,
  FormattedPriceRange,
} from "../../../components/FormattedPriceRange/FormattedPriceRange";
import { screenMinWidth } from "../../../theme";
import { useTheme } from "../../../utils/hooks/useTheme";
import { PaneNav } from "../../PaneNav/PaneNav";

type RouteHeaderProps = {
  title: string;
  durationInMinutes: number;
  priceRange?: FormattedPriceRangeProps;
  className?: string;
  routeCanonical: string;
  onBackClick: () => void;
  hidePaneNav?: boolean;
};

export function RouteHeader(props: RouteHeaderProps) {
  const { isSmScreen } = useScreenMinWidth();
  const showPaneNav = isSmScreen && !props.hidePaneNav;

  // The RouteHeader has a light background when using the light theme, we need
  // to overwrite the PaneNav breadcrumbs to be the same as the route header.
  const theme = useTheme();
  theme.breadcrumbs = theme.routeHeader;

  return (
    <Header className={props.className}>
      {showPaneNav && (
        <ThemeProvider theme={theme.routeHeader}>
          <PaneNav
            routeCanonical={props.routeCanonical}
            showRouteBreadcrumb={true}
            onBackClick={props.onBackClick}
          />
        </ThemeProvider>
      )}
      <Title>{props.title}</Title>
      <Information>
        <FormattedDuration totalMinutes={props.durationInMinutes} />
        {props.priceRange ? <InfoDot /> : null}
        {props.priceRange && <FormattedPriceRange {...props.priceRange} />}
      </Information>
    </Header>
  );
}

const Header = styled.div`
  padding: ${spacing.xl};
  min-height: 79px; /** So we have a rounded number for the loading component */
  ${screenMinWidth.md} {
    padding-top: 0;
  }
`;

const Title = styled.h4`
  font-weight: ${font_weight.medium};
  color: ${color.text.text};

  ${screenMinWidth.sm} {
    font-size: ${font_size.text_xl};
  }
`;

const Information = styled.div`
  display: flex;
  gap: ${spacing.md};
  align-items: center;
  padding-top: ${spacing.md};
  color: ${color.text.primary.primary};
  font-size: ${font_size.text_base};
`;

const InfoDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${color.text.primary.primary};
`;
