import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useSearchParamsFeatures } from "../feature/internal/searchParams/useSearchParamsFeatures";
import { useBackendExperiments } from "../utils/hooks/useBackendExperiments";
import { useBackendCookieFeatures } from "../utils/hooks/useBackendFeatures";
import { useBackendFlags } from "../utils/hooks/useBackendFlags";
import { getAqidCookie, getUidCookie } from "../utils/uid";
import { urlConfig } from "../utils/urlConfig";
import type { ApiConfig } from "./ApiConfig";

const key = "jGq3Luw3";

const ApiConfigContext = createContext<ApiConfig>({
  key: key,
  endpoint15Base: `${getApiHost()}/api/1.5/json`,
  endpoint16Base: `${getApiHost()}/api/1.6`,
  endpointNonVersioned: `${getApiHost()}/api/`,
});

export function ApiConfigProvider(props: PropsWithChildren<{}>) {
  // We only need to use the cookieFeatures here, because anything in `backendFeatures`
  // will have already been put into the cookie.
  const cookieFeatures = useBackendCookieFeatures();
  const { Debug: debug } = useSearchParamsFeatures();
  const backendExperiments = useBackendExperiments();
  const { backendFlags } = useBackendFlags();
  const uid = getUidCookie();
  const aqid = getAqidCookie();
  const host = getApiHost();

  const api = `${host}/api/`;
  const api15 = `${host}/api/1.5/json`;
  const api16 = `${host}/api/1.6`;

  const value = useMemo(() => {
    // If the frontend `debug` feature is enabled, we want to enable the "ShowDebug" backend feature.
    // We want to do that here, instead of in the cookie, because we don't want it to persist.
    const extraFeatures: string[] =
      debug && !cookieFeatures.includes("ShowDebug") ? ["ShowDebug"] : [];

    // debugFeatures are applied left to right (rome2rio-core::FeatureSettings::FromString)
    // extraFeatures should take precedent over cookieFeatures
    const backendFeatures = [...cookieFeatures, ...extraFeatures].join(",");
    return {
      endpoint15Base: api15,
      endpoint16Base: api16,
      endpointNonVersioned: api,
      key,
      uid,
      aqid,
      backendFeatures,
      backendExperiments,
      backendFlags,
    };
  }, [
    api15,
    api16,
    api,
    uid,
    aqid,
    cookieFeatures,
    debug,
    backendExperiments,
    backendFlags,
  ]);

  return (
    <ApiConfigContext.Provider value={value}>
      {props.children}
    </ApiConfigContext.Provider>
  );
}

export function useApiConfig(): ApiConfig {
  return useContext<ApiConfig>(ApiConfigContext);
}

export function getApiHost() {
  if (import.meta.env.VITE_ENVIRONMENT === "Production") {
    // Production always uses the apiHost and doesn't use the debugHost because
    // of potential security concerns.
    return urlConfig.apiHost;
  }

  const debugHost = new URLSearchParams(window.location.search).get(
    "debugHost"
  );
  return debugHost ?? urlConfig.apiHost;
}
