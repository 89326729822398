import { useEffect, useState } from "react";
import type { GeocodedPlace } from "src/PrefetchData";
import type { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { useTripGeocode } from "./hooks/useTripGeocode";
import useUser from "./hooks/useUser";

export type PlaceName = {
  canonicalName: GeocodedPlace["canonicalName"];
  shortName: GeocodedPlace["shortName"];
  longName?: GeocodedPlace["longName"];
};

type Props = {
  origin?: PlaceName;
  destination?: PlaceName;
  setOrigin: (origin: PlaceName) => void;
  setDestination: (destination: PlaceName) => void;
  placesArray?: PlaceName[];
};

export function useCreateTripFromSearch({
  origin,
  destination,
  setOrigin,
  setDestination,
  placesArray,
}: Props) {
  const { dispatch } = useTripPlannerContext();
  const { user } = useUser();

  // We are setting the initial state of the places to the origin and destination
  const [places, setPlaces] = useState<PlaceName[]>(() => {
    const initialState: PlaceName[] = [];
    if (placesArray) {
      initialState.push(...placesArray);
    }
    if (origin) {
      initialState.push(origin);
    }
    if (destination) {
      initialState.push(destination);
    }
    return initialState;
  });

  const geocodedPlaces = useTripGeocode(
    places.map((place) => place.canonicalName)
  );

  function addOrChangePlaceInPosition(place: PlaceName, position: number) {
    // We need to sync origin and destination with the parent component
    if (position === 0) {
      setOrigin(place);
    }
    if (position === 1) {
      setDestination(place);
    }
    setPlaces((places) => {
      const newPlaces = [...places];
      newPlaces[position] = place;
      return newPlaces;
    });
  }

  function removePlace(places: SortableObject<GeocodedPlace>[], key: string) {
    const filteredDestinations = places.filter((dest) => dest.id !== key);
    setPlaces(filteredDestinations);
  }

  function createTrip() {
    // If the user is logged in under multiple trips, we want to create a new trip.
    // If the user is not logged in, we want to replace the trip.
    const action = user?.id
      ? "CREATE_TRIP_FROM_SEARCH_TRIP"
      : "REPLACE_TRIP_FROM_SEARCH_TRIP";
    dispatch({ type: action, places: geocodedPlaces });
  }

  function setNewOrder(newOrder: SortableObject<{ index: number }>[]) {
    setOrigin(places[newOrder[0].index]);
    setDestination(places[newOrder[1].index]);
    setPlaces((prevPlaces: PlaceName[]) => {
      const newPlaces: PlaceName[] = [];
      newOrder.forEach((item, index) => {
        newPlaces[index] = prevPlaces[item.index];
      });
      return newPlaces;
    });
  }

  function overridePlaces(places: PlaceName[]) {
    setPlaces(places);
    if (places.length >= 1) {
      setOrigin(places[0]);
    }
    if (places.length >= 2) {
      setDestination(places[1]);
    }
  }

  // If origin or destination change, we need to update the places
  useEffect(() => {
    if (origin) {
      setPlaces((places) => {
        const newPlaces = [...places];
        newPlaces[0] = origin;
        return newPlaces;
      });
    }
  }, [origin]);

  useEffect(() => {
    if (destination) {
      setPlaces((places) => {
        const newPlaces = [...places];
        newPlaces[1] = destination;
        return newPlaces;
      });
    }
  }, [destination]);

  return {
    places,
    addOrChangePlaceInPosition,
    createTrip,
    setNewOrder,
    removePlace,
    overridePlaces,
  };
}
