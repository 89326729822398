import { Typography } from "src/design-system/components/Typography/Typography";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { Icon } from "src/design-system/components/Icon/Icon";
import { Plus } from "src/svg/tripplanner/Plus";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { hashChange } from "src/utils/location/hashChange";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { A11yOutline, A11yTargetSize } from "src/utils/accessibility";
import color from "src/design-system/tokens/color";
import { border_radius } from "src/design-system/tokens/border";
import { font_weight } from "src/design-system/tokens/typography";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import useSearch from "src/utils/hooks/useSearch";
import useUser from "src/utils/hooks/useUser";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { TripList } from "../MyTrips/TripList";
import messages from "./SavedTripsScreen.messages";

export function SavedTripsScreen() {
  const intl = useIntl();
  const location = useTypedLocation();
  const [numVisibleTrips, setNumVisibleTrips] = useState(5);
  const { user } = useUser();
  const isUserLoggedIn = !!user?.id;
  const navigate = useNavigate();

  const { searchResponse } = useSearch();
  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    searchResponse,
    "SavedTrips",
    undefined
  );

  useEffect(() => {
    if (!isUserLoggedIn) {
      navigateToNewStateHash(
        navigate,
        { highlightedTab: "trips" },
        "#trips/create",
        {
          ...location,
        }
      );
    }
  }, [isUserLoggedIn, location, navigate]);

  return (
    <Content>
      <HeadingWrapper>
        <Typography variant="heading-xl">
          {intl.formatMessage(messages.savedTrips)}
        </Typography>
        <StyledLink
          to={hashChange("#trips/create/", location)}
          onClick={() => {
            sendAnalyticsInteractionEvent("SavedTrips", "Click:NewTrip");
          }}
        >
          <StyledIcon size="small">
            <Plus tint="cod" />
          </StyledIcon>{" "}
          <Typography variant="label-md">
            {intl.formatMessage(messages.newButton)}
          </Typography>
        </StyledLink>
      </HeadingWrapper>
      <TripList
        numVisibleTrips={numVisibleTrips}
        setNumVisibleTrips={setNumVisibleTrips}
        onSetTrip={() => {
          sendAnalyticsInteractionEvent("SavedTrips", "Click:SelectTrip");
        }}
        onDeleteTrip={() => {
          sendAnalyticsInteractionEvent("SavedTrips", "Click:DeleteTrip");
        }}
        isFullScreen
      />
    </Content>
  );
}

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.xl};
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  ${A11yTargetSize};
  ${A11yOutline};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: ${color.bg.fill.fill};
  text-decoration: none;
  color: ${color.text.primary};
  border: 1px solid ${color.border.border};
  border-radius: ${border_radius.rounded_md};
  padding: ${spacing.md} ${spacing.lg};
  font-weight: ${font_weight.medium};

  &:hover,
  &:focus {
    background-color: ${color.bg.fill.hover};
  }
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  padding: ${spacing.xxl} ${spacing.xl} ${spacing.xl};
  // This is the height minus nav, fixed button and safari menu
  min-height: calc(100vh - 55px - 44px - env(safe-area-inset-bottom));
  background-color: ${color.bg.surface.secondary};
`;
