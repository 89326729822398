import { type ForwardedRef, type ReactNode, forwardRef } from "react";
import styled from "styled-components";
import { useHotelsContext } from "../../domain/HotelsScreen/HotelsContext";
import { color } from "../../theme";
import { desktopLayout, mobileLayout } from "../../utils/hooks/useLayout";

type Props = {
  leftPane: ReactNode;
  background?: ReactNode;
  rightRail?: ReactNode;
};

export function LayoutInner(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const { showMap: showMapMobile } = useHotelsContext();

  return (
    <LayoutContainer ref={ref} id="layout-container">
      {props.leftPane}
      {props.background && (
        <MapContainer $showMapMobile={showMapMobile}>
          {props.background}
        </MapContainer>
      )}
      {props.rightRail}
    </LayoutContainer>
  );
}

export const Layout = forwardRef(LayoutInner);

const LayoutContainer = styled.div`
  ${desktopLayout} {
    flex: 1;
    min-height: 0; // Allows the container to be smaller than the Pane's content.
    overflow: hidden auto;

    display: flex;
    flex-direction: row;
  }

  background-color: ${color.greyMap};
`;

/**
 * On desktop - the map is rendered beside the pane.
 * On mobile - the map is rendered in front of the pane, but is invisible until it is needed.
 */
const MapContainer = styled.div<{ $showMapMobile: Boolean }>`
  ${mobileLayout} {
    position: fixed;
    inset: 0;

    // Note: Eventually we should use display: none, but none of our map & conditional render code is set up to deal well with that.
    ${({ $showMapMobile }) => !$showMapMobile && "left: 100%;"}
  }
  ${desktopLayout} {
    display: flex;
    flex: 1;
    position: sticky;
    top: 0;
    height: 100%;
  }
  overflow: hidden;
`;
