import type { FeatureConfig } from "../../FeatureConfig";
import {
  type BackendFeatureMap,
  backendFeatureMapping,
} from "./backendFeatureMapping";

export function useBackendFeatureMapping(): Partial<FeatureConfig> {
  const backendFeatures = window.featureConfig;
  return mapBackendFeatures(backendFeatures ?? [], backendFeatureMapping);
}

export function mapBackendFeatures(
  backendFeatures: string[],
  backendFeatureMapping: Partial<BackendFeatureMap>
): Partial<FeatureConfig> {
  let partialConfig: Partial<FeatureConfig> = {};

  for (const [backendFeature, partialFrontendConfig] of Object.entries(
    backendFeatureMapping
  )) {
    if (backendFeatures?.includes(backendFeature)) {
      partialConfig = { ...partialConfig, ...partialFrontendConfig };
    }
  }

  return partialConfig;
}
