import { darken, lighten } from "polished";
import type { PropsWithChildren } from "react";
import { ThemeProvider } from "styled-components";
import { useFeature } from "../../feature/useFeature";
import { color } from "../../theme";

export type ThemeMap = typeof darkTheme;

const darkTheme = {
  body: {
    text: color.primaryOnLight,
    background: color.n300,
  },
  topNav: {
    background: color.cod,
    text: color.primaryOnDark,
    textHover: darken(0.25, color.primaryOnDark),
    iconTint: "primaryOnDark",
    borderActive: "white",
  },
  userTopNav: {
    background: color.white,
    text: color.n300,
    hoverBackground: lighten(0.75, color.n300),
    iconTint: "n300",
  },
  searchBar: {
    background: color.n300,
    iconTint: "white",
    input: {
      background: color.n200,
      backgroundActive: color.grey4,
      text: color.primaryOnDark,
      border: "transparent",
      borderFocus: color.n50,
      iconTint: "white",
      placeholder: color.n50,
      hint: color.n50,
    },
    autocompleteList: {
      background: color.n200,
      backgroundHover: color.n100,
      text: color.white,
      iconTint: "white",
    },
    switchButton: {
      background: color.n200,
      backgroundHover: lighten(0.1, color.n200),
      border: color.n300,
      iconTint: "white",
    },
  },
  navbar: {
    background: color.grey6,
    iconTint: "white",
    text: color.primaryOnDark,
    iconHoverBackground: color.iconOnDark,
  },
  routeHeader: {
    background: color.grey6,
    iconTint: "white",
    text: color.primaryOnDark,
    infoText: color.secondaryOnDark,
  },
  tripHeader: {
    background: color.grey6,
    iconTint: "white",
    text: color.primaryOnDark,
    infoText: color.secondaryOnDark,
  },
  segmentHeader: {
    iconTint: "white",
    text: color.primaryOnDark,
  },
  segmentHeaderLoading: {
    iconTint: "white",
    text: color.primaryOnDark,
  },
  hotelHeader: {
    iconTint: "white",
    text: color.primaryOnDark,
  },
  breadcrumbs: {
    iconTint: "white",
    text: color.primaryOnDark,
  },
  bottomNavbar: {
    background: color.grey6,
    iconTint: "primaryOnDark",
    text: color.primaryOnDark,
    border: color.transparent,
    borderActive: color.pink,
  },
  dropdown: {
    background: color.white,
    backgroundHover: color.n20,
    text: color.n300,
    iconTint: "cod",
  },
  tripPlanActions: {
    iconHover: color.white,
    iconDisabled: color.n50,
    icon: color.n50,
  },
};

const lightTheme: ThemeMap = {
  body: {
    text: color.cod,
    background: color.n10,
  },
  topNav: {
    background: color.white,
    text: color.cod,
    textHover: color.n60,
    iconTint: "cod",
    borderActive: color.cod,
  },
  userTopNav: {
    background: color.n300,
    text: color.primaryOnDark,
    hoverBackground: darken(0.75, color.primaryOnDark),
    iconTint: "white",
  },
  searchBar: {
    background: color.n10,
    iconTint: "cod",
    input: {
      background: color.white,
      backgroundActive: color.n30,
      text: color.n300,
      border: color.n30,
      borderFocus: color.n50,
      iconTint: "n60",
      placeholder: color.n60,
      hint: color.n60,
    },
    autocompleteList: {
      background: color.white,
      backgroundHover: color.n20,
      text: color.n300,
      iconTint: "cod",
    },
    switchButton: {
      background: color.white,
      backgroundHover: color.n20,
      border: color.n30,
      iconTint: "cod",
    },
  },
  navbar: {
    background: color.n10,
    iconTint: "cod",
    iconHoverBackground: color.n30,
    text: color.cod,
  },
  routeHeader: {
    background: color.n20,
    iconTint: "cod",
    text: color.cod,
    infoText: color.secondaryOnLight,
  },
  tripHeader: {
    background: color.n20,
    iconTint: "cod",
    text: color.cod,
    infoText: color.secondaryOnLight,
  },
  segmentHeader: {
    iconTint: "white",
    text: color.primaryOnDark,
  },
  segmentHeaderLoading: {
    iconTint: "cod",
    text: color.primaryOnLight,
  },
  hotelHeader: {
    iconTint: "cod",
    text: color.primaryOnLight,
  },
  breadcrumbs: {
    iconTint: "white",
    text: color.primaryOnDark,
  },
  bottomNavbar: {
    background: color.white,
    iconTint: "cod",
    text: color.cod,
    border: color.transparent,
    borderActive: color.pink,
  },
  dropdown: {
    background: color.white,
    backgroundHover: color.n20,
    text: color.n300,
    iconTint: "cod",
  },
  tripPlanActions: {
    iconHover: color.n90,
    iconDisabled: color.n50,
    icon: color.cod,
  },
};

export function Theme({ children }: PropsWithChildren<{}>) {
  const theme = useFeature("UseDarkMode") ? darkTheme : lightTheme;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
