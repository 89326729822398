import { getRoutesBetween } from "src/domain/HotelsScreen/TravelOptions/utils";
import { useGetRoutesFromCoordsSuspense } from "./useGetRoutesFromCoords";

type Props = {
  from: google.maps.LatLngLiteral;
  to: google.maps.LatLngLiteral;
  count?: number;
};

// Is suspenseful
export function useRoutesBetween({ from, to, count = 3 }: Props) {
  const { data: searchResponse } = useGetRoutesFromCoordsSuspense(from, to);
  return getRoutesBetween({ searchResponse, count });
}
