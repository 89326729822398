import type { TripAttributes, TripResponse } from "./api";

export function tripResponseToTripAttributes(
  tripResponse: TripResponse
): TripAttributes {
  return {
    id: tripResponse.tripId,
    slug: tripResponse.slug,
    updatedOn: tripResponse.updatedOn,
  };
}
