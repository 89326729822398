import { defineMessages } from "react-intl";

export const messages = defineMessages({
  showMoreResults: {
    id: "tripPlanner.tripCard.showMoreResults",
    description: "Button label for showing more search results",
    defaultMessage: "Show {count} more options",
  },
  showLessResults: {
    id: "tripPlanner.tripCard.showLessResults",
    description: "Button label for showing less search results",
    defaultMessage: "Show less options",
  },
  waysToTravel: {
    id: "tripPlanner.tripCard.waysToTravel",
    description: "Title for the CTA under the Search trip card",
    defaultMessage: "{ways} ways to travel to {destination}",
  },
});
