import type { TripAttributes } from "../util/api";
import { useTripPlanQuery } from "../hooks/useTripPlanQueries";
import { tripPlanAdapter } from "../util/tripPlanAdapter";
import { MyTripCardContent, MyTripCardSkeleton } from "./MyTripCardContent";

type Props = {
  attributes: TripAttributes;
  onSetTrip: (tripId: string) => void;
  onDeleteTrip: (tripId: string) => void;
  isLoggedOut?: boolean;
};

export default function MyTripCard({
  attributes,
  onSetTrip,
  onDeleteTrip,
  isLoggedOut,
}: Props) {
  const query = useTripPlanQuery(() => {}, true, attributes.id);

  if (query.isLoading) {
    return <MyTripCardSkeleton />;
  }

  return (
    query.data && (
      <MyTripCardContent
        {...tripPlanAdapter(query.data)}
        onClick={() => onSetTrip(attributes.id)}
        onClickDelete={() => onDeleteTrip(attributes.id)}
        isLoggedOut={isLoggedOut}
      />
    )
  );
}
