import { useTypedLocation } from "./useTypedLocation";

/**
 * @deprecated
 * This hook is used to determine if the current screen is the TripPlanner screen.
 * Our components should be blind to this
 */
export const useIsTripScreen = () => {
  const location = useTypedLocation();
  const parts = location.hash.slice(1).split("/");
  return parts.includes("trips");
};
