import useDeepCompareEffect from "use-deep-compare-effect";
import { type FeatureConfig, defaultFeatureConfig } from "./FeatureConfig";
import { useFeatureConfig } from "./useFeatureConfig";

// This is an escape hatch for the rare situations where you can't use the
// useFeature or useFeatureConfig hooks directly. There are significant
// downsides to using this escape hatch, for example:
//
// * It won't trigger re-renders of any components when the value changes.
// * It is mutable - anyone could change the value which could cause it to
// become out-of-sync
//
// So, using this escape hatch is almost certainly a mistake or an error, avoid
// at all costs!
export let UNSAFE_featureConfigEscapeHatch: FeatureConfig =
  defaultFeatureConfig;
export function UNSAFE_useConfigureFeatureEscapeHatch() {
  const featureConfig = useFeatureConfig();
  useDeepCompareEffect(() => {
    UNSAFE_featureConfigEscapeHatch = featureConfig;
  }, [featureConfig]);
}
