import { useState } from "react";
import { useIntl } from "react-intl";
import {
  type QueryClient,
  useQueries,
  useQueryClient,
} from "@tanstack/react-query";
import type { GeocodedPlace } from "src/PrefetchData";
import useDeepCompareEffect from "use-deep-compare-effect";
import type { ApiConfig } from "../../api/ApiConfig";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { localeToLanguageCode } from "../conversions/languageCode";
import { ONE_DAY_IN_MILLISECONDS } from "../conversions/time";
import type { SupportedLanguageCode } from "../language";
import { geocodeCanonical, getCacheKeyForGeocode } from "./useGeocode";
import { useIsTripScreen } from "./useIsTripScreen";

export function useTripGeocode(canonicalNames: string[], isEnabled?: boolean) {
  const [geocodedPlaces, setGeocodedPlaces] = useState<GeocodedPlace[]>([]);
  const intl = useIntl();
  const config = useApiConfig();
  const languageCode = localeToLanguageCode(intl.locale);
  const queryClient = useQueryClient();
  const isTripScreen = useIsTripScreen();

  const shouldInvalidateCache =
    (canonicalNames.length > 2 && !isTripScreen) || isTripScreen;

  const uniqueCanonicals = Array.from(new Set(canonicalNames));
  const geocodeResultsInfo = useQueries<GeocodedPlace[]>({
    queries: combinedGeocodeQueries({
      config,
      canonicalNames: uniqueCanonicals,
      languageCode,
      queryClient,
    }),
  });
  const geocodeReadableData = Object.fromEntries(
    geocodeResultsInfo.map((result, index) => [
      uniqueCanonicals[index],
      {
        isSuccess: result.isSuccess,
        refetch: result.refetch,
        data: result.data as GeocodedPlace,
      },
    ])
  );

  useDeepCompareEffect(() => {
    if (
      Object.values(geocodeReadableData).every((result) => result.isSuccess)
    ) {
      setGeocodedPlaces(
        canonicalNames.map((canonical) => geocodeReadableData[canonical].data)
      );
    }
  }, [geocodeReadableData]);

  // Invalidate the cache when adding a new destination so we don't get the autocomplete version of the place
  useDeepCompareEffect(() => {
    if (shouldInvalidateCache) {
      canonicalNames.forEach((canonicalName) => {
        const geocodedPlace = geocodeReadableData[canonicalName];
        if (!geocodedPlace?.data?.lat) {
          queryClient.invalidateQueries({
            queryKey: getCacheKeyForGeocode(canonicalName, languageCode),
          });
          geocodedPlace?.refetch();
        }
      });
    }
  }, [canonicalNames, isTripScreen]);

  return geocodedPlaces;
}

export function combinedGeocodeQueries({
  config,
  canonicalNames,
  languageCode,
  queryClient,
}: {
  config: ApiConfig;
  canonicalNames: string[];
  languageCode: SupportedLanguageCode;
  queryClient: QueryClient;
}) {
  const geocodeQueries = canonicalNames.map((canonicalName) => {
    const queryKey = getCacheKeyForGeocode(canonicalName, languageCode);

    return {
      queryKey,
      queryFn: async () =>
        geocodeCanonical(config, canonicalName, languageCode, queryClient),
      cacheTime: ONE_DAY_IN_MILLISECONDS,
      staleTime: ONE_DAY_IN_MILLISECONDS,
      retryOnMount: false,
      retry: 3,
    };
  });

  return geocodeQueries;
}
