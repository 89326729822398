import type { MapSegment } from "../mapSegments";

export function getBoundsFromSegments(
  segments: MapSegment[],
  bounds: google.maps.LatLngBounds
): google.maps.LatLngBounds | null {
  segments.map((segment) =>
    segment.places.map((place) => bounds.extend(place))
  );

  if (!bounds || bounds.isEmpty()) {
    return null;
  }
  return bounds;
}
