import type { FormattedRoomDetails } from "src/utils/url";
import type { RoomDetail } from "./HotelsContext";

// Utils for constructing deeplinks.

function dateMonthNumberToString(number: number) {
  return number.toLocaleString("en", {
    minimumIntegerDigits: 2,
  });
}

export function dateToFormattedISOString(date: Date) {
  const year = date.getFullYear();
  const month = dateMonthNumberToString(date.getMonth() + 1);
  const day = dateMonthNumberToString(date.getDate());
  return `${year}-${month}-${day}T00:00:00.0000000`;
}

export function formatRoomDetails(
  roomDetails: RoomDetail[]
): FormattedRoomDetails[] {
  return roomDetails.map((room) => {
    const Adults = room.adults;
    const Children = room.children.length;
    const ChildAges = room.children.map((child) => child.age ?? 6); // booking.com default age for children
    return { Adults, Children, ChildAges };
  });
}
