import styled, { css } from "styled-components";
import { useState } from "react";
import type { MobileTabs } from "./App";
import { hotelPaneWidthThin, hotelPaneWidthWide } from "./PaneContentWidths";
import { PaneFooterAds } from "./PaneFooterAds";
import { PassengerDetailsProvider } from "./PassengerDetailsProvider";
import { bottomNavBarHeight } from "./components/BottomNavbar/BottomNavbar";
import { DefaultErrorBoundary } from "./components/DefaultErrorBoundary/DefaultErrorBoundary";
import { VisuallyHidden } from "./components/VisuallyHidden/VisuallyHidden";
import { SearchScreen } from "./domain/SearchScreen/SearchScreen";
import { UserPreferencesScreen } from "./domain/UserPreferencesScreen/UserPreferencesScreen";
import { screenMinWidth } from "./theme";
import { useDisableAdsUrlParams } from "./utils/hooks/useDisableAdsUrlParams";
import { useIsTripScreen } from "./utils/hooks/useIsTripScreen";
import { type LayoutType } from "./utils/hooks/useLayout";
import {
  type ReturnStage,
  useTypedLocation,
} from "./utils/hooks/useTypedLocation";
import { useIsHotelsUrlDeeplink } from "./utils/hooks/useNavigateToHotelsPage";
import { getScreenKey } from "./domain/TripPlanner/util/getScreenKey";
import { ScrollableContainer } from "./components/ScrollableContainer/ScrollableContainer";
import { useScreenMinWidth } from "./utils/hooks/useScreenMinWidth";
import { useFeature } from "./feature/useFeature";
import color from "./design-system/tokens/color";
import { ScrollNudge } from "./domain/HotelsScreen/ScrollNudge";
import { safeSessionStorage } from "./utils/safeLocalStorage";

export const leftPaneScrollContainerId = "paneScrollContainer";

type Props = {
  highlightedTab: MobileTabs;
  layout: LayoutType;
  sectionTitle?: string;
  openScheduleIndex?: number;
  returnsFlowLocation?: ReturnStage;
};

export function PaneContent({
  highlightedTab,
  layout,
  sectionTitle,
  returnsFlowLocation,
  openScheduleIndex,
}: Props) {
  const hExScrollNudgeFeature = useFeature("HExScrollNudge");
  const location = useTypedLocation();
  const isTripsScreen = useIsTripScreen();
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  const { isSmScreen } = useScreenMinWidth();

  // Scroll Nudge is an experimental indicator that shows the user that they can scroll down.
  const [isScrollNudgeHidden, setIsScrollNudgeHidden] = useState(() => {
    return safeSessionStorage.getItem("scrollNudgeHidden") === "true";
  });

  const hideNavBarSpace = layout === "mobile";

  const backGroundColor =
    highlightedTab === "preferences" ? color.bg.fill.hover : color.bg.fill.fill;

  const onFullScreenTripPage =
    openScheduleIndex !== undefined && layout === "mobile";

  const screenKey = getScreenKey(location.hash);
  const paneStackOpen = screenKey !== undefined && layout !== "mobile";

  /* The ad is only shown on the search tab and whilst live schedules aren't loading on the segment pane.
  Since we don't want to rerequest it when toggling between tabs, we hide it on tabs that aren't the
  search one instead of unmounting it */
  const shouldHideAds =
    (highlightedTab !== "search" && highlightedTab !== "trips") ||
    (highlightedTab === "trips" && !isSmScreen) || // Hide ads on trips tab on mobile
    onFullScreenTripPage ||
    paneStackOpen;

  // During an experiment where the popunder will send users to the hotels screen,
  // we do not want to serve any ads on the hotels screen.
  const isHExPopunderDisabledAds = useDisableAdsUrlParams();
  const shouldShowLeftRail = !(isHotelsScreen && isHExPopunderDisabledAds);

  return (
    <PassengerDetailsProvider>
      <DefaultErrorBoundary defaultMessage>
        <ScrollableContainer
          zIndex={1}
          logScrollPercent
          isScrollNudgeHidden={isScrollNudgeHidden}
          setIsScrollNudgeHidden={setIsScrollNudgeHidden}
        >
          <PaneScrollContainer
            data-testid="paneScrollContainer"
            id={leftPaneScrollContainerId}
            $bottomScrollAnchor={isTripsScreen ? "none" : "auto"}
            $isTripPane={isTripsScreen}
            $fullScreenTripPage={onFullScreenTripPage}
            $hideNavBarSpace={hideNavBarSpace}
            $isHotelsScreen={isHotelsScreen}
          >
            {sectionTitle && (
              <VisuallyHidden>
                <h2>{sectionTitle}</h2>
              </VisuallyHidden>
            )}
            <AppAboveFold $backgroundColor={backGroundColor}>
              {highlightedTab === "search" ||
              highlightedTab === "hotels" ||
              highlightedTab === "trips" ? (
                <SearchScreen
                  context="transport"
                  openScheduleIndex={openScheduleIndex}
                  returnsFlowLocation={returnsFlowLocation}
                />
              ) : (
                <UserPreferencesScreen />
              )}
            </AppAboveFold>

            {highlightedTab === "hotels" && hExScrollNudgeFeature ? (
              <ScrollNudge
                hidden={isScrollNudgeHidden}
                setHidden={setIsScrollNudgeHidden}
              />
            ) : null}

            {shouldShowLeftRail && <PaneFooterAds hide={shouldHideAds} />}
          </PaneScrollContainer>
        </ScrollableContainer>
      </DefaultErrorBoundary>
    </PassengerDetailsProvider>
  );
}

const PaneScrollContainer = styled.div<{
  $fullScreenTripPage: boolean;
  $hideNavBarSpace: boolean;
  $bottomScrollAnchor: "none" | "auto";
  $isTripPane: boolean;
  $isHotelsScreen: boolean;
}>`
  position: relative;
  background-color: ${color.bg.fill.fill};
  overflow-anchor: ${(props) => props.$bottomScrollAnchor};
  // Height of nav bars.
  padding-top: ${(props) => (props.$hideNavBarSpace ? "0px" : "44px")};
  padding-bottom: ${(props) =>
    props.$fullScreenTripPage ? "0" : bottomNavBarHeight};
  min-height: 100vh;

  ${screenMinWidth.sm} {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
    box-shadow: 1px 0 12px rgba(31, 30, 30, 0.2);
    flex-grow: 1;
    width: 384px;
  }

  ${screenMinWidth.md} {
    width: 496px;
  }
  ${screenMinWidth.xl} {
    width: 576px;
  }

  ${({ $isHotelsScreen }) =>
    $isHotelsScreen &&
    css`
      ${screenMinWidth.sm} {
        width: ${hotelPaneWidthThin}px;
      }
      ${screenMinWidth.xl} {
        width: ${hotelPaneWidthWide}px;
      }
    `}
`;

const AppAboveFold = styled.div<{
  $backgroundColor?: string;
}>`
  background-color: ${({ $backgroundColor = color.bg.fill.fill }) =>
    $backgroundColor};
  display: inline;
`;
