import type { Mode } from "src/utils/types/mode";
import { formatIsoDate, numNightsBetweenTwoDates } from "src/utils/dateTime";
import type { InterestData, SearchResponse } from "src/api/SearchResponse";
import {
  type SupportedLanguageCode,
  isSupportedLanguageCode,
} from "src/utils/language";
import type { SchedulesResponse } from "src/api/SchedulesResponse";
import type { RoomDetail } from "src/domain/HotelsScreen/HotelsContext";
import {
  sendTravelAudienceEvent,
  TICKET_SELECTION_LIVE_SCHEDULE_EXIT,
} from "./travelAudience/sendTravelAudienceEvent";
import { pushCrimtanEvent } from "./crimtan/pushDataLayerEvent";
import type { DataPartners } from "./useDataPartner";
import { type TransportExit, sendAdaraEvent } from "./adara/sendAdaraEvent";
import { getAdaraTransportData } from "./adara/getAdaraTransportData";

type TicketExitParams = {
  transitMode: Mode;
  language: string;
  searchResponse?: SearchResponse;
  routeIndex?: number;
  schedulesResponse?: SchedulesResponse;
  price?: string;
  origin?: string;
  destination?: string;
  originState?: string;
  destinationState?: string;
  departureDate?: string;
  returnDate?: string;
  numTripLegs?: number;
  carriers: string[];
  currency?: string;
  passengersCount?: number;
  interestData?: InterestData;
  isCrimtanEnabled?: boolean;
  isAdaraEnabled?: boolean;
};

type CarHireExitParams = {
  origin?: string;
  destination?: string;
  isCrimtanEnabled?: boolean;
  isAdaraEnabled?: boolean;
  languageCode?: SupportedLanguageCode;
};

type HotelExitParams = {
  destination?: string;
  destinationState?: string;
  hotelName?: string;
  hotelStarRating?: number;
  checkinDate?: Date;
  checkoutDate?: Date;
  languageCode?: SupportedLanguageCode;
  searchResponse?: SearchResponse;
  avgDailyRate?: number;
  roomDetails?: RoomDetail[];
  isCrimtanEnabled: boolean;
  isAdaraEnabled: boolean;
};

export function logDataPartnerCarHireExit({
  origin,
  destination,
  languageCode,
  isCrimtanEnabled,
  isAdaraEnabled,
}: CarHireExitParams) {
  if (isCrimtanEnabled) {
    pushCrimtanEvent({
      event: "carHireExit",
      origin,
      destination,
    });
  }

  if (isAdaraEnabled) {
    sendAdaraEvent({
      event: "carHireExit",
      departureCity: origin,
      arrivalCity: destination,
      siteLanguage: languageCode,
    });
  }
}

export function logDataPartnerHotelExit({
  destination,
  destinationState,
  hotelName,
  hotelStarRating,
  checkinDate,
  checkoutDate,
  languageCode,
  searchResponse,
  avgDailyRate,
  roomDetails,
  isCrimtanEnabled,
  isAdaraEnabled,
}: HotelExitParams) {
  const formattedCheckIn = checkinDate && formatIsoDate(checkinDate);
  const formattedCheckOut = checkoutDate && formatIsoDate(checkoutDate);

  if (isCrimtanEnabled) {
    pushCrimtanEvent({
      event: "hotelExit",
      destination,
      hotelName,
      checkinDate: formattedCheckIn,
      checkoutDate: formattedCheckOut,
    });
  }

  if (isAdaraEnabled) {
    const arrivalCountry =
      searchResponse?.places[searchResponse.result.destinationPlace]
        ?.countryCode;
    const numNights =
      checkinDate &&
      checkoutDate &&
      numNightsBetweenTwoDates(checkinDate, checkoutDate);

    const { numAdults, numChildren, numRooms } =
      roomDetails?.reduce(
        (acc, room) => {
          return {
            ...acc,
            numAdults: acc.numAdults + room.adults,
            numChildren: acc.numChildren + room.children.length,
          };
        },
        { numAdults: 0, numChildren: 0, numRooms: roomDetails.length }
      ) ?? {};

    sendAdaraEvent({
      event: "hotelExit",
      arrivalCity: destination,
      hotelName,
      hotelCheckInDate: formattedCheckIn,
      hotelCheckOutDate: formattedCheckOut,
      siteLanguage: languageCode,
      numNights,
      avgDailyRate,
      currencyType: searchResponse?.request.currencyCode,
      hotelStarRating,
      arrivalState: destinationState,
      arrivalCountry,
      numAdults,
      numChildren,
      numRooms,
    });
  }
}

export function logDataPartnerTicketExit({
  transitMode,
  language,
  searchResponse,
  routeIndex,
  schedulesResponse,
  price,
  origin,
  destination,
  originState,
  destinationState,
  departureDate,
  returnDate,
  numTripLegs,
  carriers,
  currency,
  passengersCount,
  interestData,
  isCrimtanEnabled,
  isAdaraEnabled,
}: TicketExitParams) {
  const enabledPartners: DataPartners = Object.keys(interestData ?? {}).filter(
    (key) => interestData?.[key].enabled
  ) as DataPartners;

  if (isCrimtanEnabled) {
    pushCrimtanEvent({
      event: "transportExit",
      origin,
      destination,
      travelMode: transitMode,
      ticketCarriers: carriers,
      outboundDate: departureDate,
      inboundDate: returnDate,
      passengerCount: passengersCount,
    });
  }

  if (isAdaraEnabled) {
    const data = getAdaraTransportData<TransportExit>({
      searchResponse,
      routeIndex,
      schedulesResponse,
      transitMode,
      numTripLegs,
      returnDate,
    });

    // Strip out currency and commas from round trip prices.
    const formattedPrice = price?.replace(/[^\d.-]+/g, "");

    sendAdaraEvent({
      event: "transportExit",
      travelProductType: transitMode,
      departureDate: departureDate ?? data.departureDate,
      departureCity: origin,
      departureState: originState,
      departureCountry: data.departureCountry,
      departureAirportCode: data.departureAirportCode,
      arrivalDate: returnDate,
      arrivalCity: destination,
      arrivalState: destinationState,
      arrivalCountry: data.arrivalCountry,
      arrivalAirportCode: data.arrivalAirportCode,
      ticketCarriers: carriers,
      numPassengers: passengersCount,
      tripType: data.tripType,
      price: formattedPrice ?? data.price,
      currencyType: currency ?? data.currencyType,
      ticketClass: data.ticketClass,
      siteLanguage: isSupportedLanguageCode(language) ? language : undefined,
    });
  }

  if (enabledPartners.includes("TravelAudience")) {
    sendTravelAudienceEvent({
      level: TICKET_SELECTION_LIVE_SCHEDULE_EXIT,
      transitMode,
      language,
      price,
      origin,
      destination,
      departureDate,
      returnDate,
      operator: carriers.join(", "),
      currency,
    });
  }
}
