import { useState } from "react";
import {
  disableRequestMock,
  enableRequestMock,
  getRequestedMocks,
} from "src/utils/browserRequestMocks";
import Cookies from "js-cookie";
import { RotatingChevron } from "src/svg/RotatingChevron";
import { Icon } from "src/components/Icon/Icon";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { FeatureItem } from "./DebugItem";

export default function MockFlagSelector() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const activeMocks = getRequestedMocks();

  if (import.meta.env.VITE_ENVIRONMENT !== "Development") {
    return null;
  }

  return (
    <>
      <FeatureItem
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        pointer={true}
      >
        <div>
          <h3>Request Mock Flags</h3>
          <p>Intercept API requests in-browser.</p>
        </div>
        <Icon size="sm">
          <RotatingChevron
            direction={isDropdownOpen ? "up" : "down"}
            tint="cod"
          />
        </Icon>
      </FeatureItem>
      <FeatureItem>
        {isDropdownOpen && (
          <FeatureListContainer>
            <div>
              <label htmlFor="auth">Authentication (reloads window)</label>
              <input
                type="checkbox"
                id="auth"
                checked={activeMocks.includes("auth")}
                onChange={() => {
                  if (activeMocks.includes("auth")) {
                    Cookies.remove("r2r_session");
                    disableRequestMock("auth");
                  } else {
                    Cookies.set("r2r_session", "mocked");
                    enableRequestMock("auth");
                  }
                }}
              />
            </div>
            <div>
              <label htmlFor="trip">Trips (reloads window)</label>
              <input
                type="checkbox"
                id="trip"
                checked={activeMocks.includes("trip")}
                onChange={() => {
                  if (activeMocks.includes("trip")) {
                    disableRequestMock("trip");
                  } else {
                    enableRequestMock("trip");
                  }
                }}
              />
            </div>
          </FeatureListContainer>
        )}
      </FeatureItem>
    </>
  );
}

const FeatureListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  gap: ${spacing.xxl};
  width: 100%;

  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  input {
    width: 18px;
    height: 18px;
  }
`;
