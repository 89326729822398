import type {
  Hotel,
  HotelDealInfo,
  HotelDealTag,
  HotelListResponse,
} from "../../api/HotelListResponse";
import type { SupportedCurrencyCode } from "../../utils/currency";
import type { HotelProviderKind } from "../../utils/types/accommodationProviders";

export function hotelsScreenAdapter(
  hotelListResponse: HotelListResponse,
  numOfHotels = 12
): HotelsScreenViewModel {
  const { hotelList, selectedHotel } = hotelListResponse;

  const defaultFilteredHotels =
    hotelList?.filter((hotel) => {
      if (hotel.hotelId === selectedHotel?.hotelId) {
        return false;
      }
      return hotel.minRate > 0;
    }) ?? [];

  if (selectedHotel) {
    defaultFilteredHotels.unshift(selectedHotel);
  }

  const hotels = defaultFilteredHotels
    .slice(0, numOfHotels)
    .map((hotel: Hotel) => ({
      price: {
        value: hotel.minRate,
        currency: hotelListResponse.currencyCode,
      },
      title: hotel.hotelName,
      starRating: hotel.rating > 0 ? hotel.rating : undefined, // Expedia hotels have -1 rating. TODO: Remove rating on backend & make this optional.
      hotelId: hotel.hotelId,
      provider: hotelListResponse.provider,
      lat: hotel.lat,
      lng: hotel.lng,
      deal: hotel.deal,
      freeCancellation: hotel.freeCancellation,
      reviewScore: hotel.reviewScore,
    }));

  return { hotels, numDefaultFilteredHotels: defaultFilteredHotels.length };
}

export type HotelViewModel = {
  price: { value: number; currency: SupportedCurrencyCode };
  title: string;
  starRating?: number;
  hotelId: string;
  provider: HotelProviderKind;
  lat: number;
  lng: number;
  deal?: HotelDealInfo;
  freeCancellation?: boolean;
  reviewScore: number;
};

export type HotelPromotion = {
  tag: HotelDealTag;
  maxDiscount: number;
};

export type HotelsScreenViewModel = {
  hotels: HotelViewModel[];
  numDefaultFilteredHotels: number;
};
