import { type Path, useLocation } from "react-router";
import type { TripPlannerDetails } from "src/domain/TripPlanner/TripPlannerProvider";
import type { MobileTabs } from "../../App";
import type { View } from "../../analytics/generateExitPoint/generateExitPoint";
import type { AutocompleteKind } from "../../domain/AutocompleteScreen/AutocompleteScreen";

export type ReturnStage = "departing" | "return" | "summary";

export type LocationState = {
  autocomplete?: AutocompleteKind;
  searchBar?: "static" | "edit";
  carrierCode?: string;
  /** @deprecated - Prefer using state instead */
  schedulePicker?: boolean;
  openScheduleIndex?: number;
  ticketPickerView?: View; // The View that opened the ticket picker so that exit logging is assigned correctly.
  highlightedTab?: MobileTabs;
  preferencesScreen?:
    | "main"
    | "currency"
    | "language"
    | "distance"
    | "timeFormat"
    | "manageProfile"
    | "debug";
  userSearch?: boolean;
  hasUserOverwritten?: boolean;
  returnsFlowLocation?: ReturnStage;
  reorderingTripPlan?: boolean;
  tripScheduleIndex?: number;
  fromHash?: string;
  tripPlannerDetails?: TripPlannerDetails;
  editTripPlan?: boolean;
};

export type TypedLocation = Path & {
  state: LocationState | null;
  key?: string;
};

export function useTypedLocation(): TypedLocation {
  const location = useLocation();
  return location as TypedLocation;
}
