import { type MessageDescriptor, defineMessages } from "react-intl";
import type { Mode } from "../../../../utils/types/mode";

export const messages = defineMessages({
  arrivesSame: {
    id: "schedule.arrives.same",
    description: "Specifies that schedule arrives the same day",
    defaultMessage: "Arrives same day",
  },
  arrivesNext: {
    id: "schedule.arrives.next",
    description: "Specifies that schedule arrives the next day",
    defaultMessage: "Arrives next day",
  },
  arrivesNextMultiple: {
    id: "schedule.arrives.nextMultiple",
    description:
      "Specifies that schedule arrives 2 or more days in the future (ex: Arrives +3 days)",
    defaultMessage: "Arrives +{numDays} days",
  },
  arrivesPrevious: {
    id: "schedule.arrives.previous",
    description: "Specifies that schedule arrives the previous day",
    defaultMessage: "Arrives previous day",
  },
  arrivesPreviousMultiple: {
    id: "schedule.arrives.previousMultiple",
    description:
      "Specifies that schedule arrives 2 or more days in the past (ex: Arrives -3 days)",
    defaultMessage: "Arrives -{numDays} days",
  },
  stopsZero: {
    id: "schedule.stops.zero",
    description: "Specifies that the route is direct, there are no stops",
    defaultMessage: "Direct",
  },
  stopsOne: {
    id: "schedule.stops.one",
    description: "Specifies that there is exactly 1 stop (ex: 1 stop)",
    defaultMessage: "{numStops} stop",
  },
  stopsOther: {
    id: "schedule.stops.other",
    description: "Specifies that the route has 2 or more stops (ex: 2 stops)",
    defaultMessage: "{numStops} stops",
  },
  changesZero: {
    id: "schedule.changes.zero",
    description: "Specifies that the route is direct, there are no changes",
    defaultMessage: "Direct",
  },
  changesOne: {
    id: "schedule.changes.one",
    description: "Specifies that there is exactly 1 change (ex: 1 change)",
    defaultMessage: "{numChanges} change",
  },
  changesOther: {
    id: "schedule.changes.other",
    description:
      "Specifies that the route has 2 or more changes (ex: 2 changes)",
    defaultMessage: "{numChanges} changes",
  },
  chevronIcon: {
    id: "schedule.chevronIcon",
    description:
      "Tells screen readers what the arrow icon indicates (opens schedule information)",
    defaultMessage: "Open schedule info",
  },
  scheduleCellCta: {
    id: "schedule.ctaMessage",
    description: "Calls users to action to select this schedule",
    defaultMessage: "Select",
  },
  cheapest: {
    id: "schedule.cheapest",
    defaultMessage: "Cheapest",
    description:
      "Indicates that the schedule is the cheapest option, (minimising the number of characters to save horizontal layout space)",
  },
  secondCheapest: {
    id: "schedule.secondCheapest",
    defaultMessage: "2nd Cheapest",
    description:
      "Indicates that the schedule is the second cheapest option, (minimising the number of characters to save horizontal layout space)",
  },
  fastest: {
    id: "schedule.fastest",
    defaultMessage: "Fastest",
    description:
      "Indicates that the schedule is the fastest option, (minimising the number of characters to save horizontal layout space)",
  },
  secondFastest: {
    id: "schedule.secondFastest",
    defaultMessage: "2nd Fastest",
    description:
      "Indicates that the schedule is the second fastest option, (minimising the number of characters to save horizontal layout space)",
  },
  to: {
    id: "schedule.to",
    defaultMessage: "To",
    description:
      "Specifies a connector between two datetimes, it is used when a screen reader try to read the arrow in schedules page",
  },
  oneWay: {
    id: "schedule.oneWay",
    defaultMessage: "One-way",
    description:
      "Specifies this schedule is a one way ticket and not apart of a round trip.",
  },
  roundTrip: {
    id: "schedule.roundTrip",
    defaultMessage: "Round trip",
    description:
      "Specifies this schedule is apart of a round trip ticket and not a one way ticket.",
  },
  passengersSingular: {
    id: "schedule.passengersSingular",
    description:
      "Specifies the number of passengers on this schedule when there is only one passenger.",
    defaultMessage: "1 passenger",
  },
  passengersPlural: {
    id: "schedule.passengersPlural",
    description:
      "Specifies the number of passengers on this schedule when there are multiple.",
    defaultMessage: "{count} passengers",
  },
  arrivalDate: {
    defaultMessage: "Arrives {arrivalDate}",
    id: "schedule.arrivalDate",
    description: "Specifies what date the schedule will arrive",
  },
  extraCost: {
    id: "schedule.extraCost",
    defaultMessage: "Extra cost",
    description: "Specifies that this return schedule will have an extra cost.",
  },
  from: {
    id: "schedule.from",
    defaultMessage: "from",
    description:
      "Specifies that this schedule price is a starting from this price.",
  },
});

export const modeMessages: {
  [key in Mode]: MessageDescriptor;
} = defineMessages({
  animal: {
    defaultMessage: "Animal",
    id: "segmentScreen.animal",
    description: "Label of the mode type Animal when shown on a schedule item.",
  },
  bike: {
    defaultMessage: "Bike",
    id: "segmentScreen.bike",
    description: "Label of the mode type Bike when shown on a schedule item.",
  },
  bus: {
    defaultMessage: "Bus",
    id: "segmentScreen.bus",
    description: "Label of the mode type Bus when shown on a schedule item.",
  },
  busferry: {
    defaultMessage: "Bus & Ferry",
    id: "segmentScreen.busferry",
    description:
      "Label of the mode type Bus and Ferry when shown on a schedule item.",
  },
  cablecar: {
    defaultMessage: "Cable Car",
    id: "segmentScreen.cableCar",
    description:
      "Label of the mode type Cable Car when shown on a schedule item.",
  },
  car: {
    defaultMessage: "Car",
    id: "segmentScreen.car",
    description: "Label of the mode type Car when shown on a schedule item.",
  },
  carferry: {
    defaultMessage: "Car & Ferry",
    id: "segmentScreen.carferry",
    description:
      "Label of the mode type Car and Ferry when shown on a schedule item.",
  },
  ferry: {
    defaultMessage: "Ferry",
    id: "segmentScreen.ferry",
    description: "Label of the mode type Ferry when shown on a schedule item.",
  },
  helicopter: {
    defaultMessage: "Helicopter",
    id: "segmentScreen.helicopter",
    description:
      "Label of the mode type Helicopter when shown on a schedule item.",
  },
  plane: {
    defaultMessage: "Plane",
    id: "segmentScreen.plane",
    description: "Label of the mode type Plane when shown on a schedule item.",
  },
  rideshare: {
    defaultMessage: "Rideshare",
    id: "segmentScreen.rideshare",
    description:
      "Label of the mode type Rideshare when shown on a schedule item.",
  },
  taxi: {
    defaultMessage: "Taxi",
    id: "segmentScreen.taxi",
    description: "Label of the mode type Taxi when shown on a schedule item.",
  },
  shuttle: {
    defaultMessage: "Shuttle",
    id: "segmentScreen.shuttle",
    description:
      "Label of the mode type Shuttle when shown on a schedule item.",
  },
  towncar: {
    defaultMessage: "Towncar",
    id: "segmentScreen.towncar",
    description:
      "Label of the mode type Towncar when shown on a schedule item.",
  },
  train: {
    defaultMessage: "Train",
    id: "segmentScreen.train",
    description: "Label of the mode type Train when shown on a schedule item.",
  },
  tram: {
    defaultMessage: "Tram",
    id: "segmentScreen.tram",
    description: "Label of the mode type Tram when shown on a schedule item.",
  },
  walk: {
    defaultMessage: "Walk",
    id: "segmentScreen.walk",
    description: "Label of the mode type Walk when shown on a schedule item.",
  },
  unknown: {
    defaultMessage: "Unknown",
    id: "segmentScreen.unknown",
    description: "Label of the mode type Walk when shown on a schedule item.",
  },
  multi: {
    defaultMessage: "Multiple",
    id: "segmentScreen.multiple",
    description:
      "Label for multiple modes grouped when shown on a trip planner item.",
  },
});

export const dayMessages: {
  [key: string]: MessageDescriptor;
} = defineMessages({
  monday: {
    defaultMessage: "Mo",
    id: "schedule.monday",
    description: "The two letter representation for Monday.",
  },
  tuesday: {
    defaultMessage: "Tu",
    id: "schedule.tuesday",
    description: "The two letter representation for Tuesday.",
  },
  wednesday: {
    defaultMessage: "We",
    id: "schedule.wednesday",
    description: "The two letter representation for Wednesday.",
  },
  thursday: {
    defaultMessage: "Th",
    id: "schedule.thursday",
    description: "The two letter representation for Thursday.",
  },
  friday: {
    defaultMessage: "Fr",
    id: "schedule.friday",
    description: "The two letter representation for Friday.",
  },
  saturday: {
    defaultMessage: "Sa",
    id: "schedule.saturday",
    description: "The two letter representation for Saturday.",
  },
  sunday: {
    defaultMessage: "Su",
    id: "schedule.sunday",
    description: "The two letter representation for Sunday.",
  },
  daily: {
    id: "schedule.dayOfWeek.Daily",
    description: "Specifies that the route runs daily",
    defaultMessage: "Daily",
  },
  daysExcept: {
    id: "schedule.dayOfWeek.Except",
    description: "Specifies that the route runs all days except this day",
    defaultMessage: "Except {daysExcept}",
  },
  fromTo: {
    id: "schedule.dayOfWeek.FromTo",
    description:
      "Specifies that the route runs all days between these starting and ending days",
    defaultMessage: "{startDay} to {endDay}",
  },
  mondayLong: {
    defaultMessage: "Mon",
    id: "schedule.mondayLong",
    description: "The three letter representation for Monday.",
  },
  tuesdayLong: {
    defaultMessage: "Tue",
    id: "schedule.tuesdayLong",
    description: "The three letter representation for Tuesday.",
  },
  wednesdayLong: {
    defaultMessage: "Wed",
    id: "schedule.wednesdayLong",
    description: "The three letter representation for Wednesday.",
  },
  thursdayLong: {
    defaultMessage: "Thu",
    id: "schedule.thursdayLong",
    description: "The three letter representation for Thursday.",
  },
  fridayLong: {
    defaultMessage: "Fri",
    id: "schedule.fridayLong",
    description: "The three letter representation for Friday.",
  },
  saturdayLong: {
    defaultMessage: "Sat",
    id: "schedule.saturdayLong",
    description: "The three letter representation for Saturday.",
  },
  sundayLong: {
    defaultMessage: "Sun",
    id: "schedule.sundayLong",
    description: "The three letter representation for Sunday.",
  },
  multiDay: {
    defaultMessage: "{days}",
    id: "schedule.multiDay",
    description: "A representation of multiple days.",
  },
});
