import { Dropdown } from "../Dropdown/Dropdown";
import { AutocompleteList } from "../AutocompleteList/AutocompleteList";
import type { AutocompletePlace } from "../../api/AutocompleteResponse";

export const MAX_RESULTS = 5;

export type Props = {
  results: AutocompletePlace[];
  onSelectPlace: (place: AutocompletePlace) => void;
  className?: string;
};

export function AutocompleteDropdown(props: Props) {
  const truncatedResults = props.results.slice(0, MAX_RESULTS);

  return (
    <Dropdown className={props.className}>
      <AutocompleteList
        results={truncatedResults}
        isInputFocused={true}
        onSelectPlace={props.onSelectPlace}
        applyLargeHorizontalPadding
      />
    </Dropdown>
  );
}
