import type React from "react";
import { border_radius } from "src/design-system/tokens/border";
import { color, lineHeight, spacing } from "src/theme";
import { desktopLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";

type Props = {
  id: string;
  placement?: "top" | "bottom";
  anchor?: "left" | "right" | "center";
  anchorOffset?: number;
  placementOffset?: number;
  tooltipContent: React.ReactNode;
  children: React.ReactNode;
  forceShow?: boolean;
  backgroundColor?: keyof typeof color;
  className?: string;
  disableAnimation?: boolean;
  showOnMobileFocus?: boolean;
};

export default function Tooltip({
  id,
  tooltipContent,
  placement = "top",
  anchor = "center",
  anchorOffset = 0,
  placementOffset = 0,
  children,
  forceShow,
  backgroundColor = "cod",
  className,
  disableAnimation = false,
}: Props) {
  return (
    <TooltipContainer aria-describedby={id} $forceShow={forceShow}>
      {children}
      {tooltipContent && (
        <TooltipPopout
          id={id}
          $anchorOffset={anchorOffset}
          $placementOffset={placementOffset}
          $anchor={anchor}
          $placement={placement}
          $backgroundColor={backgroundColor}
          className={className}
          $disableTransition={disableAnimation}
          role="tooltip"
        >
          {tooltipContent}
        </TooltipPopout>
      )}
    </TooltipContainer>
  );
}

const TooltipPopout = styled.div<{
  $placement: "top" | "bottom";
  $anchor: "left" | "right" | "center";
  $anchorOffset: number;
  $placementOffset: number;
  $backgroundColor: keyof typeof color;
  $disableTransition: boolean;
}>`
  --triangle-size: ${spacing.sm};
  --anchor-offset: ${(props) => `${props.$anchorOffset}px`};
  --placement-offset: ${(props) => `${props.$placementOffset}px`};
  --backgroundColor: ${(props) => color[props.$backgroundColor]};

  position: absolute;
  width: max-content;
  border-radius: ${border_radius.rounded_md};
  line-height: ${lineHeight.normal};
  color: ${color.primaryOnDark};
  background-color: var(--backgroundColor);

  pointer-events: none;
  opacity: 0;

  ${(props) =>
    !props.$disableTransition &&
    css`
      transition: opacity 0.2s ease;
    `}

  ${(props) => PlacementCSS[props.$placement]}
  ${(props) => AnchorCSS[props.$anchor]}


  ::before {
    content: "";
    position: absolute;
  }
`;

const PlacementCSS = {
  bottom: css`
    top: 100%;
    margin-top: var(--placement-offset);

    ::before {
      bottom: 100%;
      border: var(--triangle-size) solid transparent;
      border-bottom: var(--triangle-size) solid var(--backgroundColor);
    }
  `,
  top: css`
    bottom: 100%;
    margin-bottom: var(--placement-offset);

    ::before {
      top: 100%;
      border: var(--triangle-size) solid transparent;
      border-top: var(--triangle-size) solid var(--backgroundColor);
    }
  `,
};

const AnchorCSS = {
  left: css`
    left: var(--anchor-offset);
    :before {
      left: ${spacing.lg};
    }
  `,
  right: css`
    right: var(--anchor-offset);
    :before {
      right: ${spacing.lg};
    }
  `,
  center: css`
    left: 50%;
    transform: translateX(-50%);
    :before {
      left: calc(50% - var(--triangle-size));
    }
  `,
};

const tooltipVisibleCSS = css`
  ${TooltipPopout} {
    opacity: 1;
    pointer-events: auto;
  }
`;

const TooltipContainer = styled.div<{
  $forceShow?: boolean;
  $showOnMobileFocus?: boolean;
}>`
  position: relative;

  ${desktopLayout} {
    &:hover {
      ${tooltipVisibleCSS};
    }
  }

  ${({ $showOnMobileFocus }) =>
    $showOnMobileFocus
      ? css`
          &:focus-within,
          &:hover {
            ${tooltipVisibleCSS};
          }
        `
      : css`
          ${desktopLayout} {
            &:focus-within {
              ${tooltipVisibleCSS};
            }
          }
        `}

  ${({ $forceShow }) =>
    $forceShow &&
    css`
      ${tooltipVisibleCSS};
    `}
`;
