import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { Rome2rioLogo } from "src/svg/Rome2rioLogo";
import { useTheme } from "src/utils/hooks/useTheme";
import styled from "styled-components";
import useCcpaManagement from "src/privacy-regulations/hooks/useCcpaManagement";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { ManageProfileScreen } from "../../auth/components/ManageProfile/ManageProfileScreen/ManageProfileScreen";
import { UserAccounts } from "../../auth/components/UserAccounts/UserAccounts";
import { Navbar } from "../../components/Navbar/Navbar";
import { spacing } from "../../theme";
import {
  type LayoutType,
  largeDesktopLayout,
  useLayout,
} from "../../utils/hooks/useLayout";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { navigateToNewState } from "../../utils/location/navigateToNewState";
import { DebugScreen } from "../DebugScreen/DebugScreen";
import { PreferenceScreen } from "./PreferenceScreen";
import messages from "./UserPreferences.messages";
import { UserPreferences } from "./UserPreferences/UserPreferences";

export function UserPreferencesScreen() {
  const location = useTypedLocation();
  const navigate = useNavigate();

  const layout = useLayout(handleLayoutChanged);
  const isMobile = layout === "mobile";

  function handleLayoutChanged(
    newLayout: LayoutType,
    previousLayout: LayoutType
  ) {
    if (newLayout !== "mobile" && previousLayout === "mobile") {
      // If the user is on the user preferences tab and changes their layout to desktop,
      // the user has no way of backing out of the pane. Since the user preferences are
      // in the site navigation on desktop we can push them back to the search screen.
      navigateToNewState(
        navigate,
        { highlightedTab: "search" },
        location,
        true
      );
    }
  }

  switch (location.state?.preferencesScreen) {
    case "currency":
      return (
        <PreferenceScreenContainer>
          <PreferenceScreen preference="currency" />
        </PreferenceScreenContainer>
      );
    case "language":
      return (
        <PreferenceScreenContainer>
          <PreferenceScreen preference="language" />
        </PreferenceScreenContainer>
      );
    case "distance":
      return isMobile ? (
        <PreferenceScreen preference="distance" />
      ) : (
        <DisplayScreen />
      );
    case "timeFormat":
      return isMobile ? (
        <PreferenceScreen preference="timeFormat" />
      ) : (
        <DisplayScreen />
      );
    case "manageProfile":
      return <ManageProfileScreen />;
    case "debug":
      return <DebugScreen />;
    default:
      return <MainUserPreferencesScreen />;
  }
}

function DisplayScreen() {
  return (
    <PreferenceScreenContainer>
      <PreferenceScreen preference="distance" />
      <PreferenceScreen preference="timeFormat" />
    </PreferenceScreenContainer>
  );
}

function MainUserPreferencesScreen() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const theme = useTheme();
  const [ccpaStatus, handleCcpaLinkClick] = useCcpaManagement();

  function openCurrencyScreen() {
    sendAnalyticsInteractionEvent("Preferences", "Click:EditCurrency");
    navigateToNewState(navigate, { preferencesScreen: "currency" }, location);
  }

  function openLanguageScreen() {
    sendAnalyticsInteractionEvent("Preferences", "Click:EditLanguage");
    navigateToNewState(navigate, { preferencesScreen: "language" }, location);
  }

  function openDistanceScreen() {
    sendAnalyticsInteractionEvent("Preferences", "Click:EditDistance");
    navigateToNewState(navigate, { preferencesScreen: "distance" }, location);
  }

  function openTimeFormatScreen() {
    sendAnalyticsInteractionEvent(
      "Preferences",
      "Click:EditTimeFormatDistance"
    );
    navigateToNewState(navigate, { preferencesScreen: "timeFormat" }, location);
  }

  function openDebugScreen() {
    navigateToNewState(navigate, { preferencesScreen: "debug" }, location);
  }

  function openGDPRConsentScreen() {
    sendAnalyticsInteractionEvent("Preferences", "Click:ManageConsent", "gdpr");
    window.googlefc?.callbackQueue?.push(window.googlefc.showRevocationMessage);
  }

  function openUsPrivacyConsentScreen() {
    sendAnalyticsInteractionEvent(
      "Preferences",
      "Click:ManageConsent",
      "usPrivacy"
    );
    handleCcpaLinkClick();
  }

  return (
    <>
      {layout === "mobile" && (
        <>
          <Navbar headingText={intl.formatMessage(messages.pageTitleProfile)} />
          <UserAccountsContainer>
            <Rome2rioContainer>
              <Rome2rioLogo
                title="logo"
                tint={theme.topNav.iconTint}
                dotTint="pink"
              />
            </Rome2rioContainer>
            <UserAccounts />
          </UserAccountsContainer>
        </>
      )}
      <UserPreferences
        onLanguageClick={openLanguageScreen}
        onDistanceClick={openDistanceScreen}
        onCurrencyClick={openCurrencyScreen}
        onTimeFormatClick={openTimeFormatScreen}
        onDebugClick={openDebugScreen}
        onGDPRConsentClick={openGDPRConsentScreen}
        onUsPrivacyConsentClick={openUsPrivacyConsentScreen}
        ccpaStatus={ccpaStatus}
      />
    </>
  );
}

const UserAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // Spacing
  margin-bottom: ${spacing.md};
  margin: 0 ${spacing.xl};
`;
const Rome2rioContainer = styled.div`
  margin-bottom: ${spacing.xxxl};
  margin-top: ${spacing.xxxl};
  display: flex;
  width: 160px;
`;

const PreferenceScreenContainer = styled.div`
  ${largeDesktopLayout} {
    height: 344px;
  }
`;
