import type { GeocodedPlace } from "src/PrefetchData";
import type { TripPlannerTransportKey } from "../TripPlannerProvider";

export function transportKeyHasPlacePair(
  places: GeocodedPlace[],
  pair: TripPlannerTransportKey
) {
  const [origin, destination] = pair.split("_");
  return places.some(
    (place, index, places) =>
      index < places.length - 1 &&
      place.canonicalName === origin &&
      places[index + 1].canonicalName === destination
  );
}
