import { type SvgProps, useColor } from "./common";

export function ChevronRight(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{props.title}</title>
      <path
        d="M9.75 30l12.5-14.046L9.75 2"
        stroke={tintColor}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
