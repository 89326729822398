import { type SvgProps, useColor } from "./common";

export function CloseCircle(props: SvgProps) {
  const tintColor = useColor(props.tint);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{props.title}</title>
      <circle
        cx="16"
        cy="16"
        r="13"
        transform="rotate(90 16 16)"
        fill={tintColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.776 20.822a1 1 0 101.415 1.412l4.367-4.375.443-.444 4.82 4.814a1 1 0 101.413-1.415L17.414 16l4.811-4.819a1 1 0 10-1.415-1.413L16 14.586l-.443-.441-4.376-4.368a1 1 0 10-1.412 1.416l4.375 4.367.442.442-.444.444-4.366 4.375z"
        fill="#FFF"
      />
    </svg>
  );
}
