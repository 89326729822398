export function removeDuplicateConsequential<T>(list: T[], prop: keyof T): T[] {
  let firstValidItem: T | undefined = undefined;

  const filteredList: T[] = list.filter((item, index, array) => {
    if (!item) {
      return false;
    }
    if (item[prop] === "" || item[prop] === undefined || item[prop] === null) {
      return false;
    }

    // Is this the first valid item? Keep it for comparison.
    if (firstValidItem === undefined) {
      firstValidItem = item;
      return true;
    }

    const previousItem = array[index - 1];
    const previousItemName = previousItem[prop];
    const currentItemName = item[prop];

    return previousItemName !== currentItemName;
  });
  return filteredList;
}
