import ReactPixel, { grantConsent, revokeConsent } from "react-facebook-pixel";

export const META_PIXEL_ID = "287459611096884";

// For more information on the purposes, see: https://iabeurope.eu/iab-europe-transparency-consent-framework-policies/#:~:text=Appendix%20A%3A%20Definitions,or%20access%20information
export const META_CONSENT_PURPOSES_REQUIRED = [1, 3, 5, 7, 10];
export const META_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];
export const META_SALE_CONSENT_REQUIRED = true;
export const META_SHARING_CONSENT_REQUIRED = true;

const options = {
  autoConfig: false, // Do not send additional information. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

let hasInitialized = false;

export const metaPixel = {
  initPixel(): void {
    if (hasInitialized) {
      return;
    }
    ReactPixel.init(META_PIXEL_ID, undefined, options);
    ReactPixel.pageView();
    hasInitialized = true;
  },
  grantMetaConsent(): void {
    grantConsent();
  },
  revokeMetaConsent(): void {
    revokeConsent();
  },
};
