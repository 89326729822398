import useUser from "../../../utils/hooks/useUser";
import { isContentTest } from "../../../utils/host";
import type { FeatureConfig } from "../../FeatureConfig";

// This is an escape-hatch for if you ever need to use custom logic for Feature
// enabling/disabling features.
// This overrides any backend experiments or default values.
export function useCustomFeatureConfig(): Partial<FeatureConfig> {
  const features: Partial<FeatureConfig> = {};

  // If the host is content-test, enable debug
  if (isContentTest()) {
    features.Debug = true;
  }

  const { user } = useUser();
  const isRome2RioUser = user?.emails?.find((entry) =>
    entry.email.includes("@rome2rio.com")
  );
  if (isRome2RioUser) {
    features.Debug = true;
  }

  return features;
}
