import type { HTMLAttributes, PropsWithChildren } from "react";
import { primitive } from "src/design-system/tokens/color";
import { color, fontSize, fontWeight, lineHeight, spacing } from "src/theme";
import styled from "styled-components";

export const placeNumberOffsetPx = 16;

type TripPlannerPlaceTitleProps = {
  index: number;
};

export function TripPlannerPlaceTitle({
  index,
  children,
  ...other
}: PropsWithChildren<TripPlannerPlaceTitleProps> &
  HTMLAttributes<HTMLDivElement>) {
  return (
    <TripPlannerPlaceTitleWrapper {...other}>
      <PlaceNumberMobile>{index + 1}</PlaceNumberMobile>
      <SubtitleTripPlanner>{children}</SubtitleTripPlanner>
    </TripPlannerPlaceTitleWrapper>
  );
}

const TripPlannerPlaceTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const PlaceNumber = styled.span`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.snug};
  border-radius: 50%;
  width: ${placeNumberOffsetPx * 2}px;
  height: ${placeNumberOffsetPx * 2}px;
  background-color: ${color.white};
  border: 1px solid ${primitive.neutral_900};
  color: ${color.black};
  opacity: 1;
`;

const PlaceNumberMobile = styled(PlaceNumber)`
  margin: 0 ${spacing.md};
  z-index: 1;
`;

const SubtitleTripPlanner = styled.span`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h4};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
  margin-top: calc(${placeNumberOffsetPx / 2}px - 0.15em);
`;
