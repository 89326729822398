import { useCallback, useState } from "react";
import type { SearchResponse, TicketLink } from "../api/SearchResponse";
import {
  type ExitPointDescription,
  generateExitPoint,
} from "../analytics/generateExitPoint/generateExitPoint";
import { useTravelSearchSettings } from "../domain/SegmentScreen/TravelSearchSettingsProvider";
import { appendInitialReferrer } from "../analytics/appendInitialReferrer/appendInitialReferrer";
import {
  getStaticSchedulesPassengerDetails,
  usePassengerDetailsContext,
} from "../PassengerDetailsProvider";
import useSearch from "./hooks/useSearch";
import { getTicketsUrlFromTemplate } from "./url";
import {
  dateFromIsoDateTime,
  formatIsoDate,
  formatIsoDateTime,
  formatIsoTime,
  localDateObj,
  nowInUtcOffset,
  timeFromIsoDateTime,
} from "./dateTime";
import { ticketMinDate } from "./adapters/ticketInfo";
import {
  originUtcOffsetFromRoute,
  originUtcOffsetFromSegment,
} from "./adapters/utcOffset";
import { getTicketLinks } from "./getTicketLinks";
import { getTicketLinkProviders } from "./getTicketLinkProviders";
import { useIsRouteSkipped } from "./hooks/useIsRouteSkipped";
import { useDefaultDateTime } from "./useDefaultDateTime";

export type TicketFormState = {
  exitUrl: string;
  exitProvider?: string;
  picker: {
    tripType: "oneway" | "return";
    outboundDate: Date;
    returnDate?: Date;
    minOutboundDate: Date;
    minReturnDate: Date;
    nowAtOrigin: Date;
    setOutboundDate: (outboundDate: Date) => void;
    setReturnDate: (returnDate?: Date) => void;
    setTripType: (tripType: TicketFormState["picker"]["tripType"]) => void;
    maxDaysAhead?: number;
    originTimezoneOffset?: number;
  };
};

export function useTicketForm({
  routeIndex,
  segmentIndexOfRoute,
  exitPoint,
}: {
  routeIndex: number;
  segmentIndexOfRoute?: number;
  exitPoint: ExitPointDescription;
}): TicketFormState | undefined {
  const { searchResponse } = useSearch();
  const { dateTimeObject, setDateTimeObject } = useTravelSearchSettings();
  // Limit Multi Passengers to bookable ground transport only.
  const isUsingMultiPassengers = exitPoint.transitMode !== "plane";
  // Use the booking info for the entire route when we're skipping the route screen,
  // this can be achieved by unsetting the segment index.
  const isRouteSkipped = useIsRouteSkipped(routeIndex);
  const segmentIndex = isRouteSkipped ? undefined : segmentIndexOfRoute;

  const maxDaysAhead =
    searchResponse?.routes[routeIndex].bookingInfo?.configuration?.maxDaysAhead;

  const originUtcOffset = searchResponse
    ? getUtcOriginOffset(searchResponse, routeIndex, segmentIndex)
    : 0;

  const ticketLink = getTicketLinks(
    searchResponse,
    routeIndex,
    segmentIndex
  )[0] as TicketLink | undefined;

  const ticketLinkProvider = getTicketLinkProviders(
    searchResponse,
    routeIndex,
    segmentIndex
  )[0];

  const appropriateInitialDateTime = useDefaultDateTime(originUtcOffset);

  const syncedTicketsDate = dateTimeObject.departureDate
    ? dateTimeObject.departureDate
    : dateFromIsoDateTime(formatIsoDateTime(appropriateInitialDateTime));
  const syncedTicketsTime = dateTimeObject.time
    ? dateTimeObject.time
    : timeFromIsoDateTime(formatIsoDateTime(appropriateInitialDateTime));
  const syncedTicketsDateTimeString = `${syncedTicketsDate}T${syncedTicketsTime}`;
  const syncedTicketDateTime = localDateObj(syncedTicketsDateTimeString);

  const minimumDate = ticketMinDate(originUtcOffset, ticketLink?.pickerConfig);

  let initialDateTime = syncedTicketDateTime;
  let initialReturnDateTime = dateTimeObject.returnDate
    ? localDateObj(dateTimeObject.returnDate)
    : undefined;

  if (initialDateTime < minimumDate) {
    // Clamp initialDateTime to the minimumDate so that the initialDateTime is
    // always valid.
    initialDateTime = minimumDate;
  }

  const [outboundDate, setOutboundDateState] = useState<Date>(initialDateTime);
  const [returnDate, setReturnDateState] = useState<Date | undefined>(
    initialReturnDateTime
  );
  const [tripType, setTripTypeState] =
    useState<TicketFormState["picker"]["tripType"]>("oneway");

  const setTripType = useCallback(
    (tripType: TicketFormState["picker"]["tripType"]) => {
      if (tripType === "oneway") {
        // If you set the trip type to oneway, remove the return date.
        setReturnDateState(undefined);
      }
      setTripTypeState(tripType);
    },
    [setTripTypeState, setReturnDateState]
  );

  const setReturnDate = useCallback(
    (date?: Date) => {
      // A side-effect of setting the return date is that we also switch the trip
      // type to "return".
      setTripTypeState(date ? "return" : "oneway");
      setReturnDateState(date);
    },
    [setReturnDateState, setTripTypeState]
  );

  const setOutboundDate = useCallback(
    (date: Date) => {
      if (returnDate && formatIsoDate(date) > formatIsoDate(returnDate)) {
        setReturnDate(undefined);
      }
      setDateTimeObject({
        departureDate: formatIsoDate(date),
        time: formatIsoTime(date),
      });
      setOutboundDateState(date);
    },
    [setOutboundDateState, returnDate, setReturnDate, setDateTimeObject]
  );

  const { passengerDetails } = usePassengerDetailsContext();

  if (!ticketLink) {
    return undefined;
  }

  const now = nowInUtcOffset(originUtcOffset);

  const ticketUrl = getTicketsUrlFromTemplate(
    ticketLink.url,
    ticketLink.templatedParameters,
    generateExitPoint(exitPoint.view, exitPoint.trigger, exitPoint.transitMode),
    outboundDate ? formatIsoDate(outboundDate) : undefined,
    returnDate ? formatIsoDate(returnDate) : undefined,
    isUsingMultiPassengers
      ? getStaticSchedulesPassengerDetails(passengerDetails)
      : undefined
  );

  const ticketUrlWithInitialReferrer = appendInitialReferrer(ticketUrl);

  return {
    exitUrl: ticketUrlWithInitialReferrer,
    exitProvider: ticketLinkProvider,
    picker: {
      tripType,
      setTripType,
      setOutboundDate,
      setReturnDate,
      minOutboundDate: minimumDate,
      minReturnDate: outboundDate,
      nowAtOrigin: now,
      outboundDate,
      returnDate,
      maxDaysAhead,
      originTimezoneOffset: originUtcOffset,
    },
  };
}

export function getUtcOriginOffset(
  response: SearchResponse,
  routeIndex: number,
  segmentIndexOfRoute?: number
) {
  if (segmentIndexOfRoute === undefined) {
    return originUtcOffsetFromRoute(response, routeIndex);
  } else {
    return originUtcOffsetFromSegment(response, segmentIndexOfRoute);
  }
}
