import type { Place, Route, Segment } from "../../api/SearchResponse";
import type { FeatureConfig } from "../../feature/FeatureConfig";
import {
  type TicketInfo,
  ticketInfoFromLinks,
} from "../../utils/adapters/ticketInfo";

export type ScheduleCapabilities = Readonly<{
  /**
   * isSchedulable means that a route/segment is able to lookup schedules using the schedules endpoint for that transit mode
   * includes Flights.
   */
  isSchedulable: boolean;

  /**
   * isDeeplinkable means that the route/segment has an exit link that can be used to search for tickets
   * guarantee's that data.ticketInfo is valid.
   */
  isDeeplinkable: boolean;

  /**
   * isBookable means that the route/segment can be searched using the /ticketableSchedule endpoint, and receive live schedules
   * can include LiveFlights.
   */
  isBookable: boolean;

  /**
   * Indicates that this is a flight that can to be scheduled.
   * Schedulable Flights often use a different UI, so this is a quick way to check that in components.
   */
  isSchedulableFlight: boolean;

  /**
   * This is a flight with the LiveSchedulesFeature enabled, that can get live flights.
   */
  isBookableFlight: boolean;

  /**
   * Indicates that the static schedules used for this should be the connected schedules API & data.
   */
  staticConnectedSchedules: boolean;

  /**
   * Data to be used when creating schedule requests.
   */
  data: {
    scheduleSearchData?: string;
    connectedScheduleData?: string;
    ticketInfo?: TicketInfo;
    originCode?: string;
    destinationCode?: string;
  };
}>;

export function isSchedulableNotBookableFlight(
  capabilities: ScheduleCapabilities
): boolean {
  return capabilities.isSchedulableFlight && !capabilities.isBookableFlight;
}

export function getCapabilities(
  routeOrSegment: Route | Segment,
  isSegment: boolean,
  originUtcOffset: number,
  isFlight: boolean,
  forceNotBookable: boolean,
  originPlace: Place,
  destinationPlace: Place,
  hasFirstOrLastWalkingSegment: boolean,
  useConnectedSchedules: FeatureConfig["UseStaticConnectedSchedules"]
): ScheduleCapabilities {
  const searchData = routeOrSegment.scheduleInfo?.data;
  const ticketInfo = ticketInfoFromLinks(routeOrSegment.links, originUtcOffset);

  // Any flight segment with a valid origin/destination code is able to be schedule searched.
  const isSchedulableFlight =
    isFlight &&
    originPlace.code !== undefined &&
    destinationPlace.code !== undefined;

  const isSchedulable = !!routeOrSegment.scheduleInfo || isSchedulableFlight;
  const isDeeplinkable = !!ticketInfo;

  const isBookableFlight = isSchedulableFlight;

  const isBookable =
    !forceNotBookable && (!!routeOrSegment.bookingInfo || isBookableFlight);

  const route = isSegment ? undefined : (routeOrSegment as Route);

  let staticConnectedSchedules = false;

  // Check for connected schedules are available for this route,
  // then check 'useConnectedSchedules' feature setting
  if (
    route?.routeData !== undefined &&
    useConnectedSchedules !== "never" &&
    !isFlight
  ) {
    switch (useConnectedSchedules) {
      case "FirstOrLastWalkingSegmentRoutes":
        staticConnectedSchedules = hasFirstOrLastWalkingSegment;
        break;
      case "FullyBookableRoutesFallback":
        staticConnectedSchedules = isBookable;
        break;
      case "FullyScheduleableNonBookableRoutes":
        staticConnectedSchedules = isSchedulable && !isBookable;
        break;
    }
  }

  let scheduleSearchData;

  if (isSchedulable) {
    scheduleSearchData = searchData;
  } else if (isBookable) {
    scheduleSearchData = routeOrSegment?.bookingInfo?.data;
  }

  return {
    isSchedulable,
    isDeeplinkable,
    isBookable,
    isSchedulableFlight,
    isBookableFlight,
    staticConnectedSchedules,

    // Data will only be filled if the capabilities require it.
    data: {
      scheduleSearchData,
      connectedScheduleData: staticConnectedSchedules
        ? route?.routeData
        : undefined,
      ticketInfo: isDeeplinkable ? ticketInfo : undefined,
      originCode: isSchedulableFlight ? originPlace.code : undefined,
      destinationCode: isSchedulableFlight ? destinationPlace.code : undefined,
    },
  };
}
