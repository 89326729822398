import { type SvgProps, useColor } from "../common";

export function Train(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fill={tintColor}
        d="M17.23 21H19l-3.147-4.492a2.456 2.456 0 001.5-.8c.4-.44.599-.962.599-1.567V5.366c0-.65-.24-1.206-.723-1.67C16.743 3.23 16.152 3 15.455 3H8.544c-.702 0-1.29.231-1.773.695-.483.465-.724 1.022-.724 1.671v8.775c0 .605.205 1.132.616 1.585.41.452.904.712 1.484.782L5 21h1.77l.302-.431h9.853l.304.431zm-1.053-5.954c-.214.208-.494.314-.83.314-.314 0-.58-.106-.796-.314a1.065 1.065 0 01-.326-.8c0-.3.11-.561.326-.783.216-.22.482-.332.796-.332.336 0 .616.112.83.332.217.222.326.482.326.783 0 .325-.11.59-.326.8zM7.57 5.588c0-.325.11-.592.326-.802.214-.21.492-.313.83-.313h6.548c.34 0 .622.105.85.313.232.21.347.477.347.802v2.808c0 .326-.115.592-.346.802a1.21 1.21 0 01-.85.31h-6.55c-.337 0-.615-.102-.83-.31a1.068 1.068 0 01-.325-.803V5.588zm.252 9.458a1.055 1.055 0 01-.326-.8c0-.3.107-.561.326-.783.22-.22.495-.332.833-.332.312 0 .578.112.796.332.217.222.327.482.327.783 0 .326-.11.59-.327.8a1.116 1.116 0 01-.796.314c-.338 0-.614-.106-.833-.314zm.266 4.071l.821-1.18h6.186l.824 1.18h-7.83z"
      />
    </svg>
  );
}
