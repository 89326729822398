import { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Icon } from "../../components/Icon/Icon";
import { ChevronDown } from "../../svg/ChevronDown";
import { Close } from "../../svg/Close";
import { spacing } from "../../theme";

import {
  makeBackendFeatureFlag,
  parseBackendFeature,
  useBackendCookieFeatures,
} from "../../utils/hooks/useBackendFeatures";
import { useLayout } from "../../utils/hooks/useLayout";
import { useTheme } from "../../utils/hooks/useTheme";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { searchParamsChange } from "../../utils/location/searchParamsChange";
import { FeatureItem } from "./DebugItem";

export function BackendFeatureSelector() {
  const backendFeatureCookie = useBackendCookieFeatures();
  const theme = useTheme();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const layout = useLayout();

  const setbackendFeaturesParam = (
    featureName: string,
    value: boolean | undefined
  ) => {
    // We can safely overwrite the backendFeatures flag, because any existing values have already been saved to the cookie
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(
      "backendFeatures",
      makeBackendFeatureFlag(featureName, value)
    );

    /** Without this, on select of a feature, the screen will update to search.
     *  We also don't want to force this on desktop, as it updates the pane on
     *  the left.
     *  */
    if (layout === "mobile") {
      navigate(searchParamsChange(searchParams, location), {
        replace: true,
        state: {
          highlightedTab: "preferences",
          preferencesScreen: "debug",
        },
      });
    } else {
      navigate(searchParamsChange(searchParams, location), {
        replace: true,
      });
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    if (isExpanded) setIsExpanded(false);
    else setIsExpanded(true);
  };

  return (
    <>
      <FeatureItem onClick={toggleExpand} pointer={true}>
        <h3>Backend Feature Cookie</h3>
        <StyledChevronIcon pointUp={isExpanded} size="sm">
          <ChevronDown tint={theme.topNav.iconTint} />
        </StyledChevronIcon>
      </FeatureItem>

      {isExpanded &&
        backendFeatureCookie.map((backendFeature) => {
          const { featureName, value } = parseBackendFeature(backendFeature);

          // Don't show any dynamic-set features, because we don't know what the actual value is
          if (value === undefined) return <></>;

          const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked;
            setbackendFeaturesParam(featureName, checked);
          };

          const removeBackendFeature = () => {
            setbackendFeaturesParam(featureName, undefined);
          };

          return (
            <FeatureItem key={featureName}>
              <FeatureName>{featureName}</FeatureName>
              <StyledCheckbox
                type="checkbox"
                defaultChecked={value}
                onChange={onCheckboxChange}
              />
              <RemoveFeatureButton onClick={removeBackendFeature}>
                <Icon size="xs">
                  <Close tint="red" title="Remove Feature from Cookie" />
                </Icon>
              </RemoveFeatureButton>
            </FeatureItem>
          );
        })}
    </>
  );
}

const FeatureName = styled.div`
  flex-grow: 2;
`;

const StyledCheckbox = styled.input`
  width: 18px;
  height: 18px;
`;

const RemoveFeatureButton = styled.div`
  cursor: pointer;
  padding-left: ${spacing.md};
`;

const StyledChevronIcon = styled(Icon)<{
  pointUp?: boolean;
}>`
  margin-left: ${spacing.md};

  ${(props) => {
    if (props.pointUp) {
      return "transform: rotate(180deg);";
    }
  }}
`;
