import { useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { ConfirmationModal } from "src/components/ConfirmationModal/ConfirmationModal";
import { Trash } from "src/svg/Trash";
import styled from "styled-components";
import { useScreenSize } from "src/utils/hooks/useScreenSize";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Input/Input";
import { Toggle } from "../../../components/Toggle/Toggle";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import { desktopLayout } from "../../../utils/hooks/useLayout";
import type { UserProfile } from "../../utils/user";
import { messages } from "./ManageProfile.messages";
import { useManageProfile } from "./useManageProfile";

type Props = {
  user: UserProfile;
};

export function ManageProfile({ user }: Props) {
  const {
    name,
    email,
    toggle,
    handleNameChange,
    handleEmailChange,
    handleOnChangeToggle,
    handleUpdate,
    disabled,
    handleDeleteProfile,
  } = useManageProfile(user);

  const intl = useIntl();
  const isSmall = useScreenSize() === "small";

  return (
    <Container>
      <InputsContainer>
        <InputContainer>
          <StyledInput
            id="manage-profile-name"
            name="manage-profile-name"
            type="text"
            label={intl.formatMessage(messages.name)}
            value={name}
            onChange={(event) => handleNameChange(event.currentTarget.value)}
            autoComplete="name"
            inputMode="text"
            required
            maxLength={100}
          />
        </InputContainer>
        <InputContainer>
          <StyledInput
            id="manage-profile-email"
            name="manage-profile-email"
            type="email"
            label={intl.formatMessage(messages.email)}
            value={email}
            onChange={(event) => handleEmailChange(event.currentTarget.value)}
            autoComplete="email"
            inputMode="email"
            required
            disabled
          />
        </InputContainer>
      </InputsContainer>
      <ToggleContainer>
        <Toggle
          label="email-marketing"
          selected={toggle}
          setSelected={handleOnChangeToggle}
        />
        <ToggleLabel htmlFor="email-marketing-toggle">
          {intl.formatMessage(messages.emailMarketingLabel)}
        </ToggleLabel>
      </ToggleContainer>
      <UpdateButton
        backgroundColor="pink"
        textColor="primaryOnDark"
        inline={!isSmall}
        onClick={handleUpdate}
        type="submit"
        disabled={disabled}
      >
        {intl.formatMessage(messages.update)}
      </UpdateButton>
      <DeleteProfileButton
        email={email}
        onDeleteProfile={handleDeleteProfile}
      />
    </Container>
  );
}

type DeleteProfileButtonProps = {
  email: string;
  onDeleteProfile: () => void;
};
function DeleteProfileButton({
  email,
  onDeleteProfile,
}: DeleteProfileButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  return (
    <>
      <TextButton
        backgroundColor="transparent"
        textColor="primaryOnLight"
        inline
        onClick={() => {
          sendAnalyticsInteractionEvent("UserProfile", "Click:DeleteProfile");
          setShowModal(true);
        }}
        type="button"
        leftIcon={<Trash tint="n80" />}
        size="small"
      >
        {intl.formatMessage(messages.deleteProfileButtonText)}
      </TextButton>
      <ConfirmationModal
        showModal={showModal}
        onCloseModal={() => {
          sendAnalyticsInteractionEvent(
            "DeleteProfileConfirmatiom",
            "Cancel:DeleteProfile"
          );
          setShowModal(false);
        }}
        title={intl.formatMessage(messages.deleteProfileTitle)}
        description={intl.formatMessage(messages.deleteProfileDescription, {
          br: <LineBreak />,
        })}
        confirmButtonText={intl.formatMessage(
          messages.deleteProfileConfirmButtonText
        )}
        cancelButtonText={intl.formatMessage(messages.cancel)}
        onConfirm={() => {
          sendAnalyticsInteractionEvent(
            "DeleteProfileConfirmatiom",
            "Confirm:DeleteProfile"
          );
          onDeleteProfile();
        }}
        question={{
          title: intl.formatMessage(messages.primaryEmail),
          correctAnswer: email,
        }}
        $height="512px"
      />
    </>
  );
}

const Container = styled.form`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  ${desktopLayout} {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
`;
const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: ${spacing.xxxl};
  margin-bottom: 40px;
`;
const InputContainer = styled.div`
  width: 100%;
`;
const StyledInput = styled(Input)``;

const ToggleContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 56px;
  ${desktopLayout} {
    margin-bottom: ${spacing.xxl};
  }
  span {
    flex-shrink: 0;
  }
`;
const ToggleLabel = styled.label`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.normal};
  margin-left: ${spacing.xxl};

  color: ${color.black};
`;

const TextButton = styled(Button)`
  margin-top: ${spacing.xxxl};
  text-decoration: underline;
  span {
    font-weight: ${fontWeight.normal};
    font-size: ${fontSize.body};
    line-height: ${lineHeight.tight};
    color: ${color.n300};
  }

  ${desktopLayout} {
    margin-top: auto;
  }
`;

const UpdateButton = styled(Button)`
  align-self: flex-end;
  min-width: 200px;
`;

// The design includes a line break in the middle of the description
// so we need to use a styled component to render it
const LineBreak = styled.br`
  display: block;
  margin-bottom: ${spacing.xl};
  content: "";
`;
