type MockKeys = "auth" | "trip";

export function getRequestedMocks(): MockKeys[] {
  const currentUrl = new URL(window.location.href);
  const enabledMocks = currentUrl.searchParams.get("enabledRequestMocks");
  return (enabledMocks ? enabledMocks.split(",") : []) as MockKeys[];
}

export function enableRequestMock(mock: MockKeys) {
  const currentUrl = new URL(window.location.href);
  const enabledMocksArray = getRequestedMocks();
  enabledMocksArray.push(mock);
  currentUrl.searchParams.set(
    "enabledRequestMocks",
    enabledMocksArray.join(",")
  );

  window.location.href = decodeURIComponent(currentUrl.href);
}

export function disableRequestMock(mock: MockKeys) {
  const currentUrl = new URL(window.location.href);
  const enabledMocksArray = getRequestedMocks();
  const index = enabledMocksArray.indexOf(mock);
  if (index > -1) {
    enabledMocksArray.splice(index, 1);
  }

  if (enabledMocksArray.length === 0) {
    currentUrl.searchParams.delete("enabledRequestMocks");
  } else {
    currentUrl.searchParams.set(
      "enabledRequestMocks",
      enabledMocksArray.join(",")
    );
  }

  window.location.href = decodeURIComponent(currentUrl.href);
}
