import { type QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet } from "react-router";
import { useSearchParams } from "react-router-dom";
import { L10nProvider } from "src/L10nProvider";
import UserProvider from "src/UserProvider";
import { ApiConfigProvider } from "src/api/ApiConfigProvider";
import { AuthProvider } from "src/auth/contexts/AuthContext";
import { DefaultErrorBoundary } from "src/components/DefaultErrorBoundary/DefaultErrorBoundary";
import { GlobalStyle } from "src/components/GlobalStyle/GlobalStyle";
import { Theme } from "src/components/Theme/Theme";
import { FeatureProvider } from "src/feature/FeatureProvider";
import { getCountryCode } from "src/utils/countryCode";
import {
  getCurrencyCodeFromCookie,
  type SupportedCurrencyCode,
} from "src/utils/currency";
import { getDistanceSystemFromCookie } from "src/utils/distanceSystem";
import { getInitialTimeFormatCookieValue } from "src/utils/timeFormat";
import {
  countryCodeToCurrencyCode,
  countryCodeToDistanceSystem,
  countryCodeToPrefers12Hour,
} from "src/utils/userPreferences";

export function ProviderLayout({ queryClient }: { queryClient: QueryClient }) {
  const [searchParams] = useSearchParams();

  // Load preferences from query params, for easy testing of different preferences.
  let prefers12Hour;
  let currencyCode;
  let distanceSystem;
  if (import.meta.env.VITE_ENVIRONMENT !== "Production") {
    prefers12Hour = searchParams.has("12hr")
      ? searchParams.get("12hr") === "true"
      : undefined;
    currencyCode = searchParams.get("currency") as SupportedCurrencyCode;

    const distanceParamMap = { km: "metric", mi: "imperial" } as const;
    const distanceParam = searchParams.get("distance");
    distanceSystem =
      distanceParam === "km" || distanceParam === "mi"
        ? distanceParamMap[distanceParam]
        : undefined;
  }

  return (
    <L10nProvider>
      <ApiConfigProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserProvider
              initialPrefers12Hour={
                getInitialTimeFormatCookieValue() ??
                prefers12Hour ??
                countryCodeToPrefers12Hour(getCountryCode())
              }
              initialCurrencyCode={
                getCurrencyCodeFromCookie() ??
                currencyCode ??
                countryCodeToCurrencyCode(getCountryCode())
              }
              initialDistanceSystem={
                getDistanceSystemFromCookie() ??
                distanceSystem ??
                countryCodeToDistanceSystem(getCountryCode())
              }
            >
              <FeatureProvider>
                <Theme>
                  <DefaultErrorBoundary defaultMessage>
                    <GlobalStyle />
                    <Outlet />
                  </DefaultErrorBoundary>
                </Theme>
              </FeatureProvider>
            </UserProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ApiConfigProvider>
    </L10nProvider>
  );
}
