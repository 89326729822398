import logError from "src/utils/logError";
import { useApiConfig } from "../../api/ApiConfigProvider";
import useUser from "../../utils/hooks/useUser";
import { type AuthType, createSession } from "../utils/session";
import type { UserProfileUpdateValues } from "../utils/user";

export function useHandleCreateSession(
  provider: AuthType
): (
  token: string,
  defaultUserProfile?: UserProfileUpdateValues,
  otpMode?: boolean
) => Promise<boolean> {
  const apiConfig = useApiConfig();
  const {
    setUserAndSyncPreferences,
    setLoadingSession,
    distanceSystem,
    currencyCode,
    language,
    prefers12Hour,
  } = useUser();

  // We want to include the current user preferences in the default profile value.
  // This is used for new user accounts, so the user will keep their current settings instead of being changed to the default.
  const existingUserPreferences: UserProfileUpdateValues = {
    profileLanguage: language,
    profileCurrency: currencyCode,
    isKilometers: distanceSystem === "metric",
    is24h: !prefers12Hour,
  };

  return async function (
    token: string,
    providerDefaultUserProfile?: UserProfileUpdateValues,
    otpMode?: boolean
  ) {
    if (!otpMode) setLoadingSession(true);
    let success = false;
    try {
      const user = await createSession(
        token,
        provider,
        { apiConfig },
        // The provider might have some default user profile values to use to sign the user up (usually name, etc)
        // Use these preferentially to the existing user preferences
        { ...existingUserPreferences, ...providerDefaultUserProfile }
      );

      if (!user) {
        throw new Error("No user returned from createSession");
      }

      success = true;
      setUserAndSyncPreferences(user);
    } catch (error) {
      if (!otpMode) {
        logError(error, ["user-accounts"]);
      }
    } finally {
      if (!otpMode) setLoadingSession(false);
    }
    return success;
  };
}
