import type React from "react";
import { type ElementType, forwardRef } from "react";
import styled from "styled-components";
import { color } from "../../theme";
import {
  type OutlineButtonProps,
  OutlineButton,
} from "../OutlineButton/OutlineButton";

// In order to reuse this component as an anchor or as a button
type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>["ref"];

function AccessibilityLinkInner(
  props: OutlineButtonProps,
  ref: PolymorphicRef<ElementType>
) {
  const { children, ...otherProps } = props;

  return (
    <StyledButton ref={ref} {...otherProps}>
      {children}
    </StyledButton>
  );
}

export const AccessibilityLink = forwardRef(AccessibilityLinkInner);

const StyledButton = styled(OutlineButton)`
  position: absolute;
  left: 6%;
  top: 9px;

  width: 250px;

  background-color: ${color.white};

  &:not(:focus) {
    clip: rect(0px, 0px, 0px, 0px);
    overflow: hidden;
    position: absolute;
    padding: 0;
  }
`;
