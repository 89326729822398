import { useCallback, useEffect } from "react";
import { AnyTripCard } from "src/components/TripPlanner/TripCard/AnyTripCard";

import { useNavigate } from "react-router";
import { SearchResultListCardSkeleton } from "src/components/TripPlanner/TripCard/SearchResultListCard/SearchResultListCardSkeleton";
import SimpleRouteCardLoading from "src/components/TripPlanner/TripCard/SimpleRouteCard/SimpleRouteCardLoading";
import { border_radius } from "src/design-system/tokens/border";

import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import type { TripPlannerDetails } from "../TripPlannerProvider";
import { createTransportKey } from "../util/createTransportKey";
import {
  createTripCardProperties,
  getCardCallback,
} from "./createTripCardProperties";
import ErrorMessage from "./ErrorMessage";

type TripPlannerTravelBlockProps = {
  origin: TripPlannerDetails["places"][number];
  destination: TripPlannerDetails["places"][number];
  index: number;
  isDragging?: boolean;
  isDraggable?: boolean;
  isPendingChanges?: boolean;
};

export function TripPlannerTravelBlock(props: TripPlannerTravelBlockProps) {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { tripPlannerDetails, tripRoutes, tripPlanningState, isMultiTrip } =
    useTripPlannerContext();
  const layout = useLayout();

  const transportKey = createTransportKey(
    props.origin.canonicalName,
    props.destination.canonicalName
  );

  const tripPlannerEntry = tripPlannerDetails.transport[transportKey];
  const staticSearchResponse = tripPlannerEntry?.searchResponse;
  const searchResponse = tripRoutes.queries[props.index];

  useEffect(() => {
    if (
      !staticSearchResponse &&
      !searchResponse.isLoading &&
      !searchResponse.data
    ) {
      tripRoutes.setActiveQueries({
        index: props.index,
        value: true,
      });
    }
  }, [
    staticSearchResponse,
    tripRoutes,
    props.index,
    searchResponse.isLoading,
    searchResponse.data,
  ]);

  const getCardProperties = useCallback(
    () =>
      createTripCardProperties(
        searchResponse.data,
        tripPlannerEntry,
        isMultiTrip || layout === "mobile"
      ),
    [tripPlannerEntry, searchResponse, isMultiTrip, layout]
  );

  const isNoRoutes = searchResponse.data?.routes.length === 0;

  if (isNoRoutes) {
    return <ErrorMessage />;
  }

  const cardProperties = getCardProperties();

  if (!isMultiTrip && !cardProperties) {
    return <SearchResultListCardSkeleton />;
  }

  if (!cardProperties) {
    return <SimpleRouteCardLoading />;
  }

  if (cardProperties && tripPlanningState.updatedPlace.value === props.index) {
    return <SimpleRouteCardLoading />;
  }

  function cardCallback() {
    navigateToNewStateHash(
      navigate,
      { highlightedTab: "trips" },
      tripHashFromSearchResponse(searchResponse.data),
      {
        ...location,
      }
    );
  }

  const callback = getCardCallback(cardCallback, cardProperties, props.index);

  return (
    <TravelBlockWrapper data-testid={`${cardProperties.type}-card`}>
      <AnyTripCard
        {...cardProperties}
        index={props.index}
        clickCallback={callback}
        isDragging={props.isDragging}
        isPendingDragChanges={props.isPendingChanges}
      />
    </TravelBlockWrapper>
  );
}

export const TravelBlockWrapper = styled.div`
  border-radius: ${border_radius.rounded_md};
  padding: ${spacing.sm} 0;

  ${desktopLayout} {
    padding: ${spacing.md} 0 0;
  }
`;
