import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { CloseButton } from "src/auth/components/CloseButton/CloseButton";
import { Dialog } from "src/components/Dialog/Dialog";
import { Button } from "src/design-system/components/Button/Button";
import {
  BodyLg,
  HeadingLg,
} from "src/design-system/components/Typography/Typography";
import { spacing } from "src/design-system/tokens/spacing";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { searchParamsChange } from "src/utils/location/searchParamsChange";
import { setSearchParamsFeature } from "src/feature/internal/searchParams/useSearchParamsFeatures";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import type { UserProfile } from "src/auth/utils/user";

type FreelancerLoginProps = {
  showLoginForm: () => void;
};

export const FREELANCER_MARKER_LOGIN_COOKIE = "freelancerMarkerLogin";

export function FreelancerMarkerLogin({ showLoginForm }: FreelancerLoginProps) {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(user === undefined);
  const [userData, setUserData] = useState(
    user ? getUserData(user) : undefined
  );

  useEffect(() => {
    if (user === undefined) {
      setIsOpen(true);
    } else {
      setUserData(getUserData(user));
    }
  }, [user]);

  useEffect(() => {
    if (userData !== undefined) {
      const credentials = `${userData.email}|${userData.fullName}`;
      Cookies.set(FREELANCER_MARKER_LOGIN_COOKIE, credentials, {
        expires: 365,
      });

      const newSearchParams = setSearchParamsFeature(
        "FreelancerQA",
        "default",
        location.search
      );
      navigate(searchParamsChange(newSearchParams, location), {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <Dialog isOpen={isOpen} onBackdropClicked={() => setIsOpen(false)}>
      <Wrapper>
        <CloseModalWrapper>
          <CloseButton onClick={() => setIsOpen(false)} />
        </CloseModalWrapper>
        <Container>
          <Title>Marker Freelancer Login</Title>
          <Description>
            Please authenticate your account by signing into
            <br />
            the site with a valid Rome2Rio email account.
          </Description>
          <SignInButton
            onPress={() => {
              showLoginForm();
              setIsOpen(false);
            }}
          >
            Sign in
          </SignInButton>
        </Container>
      </Wrapper>
    </Dialog>
  );
}

function getUserData(user: UserProfile) {
  const isRome2RioUser = user.emails?.find((entry) =>
    entry.email.includes("@rome2rio.com")
  );

  return isRome2RioUser
    ? {
        email: isRome2RioUser.email,
        fullName: user.name,
      }
    : undefined;
}

const Wrapper = styled.div`
  position: relative;
  padding: ${spacing.xxxl};
`;

const CloseModalWrapper = styled.div`
  position: absolute;
  top: ${spacing.md};
  right: ${spacing.md};

  &:hover {
    opacity: 0.6;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(HeadingLg)`
  margin-bottom: ${spacing.md};
  text-align: center;
`;

const Description = styled(BodyLg)`
  margin-bottom: ${spacing.xxl};
  text-align: center;
`;

const SignInButton = styled(Button)`
  padding-left: ${spacing.xxxl};
  padding-right: ${spacing.xxxl};
  margin: 0 auto;
`;
