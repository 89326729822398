import type { City } from "src/domain/Discover/data/places";
import { distanceBetween } from "src/utils/distanceBetween";

export default async function getDiscoveryPlaces(origin?: {
  canonicalName: string;
  lat: number;
  lng: number;
}): Promise<City[]> {
  if (!origin) {
    return [];
  }

  const staticPlaces = (await import("src/domain/Discover/data/places"))
    .default;

  const matchIndex = getIndexOfCity(origin, staticPlaces.cities);
  if (matchIndex > -1) {
    return getCityRecommendations(
      staticPlaces.cities[matchIndex],
      staticPlaces.cities
    );
  }

  const closestCity = getClosestCity(origin, staticPlaces.cities);
  return closestCity.recommendations.map((recommendationIndex) => {
    return staticPlaces.cities[recommendationIndex];
  });
}

/**
 * @returns The index of the city or -1 if not found
 */
function getIndexOfCity(
  origin: { canonicalName: string; lat: number; lng: number },
  cities: City[]
): number {
  let canonicalMatchIndex = -1;
  let latLngMatchIndex = -1;

  cities.forEach((city, index) => {
    if (city.canonicalName === origin.canonicalName) {
      canonicalMatchIndex = index;
    }
    if (city.lat === origin.lat && city.lng === origin.lng) {
      latLngMatchIndex = index;
    }
  });

  return canonicalMatchIndex > -1 ? canonicalMatchIndex : latLngMatchIndex;
}

function getCityRecommendations(origin: City, cities: City[]): City[] {
  return origin.recommendations.map((recommendationIndex) => {
    return cities[recommendationIndex];
  });
}

function getClosestCity(
  origin: { lat: number; lng: number },
  cities: City[]
): City {
  return cities.reduce(
    (closest, city) => {
      const distance = distanceBetween(city, origin);
      if (distance < closest.distance) {
        return { city, distance };
      }
      return closest;
    },
    { city: cities[0], distance: Infinity }
  ).city;
}
