import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { ButtonBase } from "src/components/Button/ButtonBase";
import styled, { ThemeProvider } from "styled-components";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import type { AutocompletePlace } from "../../api/AutocompleteResponse";
import { AutocompleteInput } from "../../components/AutocompleteInput/AutocompleteInput";
import { AutocompleteList } from "../../components/AutocompleteList/AutocompleteList";
import { Icon } from "../../components/Icon/Icon";
import { Portal } from "../../components/Portal/Portal";
import { Close } from "../../svg/Close";
import { color, spacing } from "../../theme";
import useAutocomplete from "../../utils/hooks/useAutocomplete";
import { useTheme } from "../../utils/hooks/useTheme";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import messages from "./AutocompleteScreen.messages";

export type AutocompleteKind = "origin" | "destination" | number;

type Props = {
  onSelectOption: (place: AutocompletePlace) => void;
  initialQuery?: string;
};

export function AutocompleteScreen(props: Props) {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const autocompleteState = location.state?.autocomplete!;
  const { results, query, changeQuery } = useAutocomplete(
    props.initialQuery,
    Boolean(props.initialQuery)
  );
  const intl = useIntl();

  // Overwrite the theme prop autocompleteList here in the AutocompleteScreen (mobile only)
  // because for both themes we use the same dark text on light background.
  const theme = useTheme();
  theme.searchBar.autocompleteList = {
    background: color.white,
    backgroundHover: color.n20,
    text: color.n300,
    iconTint: "cod",
  };

  const handleAutocompleteSelect = (place: AutocompletePlace) => {
    sendAnalyticsInteractionEvent("Autocomplete", "Click:AutocompleteOption");
    props.onSelectOption(place);
  };

  const handleCloseClick = () => {
    sendAnalyticsInteractionEvent("Autocomplete", "Click:Close");
    navigate(-1);
  };

  return (
    <Portal>
      <Screen>
        <Header>
          <StyledAutocompleteInput
            type={autocompleteState}
            value={query}
            onChange={changeQuery}
            // We can disable this autoFocus rule because we only ever have one instance
            // in the DOM at any one time.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <CloseWrapper
            onClick={handleCloseClick}
            aria-label={intl.formatMessage(messages.close)}
          >
            <Icon size="md">
              <Close title="close" tint="grey4" />
            </Icon>
          </CloseWrapper>
        </Header>
        <ThemeProvider theme={theme}>
          <AutocompleteList
            isInputFocused={true}
            results={results}
            onSelectPlace={handleAutocompleteSelect}
          />
        </ThemeProvider>
      </Screen>
    </Portal>
  );
}

const Screen = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: ${color.white};
  padding: ${spacing.xl};
  /* z-index should be higher than the SearchEdit modal. */
  z-index: 10001;
  overflow-y: scroll;
`;

const Header = styled.div`
  margin-bottom: ${spacing.md};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAutocompleteInput = styled(AutocompleteInput)`
  flex: 1 0 auto;
`;

const CloseWrapper = styled(ButtonBase)`
  vertical-align: middle;
  padding-left: ${spacing.xl};
`;
