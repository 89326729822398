import { useFeature } from "src/feature/useFeature";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import useSearch from "src/utils/hooks/useSearch";
import { useTripTransportIndex } from "src/utils/hooks/useTripTransportIndex";
import type { ReturnStage } from "src/utils/hooks/useTypedLocation";
import { SearchResultsScreen } from "../SearchResultsScreen/SearchResultsScreen";
import { StackedNavigation } from "../TripPlanner/StackedNavigation/StackedNavigation";

type Props = {
  returnsFlowLocation?: ReturnStage;
};

export function TransportScreen({ returnsFlowLocation }: Readonly<Props>) {
  const isCore = useFeature("ReturnToCore");
  const { isDesktop } = useScreenMinWidth();
  const transportIndex = useTripTransportIndex();
  const { searchResponse } = useSearch();

  return (
    <>
      {isCore && isDesktop ? (
        <StackedNavigation
          screen="Search Results"
          BackgroundContent={<SearchResultsScreen context="transport" />}
          transportIndex={transportIndex}
          returnsFlowLocation={returnsFlowLocation}
          searchResponse={searchResponse}
        />
      ) : (
        <SearchResultsScreen context="transport" />
      )}
    </>
  );
}
