import { useCallback } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useAuthProviders } from "src/auth/contexts/AuthContext";
import { useHandleCreateSession } from "src/auth/hooks/useHandleCreateSession";
import {
  useGoogleCustomButton,
  useGoogleLogin,
} from "src/auth/sdks/GoogleAuthSdkHooks";
import type { AuthType } from "src/auth/utils/session";
import { Button } from "src/components/Button/Button";
import { Icon } from "src/components/Icon/Icon";
import { GoogleLogo } from "src/svg/GoogleLogo";
import { color, spacing } from "src/theme";
import { desktopLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import { GoogleSignIn } from "../GoogleSignIn/GoogleSignIn";
import { messages } from "./GoogleSignInButton.messages";

export function GoogleSignInButton() {
  const intl = useIntl();
  const googleCustomButton = useGoogleCustomButton();
  const { googleLoading, setAuthProvidersLoading } = useAuthProviders();
  const handleCreateSession = useHandleCreateSession("google");
  const loginGoogle = useGoogleLogin(handleCreateSession);

  const handleClick = useCallback(async () => {
    setAuthProvidersLoading(true);
    const authType: AuthType = "google";
    sendAnalyticsInteractionEvent(
      "UserAccount",
      "Click:ThirdPartyAuthButton",
      authType
    );
    if (googleCustomButton) {
      await loginGoogle();
      setAuthProvidersLoading(false);
    } else {
      setTimeout(() => {
        setAuthProvidersLoading(false);
      }, 3000);
    }
  }, [setAuthProvidersLoading, loginGoogle, googleCustomButton]);

  if (googleCustomButton) {
    return (
      <Button
        onClick={handleClick}
        backgroundColor="white"
        textColor="primaryOnLight"
        size="large"
        border={color.n50}
        disabled={googleLoading}
      >
        <StyledIcon size="xl">
          <GoogleLogo opacity={googleLoading ? 0.3 : 1} />
        </StyledIcon>
        {intl.formatMessage(messages.googleLogin)}
      </Button>
    );
  }

  return (
    <GoogleSignInContainer $loading={googleLoading}>
      <GoogleSignIn onClick={handleClick} />
      {googleLoading && <DisableClick />}
    </GoogleSignInContainer>
  );
}

// We do not want to render the GoogleSignIn component when the button is loading
// so we will use a styled component to hide it
const GoogleSignInContainer = styled.div<{ $loading: boolean }>`
  position: relative;
  ${({ $loading }) =>
    $loading &&
    `
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
  `};
`;

const DisableClick = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  margin-top: 1px;
  ${desktopLayout} {
    margin-top: 2px;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: ${spacing.xl};
`;
