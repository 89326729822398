import { useTransition } from "@react-spring/web";
import { cloneElement } from "react";
import type { TransitionProps } from "./TransitionProps";

export function Fade(props: TransitionProps) {
  const { open, onEnter, onExited, children, duration } = props;

  const config = duration ? { duration } : {};

  const shadowTransitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: {
      opacity: 0,
      onRest: () => {
        if (!open) {
          onExited && onExited();
        }
      },
    },
    onStart: () => {
      if (open) {
        onEnter && onEnter();
      }
    },
    config,
  });

  return shadowTransitions(
    (style, item, { key }) => item && cloneElement(children, { style, key })
  );
}
