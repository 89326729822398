import { iconSize } from "../theme";
import { type SvgProps, useColor } from "./common";

export function DestinationMarker(
  props: SvgProps & {
    outline?: boolean;
    elementRef?: (node: SVGElement | null) => void;
  }
) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      ref={props.elementRef}
      width="32"
      height="38"
      viewBox="0 0 32 38"
      fill="none"
    >
      <title>{props.title}</title>
      <g filter="url(#filter0_d)">
        <path
          d="M16 5c-4.97 0-9 4.03-9 9 0 5.781 6.273 12.761 7.5 14.097.42.458.774.903 1.5.903.725 0 1.078-.445 1.5-.903 1.032-1.12 7.499-8.316 7.499-14.097 0-4.97-4.03-9-9-9z"
          fill={tintColor}
        />
        {!!props.outline && (
          <path
            d="M16 3.5C10.201 3.5 5.5 8.202 5.5 14c0 3.352 1.783 6.857 3.534 9.536 1.789 2.737 3.728 4.887 4.36 5.575l.086.096c.36.408 1.143 1.293 2.52 1.293 1.374 0 2.157-.885 2.516-1.29l.087-.097a44.799 44.799 0 004.288-5.494c1.77-2.698 3.608-6.26 3.608-9.619 0-5.798-4.702-10.5-10.5-10.5z"
            stroke="#fff"
            strokeWidth="3"
          />
        )}
      </g>
      <circle r="2.5" transform="matrix(1 0 0 -1 15.999 13.5)" fill="#fff" />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="31.999"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export function TripCardDestinationMarker(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width={iconSize.xs}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833313 6.16669C0.833313 3.12977 3.22176 0.666687 6.16665 0.666687C9.11154 0.666687 11.5 3.12977 11.5 6.16669C11.5 9.69952 7.66749 14.0965 7.05554 14.7815C7.03823 14.8009 7.02113 14.8202 7.00413 14.8394C6.77576 15.0975 6.56707 15.3334 6.16665 15.3334C5.76495 15.3334 5.55582 15.0964 5.3269 14.837C5.31064 14.8186 5.29429 14.8001 5.27776 14.7815C4.55095 13.9652 0.833313 9.69952 0.833313 6.16669ZM6.16667 4.00002C7.27124 4.00002 8.16667 4.89546 8.16667 6.00002C8.16667 7.10459 7.27124 8.00002 6.16667 8.00002C5.0621 8.00002 4.16667 7.10459 4.16667 6.00002C4.16667 4.89546 5.0621 4.00002 6.16667 4.00002Z"
        fill={tintColor}
      />
    </svg>
  );
}
