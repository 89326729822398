import { ButtonBase } from "src/components/Button/ButtonBase";
import styled from "styled-components";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { fontSize } from "src/theme";
import { useIntl } from "react-intl";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import {
  type ForwardedRef,
  forwardRef,
  Fragment,
  useCallback,
  useId,
  useRef,
  useState,
} from "react";
import Close from "src/svg/Close.svg?react";
import Marker from "src/svg/BrandDestination.svg?react";
import { AddDestinationTimelineButton } from "src/components/TripPlanner/AddDestinationTimelineButton/AddDestinationTimelineButton";
import { getSurroundingPlaces } from "src/utils/getSurroundingPlaces";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import useAutocomplete, {
  useKeyboardAutocompleteNavigation,
} from "src/utils/hooks/useAutocomplete";
import { InputBase } from "src/components/Input/InputBase";
import { FocusContext } from "src/FocusContext";
import { BlurAwayListener } from "src/components/BlurAwayListener/BlurAwayListener";
import { AutocompleteList } from "src/components/AutocompleteList/AutocompleteList";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import { A11yOutline } from "src/utils/accessibility";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { TripPlannerAutocomplete } from "../TripPlannerAutocomplete/TripPlannerAutocomplete";
import { DottedLine } from "../DottedLine/DottedLine";
import messages from "./PlaceInputList.messages";

const inputIconOffset = `10.5px`;
const inputIconWidth = `6px`;
const inputIconBorderWidth = `5.5px`;
function PlaceInputSkeleton() {
  return <Skeleton width="100%" height="48px" />;
}

type PlaceInputListProps = {
  places: string[];
  loadingIndex?: number;
  handleRemoveDestination: (index: number) => void;
  handleSelectPlace: (
    autocompletePlace: AutocompletePlace,
    index: number
  ) => void;
  isInline?: boolean;
};
export function PlaceInputList({
  places,
  handleSelectPlace,
  handleRemoveDestination,
  isInline,
  loadingIndex,
}: PlaceInputListProps) {
  const intl = useIntl();
  const listRef = useRef<HTMLUListElement>(null);
  const InputComponent = isInline ? Input : ButtonInput;
  const hasAtleast2Legs = places.filter((place) => !!place).length > 1;

  function selectAndProceed(place: AutocompletePlace, index: number) {
    handleSelectPlace(place, index);
    const nextInput = listRef.current?.querySelectorAll("input")[index + 1];
    if (nextInput && !nextInput.value) {
      nextInput.focus();
    }
  }

  return (
    <WrapperDiv>
      <List ref={listRef}>
        {places.map((place, index) => {
          const placeHolder =
            index === 0
              ? intl.formatMessage(messages.startingFrom)
              : intl.formatMessage(messages.goingTo);

          if (loadingIndex === index) {
            return <PlaceInputSkeleton key={`${index + place}`} />;
          }

          return (
            <Fragment key={`${index + place}`}>
              <InputComponent
                {...{
                  index,
                  place,
                  places,
                  placeHolder,
                  handleSelectPlace: selectAndProceed,
                  handleRemoveDestination,
                }}
              />
              {places.length - 1 !== index ? <LinkDiv /> : null}
            </Fragment>
          );
        })}
        {loadingIndex === places.length && <PlaceInputSkeleton />}
        <DottedLine />
        <AddDestinationButton
          handleAddDestination={selectAndProceed}
          isDisabled={!hasAtleast2Legs}
          places={places}
        />
      </List>
    </WrapperDiv>
  );
}

type InputProps = {
  index: number;
  place: string;
  places: string[];
  placeHolder: string;
  handleSelectPlace: (place: AutocompletePlace, index: number) => void;
  handleRemoveDestination: (index: number) => void;
};

function ButtonInput({
  index,
  handleSelectPlace,
  handleRemoveDestination,
  place,
  places,
  placeHolder,
}: InputProps) {
  const [isAutocompleteActive, setIsAutocompleteActive] = useState(false);
  const intl = useIntl();

  return (
    <>
      {isAutocompleteActive && (
        <TripPlannerAutocomplete
          onSelectOption={(place) => {
            handleSelectPlace(place, index);
            setIsAutocompleteActive(false);
          }}
          handleClose={() => setIsAutocompleteActive(false)}
          places={getSurroundingPlaces(index, places)}
        />
      )}
      <Item>
        <InputIcon type={getIconType(index, places)} isEmpty={!place} />
        <InputButton
          $isEmpty={!place}
          onClick={() => setIsAutocompleteActive(true)}
        >
          <InputSpan>{!!place ? place : placeHolder}</InputSpan>
        </InputButton>

        {handleRemoveDestination && (
          <RemoveButton
            onClick={() => handleRemoveDestination(index)}
            role="button"
            title={intl.formatMessage(messages.removePlace)}
          >
            <Close width={12} height={12} color={color.icon.secondary} />
          </RemoveButton>
        )}
      </Item>
    </>
  );
}

const Input = forwardRef(
  (
    {
      place,
      handleSelectPlace,
      index,
      places,
      placeHolder,
      handleRemoveDestination,
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const id = useId();
    const intl = useIntl();
    const resultsContainerRef = useRef<HTMLUListElement>(null);
    const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
      null
    );

    const { changeQuery, results, query } = useAutocomplete(
      place,
      Boolean(place),
      true,
      getSurroundingPlaces(index, places)
    );

    function resetInput() {
      changeQuery(place ?? "", false);
      resetFocus();
    }

    function onPressEnter() {
      const activePlace: AutocompletePlace | undefined =
        results[focusedIndex - 1];
      handleSelectPlace(activePlace, index);
      changeQuery(activePlace?.longName ?? activePlace?.shortName, false);
      resetFocus();
    }

    const { onKeyDown, focusedIndex, onFocusChanged, focusRef, resetFocus } =
      useKeyboardAutocompleteNavigation({
        id,
        results,
        onPressEnter,
        onPressEscape: resetInput,
        scrollContainerRef: resultsContainerRef,
      });

    const getIsAutocompleteOpen = useCallback(() => {
      const active = document.activeElement === inputElement;
      const resultsExist = results.length > 0;
      return active && resultsExist;
    }, [results, inputElement]);

    return (
      <FocusContext.Provider
        value={{
          focusedElement: { id, index: focusedIndex },
          onFocusChanged,
        }}
      >
        <BlurAwayListener onBlurAway={resetInput}>
          <InlineInputContainer>
            <InputIcon type={getIconType(index, places)} isEmpty={!query} />
            <InlineInput
              ref={(node) => {
                setInputElement(node);
                if (typeof ref === "function") {
                  ref(node);
                } else if (ref) {
                  ref.current = node;
                  // Focus the input when it renders for the first time.
                  node?.focus();
                }
              }}
              placeholder={placeHolder}
              onKeyDown={onKeyDown}
              onChange={(event) => {
                changeQuery(event.target.value, true);
              }}
              onFocus={({ target }) => {
                target.select();
              }}
              value={query}
            />
            {places.length > 2 && (
              <RemoveButton
                onClick={() => handleRemoveDestination(index)}
                role="button"
                title={intl.formatMessage(messages.removePlace)}
              >
                <Close width={12} height={12} color={color.icon.secondary} />
              </RemoveButton>
            )}
            {getIsAutocompleteOpen() && (
              <AutocompleteListWrapper ref={resultsContainerRef}>
                <AutocompleteList
                  focusRef={focusRef}
                  onSelectPlace={(place) => {
                    handleSelectPlace(place, index);
                    changeQuery(place.longName ?? place.shortName, false);
                    resetFocus();
                  }}
                  results={results}
                  isInputFocused
                />
              </AutocompleteListWrapper>
            )}
          </InlineInputContainer>
        </BlurAwayListener>
      </FocusContext.Provider>
    );
  }
);
Input.displayName = "Input";

function getIconType(index: number, places: string[]) {
  if (index === 0 && places.length <= 2) {
    return "origin";
  }
  if (index === 1 && places.length <= 2) {
    return "destination";
  }
  return "place";
}

function InputIcon({
  type,
  isEmpty,
}: {
  type: "origin" | "destination" | "place";
  isEmpty?: boolean;
}) {
  switch (type) {
    case "origin":
      return <StarterDiv $isEmpty={isEmpty} />;
    case "destination":
      return <MarkerSvg $isEmpty={isEmpty} />;
    case "place":
      return <DotDiv />;
  }
}

function AddDestinationButton({
  handleAddDestination,
  isDisabled,
  places,
}: {
  handleAddDestination: (place: AutocompletePlace, index: number) => void;
  isDisabled: boolean;
  places: string[];
}) {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const { isSmScreen } = useScreenMinWidth();
  const isInline = isSmScreen && isInputVisible;
  const isFullScreen = !isSmScreen && isInputVisible;

  return (
    <>
      {isFullScreen && (
        <TripPlannerAutocomplete
          handleClose={() => setIsInputVisible(false)}
          onSelectOption={(place) => {
            handleAddDestination(place, places.length);
            setIsInputVisible(false);
          }}
          places={[places[places.length - 1]]}
        />
      )}

      {isInline && (
        <Input
          place=""
          ref={inputRef}
          placeHolder={intl.formatMessage(messages.goingTo)}
          index={places.length}
          places={places}
          handleSelectPlace={(place, index) => {
            handleAddDestination(place, index);
            setIsInputVisible(false);
          }}
          handleRemoveDestination={() => {
            setIsInputVisible(false);
          }}
        />
      )}

      <AddDestinationTimelineButton
        isDisabled={isDisabled || isInputVisible}
        onClick={() => {
          sendAnalyticsInteractionEvent("CreateTrip", "Click:AddDestination");
          setIsInputVisible(true);
        }}
      />
    </>
  );
}

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const Item = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${color.bg.surface.active};
  :first-child {
    border-radius: ${spacing.md} ${spacing.md} 0 0;
  }
  :last-child {
    border-radius: 0 0 ${spacing.md} ${spacing.md};
  }
`;

const DotDiv = styled.div`
  position: absolute;
  top: 50%;
  left: ${inputIconOffset};
  margin-left: ${inputIconBorderWidth};
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: ${color.icon.icon};
`;

const InputSpan = styled.span`
  text-align: left;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled(ButtonBase)`
  display: flex;
  padding: ${spacing.xl};
  position: absolute;
  right: ${spacing.md};
  top: 50%;
  transform: translateY(-50%);
  ${A11yOutline};
`;

const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  margin: 0 ${spacing.xl};
  z-index: 1;
  ::before {
    position: absolute;
    content: "";
    height: 44px;
    border-left: 1px solid ${color.icon.secondary};
  }
`;

const StarterDiv = styled.div<{ $isEmpty?: boolean }>`
  position: absolute;
  top: 50%;
  left: ${inputIconOffset};
  width: ${inputIconWidth};
  height: ${inputIconWidth};
  transform: translateY(-50%);
  box-sizing: content-box;
  margin-left: 1.5px;
  border: ${inputIconBorderWidth} solid
    ${({ $isEmpty }) => ($isEmpty ? color.bg.brand.fill : color.icon.icon)};
  border-radius: 50%;
  z-index: 2;
`;

const MarkerSvg = styled(Marker)<{ $isEmpty?: boolean }>`
  position: absolute;
  top: 50%;
  left: ${inputIconOffset};
  transform: translateY(-50%);
  z-index: 1;
  path {
    fill: ${({ $isEmpty }) =>
      $isEmpty ? color.bg.brand.fill : color.icon.icon};
  }
`;

const InputButton = styled(ButtonBase)<{ $isEmpty?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding: ${spacing.xl} 0 ${spacing.xl}
    calc(${inputIconOffset} + ${inputIconWidth} + ${spacing.xxl});
  flex: 1;
  font-size: ${fontSize.h5};
  overflow: hidden;
  white-space: nowrap;
  color: ${({ $isEmpty }) =>
    $isEmpty ? color.icon.icon : color.text.primary.active};
`;

const InlineInputContainer = styled.div`
  position: relative;
`;

const InlineInput = styled(InputBase)`
  background-color: ${color.input.bgSurface};
  padding-left: calc(${inputIconOffset} + ${inputIconWidth} + ${spacing.xxl});
  ${A11yOutline};
`;

const AutocompleteListWrapper = styled.ul`
  list-style-type: none;
  margin: 0;

  position: absolute;
  background-color: ${color.bg.surface.surface};
  z-index: 3;
  top: 100%;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  border-radius: 0 0 ${spacing.md} ${spacing.md};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  li {
    padding-left: calc(${inputIconWidth} + ${spacing.sm});
  }
`;
