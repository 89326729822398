import styled from "styled-components";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { spacing } from "src/design-system/tokens/spacing";

export function TitleLoading() {
  return (
    <TitleLoadingContainer>
      <TitleLoadingHeading>
        <Skeleton height={20} />
        <Skeleton width={27} height={9} />
      </TitleLoadingHeading>
      <Skeleton width={90} height={12} margin="6px 0 0 0" />
    </TitleLoadingContainer>
  );
}

export function RatingWithScoreLoading() {
  return <Skeleton width={120} height={16} />;
}
export function RatingWithBoxLoading() {
  return <Skeleton width={150} height={24} />;
}

export function TagsLoading() {
  return <Skeleton width={117} height={16} />;
}

export function GettingHereLoading() {
  return <Skeleton width={150} height={24} />;
}
export function DistanceToPlaceLoading() {
  return <Skeleton width={150} height={18} />;
}

export function PricingInfoLoading() {
  return (
    <PricingInfo>
      <Skeleton width={60} height={24} />
      <Skeleton width={84} height={16} />
    </PricingInfo>
  );
}

const TitleLoadingContainer = styled.div`
  width: 100%;
`;

const TitleLoadingHeading = styled.span`
  max-width: 260px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.sm};
`;

const PricingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  row-gap: ${spacing.sm};
`;
