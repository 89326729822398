import type { SearchResponse } from "../../../../api/SearchResponse";
import { transitModeFromVehicleKind } from "../../../../utils/adapters/transitMode";
import { vehicleFromSegment } from "../../../../utils/adapters/vehicle";
import {
  type TripPointAlternativeNames,
  getAlternativeNameForRouteSegment,
} from "../../../../utils/hooks/useTimelineTripPointAlternativeNames";
import type { Mode } from "../../../../utils/types/mode";

export function interchangeCellFromSegment(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  tripPointAlternativeNames?: TripPointAlternativeNames
): InterchangeCell {
  return interchangeCellfromFromOption(
    response,
    routeIndex,
    segmentIndex,
    0,
    tripPointAlternativeNames
  );
}

function interchangeCellfromFromOption(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  optionIndex: number,
  tripPointAlternatives?: TripPointAlternativeNames
): InterchangeCell {
  const route = response.routes[routeIndex];
  const segmentLookupIndex = route.segments[segmentIndex];
  const segment = response.segments[segmentLookupIndex];
  const option = response.options[segment.options[optionIndex]];

  const lastHopIndex = option.hops[option.hops.length - 1];
  const lastHop = response.hops[lastHopIndex];

  const arrivalLine = response.lines[lastHop.line];
  const arrivalPlace =
    response.places[arrivalLine.places[arrivalLine.places.length - 1]];

  // Get the next Vehicle
  const nextSegmentIndex = route.segments[segmentIndex + 1];
  const nextSegment = response.segments[nextSegmentIndex];
  const nextVehicle = vehicleFromSegment(response, nextSegment);
  const transitMode = transitModeFromVehicleKind(nextVehicle.kind);

  // Get the nearby city for this segment stop
  const interchangeName = getAlternativeNameForRouteSegment(
    segmentIndex + 1,
    route,
    transitMode,
    tripPointAlternatives
  );

  return {
    type: "interchange",
    interchange: arrivalPlace.shortName,
    interchangeName,
    transitMode,
    id: `interchange-${routeIndex}-${segmentIndex}-${optionIndex}`,
  };
}

export type InterchangeCell = {
  type: "interchange";
  id: string;
  interchange: string;
  transitMode: Mode;
  interchangeName?: string;
};
