import type { PropsWithChildren } from "react";
import { useIntl } from "react-intl";
import { isLoggedIn } from "src/auth/utils/session";
import { border_radius } from "src/design-system/tokens/border";
import { UserCircleOutline } from "src/svg/UserCircleOutline";
import { desktopLayout, largeDesktopLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";
import { ButtonBase } from "../../../components/Button/ButtonBase";
import { ClickAwayListener } from "../../../components/ClickAwayListener/ClickAwayListener";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { Icon } from "../../../components/Icon/Icon";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import type { ThemeMap } from "../../../components/Theme/Theme";
import { Close } from "../../../svg/Close";
import { UserCircle } from "../../../svg/UserCircle";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import { useTheme } from "../../../utils/hooks/useTheme";
import { messages } from "./SignInButton.messages";

type SignInButtonProps = {
  text?: string;
  label?: string;
  handleClose?: () => void;
  handleClickButton: () => void;
  isDropdownOpen?: boolean;
  handleClickOffDropdown?: () => void;
  fullWidth?: boolean;
  variant?: "default" | "tripsContext" | "tripsContextLg" | "horizontalSearch";
  className?: string;
};

export function SignInButton(props: PropsWithChildren<SignInButtonProps>) {
  const { variant = "default" } = props;
  const theme = useTheme();
  const intl = useIntl();
  const isSignedIn = isLoggedIn();

  const text = props.text ?? (
    <Skeleton repeat={1} height={16} width={75} lightOnDark={!isSignedIn} />
  );

  return (
    <DropdownWrapper>
      <DropdownButton
        data-hj-suppress
        aria-label={props.label}
        onClick={props.handleClickButton}
        fullWidth={props.fullWidth}
        theme={theme}
        $variant={variant}
        $isSignedIn={isSignedIn}
      >
        {variant === "default" && <DefaultButtonContent text={text} />}
        {(variant === "tripsContext" ||
          variant === "tripsContextLg" ||
          variant === "horizontalSearch") && (
          <TripsContextButtonContent
            text={text}
            isSignedIn={isSignedIn}
            variant={variant}
          />
        )}
      </DropdownButton>
      {props.isDropdownOpen && (
        <ClickAwayListener onClickAway={props.handleClickOffDropdown}>
          <StyledDropdown anchor="right">
            <CloseButton onClick={props.handleClose}>
              <CloseIcon size="sm">
                <Close
                  title={intl.formatMessage(messages.closeButtonTitle)}
                  tint="cod"
                />
              </CloseIcon>
            </CloseButton>
            {props.children}
          </StyledDropdown>
        </ClickAwayListener>
      )}
    </DropdownWrapper>
  );
}

function DefaultButtonContent({ text }: { text: string | JSX.Element }) {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <>
      <TextButton color={theme.userTopNav.text}>{text}</TextButton>
      <UserIcon size="xxl">
        <UserCircle
          tint={theme.userTopNav.iconTint}
          title={intl.formatMessage(messages.profile)}
        />
      </UserIcon>
    </>
  );
}

function TripsContextButtonContent({
  text,
  variant,
  isSignedIn,
}: {
  text: string | JSX.Element;
  variant: "default" | "tripsContext" | "tripsContextLg" | "horizontalSearch";
  isSignedIn: boolean;
}) {
  const intl = useIntl();

  return (
    <>
      <UserIcon size="xl">
        <UserCircleOutline
          tint={variant === "tripsContextLg" ? "white" : "cod"}
          title={intl.formatMessage(messages.profile)}
        />
      </UserIcon>
      <TextButton
        $size={variant === "tripsContextLg" ? "lg" : "md"}
        color={isSignedIn ? color.cod : color.white}
      >
        {text}
      </TextButton>
    </>
  );
}

const DropdownWrapper = styled.div`
  position: relative;
`;
const CloseButton = styled(ButtonBase)`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${spacing.xl};
`;

const CloseIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonStyles = {
  default: css`
    border-radius: ${border_radius.rounded_xxl};
    padding: ${spacing.sm} ${spacing.md} ${spacing.sm} ${spacing.lg};
    &:hover {
      background: ${(props) => props.theme.userTopNav.hoverBackground};
    }
  `,
  tripsContext: css`
    border-radius: ${border_radius.rounded_lg};
    padding: ${spacing.md} ${spacing.lg};
  `,
  tripsContextSignedIn: css`
    background: ${color.white};
    color: ${color.iconForeground};
    border: 1px solid ${color.n30};
  `,
  tripsContextLg: css`
    border-radius: ${border_radius.rounded_lg};
    padding: ${spacing.md} ${spacing.lg};
    background: ${color.pink};
    height: 48px;
  `,
  horizontalSearch: css`
    border-radius: ${border_radius.rounded_lg};
    padding: ${spacing.md} ${spacing.lg};
    background: ${color.white};

    span {
      color: ${color.cod};
    }

    &:hover {
      background-color: ${color.n30};
    }
  `,
};

const DropdownButton = styled.button<{
  theme: ThemeMap;
  fullWidth?: boolean;
  $variant: "default" | "tripsContext" | "tripsContextLg" | "horizontalSearch";
  $isSignedIn: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.md};
  background: ${(props) => props.theme.userTopNav.background};
  cursor: pointer;

  ${(props) => {
    if (props.fullWidth) {
      return `
        width: 100%;
        justify-content: center;
      `;
    }
  }}

  ${({ $variant }) => ButtonStyles[$variant]}
  ${({ $variant, $isSignedIn }) =>
    $isSignedIn &&
    $variant === "tripsContext" &&
    ButtonStyles.tripsContextSignedIn}
`;

const UserIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 360px;
  padding-top: ${spacing.xxxl};
  padding-bottom: ${spacing.xl};
  padding-left: ${spacing.xxxl};
  padding-right: ${spacing.xxxl};

  border-radius: ${border_radius.rounded_md};
  top: calc(100% + ${spacing.lg});
`;

const TextButton = styled.span<{ color: string; $size?: "md" | "lg" }>`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  color: ${(props) => props.color};

  // Limit Character Length
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${desktopLayout} {
    max-width: 75px;
    ${({ $size }) =>
      $size === "lg" &&
      css`
        max-width: 200px;
      `}
  }

  ${largeDesktopLayout} {
    max-width: 200px;
  }
`;
