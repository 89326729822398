import { logExit } from "../logExit";
import useSearch from "../../utils/hooks/useSearch";
import type {
  PartnerPageExitCategory,
  Trigger,
  View,
} from "../generateExitPoint/generateExitPoint";
import type { Mode } from "../../utils/types/mode";
import { useApiConfig } from "../../api/ApiConfigProvider";
import type { LogExitCategory } from "../analyticsEventTypes";

export const NON_COMMERCIAL_PROVIDER = "NonCommercial";

// Creates and returns a callback that, when invoked, logs exits to the Rome2Rio
// backend.
export function useLogExitCallback(
  exitKind: LogExitCategory,
  view: View,
  trigger: Trigger,
  transitMode?: Mode,
  partnerPage?: PartnerPageExitCategory
) {
  const { searchResponse, origin, destination } = useSearch();
  const requestId = searchResponse?.request.requestId;
  const config = useApiConfig();

  return (
    destinationUrl: string,
    provider?: string,
    agencyIds?: string[],
    overrideTrigger?: Trigger,
    eventId?: string
  ) => {
    logExit(config, {
      category: exitKind,
      provider: provider,
      agencyIds: agencyIds,
      originCanonical: origin?.canonicalName,
      destinationCanonical: destination?.canonicalName,
      destinationUrl,
      requestId,
      exitPoint: {
        view,
        trigger: overrideTrigger ?? trigger,
        transitMode,
      },
      partnerPage: partnerPage ?? "NotSpecified",
      navigationEventId: eventId,
    });
  };
}
