import type { PropsWithChildren } from "react";
import styled from "styled-components";
import { RadioGroupContext } from "./RadioGroupContext";

type RadioGroupProps<T extends string> = PropsWithChildren<{
  name: string;
  value: T;
  onChange: (value: T) => void;
  className?: string;
}>;

/**
 * RadioGroup is a helpful wrapper used to group Radio components that provides
 * an easier API.
 *
 * You can utilize useRadioGroup to create Components that can be children of
 * a RadioGroup, see Radio for an example.
 */
export function RadioGroup<T extends string>({
  name,
  value,
  onChange,
  className,
  children,
}: RadioGroupProps<T>) {
  return (
    <RadioGroupContext.Provider
      value={{ name, value, onChange: onChange as (value: string) => void }}
    >
      <FormGroup role="radiogroup" aria-label={name} className={className}>
        {children}
      </FormGroup>
    </RadioGroupContext.Provider>
  );
}

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
