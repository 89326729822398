import { type SvgProps, useColor } from "./common";

type Props = {
  active?: boolean;
} & SvgProps;

export function MenuLine(props: Props) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 6.00033C0.666748 5.57954 0.995073 5.23842 1.40008 5.23842H14.6001C15.0051 5.23842 15.3334 5.57954 15.3334 6.00033C15.3334 6.42111 15.0051 6.76223 14.6001 6.76223H1.40008C0.995073 6.76223 0.666748 6.42111 0.666748 6.00033Z"
        fill={tintColor}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 1.4289C0.666748 1.00811 0.995073 0.666992 1.40008 0.666992H14.6001C15.0051 0.666992 15.3334 1.00811 15.3334 1.4289C15.3334 1.84969 15.0051 2.1908 14.6001 2.1908H1.40008C0.995073 2.1908 0.666748 1.84969 0.666748 1.4289Z"
        fill={tintColor}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 10.5718C0.666748 10.151 0.995073 9.80985 1.40008 9.80985H14.6001C15.0051 9.80985 15.3334 10.151 15.3334 10.5718C15.3334 10.9925 15.0051 11.3337 14.6001 11.3337H1.40008C0.995073 11.3337 0.666748 10.9925 0.666748 10.5718Z"
        fill={tintColor}
      />
    </svg>
  );
}
