import { useQuery } from "@tanstack/react-query";
import type { PassengerDetails } from "src/PassengerDetailsProvider";
import { sojernPixel } from "src/data-partners/sojern/sojernPixel";
import type { SchedulesRequest } from "src/api/SchedulesResponse";
import type { Request } from "src/api/SearchResponse";
import type { SupportedLanguageCode } from "src/utils/language";
import type { Mode } from "src/utils/types/mode";
import { getPath } from "src/utils/url";

const useSojernPixel = (
  searchRequest?: Request,
  isSojernEnabled?: boolean,
  uidCookie?: string,
  schedulesRequest?: SchedulesRequest,
  passengers?: PassengerDetails, // doesn't exist on schedulesRequest...
  transitMode?: Mode
) => {
  useQuery({
    queryKey: [searchRequest, schedulesRequest, isSojernEnabled], // keys to invalidate the query
    queryFn: () => {
      if (!searchRequest || !isSojernEnabled || !uidCookie) {
        return "";
      }

      const { oName, dName, languageCode, currencyCode } = searchRequest;

      sojernPixel.triggerPageLoad({
        origin: { shortName: oName },
        destination: {
          shortName: dName,
        },
        languageCode: languageCode as SupportedLanguageCode,
        currencyCode: currencyCode,
        uid: uidCookie,
        path: getPath(oName, dName, languageCode as SupportedLanguageCode),
        pageType: getPageType(transitMode),
        adultsPassengers: passengers?.filter(
          (passenger) =>
            passenger.type === "adult" || passenger.type === "senior"
        ).length,
        childPassengers: passengers?.filter(
          (passenger) => passenger.type === "youth"
        ).length,
        departureDate: schedulesRequest?.outboundDateTime,
        returnDate: schedulesRequest?.inboundDateTime,
      });

      // Queries must return a value.
      return "";
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity, // ensures we only send the pixel once for each unique query key
  });
};

function getPageType(mode?: string) {
  switch (mode) {
    case "plane":
      return "FlightSearch";
    case "bus":
      return "BusSearch";
    case "train":
      return "TrainSearch";
    case "ferry":
      return "FerrySearch";
    default:
      return "TripSearch";
  }
}

export default useSojernPixel;
