import styled from "styled-components";
import type { FeatureName, FeatureValue } from "../../feature/FeatureConfig";

type Props = {
  featureName: FeatureName;
  valueIndex: number;
  possibleFeatureValues: readonly FeatureValue<FeatureName>[];
  setFeature: (featureName: FeatureName, valueIndex: number) => void;
};

export function DebugFeatureInput({
  featureName,
  valueIndex,
  possibleFeatureValues,
  setFeature,
}: Props) {
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const valIndex = possibleFeatureValues.indexOf(checked);
    setFeature(featureName, valIndex);
  };

  const onSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFeature(featureName, parseInt(e.target.value));
  };

  return (
    <div>
      {isBooleanFeature(possibleFeatureValues) ? (
        <StyledCheckbox
          data-testid={`${featureName}-Test`}
          type="checkbox"
          defaultChecked={possibleFeatureValues[valueIndex]}
          onChange={onCheckboxChange}
        />
      ) : (
        <StyledSelection
          data-testid={`${featureName}-Test`}
          defaultValue={`${valueIndex}` as string | undefined}
          onChange={onSelectionChange}
        >
          {possibleFeatureValues.map((featureState, index) => {
            return (
              <option key={index} value={`${index}`}>
                {featureState}
              </option>
            );
          })}
        </StyledSelection>
      )}
    </div>
  );
}

function isBooleanFeature(
  possibleValues: readonly FeatureValue<FeatureName>[]
): possibleValues is [boolean, boolean] {
  return (
    possibleValues.includes(true) &&
    possibleValues.includes(false) &&
    possibleValues.length === 2
  );
}

const StyledCheckbox = styled.input`
  width: 18px;
  height: 18px;
`;

const StyledSelection = styled.select`
  min-width: 150px;
`;
