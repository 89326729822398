import type { TripPlannerDetails } from "../TripPlannerProvider";

export const TRIP_DATA_VERSION = 1;

type TripPlanID = {
  id: string;
};

// The potential schemas in LocalStorage
export type TripPlannerSchemas =
  | TripPlannerDetails
  | TripPlannerDetails[]
  | TripPlanID;

// The current schema we wish to use
type CurrentTripPlanSchema = TripPlannerDetails;

function getDefaultTripPlan(localPlan?: TripPlanID | TripPlannerDetails) {
  if (localPlan) {
    return {
      ...localPlan,
      places: "places" in localPlan ? localPlan.places : [],
      transport: "transport" in localPlan ? localPlan.transport : {},
    };
  }
  return {
    id: undefined,
    places: [],
    transport: {},
  };
}

export function migrateTripPlanSchema(
  tripPlannerDetails?: object
): CurrentTripPlanSchema {
  const tripPlan = migrateTripPlanToArrayFormat(
    tripPlannerDetails as TripPlannerSchemas
  )[0];
  return getDefaultTripPlan(tripPlan);
}

export function migrateTripPlanToArrayFormat(
  tripPlannerDetails: TripPlannerSchemas
): (TripPlannerDetails | TripPlanID)[] {
  if (Array.isArray(tripPlannerDetails)) {
    return tripPlannerDetails;
  }
  return [tripPlannerDetails];
}
