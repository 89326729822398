import type { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import type {
  SearchResponse,
  Option,
  Route,
  Segment,
} from "../../api/SearchResponse";

export const durationInMinutesFromOption = (
  response: SearchResponse | PartialSearchResponse,
  option: Option
): number => {
  let result = 0;

  if (option.transfers !== undefined) {
    for (const transferDuration of option.transfers) {
      result += transferDuration;
    }
  }

  for (const hopIndex of option.hops) {
    const hop = response.hops[hopIndex];
    result += hop.duration;
  }

  return Math.round(result / 60);
};

export const durationInMinutesFromSegment = (
  response: SearchResponse | PartialSearchResponse,
  segment: Segment
): number => {
  const optionDurations = segment.options
    .map((optionIndex) => {
      const option = response.options[optionIndex];
      return durationInMinutesFromOption(response, option);
    })
    .sort((leftDuration, rightDuration) => leftDuration - rightDuration);
  return optionDurations[0];
};

export const durationInMinutesFromRoute = (
  response: SearchResponse | PartialSearchResponse,
  route: Route
): number => {
  return Math.round(route.duration / 60);
};
