import { type SvgProps, useColor } from "../common";

export function Destination(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fill={tintColor}
        d="M12 7.878c.796 0 1.441.652 1.441 1.456 0 .803-.645 1.455-1.44 1.455-.796 0-1.44-.652-1.44-1.455 0-.804.644-1.456 1.44-1.456z"
      />
      <path
        fill={tintColor}
        fillRule="evenodd"
        d="M12.001 3A7.002 7.002 0 005 10c0 2.235 1.19 4.572 2.357 6.358a29.834 29.834 0 002.964 3.78c.24.272.763.863 1.68.863.917 0 1.439-.59 1.678-.86l.058-.065c.36-.39 1.652-1.822 2.86-3.663 1.18-1.799 2.405-4.173 2.405-6.413 0-3.865-3.135-7-7-7zM7 10a5.002 5.002 0 0110.003 0c0 1.615-.93 3.566-2.079 5.316a27.88 27.88 0 01-2.658 3.405c-.028.03-.052.056-.072.08l-.115.122a.96.96 0 01-.078.073.94.94 0 01-.078-.073 4.46 4.46 0 01-.185-.2 27.825 27.825 0 01-2.707-3.46C7.902 13.538 7 11.62 7 10z"
      />
    </svg>
  );
}
