import { type IntlShape, FormattedDate } from "react-intl";

// Not using Intl.DateFormatOptions as part of type because it lists types as 'string' instead
// of the enumerated options. Options have been manually enumerated for ease of development.
// And an issue has been filed with TypeScript here: https://github.com/microsoft/TypeScript/issues/35865
// Format options taken from here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
export type FormattedIsoDateProps = {
  date: string; // YYYY-MM-DD
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  weekday?: "long" | "short" | "narrow";
};

export function FormattedIsoDate(props: FormattedIsoDateProps) {
  // It's crucial that the timezone of the "value" prop and the "timezone" prop
  // match for the user to see the correct date. So we're normalizing with UTC.
  const utcDate = toUtcDate(props.date);

  return (
    <FormattedDate
      value={utcDate}
      timeZone="utc"
      year={props.year}
      month={props.month}
      day={props.day}
      weekday={props.weekday}
    />
  );
}

// This is an imperative version of the component above, 'FormattedIsoDate'. It
// is intended to be used in situations where you can't or don't want to use a
// component. e.g. aria-label, etc.
export function formatDate(
  date: string,
  intl: IntlShape,
  options: Omit<FormattedIsoDateProps, "date">
): string {
  // It's crucial that the timezone of the "value" prop and the "timezone" prop
  // match for the user to see the correct date. So we're normalizing with UTC.
  const utcDate = toUtcDate(date);

  return intl.formatDate(utcDate, {
    ...options,
    timeZone: "utc",
  });
}

function toUtcDate(date: string) {
  const [year, month, day] = date.split("-");

  return new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1, // months in a JS Date are zero-indexed.
      parseInt(day)
    )
  );
}
