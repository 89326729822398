import { useMediaQuery } from "react-responsive";
import { breakpoints } from "src/theme";

/**
 * This hook is designed with mobile first styling in mind
 *  Breakpoints:
 * - sm: 768px  (Extra small devices, phones in landscape, small tablets)
 * - md: 1024px (Small devices, tablets, large phones in landscape)
 * - lg: 1200px (Medium devices, small laptops and desktop monitors)
 * - xl: 1440px (Large devices, desktop monitors and small TVs)
 * - xxl: 1740px (Extra large devices, large desktop monitors and TVs)
 *
 * @example
 * const {isXl} = useScreenWidth();
 */
export function useScreenMinWidth() {
  const isSmScreen = useMediaQuery({ minWidth: breakpoints.sm });
  const isMdScreen = useMediaQuery({ minWidth: breakpoints.md });
  const isLgScreen = useMediaQuery({ minWidth: breakpoints.lg });
  const isXlScreen = useMediaQuery({ minWidth: breakpoints.xl });
  const isXxlScreen = useMediaQuery({ minWidth: breakpoints.xxl });

  return {
    /**  768px  (Extra small devices, phones in landscape, small tablets) */ isSmScreen,
    /** 1024px (Small devices, tablets, large phones in landscape) */ isMdScreen,
    /** 1200px (Medium devices, small laptops and desktop monitors)*/ isLgScreen,
    /** 1440px (Large devices, desktop monitors and small TVs) */ isXlScreen,
    /** 1740px (Extra large devices, large desktop monitors and TVs) */ isXxlScreen,
    isMobile: !isSmScreen,
    isTablet: isSmScreen && !isMdScreen,
    isDesktop: isMdScreen,
  };
}
