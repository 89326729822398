import { type ForwardedRef, forwardRef } from "react";
import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight, spacing } from "../../theme";
import type { MergeElementProps } from "../../utils/MergeElementProps";
import { InputBase } from "./InputBase";

export type InputProps = MergeElementProps<
  "input",
  {
    id: string;
    label: string;
    isValid?: boolean;
    validationMessage?: string;
  }
>;

export const Input = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { label, id, isValid, validationMessage, ...otherProps } = props;

    return (
      <InputContainer>
        <StyledLabel id={`${id}-label`} htmlFor={id}>
          {label}
        </StyledLabel>
        <StyledInputBase
          ref={ref}
          id={id}
          aria-invalid={!isValid}
          aria-describedby={`${id}-validation`}
          {...otherProps}
        />
        {!isValid && (
          <Collapse>
            <Validation id={`${id}-validation`}>{validationMessage}</Validation>
          </Collapse>
        )}
      </InputContainer>
    );
  }
);
Input.displayName = "Input";

const InputContainer = styled.div`
  width: 100%;
`;
const StyledLabel = styled.label`
  font-size: ${fontSize.h6};
`;

const StyledInputBase = styled(InputBase)`
  margin-top: ${spacing.md};
  border: 1px solid ${color.n40};
  background-color: transparent;
  &:disabled {
    background-color: ${color.n20};
  }
`;
const Collapse = styled.div`
  position: relative;
`;

const Validation = styled.span`
  position: absolute;
  display: block;
  font-size: ${fontSize.small};
  line-height: ${lineHeight.loose};
  font-weight: ${fontWeight.normal};
  padding-top: ${spacing.sm};
  color: ${color.high};
  height: calc(${fontSize.small} * ${lineHeight.loose});
  right: 0;
`;
