import { useNavigate } from "react-router";
import { Navbar } from "src/components/Navbar/Navbar";
import { fontSize, fontWeight } from "src/theme";
import { useGetPermaLink } from "src/utils/hooks/useGetPermaLink";
import { desktopLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { navigateToNewState } from "../../utils/location/navigateToNewState";
import { BackendFeatureSelector } from "../DebugWindow/BackendFeatureSelector";
import { BackendFlagSelector } from "../DebugWindow/BackendFlagSelector";
import { FrontendFeatureSelector } from "../DebugWindow/FrontendFeatureSelector";
import { AssignUidButton } from "../DebugWindow/AssignUidButton";
import MockFlagSelector from "../DebugWindow/RequestMockFlagSelector";

export function DebugScreen() {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const permalink = useGetPermaLink();

  const handleBack = () => {
    // go back to main user preferences screen
    navigateToNewState(navigate, { preferencesScreen: "main" }, location);
  };

  return (
    <Content>
      <Navbar headingText="Debug" onBackClick={handleBack} />
      <Text>
        <a href={permalink.href}>Current Feature Permalink</a>
      </Text>
      <FrontendFeatureSelector />
      <BackendFeatureSelector />
      <BackendFlagSelector />
      <MockFlagSelector />
      <AssignUidButton />
    </Content>
  );
}

const Content = styled.div`
  ${desktopLayout} {
    padding-top: calc(44px - 16px);
  }
`;

const Text = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.normal};
  border-bottom: 1px solid #ebebeb;
`;
