import LazyHotelLargeSearchBar from "./domain/HotelsScreen/HotelSearch/LazyHotelLargeSearchBar";
import { LargeSearchBar } from "./domain/LargeSearchBar/LargeSearchBar";
import { useIsTripCreated } from "./domain/TripPlanner/hooks/useTripURL";
import { LazyTripSearchBar } from "./domain/TripPlanner/TripSearchBar/LazyTripSearchBar";
import { useFeature } from "./feature/useFeature";
import { useIsTripScreen } from "./utils/hooks/useIsTripScreen";
import { useIsHotelsUrlDeeplink } from "./utils/hooks/useNavigateToHotelsPage";

export default function SearchBar() {
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  const isTripScreen = useIsTripScreen();
  const isTripCreated = useIsTripCreated();
  const isCore = useFeature("ReturnToCore");

  if (isHotelsScreen) {
    return <LazyHotelLargeSearchBar />;
  } else if (isTripScreen) {
    if (isCore && !isTripCreated) {
      return <></>;
    }
    return <LazyTripSearchBar />;
  } else {
    return <LargeSearchBar />;
  }
}
