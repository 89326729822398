import { useCallback } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { AppleSignIn } from "src/auth/components/AppleSignIn/AppleSignIn";
import { useAuthProviders } from "src/auth/contexts/AuthContext";
import type { AuthType } from "src/auth/utils/session";
import { Button } from "src/components/Button/Button";
import { Icon } from "src/components/Icon/Icon";
import { AppleLogo } from "src/svg/AppleLogo";
import { spacing } from "src/theme";
import styled from "styled-components";
import { messages } from "./AppleSignInButton.messages";

export function AppleSignInButton() {
  const intl = useIntl();
  const { appleLoading, setAuthProvidersLoading } = useAuthProviders();

  const handleClick = useCallback(
    async (signInWithApple: VoidFunction) => {
      setAuthProvidersLoading(true);
      setTimeout(() => {
        setAuthProvidersLoading(false);
      }, 3000);

      const authType: AuthType = "apple";
      sendAnalyticsInteractionEvent(
        "UserAccount",
        "Click:ThirdPartyAuthButton",
        authType
      );
      // Apple sign in is a bit slow and blocks any other procces execution in the app during the sign in process
      // so we need to wait a bit to set the loading state to false
      await new Promise((resolve) => setTimeout(resolve, 100));
      signInWithApple();
    },
    [setAuthProvidersLoading]
  );
  return (
    <AppleSignIn>
      {({ signInWithApple }) => (
        <Button
          textColor="primaryOnDark"
          backgroundColor="cod"
          disabledBackgroundColor="n50"
          disabledTextColor="primaryOnDark"
          border="#A2A2A2"
          size="large"
          onClick={() => {
            handleClick(signInWithApple);
          }}
          disabled={appleLoading}
        >
          <StyledIcon size="xl">
            <AppleLogo tint="white" />
          </StyledIcon>
          {intl.formatMessage(messages.appleLogin)}
        </Button>
      )}
    </AppleSignIn>
  );
}

const StyledIcon = styled(Icon)`
  position: absolute;
  left: ${spacing.xl};
`;
