import markerSDK, { type MarkerSdk } from "@marker.io/browser";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { FREELANCER_MARKER_LOGIN_COOKIE } from "src/domain/FreelancerMarkerLogin/FreelancerMarkerLogin";
import type { FeatureConfig, FeatureName } from "src/feature/FeatureConfig";
import { setSearchParamsFeature } from "src/feature/internal/searchParams/useSearchParamsFeatures";
import { useFeatureConfig } from "src/feature/useFeatureConfig";
import { searchParamsChange } from "../location/searchParamsChange";
import { useGetPermaLink } from "./useGetPermaLink";
import { useTypedLocation } from "./useTypedLocation";
import useUser from "./useUser";

type MarkerFeature = {
  featureName: FeatureName;
  markerId: string;
};

function getMarkerProjectFromFeature(frontendFeatures: FeatureConfig) {
  // Add a frontend feature to markerProjects along with
  // an active Marker.io project ID. When that feature is
  // active in the browser, it will trigger the Marker.io widget
  // for the Marker.io project associated with that feature.
  // Be selective with which feature name you associate
  // with a Marker.io project, as only the first match will
  // be used to trigger the form widget in the browser.
  const markerProjects: MarkerFeature[] = [
    {
      featureName: "DataOpsQA",
      markerId: "673183d9b1d07f2c2df6b5c6",
    },
    {
      featureName: "IslandRoutingQA",
      markerId: "673fb81f02e5bf936d09eeea",
    },
    {
      featureName: "MobileTripsV2QA",
      markerId: "6743c848d1542b1e81d8eeca",
    },
    {
      featureName: "SearchExperienceQA",
      markerId: "675f741fb1668626a6fe3387",
    },
    {
      featureName: "DriveOverTaxiQA",
      markerId: "6791b76002ab98892b11bb51",
    },
  ];

  let currentProjectId = "";

  for (let markerProject of markerProjects) {
    if (frontendFeatures[markerProject.featureName]) {
      currentProjectId = markerProject.markerId;
      break;
    }
  }

  return currentProjectId;
}

function getFreelancerCredentials() {
  const cookieValue = Cookies.get(FREELANCER_MARKER_LOGIN_COOKIE);

  if (!cookieValue) {
    return;
  }

  const [email, fullName] = cookieValue.split("|");

  return {
    email,
    fullName,
  };
}

export function useMarker() {
  const marker = useRef<MarkerSdk | undefined>();
  const permalink = useGetPermaLink().href;
  const frontendFeatures = useFeatureConfig();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const [projectId, setProjectId] = useState<string>("");

  const freelancerCredentials =
    frontendFeatures.FreelancerQA !== "hide" && getFreelancerCredentials();

  useEffect(() => {
    const freelanceQaProjectId = "66ff4fcee3ed7bfa489136a1";
    const currentProjectId = freelancerCredentials
      ? freelanceQaProjectId
      : getMarkerProjectFromFeature(frontendFeatures);

    if (
      freelancerCredentials &&
      !frontendFeatures.LogAnalyticsEventsToConsole
    ) {
      const newSearchParams = setSearchParamsFeature(
        "LogAnalyticsEventsToConsole",
        true,
        location.search
      );
      navigate(searchParamsChange(newSearchParams, location), {
        replace: true,
      });
    }

    setProjectId(currentProjectId);

    marker.current?.setCustomData({
      permalink,
    });

    // The permalink will always update if a FE feature changes
    // so we just listen to the permalink as the dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permalink]);

  useEffect(() => {
    async function loadMarker() {
      const script = document.createElement("script");
      script.src = "https://edge.marker.io/latest/iframe.support.js";
      script.async = true;

      document.body.appendChild(script);

      // Initialise Marker script
      return await markerSDK.loadWidget({
        project: projectId,
        customData: {
          permalink,
        },
        ...(freelancerCredentials && {
          reporter: {
            email: freelancerCredentials.email,
            fullName: freelancerCredentials.fullName,
          },
        }),
      });
    }
    const isRome2RioUser =
      user?.emails?.find((entry) => entry.email.includes("@rome2rio.com")) ??
      import.meta.env.VITE_ENVIRONMENT !== "Production";

    if (projectId && marker.current === undefined && isRome2RioUser) {
      loadMarker()
        .then((widget) => {
          marker.current = widget;
        })
        .catch((error) => console.warn(error));
    }

    if (projectId) {
      marker.current?.show();
    } else {
      marker.current?.hide();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, user]);
}
