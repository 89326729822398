import type { SearchResponse } from "src/api/SearchResponse";
import { TransitIcon } from "src/components/Icon/TransitIcon";
import { spacing } from "src/design-system/tokens/spacing";
import { getRoutesBetween } from "src/domain/HotelsScreen/TravelOptions/utils";
import styled from "styled-components";

type Props = {
  searchResponse: SearchResponse;
  count?: number;
};
export function MajorModes({ searchResponse, count = 3 }: Props) {
  const routes = getRoutesBetween({ searchResponse, count }) ?? [];

  return (
    <ModeSummary>
      {routes.map((route, i) => {
        const mode = route.transitModeLabels[0];
        const shouldRotate = mode === "plane";
        const TransitIconContainer = shouldRotate
          ? RotatedTransitIcon
          : TransitIcon;
        return (
          <TransitIconContainer
            transitMode={mode}
            tint={mode}
            key={`${mode}-${i}`}
          />
        );
      })}
    </ModeSummary>
  );
}

const RotatedTransitIcon = styled(TransitIcon)`
  transform-origin: center center;
  transform: rotate(45deg);
`;

export const ModeSummary = styled.div`
  display: flex;
  flex: 1;
  height: 16px;
  gap: ${spacing.md};
`;
