import { defineMessages } from "react-intl";

const messages = defineMessages({
  edit: {
    id: "tripPlanner.tripcard.edit",
    description: "Button title for editing a place",
    defaultMessage: "Edit",
  },
  moreOptions: {
    id: "tripPlanner.tripcard.moreOptions",
    description:
      "Button title for open a dropdown with more options, like remove place, edit place, re-order place, etc. placeName is the name of the place (London, Melbourne, Paris, etc).",
    defaultMessage: "More options for {placeName}",
  },
  removePlace: {
    id: "tripPlanner.tripcard.removePlace",
    description: "Button title for remove a place from the trip",
    defaultMessage: "Remove",
  },
  reorderTrip: {
    id: "tripPlanner.tripcard.reorderTrip",
    description: "Button title for reordering the trip",
    defaultMessage: "Reorder",
  },
  exploreHotels: {
    id: "tripPlanner.tripcard.exploreHotels",
    description: "Button title for explore hotels in a place",
    defaultMessage: "Explore hotels",
  },
});

export default messages;
