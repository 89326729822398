import type { SearchResponse } from "src/api/SearchResponse";
import type { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";

/** When we save from the transport tab, we wont have /transport/ in the URL.
 *  If this happens we want to alter the URL for trip planner navigation. Otherwise,
 *  we just return the already modified URL.
 */
export function createTripHashForCard(
  searchResponse: SearchResponse | PartialSearchResponse,
  hash?: string
) {
  if (hash?.includes("transport")) {
    return hash;
  }

  const segmentHash = hash?.split("#trips/")[1];
  const currentTripHash = tripHashFromSearchResponse(searchResponse);

  if (segmentHash && segmentHash.length > 0) {
    return `${currentTripHash}/${segmentHash}`;
  }

  return currentTripHash;
}

export function tripHashFromSearchResponse(
  searchResponse?: SearchResponse | PartialSearchResponse,
  screenKey: string = "transport"
) {
  if (searchResponse) {
    if ("request" in searchResponse) {
      return `#trips/${screenKey}/${searchResponse.request.oName}/${searchResponse.request.dName}`;
    }
    return `#trips/${screenKey}/${searchResponse.places[0].canonicalName}/${searchResponse.places[1].canonicalName}`;
  }
}
