import { useIntl } from "react-intl";
import { ButtonBase } from "src/components/Button/ButtonBase";
import { Close } from "src/svg/Close";
import styled from "styled-components";
import { type color, iconSize, spacing } from "src/theme";
import { A11yOutline } from "src/utils/accessibility";
import messages from "./CloseButton.messages";

type CloseButtonProps = {
  onClick: () => void;
  size?: keyof typeof iconSize;
  tint?: keyof typeof color;
};

export function CloseButton(props: CloseButtonProps) {
  const intl = useIntl();
  return (
    <IconButton onClick={props.onClick} size={props.size ?? "sm-1"}>
      <Close
        title={intl.formatMessage(messages.close)}
        tint={props.tint ?? "n90"}
      />
    </IconButton>
  );
}

const IconButton = styled(ButtonBase)<{
  size: keyof typeof iconSize;
}>`
  ${A11yOutline};
  width: 24px;
  height: 24px;
  border-radius: ${spacing.sm};
  svg {
    width: ${({ size }) => iconSize[size]};
    height: ${({ size }) => iconSize[size]};
  }
`;
