import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { SearchFilter } from "src/components/SearchFilter/SearchFilter";
import styled from "styled-components";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { RadioGroupList } from "../../components/RadioGroupList/RadioGroupList";
import { spacing } from "../../theme";
import {
  type SupportedCurrencyCode,
  currencyMessages,
  isSupportedCurrencyCode,
  supportedCurrencyCodes,
} from "../../utils/currency";
import { largeDesktopLayout, useLayout } from "../../utils/hooks/useLayout";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import useUser from "../../utils/hooks/useUser";
import { navigateToNewState } from "../../utils/location/navigateToNewState";
import messages from "./CurrencyScreen.messages";

const POPULAR_CURRENCIES = ["AUD", "EUR", "GBP", "USD"] as const;
type PopularCurrency = (typeof POPULAR_CURRENCIES)[number];

export function CurrencyScreenContent() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const isDesktop = layout === "desktop";
  const { currencyCode, saveCurrencyCode } = useUser();
  const [supportedCurrencyCodesFiltered, setSupportedCurrencyCodesFiltered] =
    useState<SupportedCurrencyCode[]>(supportedCurrencyCodes);

  function handleChange(newCurrencyCode: string) {
    if (!isSupportedCurrencyCode(newCurrencyCode)) {
      throw new Error(
        `received ${newCurrencyCode} but expected one of: ${supportedCurrencyCodes.join(
          ", "
        )}`
      );
    }
    sendAnalyticsInteractionEvent(
      "Preferences",
      "Click:SelectCurrency",
      `${currencyCode}->${newCurrencyCode}`
    );

    saveCurrencyCode(newCurrencyCode);

    // scroll to the top when user goes back to main user preferences screen
    document.documentElement.scrollTop = 0;

    // go back to main user preferences screen
    if (!isDesktop) {
      navigateToNewState(navigate, { preferencesScreen: "main" }, location);
    }
  }

  const isFiltered =
    supportedCurrencyCodesFiltered.length < supportedCurrencyCodes.length;

  const currencyOptions = supportedCurrencyCodesFiltered.map(
    (currencyCode) => ({
      value: currencyCode,
      label: intl.formatMessage(currencyMessages[currencyCode]),
      subtitle: currencyCode,
      group: isFiltered
        ? undefined
        : POPULAR_CURRENCIES.includes(currencyCode as PopularCurrency)
        ? intl.formatMessage(messages.suggestedCurrencies)
        : intl.formatMessage(messages.allCurrencies),
    })
  );

  const groups = isFiltered
    ? []
    : [
        intl.formatMessage(messages.suggestedCurrencies),
        intl.formatMessage(messages.allCurrencies),
      ];

  function filteringFunction(
    currencyCode: SupportedCurrencyCode,
    filter: string
  ) {
    return (
      intl
        .formatMessage(currencyMessages[currencyCode])
        .toLowerCase()
        .includes(filter.trim().toLowerCase()) ||
      currencyCode.toLowerCase().includes(filter.trim().toLowerCase())
    );
  }

  return (
    <>
      <FilterContainer>
        <SearchFilter
          data={supportedCurrencyCodes}
          onFilter={(filteredData) =>
            // convert from the default message from intl to the currency code
            setSupportedCurrencyCodesFiltered(filteredData)
          }
          filteringFunction={filteringFunction}
        />
      </FilterContainer>
      <RadioGroupList
        name="currency"
        selectedValue={currencyCode}
        onChange={handleChange}
        options={currencyOptions}
        groups={groups}
      />
    </>
  );
}

export function useCurrencyPreferenceScreen() {
  const intl = useIntl();
  const layout = useLayout();

  const description =
    layout === "desktop" ? intl.formatMessage(messages.description) : undefined;

  return {
    heading: intl.formatMessage(messages.heading),
    description,
  };
}

const FilterContainer = styled.div`
  max-width: 100%;
  margin: 0 0 ${spacing.xl} 0;
  display: flex;
  justify-content: flex-end;
  ${largeDesktopLayout} {
    position: absolute;
    right: ${spacing.xxl};
    top: calc(${spacing.xxxl} + ${spacing.xxl} + 26px);
  }
`;
