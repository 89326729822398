import type { ScreenLocations } from "../getLocations/getLocations";
import type { Screen } from "../generateScreenComponentsRendered/Screen";

export function formatGaLocationsLabel(
  screen: Screen,
  locations: ScreenLocations
): string {
  const origin_name = cleanPlaceName(
    locations.origin.canonicalName ?? locations.origin.shortName
  );
  const dest_name = cleanPlaceName(
    locations.destination.canonicalName ?? locations.destination.shortName
  );
  return `v1:${screen}:${origin_name}:${locations.origin.kind}:${dest_name}:${locations.destination.kind}`;
}

function cleanPlaceName(placeName: string): string {
  return placeName
    .trim()
    .replace(/ - /g, " ")
    .replace(/\s/g, "-")
    .replace(/,/, "")
    .replace(/:/, "");
}
