import type { iconSize } from "src/theme";
import { useTheme } from "../../utils/hooks/useTheme";
import { Icon } from "../Icon/Icon";
import { City } from "../../svg/autocomplete/City";
import { Plane } from "../../svg/autocomplete/Plane";
import { Train } from "../../svg/autocomplete/Train";
import { Globe } from "../../svg/autocomplete/Globe";
import { Destination } from "../../svg/autocomplete/Destination";

type IconProps = {
  kind: string;
  size?: keyof typeof iconSize;
};

export function AutocompleteIcon(props: IconProps) {
  const theme = useTheme();
  const iconTint = theme.searchBar.autocompleteList.iconTint;
  const size = props.size ?? "xxl";

  switch (props.kind) {
    case "city":
    case "capital":
    case "town":
    case "village":
      return (
        <Icon size={size}>
          <City title="building" tint={iconTint} />
        </Icon>
      );
    case "airport":
      return (
        <Icon size={size}>
          <Plane title="plane" tint={iconTint} />
        </Icon>
      );
    case "station":
      return (
        <Icon size={size}>
          <Train title="train" tint={iconTint} />
        </Icon>
      );
    case "country":
      return (
        <Icon size={size}>
          <Globe title="globe" tint={iconTint} />
        </Icon>
      );
    default:
      return (
        <Icon size={size}>
          <Destination title="pin" tint={iconTint} />
        </Icon>
      );
  }
}
