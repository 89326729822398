import useCarHireRedirect from "src/utils/hooks/useCarHireRedirect";
import messages from "src/domain/SearchResultsScreen/SearchResultsCarHirePromo/SearchResultsCarHirePromo.messages";
import { useIntl } from "react-intl";
import { KeyFilled } from "src/svg/KeyFilled";
import type { AnalyticsEventCategory } from "src/analytics/analyticsEventTypes";
import { PanePromoButton } from "../PanePromoButton/PanePromoButton";

export default function CarRentalPromo({
  eventCategory = "SearchResults",
}: {
  eventCategory?: AnalyticsEventCategory;
}) {
  const intl = useIntl();
  const handleCarHirePromoClicked = useCarHireRedirect(eventCategory);

  const promoText = intl.formatMessage(messages.header);
  const tagline = intl.formatMessage(messages.tagline);

  return (
    <PanePromoButton
      promoText={promoText}
      tagline={tagline}
      onClick={handleCarHirePromoClicked}
      iconSize="xl"
    >
      <KeyFilled tint="cod" />
    </PanePromoButton>
  );
}
