import { useCallback } from "react";
import { useApiConfig } from "src/api/ApiConfigProvider";
import useUser from "src/utils/hooks/useUser";
import { type onDeleteCallback, deleteTripPlan } from "../util/api";
import { useTripList } from "./useTripList";

export default function useDeleteTrip(onDeleteCallback?: onDeleteCallback) {
  const apiConfig = useApiConfig();
  const { deleteTripListing } = useTripList();
  const { user } = useUser();

  const deleteTrip = useCallback(
    async (tripId: string) => {
      deleteTripListing(tripId);
      return await deleteTripPlan(
        apiConfig,
        tripId,
        onDeleteCallback,
        !!user?.id
      );
    },
    [apiConfig, onDeleteCallback, user?.id, deleteTripListing]
  );

  return deleteTrip;
}
