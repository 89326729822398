import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import { Drawer } from "src/components/Drawer/Drawer";
import { type RefObject, memo } from "react";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { useNavigate, useNavigationType } from "react-router";
import {
  type ReturnStage,
  useTypedLocation,
} from "src/utils/hooks/useTypedLocation";
import { SearchOverrideProvider } from "src/utils/hooks/SearchOverrideProvider";
import styled from "styled-components";
import { Dialog } from "src/components/Dialog/Dialog";
import { SearchResultsLoading } from "src/domain/SearchResultsScreen/SearchResultsLoading/SearchResultsLoading";
import { useScroll } from "src/ScrollContext";
import { useTripSearchResponse } from "../hooks/useTripSearchResponse";
import { TripPlannerTransport } from "../Transport/TripPlannerTransport";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";

type TripPlannerDrawerProps = {
  transportIndex: number;
  returnsFlowLocation?: ReturnStage;
};

export const MemoizedTripPlannerDrawer = memo(
  TripPlannerDrawer,
  (prev, next) => {
    return (
      prev.transportIndex === next.transportIndex &&
      prev.returnsFlowLocation === next.returnsFlowLocation
    );
  }
);

function TripPlannerDrawer({
  transportIndex,
  returnsFlowLocation,
}: TripPlannerDrawerProps) {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const { tripInteraction } = useTripPlannerContext();

  const closeDrawer = () => {
    tripInteraction.setIsInteractionMade(true);
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
        tripScheduleIndex: undefined,
        returnsFlowLocation: "departing",
      },
      "#trips",
      {
        ...location,
      },
      true
    );
  };

  return (
    <>
      {layout === "mobile" ? (
        <MobileTripPlannerDrawer
          transportIndex={transportIndex}
          closeDrawer={closeDrawer}
          returnsFlowLocation={returnsFlowLocation}
        />
      ) : (
        <DesktopTripPlannerDialog
          transportIndex={transportIndex}
          closeDrawer={closeDrawer}
          returnsFlowLocation={returnsFlowLocation}
        />
      )}
    </>
  );
}

type DrawerProps = {
  closeDrawer: () => void;
  transportIndex: number;
  returnsFlowLocation?: ReturnStage;
};

function DesktopTripPlannerDialog({
  transportIndex,
  returnsFlowLocation,
  closeDrawer,
}: DrawerProps) {
  return (
    <StyledDialog
      isOpen={true}
      onBackdropClicked={closeDrawer}
      onClickOutside={closeDrawer}
      variant="small"
    >
      <ModalWrapper>
        <TripPlannerDrawerContent
          Content={
            <TripPlannerTransport
              {...{
                transportIndex,
                returnsFlowLocation,
                context: "tripPlanner",
              }}
            />
          }
        />
      </ModalWrapper>
    </StyledDialog>
  );
}

function MobileTripPlannerDrawer({
  returnsFlowLocation,
  closeDrawer,
  transportIndex,
}: DrawerProps) {
  const { scrollRef } = useScroll();
  const navigationType = useNavigationType();
  const isBrowserForwardBackNavigation = navigationType === "POP";

  return (
    <Drawer
      isOpen={true}
      onBackdropClicked={closeDrawer}
      onCloseClicked={closeDrawer}
      hideCloseButton
      heightPercentage="95"
      greyBackground
      hasPadding={false}
      contentRef={scrollRef as RefObject<HTMLDivElement>}
      disableAnimation={isBrowserForwardBackNavigation}
    >
      <TripPlannerDrawerContent
        Content={
          <TripPlannerTransport
            {...{
              transportIndex,
              returnsFlowLocation,
              context: "tripPlanner",
            }}
          />
        }
      />
    </Drawer>
  );
}

type TripPlannerDrawerContentProps = {
  Content: JSX.Element;
};

function TripPlannerDrawerContent({ Content }: TripPlannerDrawerContentProps) {
  const {
    tripDestination,
    tripOrigin,
    tripSearchResponse,
    isTripSearchResponseLoading,
  } = useTripSearchResponse();
  const { scrollRef } = useScroll();
  const layout = useLayout();

  function overrideScrollRef(node: HTMLDivElement) {
    if (layout === "tablet" && scrollRef) {
      scrollRef.current = node;
    }
  }

  return isTripSearchResponseLoading || !tripSearchResponse ? (
    <SearchResultsLoading />
  ) : (
    <SearchOverrideProvider
      value={{
        origin: tripOrigin,
        destination: tripDestination,
        searchResponse: tripSearchResponse,
      }}
    >
      <ContentContainer ref={overrideScrollRef}>{Content}</ContentContainer>
    </SearchOverrideProvider>
  );
}

const StyledDialog = styled(Dialog)`
  max-width: 738px;
  min-width: 738px;
  overflow: hidden;
`;

const ModalWrapper = styled.div`
  max-height: 90vh;
  position: relative;
  transform: translateY(0);
`;
const ContentContainer = styled.div`
  padding-top: 72px;
  ${desktopLayout} {
    overflow-y: auto;
    // 72px is the height of the header, and it's fixed positioned.
    // So we need to space content down by 72px to avoid it being hidden behind the header.
    height: calc(90vh - 72px);
  }
`;
